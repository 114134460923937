import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  ICharacter,
  ICharacterFellowship,
  ICharacterSkill,
  IFellowship,
  IFellowshipKindEnum,
  IFracture,
} from 'src/graphql/types';
import {
  TLore,
  TSkill,
} from 'src/components/0500_character_builders/dystopia_rising/types';
import loreData from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/LoreGroup/data';
import { skillNames } from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/data';
import Bio from './Bio';
import Professions from './Professions';
import Stats from './Stats';
import Lores from './Lores';
import Fractures from './Fractures';
import CommonSkills from './CommonSkills';
import TicketAndShifts, { TShift, TTicket } from '../TicketAndShifts';
import Fellowships from './Fellowships';
import PlayerNotes from './PlayerNotes';

export type TCharacter =
  | (Pick<
      ICharacter,
      | 'id'
      | 'name'
      | 'body'
      | 'mind'
      | 'resolve'
      | 'infection'
      | 'currentLife'
      | 'lifeCapacity'
      | 'latestLifecycleGainsThisYear'
      | 'faithId'
      | 'lineageId'
      | 'strainId'
      | 'variant'
      | 'loreIds'
      | 'buildUsed'
      | 'buildEarned'
      | 'playerNotes'
    > & {
      fractures: Pick<IFracture, 'id' | 'name' | 'createdAt' | 'removedAt'>[];
      skills: Pick<ICharacterSkill, 'id' | 'level' | 'positions'>[];
      characterFellowships: (Pick<
        ICharacterFellowship,
        'id' | 'rank' | 'removedAt'
      > & {
        fellowship: Pick<IFellowship, 'id' | 'name' | 'kind'>;
      })[];
    })
  | null;
interface IProps {
  character?: TCharacter;
  shifts?: TShift[];
  ticket?: TTicket;
}

const Character: FC<IProps> = ({ character, shifts, ticket }) => {
  const methods = useForm({
    defaultValues: {
      name: '',
      strainId: 0,
      lineageId: 0,
      variant: 'standard',
      faithId: 0,
      lores: [] as TLore[],
      fractures: [] as string[],
      characterFellowships: [] as ICharacterFellowship[],
      stats: {
        body: 0,
        mind: 0,
        resolve: 0,
        infection: 0,
        currentLife: 0,
        lifeCapacity: 0,
      },
      latestLifecycleGainsThisYear: [] as string[],
      skills: [] as TSkill[],
      xpEarned: 0,
      xpUsed: 0,
    },
  });
  const { reset } = methods;

  useEffect(() => {
    reset({
      name: character?.name,
      lineageId: character?.lineageId ?? 0,
      strainId: character?.strainId ?? 0,
      faithId: character?.faithId ?? 0,
      variant: character?.variant ?? 'standard',
      lores: (character?.loreIds ?? [])
        .filter(x => x >= 201)
        .map(x => ({ id: x, name: loreData.find(y => y.id === x)?.name })),
      fractures: (character?.fractures ?? [])
        .filter(x => !x.removedAt)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(x => x.name),
      characterFellowships:
        character?.characterFellowships
          .filter(x => !x.removedAt)
          .sort((a, b) => a.fellowship.name.localeCompare(b.fellowship.name)) ??
        [],
      stats: {
        body: character?.body ?? 0,
        mind: character?.mind ?? 0,
        resolve: character?.resolve ?? 0,
        infection: character?.infection ?? 0,
        currentLife: character?.currentLife ?? 0,
        lifeCapacity: character?.lifeCapacity ?? 0,
      },
      skills: (character?.skills ?? []).map(x => ({
        ...x,
        name: skillNames[x.id] ?? x.id,
        positions: x.positions ?? undefined,
      })),
      latestLifecycleGainsThisYear:
        character?.latestLifecycleGainsThisYear ?? [],
      xpEarned: character?.buildEarned ?? 0,
      xpUsed: character?.buildUsed ?? 0,
    });
  }, [ character, reset ]);

  return (
    <FormProvider {...methods}>
      <div className="flex gap-2 place-items-start">
        <div className="grid gap-2 w-full">
          <Bio />
          <Lores />
          <Fractures />
          <Fellowships type={IFellowshipKindEnum.Society} />
          <Fellowships type={IFellowshipKindEnum.Disease} />
        </div>
        <div className="grid gap-2 w-full">
          <Stats />
          <Professions />
          <CommonSkills category="development" maxRows={14} />
        </div>
        <div className="grid gap-2 w-full">
          <TicketAndShifts shifts={shifts} ticket={ticket} />
          <CommonSkills category="impact" maxRows={26} />
          <PlayerNotes text={character?.playerNotes} />
        </div>
      </div>
    </FormProvider>
  );
};

export default Character;
