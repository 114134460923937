import { FC } from 'react';
import clsx from 'clsx';
import useSkillFinder from 'src/components/0500_character_builders/dystopia_rising/hooks/useSkillFinder';
import useXp from 'src/components/0500_character_builders/dystopia_rising/hooks/useXp';

const Professions: FC = () => {
  const { getProfession, professionsFinder, professionSkillsFinder } =
    useSkillFinder();
  const professions = professionsFinder().sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const { professionsCost, professionSkillsCost } = useXp();

  const professionSkills = professionSkillsFinder({}).map(x => ({
    ...x,
    profession: getProfession(x.name),
  }));

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>
            Professions
            {professionsCost > 0 &&
              professionSkillsCost === 0 &&
              ` (${professionsCost}XP)`}
            {professionsCost > 0 &&
              professionSkillsCost > 0 &&
              ` (${professionsCost} + ${professionSkillsCost}XP)`}
          </th>
        </tr>
      </thead>
      <tbody className="grid-4-1 numeric-last">
        {[ 0, 1, 2 ].map(i => (
          <>
            <tr
              key={`profession-${i}`}
              className={clsx(!professions[i] && 'text-transparent')}
            >
              <td colSpan={2} className="font-bold">
                Profession {i + 1}: {professions[i]?.name ?? '.'}
              </td>
            </tr>
            {professions[i] &&
              professionSkills
                .filter(x => x.profession === professions[i].name)
                .map(x => (
                  <tr key={x.name}>
                    <td>{x.name}</td>
                    <td>{x.level}</td>
                  </tr>
                ))}
          </>
        ))}
      </tbody>
    </table>
  );
};

export default Professions;
