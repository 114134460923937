import { FC } from 'react';
import Table from 'src/components/0100_table';
import usePlayerCorrectiveActions from 'src/hooks/players/usePlayerCorrectiveActions';
import { isBefore, parseISO } from 'date-fns';
import { ICorrectiveAction } from 'src/graphql/types';
import { isEqual } from 'lodash';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerOwnedBranches from 'src/hooks/players/usePlayerOwnedBranches';
import useRootUser from 'src/hooks/players/useRootUser';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import CorrectiveAction from './CorrectiveAction';

type TCorrectiveAction = Pick<ICorrectiveAction, 'id' | 'endsAt'>;

const sort = (a: TCorrectiveAction, b: TCorrectiveAction) => {
  const aDate = parseISO(a.endsAt || '9999-12-31');
  const bDate = parseISO(b.endsAt || '9999-12-31');

  if (isEqual(aDate, bDate)) {
    return b.id - a.id;
  }

  return isBefore(aDate, bDate) ? 1 : -1;
};

const CorrectiveActions: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const { isPermitted: canSeeCorrectiveActions } = usePermission({
    action: 'show_corrective_actions',
    playerId: Number(playerId),
  });
  const {
    rootUser: { id: rootUserId },
  } = useRootUser();
  const { ownedBranches } = usePlayerOwnedBranches({ playerId: rootUserId });

  const { isPermitted: isPrivileged } = usePermission({
    action: 'update_player_data_sensitive',
    playerId: Number(playerId),
  });
  const { isPermitted: isAdmin } = usePermission({
    action: 'destroy_corrective_action',
  });
  const { correctiveActions, fetching } = usePlayerCorrectiveActions({
    playerId: Number(playerId),
  });

  if (!canSeeCorrectiveActions) return null;

  return (
    <Table
      headers={[
        { content: 'Type', className: 'min-w-[128px]' },
        { content: 'Branch', className: 'min-w-[64px]' },
        { content: 'Issued', className: 'min-w-[128px]' },
        { content: 'Expires', className: 'min-w-[128px]' },
        { content: 'Reason' },
        isPrivileged
          ? {
              content: '',
              className: 'text-center',
            }
          : undefined,
      ]}
    >
      {isPrivileged && (
        <CorrectiveAction key="new-corrective-action" canCreateAndUpdate />
      )}
      {correctiveActions
        .sort((a, b) => sort(a, b))
        .map(x => (
          <CorrectiveAction
            key={x.id}
            hasWriteAccess={isPrivileged}
            correctiveAction={x}
            canCreateAndUpdate={
              isAdmin ||
              (isPrivileged &&
                ownedBranches.map(y => y.id).includes(x.branch.id))
            }
            canDelete={isAdmin}
          />
        ))}
    </Table>
  );
};

export default CorrectiveActions;
