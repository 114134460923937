import clsx from 'clsx';
import { FC, useCallback, useRef } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import CMFont from 'src/assets/fonts/Carta Marina Regular.otf';
import BSFont from 'src/assets/fonts/Lexend-VariableFont_wght.ttf';
import BCFont from 'src/assets/fonts/LibreBarcode128-Regular.ttf';
import BGArtisan from 'src/assets/bg-artisan.png';
import BGBenediction from 'src/assets/bg-benediction.png';
import BGCriminal from 'src/assets/bg-criminal.png';
import BGCulinary from 'src/assets/bg-culinary.png';
import BGNecrology from 'src/assets/bg-necrology.png';
import BGHeader from 'src/assets/bg-header.png';
import BGFooter from 'src/assets/bg-footer.png';
import BGRepro from 'src/assets/bg-repro.png';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { format, parseISO } from 'date-fns';
import useItem from 'src/hooks/items/useItem';
import ItemPrintout from './ItemPrintout';
import ItemEditor from './ItemEditor';
import PrintControl from './PrintControl';

interface IProps {
  printoutOnly?: boolean;
}

const ItemView: FC<IProps> = ({ printoutOnly }) => {
  const { _splat: itemId } = useParams({ strict: false });
  const { item } = useItem({ itemId: Number(itemId) });
  const navigate = useNavigate();
  const { print: isPrintout } = useSearch({ strict: false });
  const ref = useRef<HTMLDivElement>(null);
  const { isPermitted: canCreateItem } = usePermission({
    action: 'create_item',
  });

  const handleOnLoad = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div ref={ref} className="overflow-auto lg:max-h-[calc(100vh-64px)]">
      <link rel="preload" href={CMFont} as="font" crossOrigin="anonymous" />
      <link rel="preload" href={BSFont} as="font" crossOrigin="anonymous" />
      <link rel="preload" href={BCFont} as="font" crossOrigin="anonymous" />
      <img src={BGArtisan} className="invisible h-0 w-0" alt="preload" />
      <img src={BGBenediction} className="invisible h-0 w-0" alt="preload" />
      <img src={BGCriminal} className="invisible h-0 w-0" alt="preload" />
      <img src={BGCulinary} className="invisible h-0 w-0" alt="preload" />
      <img src={BGNecrology} className="invisible h-0 w-0" alt="preload" />
      <img src={BGHeader} className="invisible h-0 w-0" alt="preload" />
      <img src={BGFooter} className="invisible h-0 w-0" alt="preload" />
      <img src={BGRepro} className="invisible h-0 w-0" alt="preload" />
      {!printoutOnly && canCreateItem && (
        <div className="flex justify-between border-b border-juno-gray-700 mb-2">
          <button
            type="button"
            className={clsx(
              'w-full p-2 border border-b-0 rounded-t transition-all duration-300',
              !isPrintout
                ? 'text-shadow text-juno-gray-50 dark-box border-juno-gray-700'
                : 'text-juno-gray-700 border-transparent',
            )}
            onClick={() => {
              navigate({ to: '.', search: x => ({ ...x, print: undefined }) });
            }}
          >
            Data Entry
          </button>
          <button
            type="button"
            className={clsx(
              'w-full p-2 border border-b-0 rounded-t transition-all duration-300',
              isPrintout
                ? 'text-shadow text-juno-gray-50 dark-box border-juno-gray-700'
                : 'text-juno-gray-700 border-transparent',
            )}
            onClick={() => {
              navigate({ to: '.', search: x => ({ ...x, print: true }) });
            }}
          >
            Preview
          </button>
        </div>
      )}
      {!canCreateItem || printoutOnly || (canCreateItem && isPrintout) ? (
        <div className="grid gap-2">
          <PrintControl />
          <ItemPrintout />
        </div>
      ) : (
        <ItemEditor onLoad={handleOnLoad} />
      )}
      {item && (
        <div className="text-right opacity-75">
          Last updated: {format(parseISO(item.updatedAt), 'yyyy-MMM-dd')}
        </div>
      )}
    </div>
  );
};

export default ItemView;
