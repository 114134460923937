import { useSearch } from '@tanstack/react-router';
import {
  isValid,
  parseISO,
  startOfMonth,
  addMonths,
  endOfMonth,
  format,
} from 'date-fns';
import { useMemo } from 'react';

interface IProps {
  initStart?: Date;
  initEnd?: Date;
  previousMonthsJump?: number;
  nextMonthsJump?: number;
}

const useCalendarNavigations = ({
  initStart = addMonths(startOfMonth(new Date()), -1),
  initEnd = addMonths(endOfMonth(new Date()), 2),
  previousMonthsJump = 1,
  nextMonthsJump = 1,
}: IProps) => {
  const startDate = useSearch({ strict: false, select: x => x.start });
  const endDate = useSearch({ strict: false, select: x => x.end });
  const normalizedStart =
    startDate && isValid(parseISO(startDate))
      ? startOfMonth(parseISO(startDate))
      : initStart;
  const normalizedEnd =
    endDate && isValid(parseISO(endDate))
      ? endOfMonth(parseISO(endDate))
      : initEnd;
  const previous = useMemo(
    () =>
      format(addMonths(normalizedStart, previousMonthsJump * -1), 'yyyy-MM-dd'),
    [ normalizedStart, previousMonthsJump ],
  );
  const next = useMemo(
    () => format(addMonths(normalizedEnd, nextMonthsJump), 'yyyy-MM-dd'),
    [ nextMonthsJump, normalizedEnd ],
  );

  return {
    previous,
    next,
    start: normalizedStart,
    end: normalizedEnd,
  };
};

export default useCalendarNavigations;
