import {
  useLinkProps,
  useLocation,
  useMatch,
  useParams,
} from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC, useMemo } from 'react';

import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const BranchHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, branchId } = useParams({
    strict: false,
  });
  const { isPermitted } = usePermission({
    action: 'manage_branch',
    branchId: Number(branchId),
  });

  const isBranchSection = pathname.includes('branches');

  const linkParams = {
    organizationSlug: String(organizationSlug),
    branchId: String(branchId),
  };
  const linkToBasics = useLinkProps({
    to: '/$organizationSlug/branches/$branchId/*',
    params: linkParams,
  });
  const linkToLocations = useLinkProps({
    to: '/$organizationSlug/branches/$branchId/locations',
    params: linkParams,
  });
  const linkToAttendance = useLinkProps({
    to: '/$organizationSlug/branches/$branchId/attendance',
    params: linkParams,
  });
  const linkToCorrectiveActions = useLinkProps({
    to: '/$organizationSlug/branches/$branchId/corrective_actions',
    params: linkParams,
  });
  const linkToCaps = useLinkProps({
    to: '/$organizationSlug/branches/$branchId/caps',
    params: linkParams,
  });

  const matchLocations = useMatch({
    from: '/$organizationSlug/branches/$branchId/locations',
    shouldThrow: false,
  });
  const matchAttendance = useMatch({
    from: '/$organizationSlug/branches/$branchId/attendance',
    shouldThrow: false,
  });
  const matchCaps = useMatch({
    from: '/$organizationSlug/branches/$branchId/caps',
    shouldThrow: false,
  });
  const matchCorrectiveActions = useMatch({
    from: '/$organizationSlug/branches/$branchId/corrective_actions',
    shouldThrow: false,
  });

  const section = useMemo(() => {
    if (matchLocations) return 'locations';
    if (matchAttendance) return 'attendance';
    if (matchCaps) return 'caps';
    if (matchCorrectiveActions) return 'corrective_actions';

    return 'basics';
  }, [ matchAttendance, matchCaps, matchCorrectiveActions, matchLocations ]);

  if (!isBranchSection) return null;
  if (!branchId) return null;
  if (!isPermitted) return null;

  return (
    <ResponsiveTabGroup label={startCase(section)}>
      <Tab
        label="Basics"
        to={linkToBasics.href}
        highlightMode="responsive"
        isActive={section === 'basics'}
      />
      <Tab
        label="Locations"
        to={linkToLocations.href}
        highlightMode="responsive"
      />
      <Tab
        label="Attendance"
        to={linkToAttendance.href}
        highlightMode="responsive"
      />
      <Tab label="CAPS" to={linkToCaps.href} highlightMode="responsive" />
      <Tab
        label="Corrective Actions"
        to={linkToCorrectiveActions.href}
        highlightMode="responsive"
      />
    </ResponsiveTabGroup>
  );
};

export default BranchHeader;
