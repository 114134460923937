import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const PlayerHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, playerId } = useParams({ strict: false });
  const { isPermitted: canSeeCorrectiveActions } = usePermission({
    action: 'show_corrective_actions',
    playerId: Number(playerId),
  });

  const linkParams = {
    organizationSlug: String(organizationSlug),
    playerId: String(playerId),
  };
  const linkToBio = useLinkProps({
    to: '/$organizationSlug/players/$playerId/*',
    params: linkParams,
  });
  const linkToAttendance = useLinkProps({
    to: '/$organizationSlug/players/$playerId/attendance',
    params: linkParams,
  });
  const linkToMemberships = useLinkProps({
    to: '/$organizationSlug/players/$playerId/memberships',
    params: linkParams,
  });
  const linkToPerks = useLinkProps({
    to: '/$organizationSlug/players/$playerId/perks',
    params: linkParams,
  });
  const linkToCorrectiveActions = useLinkProps({
    to: '/$organizationSlug/players/$playerId/corrective_actions',
    params: linkParams,
  });
  const linkToReferrals = useLinkProps({
    to: '/$organizationSlug/players/$playerId/referrals',
    params: linkParams,
  });

  const playerSection =
    pathname.match(
      /(bio|attendance|memberships|perks|corrective_actions|referrals)/,
    )?.[1] ?? 'bio';
  const isCharacterSection = pathname.includes('characters');

  if (!playerId) return null;
  if (playerId === 'new') return null;
  if (playerId && isCharacterSection) return null;

  return (
    <ResponsiveTabGroup label={startCase(playerSection)}>
      <Tab
        label="Bio"
        to={linkToBio.href}
        highlightMode="responsive"
        isActive={playerSection === 'bio'}
      />
      <Tab
        label="Attendance"
        to={linkToAttendance.href}
        highlightMode="responsive"
      />
      <Tab
        label="Memberships"
        to={linkToMemberships.href}
        highlightMode="responsive"
      />
      <Tab label="Perks" to={linkToPerks.href} highlightMode="responsive" />
      {canSeeCorrectiveActions && (
        <Tab
          label="Corrective Actions"
          to={linkToCorrectiveActions.href}
          highlightMode="responsive"
        />
      )}
      <Tab
        label="Referrals"
        to={linkToReferrals.href}
        highlightMode="responsive"
      />
    </ResponsiveTabGroup>
  );
};

export default PlayerHeader;
