import { useParams, useSearch } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingBuild from 'src/components/0500_character_builders/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Build: FC = () => {
  const { eventId } = useParams({ strict: false });
  const { player_id: playerId, character_id: characterId } = useSearch({
    strict: false,
  });

  const character = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return (
    <DystopiaRisingBuild
      showLifecycle
      {...character}
      canEdit
      eventId={Number(eventId)}
      playerId={Number(playerId)}
    />
  );
};

export default Build;
