import { IShift, IShiftKindEnum } from 'src/graphql/types';

type TShift = Pick<IShift, 'label' | 'identifier'>;

export const numericalIdentifier = (n: number | null | undefined) => {
  if (!n) return 0;

  return Math.floor(n / 100);
};

export const identifierToDay = (n: number | null | undefined) => {
  switch (numericalIdentifier(n)) {
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    case 7:
      return 'Sun';
    default:
      return '';
  }
};

export const hasDayInLabel = (s: string) =>
  s.match(/(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\s+/i);

const normalize = (s: string) =>
  s
    .replace(
      /(\d+)([:.]\d+)?\s*pm/i,
      (match, digit, rest) => `${parseInt(digit, 10) + 12}${rest ?? ':00'} pm`,
    )
    .replace(/(\d+)([:.]\d+)?\s*am/i, (match, digit: string, rest: string) =>
      digit.trim().length === 1
        ? `0${digit}${rest ?? ':00'} am`
        : `${digit}${rest ?? ':00'} am`,
    )
    .replace(/noon/i, '12 pm')
    .replace(/midnight/i, '0 am');

export const sortFunctor = (a: TShift, b: TShift, kind: IShiftKindEnum) => {
  if (kind === IShiftKindEnum.Special) {
    return (a.label ?? '').localeCompare(b.label ?? '');
  }

  const aLabel = numericalIdentifier(a.identifier) + normalize(a.label ?? '');
  const bLabel = numericalIdentifier(b.identifier) + normalize(b.label ?? '');

  return aLabel.localeCompare(bLabel);
};
