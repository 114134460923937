import { createRootRoute } from '@tanstack/react-router';
import { FC, useMemo, useState } from 'react';
import RootUserContext, {
  initialState,
  TRootUserContext,
} from 'src/contexts/RootUser';
import graphqlClient from 'src/graphqlClient';
import AuthenticationLayer from 'src/pages/AuthenticationLayer';
import GridLayout from 'src/pages/GridLayout';
import MaintenanceMode from 'src/pages/MaintenanceMode';
import RedirectLayer from 'src/pages/RedirectLayer';
import { Provider } from 'urql';

const RootComponent: FC = () => {
  const [ rootUser, setRootUser ] =
    useState<TRootUserContext['rootUser']>(initialState);

  const rootUserContextValue = useMemo(
    () => ({ rootUser, setRootUser }),
    [ rootUser ],
  );

  return (
    <Provider value={graphqlClient}>
      <RootUserContext.Provider value={rootUserContextValue}>
        {import.meta.env.VITE_MAINTENANCE_MODE === 'true' ? (
          <MaintenanceMode />
        ) : (
          <>
            <RedirectLayer />
            <AuthenticationLayer>
              <GridLayout />
            </AuthenticationLayer>
          </>
        )}
      </RootUserContext.Provider>
    </Provider>
  );
};

export const Route = createRootRoute({
  component: () => <RootComponent />,
});
