import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Attendee, { TAttendee } from './Attendee';

export type TOnFilterChange = {
  length: number;
  hasSearchQuery: boolean;
  identifier?: string;
};

interface IProps {
  attendees: TAttendee[];
  isExpanded: boolean;
  extraXpCost: number;
  onFilterChange?: ({ length, hasSearchQuery }: TOnFilterChange) => void;
}

const TicketAttendees: FC<IProps> = ({
  attendees,
  isExpanded,
  extraXpCost,
  onFilterChange,
}) => {
  const { watch } = useFormContext();
  const { searchQuery } = watch();
  const hasSearchQuery = useMemo(
    () => searchQuery.trim().length > 0,
    [ searchQuery ],
  );
  const filteredAttendees = useMemo(
    () =>
      hasSearchQuery
        ? attendees.filter(
            atd =>
              atd.user.fullName.match(new RegExp(searchQuery, 'i')) ||
              String(atd.user.id).match(new RegExp(searchQuery, 'i')) ||
              atd.attendeeCharacters.some(x =>
                x.character.name.match(new RegExp(searchQuery, 'i')),
              ),
          )
        : attendees,
    [ attendees, hasSearchQuery, searchQuery ],
  );

  useEffect(() => {
    onFilterChange?.({ length: filteredAttendees.length, hasSearchQuery });
  }, [ filteredAttendees.length, hasSearchQuery, onFilterChange ]);

  return (
    <>
      {filteredAttendees
        .sort((a, b) => a.user.id - b.user.id)
        .map(x => (
          <Attendee
            key={x.id}
            attendee={x}
            isExpanded={isExpanded}
            extraXpCost={extraXpCost}
          />
        ))}
      {filteredAttendees.length > 0 && isExpanded && (
        <tr>
          <td className="h-2" />
        </tr>
      )}
    </>
  );
};

export default TicketAttendees;
