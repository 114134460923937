import { FC, useCallback } from 'react';
import useBranch from 'src/hooks/organizations/branches/useBranch';
import AllLocationsSelect from 'src/components/0400_all_locations_select';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import {
  IAssignBranchLocationMutation,
  IAssignBranchLocationMutationVariables,
} from 'src/graphql/mutations/branches.graphql.types';
import { assignBranchLocation } from 'src/graphql/mutations/branches.graphql';
import usePermission from 'src/hooks/permissions/usePermissions';
import Location from './Location';

const Locations: FC = () => {
  const { data } = useBranch({});
  const { isPermitted } = usePermission({
    action: 'manage_branch',
    branchId: data?.branch?.id,
  });
  const methods = useForm({ defaultValues: { searchBranchId: 0 }});
  const { register } = methods;
  const [ , assign ] = useMutation<
    IAssignBranchLocationMutation,
    IAssignBranchLocationMutationVariables
  >(assignBranchLocation);
  const handleAssign = useCallback(
    ({ locationId }: { locationId: number }) => {
      if (data?.branch?.id) {
        assign({
          branchId: data?.branch?.id,
          locationId,
        });
      }
    },
    [ assign, data?.branch?.id ],
  );

  if (!isPermitted) return null;
  if (!data?.branch?.locations) return null;

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center">
        <div className="grid gap-4 max-w-[480px]">
          {data.branch.locations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => (
              <Location key={x.id} {...x} branchId={Number(data.branch?.id)} />
            ))}
          <div className="border border-l-4 border-juno-gray-700 transition-all duration-300 hover:border-juno-gray-200">
            <div className="dark-box p-2">Assign Existing Location</div>
            <div className="w-full p-2">
              <AllLocationsSelect
                width="w-full"
                existingIds={data.branch.locations.map(x => x.id)}
                selectedValue={-1}
                {...register('searchBranchId')}
                onSelectionChange={x => handleAssign({ locationId: Number(x) })}
              />
            </div>
          </div>
          <Location id={null} branchId={data.branch.id} />
        </div>
      </div>
    </FormProvider>
  );
};

export default Locations;
