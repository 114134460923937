import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Inventories from 'src/components/0500_logistics/dystopia_rising/Inventories';
import useCharacterInventories from 'src/hooks/characters/useCharacterInventories';

const Inventory: FC = () => {
  const { playerId, characterId } = useParams({ strict: false });
  const { user, character, inventories } = useCharacterInventories({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return (
    <div className="flex justify-center w-full">
      <div className="max-w-[640px]">
        <Inventories
          user={user}
          character={character}
          inventories={inventories}
        />
      </div>
    </div>
  );
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId/inventory',
)({
  component: Inventory,
});
