import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';
import Title from 'src/components/0100_title';
import Card from 'src/components/0200_card';
import useAuth from 'src/hooks/auths/useAuth';
import useRootUser from 'src/hooks/players/useRootUser';

const UserWelcome: FC = () => {
  const { handleLogout } = useAuth();
  const { rootUser } = useRootUser();

  return (
    <div>
      <Title title="Select Your Organization" />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 justify-items-center gap-4">
        {rootUser.organizations
          .sort((a, b) => a.id - b.id)
          .map(x => (
            <Card
              key={x.id}
              title={x.name}
              shorthand={x.shorthand}
              to={`/${x.slug}/players/${rootUser.id}/characters`}
            />
          ))}
        <Card
          key="-1"
          title="Sign Out"
          faIcon={faPowerOff}
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default UserWelcome;
