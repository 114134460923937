import { getCharacterRetirementPaths } from 'src/graphql/queries/characters.graphql';
import {
  IGetCharacterRetirementPathsQuery,
  IGetCharacterRetirementPathsQueryVariables,
} from 'src/graphql/queries/characters.graphql.types';
import { useQuery } from 'urql';

const useCharacterRetirementPaths = ({
  playerId,
  characterId,
  enabled,
}: {
  playerId: number;
  characterId: number;
  enabled: boolean;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetCharacterRetirementPathsQuery,
    IGetCharacterRetirementPathsQueryVariables
  >({
    query: getCharacterRetirementPaths,
    variables: { playerId, characterId },
    requestPolicy: 'cache-and-network',
    pause: !(playerId > 0 && characterId > 0 && enabled),
  });

  return {
    data,
    error,
    retirementPaths: data?.user?.character?.retirementPaths,
    alive: data?.user?.character?.alive,
    fetching,
    stale,
    refetch,
  };
};

export default useCharacterRetirementPaths;
