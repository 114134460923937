import { startCase } from 'lodash';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import ResponseBox from 'src/components/0100_response_box';
import { createFellowship } from 'src/graphql/mutations/fellowships.graphql';
import {
  ICreateFellowshipMutation,
  ICreateFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import { IFellowshipKindEnum } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  type: IFellowshipKindEnum;
}

const NewFellowship: FC<IProps> = ({ type }) => {
  const { buttonState } = useButtonStates();
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);
  const {
    register,
    reset,
    setFocus,
    watch,
    formState: { isValid },
  } = useForm({ defaultValues: { name: '' }});
  const { name } = watch();
  const [ createResult, create ] = useMutation<
    ICreateFellowshipMutation,
    ICreateFellowshipMutationVariables
  >(createFellowship);

  const handleCreate = useCallback(() => {
    setIsSuccessful(false);
    setError(null);

    create({ name, type }).then(res => {
      if (res.data?.createFellowship?.organization) {
        reset({ name: '' });
        setFocus('name');
        setIsSuccessful(true);
        setTimeout(() => setIsSuccessful(false), 3000);
      }

      if (res.data?.createFellowship?.error) {
        setError(res.data.createFellowship.error);
        setFocus('name');
      }
    });
  }, [ create, name, reset, setFocus, type ]);

  return (
    <div>
      <div className="flex justify-between gap-2">
        <Input
          fullWidth
          placeholder={`Create New ${startCase(type)}...`}
          {...register('name', { required: true, minLength: 3 })}
        />
        <Button
          defaultLabel="Create"
          className="min-w-[128px]"
          state={buttonState({
            isValid,
            isDirty: !isSuccessful,
            isFetching: createResult.fetching,
            isSuccessful,
          })}
          stateLabel={{ loading: 'Creating...', success: 'Created!' }}
          onClick={handleCreate}
        />
      </div>
      {error && <ResponseBox type="error">{error}</ResponseBox>}
    </div>
  );
};

export default NewFellowship;
