import {
  faArrowRight,
  faCalendar,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import Loading from 'src/components/0100_loading';
import PlayerBadges from 'src/components/0200_player_badges';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';
import usePreferences from 'src/hooks/preferences/usePreferences';
import ProgressBar from 'src/components/0100_progress_bar';
import { ICharacterStatusEnum } from 'src/graphql/types';
import { format, isBefore, parseISO } from 'date-fns';
import SidebarLink from 'src/components/0100_sidebar_link';
import { Link, useParams } from '@tanstack/react-router';
import useTargetCharacterSection from 'src/hooks/characters/useTargetCharacterSection';
import useTargetPlayerSection from 'src/hooks/players/useTargetPlayerSection';
import Membership from './Membership';

interface IProps {
  playerId: number;
  dismissable?: boolean;
  isOtherPlayer?: boolean;
}

const PlayerSidebar: FC<IProps> = ({
  playerId,
  dismissable,
  isOtherPlayer,
}) => {
  const {
    organizationSlug,
    playerId: playerIdInURL,
    characterId: characterIdInURL,
  } = useParams({ strict: false });

  const { data, fetching, stale } = usePlayerWithCharactersInOrganization({
    playerId,
  });
  const [ isCharactersExpanded, setIsCharactersExpanded ] = useState(true);
  const { setInspectedPlayerId } = usePreferences();
  const { targetCharacterSection } = useTargetCharacterSection();
  const { targetPlayerSection } = useTargetPlayerSection();

  useEffect(() => {
    if (playerId === Number(playerIdInURL) && characterIdInURL) {
      setIsCharactersExpanded(true);
    }
  }, [ playerIdInURL, characterIdInURL, playerId ]);

  if (fetching) return <Loading className="p-2" />;
  if (!data || !data.user) return null;

  const { user } = data;
  const {
    id,
    emailAddress,
    fullName,
    userOrganization,
    characters,
    lastMembership,
  } = user;
  const highlightPlayerPanel = Number(playerIdInURL) === playerId;

  return (
    <div
      className={clsx(isOtherPlayer ? 'green-box-angled' : 'gray-box-angled')}
    >
      <div
        className={clsx(
          'border-l-4 hover:border-juno-gray-200 transition-all duration-300 overflow-x-hidden',
          highlightPlayerPanel
            ? 'border-juno-gray-200'
            : 'border-transparent brightness-75',
        )}
      >
        <div className="flex justify-between">
          <Link
            className={clsx(
              'block px-2 py-1 text-left w-full',
              stale && 'blur-xs',
            )}
            to={targetPlayerSection}
            params={{
              organizationSlug: String(organizationSlug),
              playerId: String(playerId),
            }}
          >
            <div className="uppercase text-xs text-juno-gray-200">
              {isOtherPlayer ? 'Account Lookup' : 'My Player Account'}
            </div>
            <div className="font-semibold">
              {`#${id}`}
              <PlayerBadges withBranch userOrganization={userOrganization} />
            </div>
            <div>{fullName}</div>
            <div>{emailAddress}</div>
            <Membership membership={lastMembership} />
          </Link>
          <div className="w-0">
            {dismissable && (
              <button type="button" onClick={() => setInspectedPlayerId(null)}>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="absolute text-2xl -mt-4 -ml-8"
                />
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'border-l-4 hover:border-juno-gray-200 transition-all duration-300',
          highlightPlayerPanel ? 'border-juno-gray-200' : 'border-transparent',
        )}
      >
        <button
          type="button"
          className="flex justify-between w-full px-2 py-1 text-left font-bold"
          onClick={() => setIsCharactersExpanded(x => !x)}
        >
          <div>Characters</div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(
              'px-2 transition-transform duration-300',
              isCharactersExpanded && '-scale-y-100',
            )}
          />
        </button>
        <Link
          className={clsx(
            'border-l-4 flex justify-end items-center gap-2 pr-4 transition-all duration-300 ease-in-out hover:border-juno-gray-200 hover:text-shadow overflow-hidden',
            isCharactersExpanded ? 'max-h-32 opacity-100' : 'max-h-0 opacity-0',
          )}
          activeProps={{
            className: 'border-juno-gray-200',
          }}
          inactiveProps={{
            className: 'border-transparent',
          }}
          to="/$organizationSlug/players/$playerId/characters"
          params={{
            organizationSlug: String(organizationSlug),
            playerId: String(playerId),
          }}
        >
          <div className="flex w-full justify-between items-center gap-2">
            <div className="w-full border-t border-juno-gray-700 ml-1 brightness-200" />
            <div className="flex gap-2 items-center">
              Manage
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </div>
        </Link>
        {characters
          .sort((a, b) => {
            if (a.status === 'active' && b.status === 'active') {
              if (a.buildEarned === b.buildEarned) {
                return a.id - b.id;
              }
              return b.buildEarned - a.buildEarned;
            }

            if (a.status === 'active') return -1;
            return 1;
          })
          .map(character => (
            <div
              key={character.id}
              className={clsx(
                'border-l-4 transition-all duration-300 ease-in-out hover:border-juno-gray-200 hover:text-shadow overflow-hidden',
                isCharactersExpanded ? 'max-h-32' : 'max-h-0',
                character.id === Number(characterIdInURL)
                  ? 'border-juno-gray-200 text-shadow'
                  : 'border-transparent brightness-75',
                stale && 'blur-xs',
              )}
            >
              <Link
                className="block px-2 py-1 text-left w-full"
                to={targetCharacterSection}
                params={{
                  organizationSlug: String(organizationSlug),
                  playerId: String(playerId),
                  characterId: String(character.id),
                }}
              >
                <div>{character.name}</div>
                <div className="grid gap-1">
                  <div className="flex justify-between items-center">
                    <div className="text-sm">{`XP: ${character.buildUsed}/${character.buildEarned}`}</div>
                    {character.status === ICharacterStatusEnum.Active && (
                      <div className="cyan-box text-xs uppercase px-1 py-0.5 rounded-xs -mt-1">
                        Active
                      </div>
                    )}
                    {character.status === ICharacterStatusEnum.Experimental && (
                      <div className="purple-box text-xs uppercase px-1 py-0.5 rounded-xs -mt-1">
                        Experimental
                      </div>
                    )}
                  </div>
                  {character.buildEarned > 0 && (
                    <ProgressBar
                      animate
                      limit={character.buildEarned}
                      nominal={character.buildUsed}
                      highlightClassName="border-juno-cyan-200 brightness-200"
                      mutedClassName="border-juno-gray-700"
                    />
                  )}
                </div>
              </Link>
            </div>
          ))}
      </div>
      {user.upcomingEvents.length > 0 && (
        <div
          className={clsx(
            'border-l-4 gray-box-angled',
            highlightPlayerPanel
              ? 'border-juno-gray-200'
              : 'border-transparent',
          )}
        >
          <div className="px-2 py-1 font-bold flex items-center gap-2">
            <FontAwesomeIcon icon={faCalendar} className="fa-fw -rotate-12" />
            Upcoming Events
          </div>
          <div className="grid">
            {user.upcomingEvents
              .sort((a, b) =>
                isBefore(parseISO(a.event.startsAt), parseISO(b.event.startsAt))
                  ? -1
                  : 1,
              )
              .map(x => (
                <SidebarLink
                  key={x.id}
                  to="/$organizationSlug/events/$eventId/*"
                  params={{
                    organizationSlug: String(organizationSlug),
                    eventId: String(x.event.id),
                  }}
                  search={{ player_id: user.id }}
                >
                  <div>{x.event.name}</div>
                  <div>
                    {format(parseISO(x.event.startsAt), 'MMM d, HH:mm')}
                  </div>
                  <div>{x.event.location.name}</div>
                </SidebarLink>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PlayerSidebar;
