import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';
import usePermission from 'src/hooks/permissions/usePermissions';

const Printout: FC = () => {
  const { characterId, playerId } = useParams({ strict: false });
  const { player, character } = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });
  const { isPermitted: canPrint } = usePermission({
    action: 'update_character_data_sensitive',
    characterId: Number(character?.id),
  });

  if (!canPrint) return null;

  return (
    <div className="printout-container">
      <DystopiaRisingPrintout character={character} player={player} />
    </div>
  );
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId/printout',
)({
  component: Printout,
});
