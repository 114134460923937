import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMonths, isBefore, parseISO } from 'date-fns';
import { FC, useState } from 'react';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import AugmentedInput from 'src/components/0200_augmented_input';
import DatePicker from 'src/components/0200_datepicker';
import useOrganizationMemberships from 'src/hooks/organizations/useOrganizationMemberships';
import usePermission from 'src/hooks/permissions/usePermissions';

const headerRow = `${[
  'ID',
  'Purchase Date',
  'Granting Branch',
  'Grantor ID',
  'Grantor Name',
  'Recipient ID',
  'Recipient Name',
  'Recipient Role',
].join(',')}\n`;

const Memberships: FC = () => {
  const [ anchorDate, setAnchorDate ] = useState(addMonths(new Date(), -1));
  const { memberships, fetching, stale } = useOrganizationMemberships({
    on: anchorDate,
  });
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });

  if (!canManageOrganization) return null;

  return (
    <div>
      <Title title="Memberships" />
      <AugmentedInput title="Accounting Cycle">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faChevronLeft}
            onClick={() => setAnchorDate(x => addMonths(x, -1))}
          />

          <DatePicker
            complex
            showMonthYearPicker
            maxDate={new Date()}
            date={anchorDate}
            dateFormat="yyyy MMM"
            onUpdate={x => x && setAnchorDate(x)}
          />
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faChevronRight}
            onClick={() => setAnchorDate(x => addMonths(x, 1))}
          />
        </div>
      </AugmentedInput>
      {fetching || stale ? (
        <Loading />
      ) : (
        <div className="overflow-auto max-h-[67vh] max-w-full text-juno-gray-200 text-xs select-all">
          <pre>
            {headerRow +
              memberships
                .sort((a, b) => {
                  const branchA = a.grantingBranch?.shorthand || '';
                  const branchB = b.grantingBranch?.shorthand || '';

                  if (branchA === branchB) {
                    return isBefore(
                      parseISO(a.createdAt),
                      parseISO(b.createdAt),
                    )
                      ? -1
                      : 1;
                  }

                  return branchA.localeCompare(branchB);
                })
                .map(membership =>
                  [
                    membership.id,
                    membership.createdAt,
                    membership.grantingBranch?.shorthand,
                    membership.grantor?.id,
                    membership.grantor?.fullName,
                    membership.user.id,
                    membership.user.fullName,
                    membership.user.userOrganization?.role,
                  ].join(','),
                )
                .join('\n')}
          </pre>
        </div>
      )}
    </div>
  );
};

export default Memberships;
