import { useQuery } from 'urql';
import {
  IGetPlayerMembershipsQuery,
  IGetPlayerMembershipsQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { getPlayerMemberships } from 'src/graphql/queries/players.graphql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerMemberships = ({ playerId }: { playerId: number }) => {
  const { isRootUserValidated } = useAuth();

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerMembershipsQuery,
    IGetPlayerMembershipsQueryVariables
  >({
    query: getPlayerMemberships,
    variables: { playerId },
    requestPolicy: 'network-only',
    pause: !isRootUserValidated && Number(playerId || 0) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching: fetching || stale,
    refetch,
    memberships: data?.user?.memberships || [],
  };
};

export default usePlayerMemberships;
