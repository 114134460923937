import { useDebouncedValue } from 'rooks';
import { getItems } from 'src/graphql/queries/items.graphql';
import {
  IGetItemsQuery,
  IGetItemsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { IItemKindEnum } from 'src/graphql/types';
import { useQuery } from 'urql';
import useAuth from '../auths/useAuth';

const useItems = ({
  keyword,
  kind,
}: {
  keyword: string;
  kind?: IItemKindEnum;
}) => {
  const { isRootUserValidated } = useAuth();
  const [ debouncedKeyword ] = useDebouncedValue(keyword, 100);

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetItemsQuery,
    IGetItemsQueryVariables
  >({
    query: getItems,
    variables: {
      keyword: debouncedKeyword,
      kind,
    },
    requestPolicy: 'cache-and-network',
    pause: !isRootUserValidated,
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    items: data?.organization.items ?? [],
  };
};

export default useItems;
