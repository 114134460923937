import { getOrganizationArmorUpgradeComponents } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationArmorUpgradeComponentsQuery,
  IGetOrganizationArmorUpgradeComponentsQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { useQuery } from 'urql';

const useOrganizationArmorUpgradeComponents = () => {
  const [{ data, error, fetching, stale }] = useQuery<
    IGetOrganizationArmorUpgradeComponentsQuery,
    IGetOrganizationArmorUpgradeComponentsQueryVariables
  >({
    query: getOrganizationArmorUpgradeComponents,
    requestPolicy: 'cache-and-network',
  });

  return {
    error,
    fetching,
    stale,
    data,
    components: data?.organization.armorUpgradeComponents ?? [],
  };
};

export default useOrganizationArmorUpgradeComponents;
