import { useCallback, useState } from 'react';
import { TTransactableItem } from '../LineItem';

const useItemsToBranch = () => {
  const [ itemsToBranch, setItemsToBranch ] = useState<TTransactableItem[]>([]);

  const addItemToBranch = useCallback(
    ({
      itemId,
      itemName,
      itemKind,
      registeredItemId,
      registeredItemDescription,
      expiresAt,
      stack,
      limit,
      nonce,
    }: TTransactableItem) => {
      const matching = itemsToBranch.find(x => x.nonce === nonce);

      if (!matching) {
        setItemsToBranch(x => [
          ...x,
          {
            itemId,
            itemName,
            itemKind,
            registeredItemId,
            registeredItemDescription,
            expiresAt,
            stack,
            limit,
            nonce,
          },
        ]);
      }
    },
    [ itemsToBranch ],
  );

  const updateItemToBranch = useCallback(
    ({ nonce, stack }: { nonce: string; stack: number }) => {
      setItemsToBranch(prevState => {
        const matchingEntry = prevState.find(x => x.nonce === nonce);

        if (matchingEntry) {
          return prevState
            .filter(x => x.nonce !== nonce)
            .concat({ ...matchingEntry, stack });
        }

        return prevState;
      });
    },
    [],
  );

  const removeItemToBranch = useCallback((nonce: string) => {
    setItemsToBranch(x => x.filter(y => y.nonce !== nonce));
  }, []);

  const resetItemsToBranch = useCallback(() => {
    setItemsToBranch([]);
  }, []);

  return {
    itemsToBranch,
    addItemToBranch,
    updateItemToBranch,
    removeItemToBranch,
    resetItemsToBranch,
  };
};

export default useItemsToBranch;
