import { getPlayerWithCharactersInOrganization } from 'src/graphql/queries/players.graphql';
import {
  IGetPlayerWithCharactersInOrganizationQuery,
  IGetPlayerWithCharactersInOrganizationQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerWithCharactersInOrganization = ({
  playerId,
}: {
  playerId: number;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerWithCharactersInOrganizationQuery,
    IGetPlayerWithCharactersInOrganizationQueryVariables
  >({
    query: getPlayerWithCharactersInOrganization,
    variables: { playerId },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    player: data?.user,
    characters: data?.user?.characters || [],
  };
};

export default usePlayerWithCharactersInOrganization;
