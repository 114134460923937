import { FC, useState } from 'react';
import Button from 'src/components/0100_button';
import { createItemReproduction } from 'src/graphql/mutations/items.graphql';
import {
  ICreateItemReproductionMutation,
  ICreateItemReproductionMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  blueprintId: number;
}

const NewItemReproduction: FC<IProps> = ({ blueprintId }) => {
  const { buttonState } = useButtonStates();
  const [ , setError ] = useState<string | null>(null);
  const [ createResult, create ] = useMutation<
    ICreateItemReproductionMutation,
    ICreateItemReproductionMutationVariables
  >(createItemReproduction);

  const handleClick = () => {
    setError(null);
    create({ itemId: blueprintId }).then(res => {
      if (res.data?.createItemReproduction?.error) {
        setError(res.data.createItemReproduction.error);
      }
    });
  };

  return (
    <Button
      className="w-full"
      defaultLabel="Create New Reproduction Procedure"
      state={buttonState({
        isFetching: createResult.fetching,
        isDirty: true,
        isValid: !createResult.fetching,
      })}
      stateLabel={{ loading: 'Creating Reproduction Procedure...' }}
      onClick={handleClick}
    />
  );
};

export default NewItemReproduction;
