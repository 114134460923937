import { useQuery } from 'urql';
import {
  IGetPlayerOwnedBranchesQuery,
  IGetPlayerOwnedBranchesQueryVariables,
} from 'src/graphql/queries/players.graphql.types';
import { getPlayerOwnedBranches } from 'src/graphql/queries/players.graphql';
import { useParams } from '@tanstack/react-router';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

const usePlayerOwnedBranches = ({
  playerId,
  pause = false,
}: {
  playerId: number;
  pause?: boolean;
}) => {
  const { isRootUserValidated } = useAuth();
  const { organizationSlug } = useParams({ strict: false });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetPlayerOwnedBranchesQuery,
    IGetPlayerOwnedBranchesQueryVariables
  >({
    query: getPlayerOwnedBranches,
    variables: { playerId },
    requestPolicy: 'cache-and-network',
    pause:
      pause ||
      (!isRootUserValidated && !organizationSlug && Number(playerId || 0) <= 0),
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    ownedBranches: data?.user?.ownedBranches || [],
  };
};

export default usePlayerOwnedBranches;
