import { startCase } from 'lodash';
import { FC, forwardRef } from 'react';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

const options = [
  'refusal_of_service',
  'suspension',
  'violation',
  'warning',
].map(x => ({ value: x, label: startCase(x) }));

interface IProps extends IBaseSelectProps {}

const CorrectiveActionTypeSelect: FC<IProps> = forwardRef(
  ({ ...props }, ref) => <BaseSelect options={options} {...props} />,
);

export default CorrectiveActionTypeSelect;
