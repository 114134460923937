import { FC } from 'react';

import useCharacter from 'src/hooks/characters/useCharacter';
import DystopiaRisingFellowships from 'src/components/0500_character_fellowships/dystopia_rising';
import DystopiaRisingFractures from 'src/components/0500_character_fractures/dystopia_rising';
import DystopiaRisingNotes from 'src/components/0500_character_notes/dystopia_rising';
import { IFellowshipKindEnum } from 'src/graphql/types';
import { useSearch } from '@tanstack/react-router';

const Journal: FC = () => {
  const { player_id: playerId, character_id: characterId } = useSearch({
    strict: false,
  });

  const character = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return (
    <>
      <div className="grid lg:grid-cols-3 gap-8">
        <DystopiaRisingFractures {...character} />
        <DystopiaRisingFellowships
          {...character}
          type={IFellowshipKindEnum.Society}
          onFellowshipAssignment={() => character.refetch()}
        />
        <DystopiaRisingFellowships
          {...character}
          type={IFellowshipKindEnum.Disease}
          onFellowshipAssignment={() => character.refetch()}
        />
      </div>
      <DystopiaRisingNotes {...character} />
    </>
  );
};

export default Journal;
