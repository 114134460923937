import clsx from 'clsx';
import {
  differenceInDays,
  differenceInHours,
  format,
  formatDistanceToNow,
  isBefore,
} from 'date-fns';
import { FC, useMemo } from 'react';
import ProgressBar from '../0100_progress_bar';

type TPair = {
  startsAt: Date;
  endsAt: Date;
};

interface IProps extends TPair {}

const CalendarSquare: FC<IProps> = ({ startsAt, endsAt }) => {
  const gameDuration = differenceInHours(endsAt, startsAt);
  const elapsed = differenceInHours(new Date(), startsAt);
  const gameProgress = useMemo(() => {
    const now = new Date();

    if (isBefore(startsAt, now) && isBefore(now, endsAt)) {
      return 'inProgress';
    }

    if (isBefore(endsAt, now)) {
      return 'concluded';
    }

    return 'upcoming';
  }, [ startsAt, endsAt ]);

  const dateDistance = useMemo(() => {
    switch (gameProgress) {
      case 'inProgress':
        return 'In Progress';
      case 'concluded':
        return 'Concluded';
      default:
      // no-op
    }

    const dayDiff = differenceInDays(startsAt, new Date());
    if (dayDiff > 30) return 'Upcoming';

    return formatDistanceToNow(startsAt, { addSuffix: true });
  }, [ gameProgress, startsAt ]);

  return (
    <div className="grid justify-items-end -mt-[6px]">
      <div
        className={clsx(
          'text-xs uppercase text-right',
          gameProgress === 'concluded' && 'text-juno-gray-200 opacity-75',
          gameProgress === 'upcoming' && 'text-juno-cyan-200 brightness-150',
          gameProgress === 'inProgress' &&
            'text-juno-orange-200 brightness-125',
        )}
      >
        {dateDistance}
        {gameProgress === 'inProgress' && (
          <div className=" pb-1">
            <ProgressBar
              animate
              limit={gameDuration}
              nominal={elapsed}
              highlightClassName="border-juno-orange-400"
              mutedClassName="border-juno-gray-700"
            />
          </div>
        )}
      </div>
      <div
        className={clsx(
          'w-16 border text-center',
          gameProgress === 'concluded' && 'border-juno-gray-700',
          gameProgress === 'upcoming' && 'border-juno-cyan-200',
          gameProgress === 'inProgress' && 'border-juno-orange-400',
        )}
      >
        <div
          className={clsx(
            'border-b font-bold',
            gameProgress === 'concluded' &&
              'border-b-juno-gray-700 bg-juno-gray-700 text-juno-gray-200',
            gameProgress === 'upcoming' &&
              'border-b-juno-cyan-200 bg-juno-cyan-400 text-juno-gray-50',
            gameProgress === 'inProgress' &&
              'border-b-juno-orange-200 bg-juno-orange-200 text-juno-gray-50',
          )}
        >
          {format(startsAt, 'MMM')}
        </div>
        <div
          className={clsx(
            'text-3xl pt-1',
            gameProgress === 'concluded' && 'text-juno-gray-700',
            gameProgress === 'upcoming' && 'text-juno-cyan-200 brightness-150',
            gameProgress === 'inProgress' &&
              'text-juno-orange-200 brightness-125',
          )}
        >
          {format(startsAt, 'd')}
        </div>
        <div
          className={clsx(
            'uppercase text-sm pb-1 font-bold',
            gameProgress === 'concluded' && 'text-juno-gray-700',
            gameProgress === 'upcoming' && 'text-juno-cyan-200 brightness-150',
            gameProgress === 'inProgress' &&
              'text-juno-orange-200 brightness-125',
          )}
        >
          {format(startsAt, 'EEE')}
        </div>
      </div>
    </div>
  );
};

export default CalendarSquare;
