import { createFileRoute } from '@tanstack/react-router';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import Event from 'src/pages/Event';

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/attendance',
)({
  component: () => (
    <Event
      initStart={addMonths(startOfMonth(new Date()), -11)}
      initEnd={addMonths(endOfMonth(new Date()), 1)}
      previousMonthsJump={12}
    />
  ),
});
