import { faCheck, faCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import { IItemKindEnum, IItemLifetimeUnitEnum } from 'src/graphql/types';
import DatePicker from 'src/components/0200_datepicker';
import { addDays, addMonths, addYears, parseISO } from 'date-fns';
import Slider from 'rc-slider';
import clsx from 'clsx';

export type TTransactableItem = {
  itemId: number;
  itemName: string;
  itemKind: IItemKindEnum;
  registeredItemId?: number;
  registeredItemDescription?: string | null;
  stack?: number;
  craftingStack?: number;
  description?: string;
  expiresAt?: string;
  itemLifetimeAmount?: number;
  itemLifetimeUnit?: IItemLifetimeUnitEnum;
  isCrafting?: boolean;
  limit?: number;
  nonce: string;
  options?: TTransactableItem[];
  selectedOptionItemId?: number;
  armorUpgradePoint?: number;
};

interface IProps extends TTransactableItem {
  onStackChange: (stack: number) => void;
  onDescriptionChange?: (description: string | null) => void;
  onExpirationChange?: (date: string | null) => void;
  onSetArmorUpgrade?: (point: number) => void;
  onSelectOutputOption?: (itemId: number | null) => void;
  onRemove: () => void;
}

const LineItem: FC<IProps> = ({
  itemName,
  itemKind,
  stack,
  craftingStack,
  expiresAt,
  itemLifetimeUnit,
  itemLifetimeAmount,
  isCrafting,
  options,
  selectedOptionItemId,
  armorUpgradePoint = 0,
  onStackChange,
  onDescriptionChange,
  onExpirationChange,
  onSetArmorUpgrade,
  onSelectOutputOption,
  onRemove,
}) => {
  const { register, watch } = useForm({
    defaultValues: { description: '' },
  });
  const { description } = watch();
  const [ isUnique, setIsUnique ] = useState(false);
  const [ isCustomExpiration, setIsCustomExpiration ] = useState(false);

  const syncPersonalization = useCallback(() => {
    if (isUnique) {
      onDescriptionChange?.(description);
    }
  }, [ description, isUnique, onDescriptionChange ]);

  const defaultExpiration = useMemo(() => {
    switch (itemLifetimeUnit) {
      case IItemLifetimeUnitEnum.Month:
        return addMonths(new Date(), itemLifetimeAmount ?? 1);
      default:
        return addDays(new Date(), itemLifetimeAmount ?? 1);
    }
  }, [ itemLifetimeUnit, itemLifetimeAmount ]);

  const outputOptionItemIds = useMemo(
    () =>
      options && options.length > 0 ? options.map(x => x.itemId).sort() : [],
    [ options ],
  );
  const hasValidOutputSelection = useMemo(() => {
    if (outputOptionItemIds.length > 0) {
      return outputOptionItemIds.some(x => x === selectedOptionItemId);
    }
    return true;
  }, [ outputOptionItemIds, selectedOptionItemId ]);

  useEffect(() => {
    if (outputOptionItemIds.length > 0) {
      if (!hasValidOutputSelection) {
        onSelectOutputOption?.(outputOptionItemIds[0]);
      }
    }
  }, [ hasValidOutputSelection, onSelectOutputOption, outputOptionItemIds ]);

  return (
    <div className="flex justify-between items-start gap-2 p-2 border-b border-juno-gray-700">
      <div className="w-full flex gap-2 items-start">
        <div className="min-w-[44px] w-[44px]">
          <Input
            fullWidth
            type="number"
            value={stack ?? 1}
            className="text-right"
            onFocus={x => x.target.select()}
            onChange={x => onStackChange(Number(x.target.value))}
          />
        </div>
        <div className="w-full">
          {options && options.length > 0 ? (
            <div>
              <div className="uppercase text-sm text-juno-gray-200 border-b border-juno-gray-700">
                Choose One Of {itemName}
              </div>

              {options.map(opt => (
                <button
                  type="button"
                  key={opt.itemId}
                  className={clsx(
                    'flex items-center text-left hover:text-shadow hover:brightness-125 transition-all duration-300 border rounded-xs w-full py-1 my-1',
                    selectedOptionItemId !== opt.itemId
                      ? 'opacity-50 border-transparent'
                      : 'text-shadow border-juno-gray-200',
                  )}
                  onClick={() => onSelectOutputOption?.(opt.itemId)}
                >
                  <FontAwesomeIcon
                    icon={
                      selectedOptionItemId === opt.itemId ? faCheck : faCircle
                    }
                    className={clsx(
                      'fa-fw px-2',
                      selectedOptionItemId !== opt.itemId && 'opacity-25',
                    )}
                  />
                  <div>
                    <div>{opt.itemName}</div>
                    <div className="uppercase text-xs text-juno-gray-200">
                      {startCase(opt.itemKind)}
                      {isCrafting &&
                        opt.craftingStack &&
                        ` - Crafts ${opt.craftingStack} `}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ) : (
            <>
              <div>{itemName}</div>
              <div className="uppercase text-xs text-juno-gray-200">
                {startCase(itemKind)}
                {isCrafting && craftingStack && ` - Crafts ${craftingStack} `}
                {itemKind === IItemKindEnum.GenericItem && (
                  <button
                    type="button"
                    className="pl-2 underline"
                    onClick={() => {
                      if (isUnique) {
                        onDescriptionChange?.(null);
                      }
                      setIsUnique(x => !x);
                    }}
                  >
                    {isUnique ? 'Cancel Personalization' : 'Personalize'}
                  </button>
                )}
              </div>
            </>
          )}

          {isUnique && (
            <div className="pt-2">
              <div className="w-full">
                <Input
                  fullWidth
                  placeholder="Describe Personalization..."
                  {...register('description', { onBlur: syncPersonalization })}
                />
              </div>
            </div>
          )}
          <div className="text-sm">
            {isCustomExpiration ? (
              <div className="flex items-center">
                <DatePicker
                  date={expiresAt ? parseISO(expiresAt) : defaultExpiration}
                  onUpdate={date =>
                    date && onExpirationChange?.(date.toISOString())
                  }
                  maxDate={addYears(new Date(), 3)}
                />
                <button
                  type="button"
                  className="-ml-4 text-juno-gray-200"
                  onClick={() => {
                    setIsCustomExpiration(false);
                    onExpirationChange?.(null);
                  }}
                >
                  Use Default
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="text-juno-gray-200 underline"
                onClick={() => {
                  setIsCustomExpiration(true);
                  onExpirationChange?.(defaultExpiration.toISOString());
                }}
              >
                Customize Expiration
              </button>
            )}
          </div>
          {[ IItemKindEnum.Armor, IItemKindEnum.ArmorNecrology ].includes(
            itemKind,
          ) && (
            <div className="flex items-center gap-4">
              <div className="text-juno-gray-200">Armor Points</div>
              <div className="w-16">
                <Slider
                  dots
                  min={0}
                  max={2}
                  onChange={x => onSetArmorUpgrade?.(Number(x))}
                />
              </div>
              <div>
                {armorUpgradePoint === 0
                  ? 'Base'
                  : `+${armorUpgradePoint * 5}`}{' '}
              </div>
            </div>
          )}
        </div>
      </div>
      <button type="button" className="w-8" onClick={onRemove}>
        <FontAwesomeIcon icon={faTrash} className="fa-fw" />
      </button>
    </div>
  );
};

export default LineItem;
