import { FC, useCallback } from 'react';
import { useMutation } from 'urql';
import { useForm } from 'react-hook-form';
import { createEventTicket } from 'src/graphql/mutations/events.graphql';
import {
  ICreateEventTicketMutation,
  ICreateEventTicketMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { IEventConfig, ITicketRestrictionEnum } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import Input from 'src/components/0100_input';
import Slider from 'rc-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import Button from 'src/components/0100_button';
import { useParams } from '@tanstack/react-router';

interface IProps {
  name: string;
  config?: IEventConfig;
  adjustableShift: boolean;
}

const NewTicket: FC<IProps> = ({ config, name, adjustableShift }) => {
  const { eventId } = useParams({ strict: false });
  const { buttonState } = useButtonStates();
  const {
    register,
    setValue,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      label: '',
      cost: '0',
      limit: '10',
      shiftResponsibilities: 0,
    },
  });
  const { label, cost, limit, shiftResponsibilities } = watch();
  const [ createResult, create ] = useMutation<
    ICreateEventTicketMutation,
    ICreateEventTicketMutationVariables
  >(createEventTicket);
  const handleCreateTicket = useCallback(() => {
    create({
      eventId: Number(eventId),
      label,
      cost: Number(cost),
      limit: limit.trim() === '' ? undefined : Number(limit),
      shiftResponsibilities,
      restrictionType: name as ITicketRestrictionEnum,
    }).then(res => {
      if (res.data?.createEventTicket?.event) {
        setValue('label', '');
      }
    });
  }, [
    cost,
    create,
    eventId,
    label,
    limit,
    name,
    setValue,
    shiftResponsibilities,
  ]);

  return (
    <table className="table-fixed w-full mt-2">
      <tbody>
        <tr>
          <td className="">
            <Input
              fullWidth
              placeholder="Ticket Name"
              {...register('label', { required: true })}
            />
          </td>
          <td className="w-[64px] pl-2">
            <Input
              fullWidth
              type="number"
              className="text-right"
              {...register('cost', { required: true })}
            />
          </td>
          <td className="w-[64px] pl-2">
            <Input
              fullWidth
              type="number"
              className="text-right"
              placeholder="∞"
              {...register('limit')}
            />
          </td>
          <td className="w-[64px]" />
        </tr>
        <tr>
          <td className="py-2">
            <div className="flex items-center gap-4 col-span-6 pl-2 pr-[6px]">
              <Slider
                dots
                disabled={!adjustableShift}
                min={0}
                max={config?.maxShift || 0}
                value={shiftResponsibilities}
                onChange={x => setValue('shiftResponsibilities', Number(x))}
              />
              <div className="flex gap-1 items-center">
                {shiftResponsibilities} <FontAwesomeIcon icon={faStopwatch} />
              </div>
            </div>
          </td>
          <td colSpan={3} className="pt-2 pl-2 pb-2">
            <Button
              className="w-full"
              defaultLabel="Add Ticket"
              state={buttonState({
                isValid,
                isDirty: true,
                isFetching: createResult.fetching,
              })}
              onClick={() => isValid && handleCreateTicket()}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default NewTicket;
