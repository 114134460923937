import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useParticipationParams from '../hooks/useParticipationParams';

const ParticipantControl: FC = () => {
  const { isPaid, printRequested, isLocal, updateFilter } =
    useParticipationParams();

  return (
    <div className="border-b border-juno-gray-700 pb-0 pt-2 mb-4 max-w-[calc(100vw-16px)] sticky top-0 z-10 gray-box">
      <div className="flex sm:justify-center items-center gap-4  overflow-auto">
        <div>
          <div className="text-xs uppercase">Payment</div>
          <div className="flex items-center">
            <Tab
              label="All"
              highlightMode="horizontal"
              isActive={isPaid === undefined}
              onClick={() => updateFilter('is_paid', null)}
            />
            <Tab
              label="Paid"
              highlightMode="horizontal"
              isActive={isPaid === true}
              onClick={() => updateFilter('is_paid', true)}
            />
          </div>
        </div>

        <div>
          <div className="text-xs uppercase">Participation</div>
          <div className="flex items-center">
            <Tab
              label="All"
              highlightMode="horizontal"
              isActive={printRequested === undefined}
              onClick={() => updateFilter('is_participating', null)}
            />
            <Tab
              label="Participating"
              highlightMode="horizontal"
              isActive={printRequested === true}
              onClick={() => updateFilter('is_participating', true)}
            />
          </div>
        </div>

        <div>
          <div className="text-xs uppercase">Residency</div>
          <div className="flex items-center">
            <Tab
              label="All"
              highlightMode="horizontal"
              isActive={isLocal === undefined}
              onClick={() => updateFilter('is_local', null)}
            />
            <Tab
              label="Locals"
              highlightMode="horizontal"
              isActive={isLocal === true}
              onClick={() => updateFilter('is_local', true)}
            />
            <Tab
              label="Travelers"
              highlightMode="horizontal"
              isActive={isLocal === false}
              onClick={() => updateFilter('is_local', false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantControl;
