import { useCallback, useState } from 'react';
import { TTransactableItem } from '../LineItem';
import { TAddTransactableItem } from '../OneSidedTransaction';

const useItemsToCharacter = () => {
  const [ itemsToCharacter, setItemsToCharacter ] = useState<TTransactableItem[]>(
    [],
  );

  const addItemToCharacter = useCallback(
    ({
      itemId,
      itemName,
      itemKind,
      stack = 1,
      registeredItemId,
      expiresAt,
      isCrafting,
      itemLifetimeAmount,
      itemLifetimeUnit,
    }: TAddTransactableItem) => {
      setItemsToCharacter(x => [
        ...x,
        {
          itemId,
          itemName,
          itemKind,
          isCrafting,
          stack,
          registeredItemId,
          expiresAt,
          itemLifetimeAmount,
          itemLifetimeUnit,
          nonce: `${[ Number(new Date()), itemId ].join('-')}`,
        },
      ]);
    },
    [],
  );

  const updateItemToCharacter = useCallback(
    ({
      nonce,
      stack,
      description,
      expiresAt,
      armorUpgradePoint,
    }: {
      nonce: string;
      stack?: number;
      description?: string | null;
      expiresAt?: string | null;
      armorUpgradePoint?: number;
    }) => {
      setItemsToCharacter(prevState => {
        const matchingEntry = prevState.find(x => x.nonce === nonce);

        if (matchingEntry) {
          return prevState
            .filter(x => x.nonce !== nonce)
            .concat({
              ...matchingEntry,
              stack: stack ?? matchingEntry.stack,
              description: description === null ? undefined : description,
              expiresAt: expiresAt === null ? undefined : expiresAt,
              armorUpgradePoint:
                armorUpgradePoint ?? matchingEntry.armorUpgradePoint,
            });
        }

        return prevState;
      });
    },
    [],
  );

  const removeItemToCharacter = useCallback(
    (nonce: string) =>
      setItemsToCharacter(x => x.filter(y => y.nonce !== nonce)),
    [],
  );

  const resetItemsToCharacter = useCallback(() => setItemsToCharacter([]), []);

  return {
    itemsToCharacter,
    addItemToCharacter,
    updateItemToCharacter,
    removeItemToCharacter,
    resetItemsToCharacter,
  };
};

export default useItemsToCharacter;
