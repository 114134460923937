import { useParams } from '@tanstack/react-router';
import { FC } from 'react';
import LoupeView from 'src/components/0500_blueprints/dystopia_rising/LoupeView';
import usePermission from 'src/hooks/permissions/usePermissions';

const EventBlueprint: FC = () => {
  const { eventId } = useParams({ strict: false });
  const { isPermitted: canAssistLogistics } = usePermission({
    action: 'assists_logistics',
    eventId: Number(eventId),
  });

  if (!canAssistLogistics) return null;

  return (
    <LoupeView components={{ withItemCreation: false, withTitle: true }} />
  );
};

export default EventBlueprint;
