import { FC } from 'react';
import { useSearch } from '@tanstack/react-router';
import Journal from './Journal';
import Build from './Build';
import Printout from './Printout';
import XpAudit from './XpAudit';
import Bio from '../../Players/Bio';
import Memberships from '../../Players/Memberships';
import CorrectiveActions from '../../Players/CorrectiveActions';
import Perks from '../../Players/Perks';
import Referrals from '../../Players/Referrals';

const Checkout: FC = () => {
  const {
    player_id: playerId,
    character_id: characterId,
    checkout_section: section,
  } = useSearch({ strict: false });

  if (playerId && characterId) {
    return (
      <>
        {(!section || section === 'build') && <Build />}
        {section === 'journal' && <Journal />}
        {section === 'printout' && <Printout />}
        {section === 'xp_audit' && <XpAudit />}
      </>
    );
  }

  if (playerId) {
    return (
      <>
        {(!section || section === 'bio') && <Bio />}
        {section === 'memberships' && <Memberships />}
        {section === 'corrective_actions' && <CorrectiveActions />}
        {section === 'perks' && <Perks />}
        {section === 'referrals' && <Referrals />}
      </>
    );
  }

  return (
    <div className="text-center">
      Please Select a Character from the Sidebar
    </div>
  );
};

export default Checkout;
