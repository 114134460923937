import {
  faChevronDown,
  faChevronUp,
  faEyeSlash,
  faHome,
  faLaptop,
  faPersonHiking,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useMemo, useState } from 'react';
import { useOutsideClickRef } from 'rooks';
import clsx from 'clsx';
import { isFuture, isPast } from 'date-fns';
import { IEventWithAttendance } from 'src/hooks/events/useEventsWithAttendances';
import { IEventAttendeeKindEnum } from 'src/graphql/types';
import DropdownPanel from '../0100_dropdown_panel';
import Actions from './Actions';

interface IProps {
  event: IEventWithAttendance;
  homeBranchId?: number;
}

const EventCell: FC<IProps> = ({ event, homeBranchId }) => {
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ focusRef ] = useOutsideClickRef(() => setIsExpanded(false));

  const isClosed =
    isPast(event.endsAt) ||
    !event.registrationOpensAt ||
    isFuture(event.registrationOpensAt);
  const color = useMemo(() => {
    if (!event.attendance) return undefined;
    if (event.attendance.buildAwarded) return 'purple';
    if (event.attendance.checkinApproved) return 'green';

    return 'white';
  }, [ event ]);

  return (
    <button
      ref={focusRef}
      type="button"
      className={clsx(
        'border border-juno-gray-700 py-1 px-4 mr-2 mb-2 hover:border-juno-gray-200 transition-all duration-300',
        !event.attendance && isClosed
          ? 'text-juno-gray-700'
          : 'hover:text-shadow',
        color === 'green' && 'green-box',
        color === 'purple' && 'purple-box',
        color === 'white' && 'white-box text-juno-gray-900',
        homeBranchId === event.branch.id && 'rounded-full',
      )}
      onClick={() => setIsExpanded(x => !x)}
    >
      <div className="flex">
        {event.isVirtual && (
          <FontAwesomeIcon icon={faLaptop} className="pt-1 pr-2" />
        )}

        {event.attendance && (
          <div className="flex pt-0.5 pr-2">
            <FontAwesomeIcon
              icon={
                event.attendance.kind === IEventAttendeeKindEnum.HomeGame
                  ? faHome
                  : faPersonHiking
              }
            />
            {event.attendance.kind ===
              IEventAttendeeKindEnum.TravelAsHomeGame && (
              <FontAwesomeIcon icon={faChevronUp} className="text-xs -mt-1" />
            )}
            {event.attendance.attendeeCharacters.length > 1 && (
              <div className="text-xs -mt-1">
                {event.attendance.attendeeCharacters.length}
              </div>
            )}
          </div>
        )}
        {!event.registrationOpensAt && (
          <FontAwesomeIcon icon={faEyeSlash} className="pt-1 pr-1" />
        )}
        <div>{event.branch.shorthand}</div>

        {event.attendance && event.attendance.buildGrowth > 0 && (
          <div className="text-xs -mt-0.5">{`+${event.attendance.buildGrowth}XP`}</div>
        )}

        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'mt-0.5 ml-2 transition-transform origin-center duration-300',
            {
              '-scale-y-100': isExpanded,
            },
          )}
        />
      </div>
      <div className="relative flex h-0 -ml-[17px]">
        <DropdownPanel
          className={clsx('w-40 border-juno-gray-200 -mr-4 mt-2', {
            border: isExpanded,
          })}
          isExpanded={isExpanded}
        >
          <Actions event={event} isExpanded={isExpanded} />
        </DropdownPanel>
      </div>
    </button>
  );
};

export default EventCell;
