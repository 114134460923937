import {
  FileRouteTypes,
  useLinkProps,
  useParams,
} from '@tanstack/react-router';
import { FC } from 'react';
import SidebarLink from 'src/components/0100_sidebar_link';

interface IProps {
  id: number;
  name: string;
  eventAttendeesSize?: number | null;
}

const Event: FC<IProps> = ({ id, name, eventAttendeesSize = 0 }) => {
  const { organizationSlug } = useParams({ strict: false });
  const linkToEvent = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: String(id),
    },
  });

  return (
    <SidebarLink
      to={String(linkToEvent.href) as unknown as FileRouteTypes['fullPaths']}
      target="_event"
      className="flex justify-between"
    >
      <div>{name}</div>
      <div>{eventAttendeesSize}</div>
    </SidebarLink>
  );
};

export default Event;
