import { faCircle, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearch } from '@tanstack/react-router';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import Title from 'src/components/0100_title';

interface IProps {
  filterKey: 'print_requested' | 'print_paid' | 'print_local';
  title: string;
  trueLabel: string;
  falseLabel: string;
}

const Boolean: FC<IProps> = ({ filterKey, title, trueLabel, falseLabel }) => {
  const navigate = useNavigate();
  const searchParams = useSearch({ strict: false });
  const valueFromUrl = searchParams[filterKey];
  const isDefined = valueFromUrl !== undefined;

  const handleClick = useCallback(
    (value: boolean | null) => {
      if (value === null) {
        navigate({ to: '.', search: x => ({ ...x, [filterKey]: undefined }) });
      } else {
        navigate({ to: '.', search: x => ({ ...x, [filterKey]: value }) });
      }
    },
    [ filterKey, navigate ],
  );

  return (
    <div>
      <Title variant="heading">{title}</Title>
      <div className="grid gap-2">
        <button
          type="button"
          className={clsx(
            'text-left',
            !isDefined ? 'text-shadow' : 'opacity-50',
          )}
          onClick={() => handleClick(null)}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={isDefined ? faCircle : faCircleDot}
          />
          All
        </button>
        <button
          type="button"
          className={clsx(
            'text-left',
            valueFromUrl ? 'text-shadow' : 'opacity-50',
          )}
          onClick={() => handleClick(true)}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={valueFromUrl ? faCircleDot : faCircle}
          />
          {trueLabel}
        </button>
        <button
          type="button"
          className={clsx(
            'text-left',
            !isDefined || valueFromUrl ? 'opacity-50' : 'text-shadow',
          )}
          onClick={() => handleClick(false)}
        >
          <FontAwesomeIcon
            className="pr-2 fa-fw"
            icon={!isDefined || valueFromUrl ? faCircle : faCircleDot}
          />
          {falseLabel}
        </button>
      </div>
    </div>
  );
};

export default Boolean;
