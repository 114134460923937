import { FC } from 'react';
import { format, isBefore, parseISO } from 'date-fns';
import clsx from 'clsx';
import { TTransactableItem } from '../../../LineItem';
import { TInventory } from '../../../Inventories';
import { TSelectedInventory } from '../Boolean';
import Selection from '../../../Selection';

interface IProps {
  requirement: TTransactableItem;
  inventories: TInventory[];
  selectedCraftingComponents: TSelectedInventory[];
  onUpdateSelectedCraftingComponent?: ({
    itemId,
    registeredItemId,
    expiresAt,
    stack,
  }: TSelectedInventory) => void;
}

const Disjunction: FC<IProps> = ({
  requirement,
  inventories,
  selectedCraftingComponents,
  onUpdateSelectedCraftingComponent,
}) => {
  const selectedCount = selectedCraftingComponents
    .map(x => x.stack)
    .reduce((a, b) => a + b, 0);

  return (
    <div>
      <div className="border-y border-juno-gray-700">
        <div
          className={clsx(
            'flex items-center justify-between p-2',
            selectedCount === requirement.stack ? 'midtone-box' : 'orange-box',
          )}
        >
          <div>{requirement.itemName}</div>
          <div className="text-right">
            {selectedCount}/{requirement.stack}
          </div>
        </div>
      </div>

      <div className="flex justify-between border-b border-juno-gray-700 pt-2 pl-2 text-xs uppercase text-juno-gray-200">
        <div>Possible Combinations</div>
        <div className="flex justify-end">
          <div className="w-8">Use</div>
          <div className="w-8">Cap</div>
        </div>
      </div>
      <div className="ml-4 border-l border-juno-gray-700">
        {requirement.options?.map(option => (
          <div key={option.itemId}>
            <div className="py-1 pl-2 border-b border-juno-gray-700 midtone-box">
              {option.itemName}
            </div>
            {inventories
              .filter(inv => inv.itemId === option.itemId)
              .sort((a, b) => {
                if (!a.expiresAt) return 1;
                if (!b.expiresAt) return -1;

                return isBefore(parseISO(a.expiresAt), parseISO(b.expiresAt))
                  ? -1
                  : 1;
              })
              .map(inv => (
                <div
                  key={[ inv.itemId, inv.expiresAt, inv.registeredItemId ].join(
                    '-',
                  )}
                  className="flex justify-between p-1 px-2 border-b border-juno-gray-700"
                >
                  <div className="pl-4">
                    {inv.expiresAt
                      ? format(parseISO(inv.expiresAt), 'yyyy MMM dd')
                      : 'Does not expire'}
                  </div>
                  <div className="flex justify-end">
                    <Selection
                      {...selectedCraftingComponents.find(
                        y =>
                          y.itemId === inv.itemId &&
                          y.registeredItemId === inv.registeredItemId &&
                          y.expiresAt === inv.expiresAt,
                      )}
                      limit={inv.stack}
                      onAdjustSelection={stack =>
                        onUpdateSelectedCraftingComponent?.({
                          itemId: inv.itemId,
                          registeredItemId: inv.registeredItemId,
                          expiresAt: inv.expiresAt,
                          stack,
                        })
                      }
                    />
                    <div className="w-8 text-right">{inv.stack}</div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Disjunction;
