import { FC, PropsWithChildren, ReactNode, forwardRef } from 'react';
import Input, { IProps as IInputProps } from 'src/components/0100_input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Loading from '../0100_loading';

interface IProps extends IInputProps {
  title: string;
  contentWhenLocked?: string | ReactNode;
  displayStacked?: boolean;
  subtitle?: ReactNode;
  footnote?: ReactNode;
  isBusy?: boolean;
  isCompact?: boolean;
  isUpdated?: boolean;
  required?: boolean;
  error?: string;
}

const AugmentedInput: FC<PropsWithChildren<IProps>> = forwardRef<
  HTMLInputElement,
  IProps
>(
  (
    {
      title,
      subtitle,
      footnote,
      error,
      displayStacked = false,
      isBusy = false,
      isCompact = false,
      isUpdated = false,
      required = false,
      children,
      ...props
    },
    ref,
  ) => (
    <div
      className={clsx(
        displayStacked ? `grid ${isCompact ? '' : 'gap-2'}` : 'flex p-2',
      )}
    >
      {displayStacked ? (
        <div>
          <div className="flex justify-between items-center">
            <div className={isCompact ? 'text-juno-gray-200 text-sm' : ''}>
              {title}
              {required && <span>*</span>}
            </div>
            {isBusy && <Loading size="small" />}
            {isUpdated && <FontAwesomeIcon icon={faCheck} />}
          </div>
          <div>{subtitle}</div>
        </div>
      ) : (
        <div className="w-2/5 pt-1.5">
          {title}
          {required && <span>*</span>}
          {subtitle}
        </div>
      )}
      <div className={clsx('grid gap-2', displayStacked ? 'w-full' : 'w-3/5')}>
        <div className="flex items-start">
          {children || <Input ref={ref} {...props} />}

          {!displayStacked && isBusy && (
            <Loading size="small" className="pl-2 pt-2" />
          )}
          {!displayStacked && isUpdated && (
            <FontAwesomeIcon icon={faCheck} className="pl-2 pt-2" />
          )}
        </div>
        {footnote && <div className="opacity-50">{footnote}</div>}
        {!!error && <div className="text-juno-orange-200">{error}</div>}
      </div>
    </div>
  ),
);

export default AugmentedInput;
