import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { isAfter } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import DatePicker from 'src/components/0200_datepicker';
import {
  createSpecialDate,
  destroySpecialDate,
  updateSpecialDate,
} from 'src/graphql/mutations/specialDates.graphql';
import {
  ICreateSpecialDateMutation,
  ICreateSpecialDateMutationVariables,
  IDestroySpecialDateMutation,
  IDestroySpecialDateMutationVariables,
  IUpdateSpecialDateMutation,
  IUpdateSpecialDateMutationVariables,
} from 'src/graphql/mutations/specialDates.graphql.types';
import { useMutation } from 'urql';

interface IProps {
  id?: number;
  startsAt?: Date;
  endsAt?: Date;
  title?: string;
  canEdit?: boolean;
}

const SpecialDate: FC<IProps> = ({ canEdit, ...specialDate }) => {
  const { register, watch, setValue, reset } = useForm({
    defaultValues: {
      startsAt: null as Date | null,
      endsAt: null as Date | null,
      title: '' as string | null,
    },
  });
  const { startsAt, endsAt, title } = watch();
  const [ isUpdated, setIsUpdated ] = useState(false);
  const [ isDeleted, setIsDeleted ] = useState(false);
  const isValid = useMemo(
    () => startsAt && endsAt && title && isAfter(endsAt, startsAt),
    [ startsAt, endsAt, title ],
  );
  const [ createResult, create ] = useMutation<
    ICreateSpecialDateMutation,
    ICreateSpecialDateMutationVariables
  >(createSpecialDate);
  const [ destroyResult, destroy ] = useMutation<
    IDestroySpecialDateMutation,
    IDestroySpecialDateMutationVariables
  >(destroySpecialDate);
  const [ updateResult, update ] = useMutation<
    IUpdateSpecialDateMutation,
    IUpdateSpecialDateMutationVariables
  >(updateSpecialDate);

  const handleCreate = useCallback(() => {
    if (!startsAt || !endsAt || !title) return;

    create({
      startsAt: startsAt.toISOString(),
      endsAt: endsAt.toISOString(),
      title,
    }).then(res => {
      if (res.data?.createSpecialDate?.organization?.id) {
        reset({
          startsAt: null,
          endsAt: null,
          title: '',
        });
      }
    });
  }, [ startsAt, endsAt, title, create, reset ]);

  const isLoading = useMemo(
    () => createResult.fetching || updateResult.fetching,
    [ createResult.fetching, updateResult.fetching ],
  );

  const handleUpdate = useCallback(
    ({
      startsAt,
      endsAt,
      title,
    }: {
      startsAt?: string;
      endsAt?: string;
      title?: string;
    }) => {
      if (!specialDate.id) return;

      update({ id: specialDate.id, startsAt, endsAt, title }).then(res => {
        if (res.data?.updateSpecialDate?.specialDate?.id) {
          setIsUpdated(true);
          setTimeout(() => setIsUpdated(false), 3000);
        }
      });
    },
    [ specialDate.id, update ],
  );

  const handleDestroy = useCallback(() => {
    if (!specialDate.id) return;

    destroy({ id: specialDate.id });
    setIsDeleted(true);
  }, [ destroy, specialDate.id ]);

  useEffect(() => {
    if (specialDate.id) {
      setIsUpdated(false);
      reset({
        startsAt: specialDate.startsAt,
        endsAt: specialDate.endsAt,
        title: specialDate.title,
      });
    }
  }, [
    reset,
    specialDate.id,
    specialDate.startsAt,
    specialDate.endsAt,
    specialDate.title,
  ]);

  if (isDeleted) return null;

  return (
    <tr
      className={clsx(
        specialDate.id &&
          endsAt &&
          isAfter(new Date(), endsAt) &&
          'opacity-75 bg-juno-gray-700',
      )}
    >
      <td className="pt-1 pr-2">
        <div className="flex items-center">
          <DatePicker
            complex
            fillWidth
            disabled={!canEdit}
            placeholder="Set Start Date"
            date={startsAt ?? undefined}
            filterDate={x => (endsAt ? isAfter(endsAt, x) : true)}
            onUpdate={x => {
              if (x) {
                setValue('startsAt', x);
                handleUpdate({ startsAt: x.toISOString() });
              }
            }}
          />
        </div>
      </td>
      <td className="pt-1 pr-2">
        <div className="flex items-center">
          <DatePicker
            complex
            fillWidth
            disabled={!canEdit}
            placeholder="Set End Date"
            date={endsAt ?? undefined}
            filterDate={x => (startsAt ? isAfter(x, startsAt) : true)}
            onUpdate={x => {
              if (x) {
                setValue('endsAt', x);
                handleUpdate({ endsAt: x.toISOString() });
              }
            }}
          />
        </div>
      </td>
      <td className="py-1">
        <div className="w-full">
          <Input
            fullWidth
            disabled={!canEdit}
            placeholder="Ex: Cross-Network Game"
            {...register('title')}
            onBlur={() => title && handleUpdate({ title })}
          />
        </div>
      </td>
      {canEdit ? (
        <td className="p-1 pt-2 flex justify-center items-center">
          {isLoading && <Loading size="small" className="pt-1" />}
          {specialDate.id && !isUpdated && !isLoading && (
            <ConfirmDropdown
              confirmLabel="Confirm Removal"
              align="right"
              onConfirm={handleDestroy}
            />
          )}
          {specialDate.id && isUpdated && (
            <FontAwesomeIcon icon={faCheck} className="fa-fw pt-1" />
          )}
          {!specialDate.id && !isLoading && (
            <button type="button" onClick={handleCreate}>
              <FontAwesomeIcon
                icon={faPlus}
                className={clsx(
                  'fa-fw',
                  !isValid && 'opacity-50 cursor-not-allowed',
                )}
              />
            </button>
          )}
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
};

export default SpecialDate;
