import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import useSearchPlayers from 'src/hooks/players/useSearchPlayers';
import { useOutsideClickRef } from 'rooks';
import { useForm } from 'react-hook-form';
import { IOrganizationRoleEnum } from 'src/graphql/types';
import { useParams } from '@tanstack/react-router';
import SearchResult from '../0300_search_result';
import DropdownPanel from '../0100_dropdown_panel';
import Input from '../0100_input';

interface IProps {
  isDisabled?: boolean;
  title?: string;
  placeholder: string;
  playersOnly?: boolean;
  withCharacters?: boolean;
  accessLevelFilters?: IOrganizationRoleEnum[];
  branchId?: number;
  characterIcon?: ({ characterId }: { characterId?: number }) => ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
  onSearchResultClick?: ({
    playerId,
    characterId,
  }: {
    playerId?: number;
    characterId?: number;
  }) => void;
  onQueryChange?: (query: string) => void;
}

const PlayerSearch: FC<IProps> = ({
  isDisabled,
  title,
  accessLevelFilters,
  branchId,
  placeholder,
  playersOnly,
  withCharacters = false,
  characterIcon,
  onBlur,
  onFocus,
  onSearchResultClick,
  onQueryChange,
}) => {
  const [ isFocused, setIsFocused ] = useState(false);
  const [ lastExecutedSearchQuery, setLastExecutedSearchQuery ] = useState('');
  const [ lastExecutedQuerySlug, setLastExecutedQuerySlug ] = useState('');
  const { organizationSlug } = useParams({ strict: false });
  const [ ref ] = useOutsideClickRef(() => setIsFocused(false));

  const {
    getValues,
    register,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: { query: '' },
    values: { query: lastExecutedSearchQuery },
  });

  const { data, fetching, executeSearch } = useSearchPlayers({
    query: getValues('query'),
    playersOnly,
    accessLevelFilters,
    branchId,
  });

  const { onBlur: onFormBlur, ...rest } = register('query');
  const executableQuery = watch('query').trim().length >= 1;
  const canExecuteSearch = isDirty && executableQuery && !isDisabled;

  const handleSubmit = useCallback(() => {
    if (getValues('query').trim().length >= 1) {
      executeSearch();

      setLastExecutedSearchQuery(getValues('query'));
      setLastExecutedQuerySlug(String(organizationSlug));
    }
  }, [ getValues, organizationSlug, executeSearch ]);

  useEffect(() => {
    if (
      lastExecutedQuerySlug !== '' &&
      lastExecutedQuerySlug !== organizationSlug
    ) {
      handleSubmit();
    }
  }, [ handleSubmit, lastExecutedQuerySlug, organizationSlug ]);

  return (
    <div className="grid gap-1">
      {title && (
        <div className="uppercase text-xs text-juno-gray-200">{title}</div>
      )}
      <div ref={ref} className="flex">
        <Input
          fullWidth
          disabled={isDisabled}
          placeholder={placeholder}
          onEnter={handleSubmit}
          onFocus={() => {
            setIsFocused(true);
            onFocus?.();
          }}
          onBlur={x => {
            onFormBlur(x);
            setTimeout(() => {
              setIsFocused(false);
              onBlur?.();
            }, 200);
          }}
          {...rest}
          onChange={x => {
            rest.onChange(x);
            onQueryChange?.(x.target.value);
          }}
        />
        <button
          type="button"
          disabled={!canExecuteSearch}
          className="-ml-6"
          onClick={() => {
            handleSubmit();
            setIsFocused(true);
          }}
        >
          <FontAwesomeIcon
            icon={faSearch}
            className={clsx(
              canExecuteSearch ? 'text-juno-gray-200' : 'text-juno-gray-700',
            )}
          />
        </button>
      </div>
      <DropdownPanel
        isExpanded={isFocused}
        zIndex={10}
        className={clsx(
          'mt-2 border-juno-gray-700',
          isFocused && executableQuery && 'border',
        )}
      >
        {!isDirty &&
          !fetching &&
          data &&
          data.searchPlayer.length > 0 &&
          data.searchPlayer.map(x => {
            // eslint-disable-next-line no-underscore-dangle
            if (x.__typename === 'User') {
              if (withCharacters) {
                return (
                  <div key={x.id}>
                    <button
                      type="button"
                      disabled
                      className="px-4 pt-2 flex justify-between border-l-4 border-transparent"
                      onClick={e => e.preventDefault()}
                    >
                      <div className="w-12 pr-1 text-right">{x.id}</div>
                      <div className="w-48 text-left  text-juno-gray-200">
                        {x.fullName}
                        {x.preferredName && (
                          <div>
                            a.k.a. {[ x.firstName, x.lastName ].join(' ')}
                          </div>
                        )}
                      </div>
                    </button>
                    <div className="pr-1">
                      {x.characters.map(character => (
                        <SearchResult
                          characterOnly
                          key={`user-${x.id}-character-${character.id}`}
                          playerId={x.id}
                          characterName={character.name}
                          characterId={character.id}
                          characterIcon={characterIcon}
                          onClick={onSearchResultClick}
                        />
                      ))}
                    </div>
                  </div>
                );
              }

              return (
                <SearchResult
                  key={`user-${x.id}`}
                  playerName={x.fullName}
                  playerFirstName={x.firstName}
                  playerLastName={x.lastName}
                  playerHasPreferredName={!!x.preferredName}
                  playerId={x.id}
                  userOrganization={x.userOrganization}
                  onClick={onSearchResultClick}
                />
              );
            }

            // eslint-disable-next-line no-underscore-dangle
            if (x.__typename === 'Character') {
              return (
                <SearchResult
                  key={`character-${x.id}`}
                  characterName={x.name}
                  characterId={x.id}
                  characterIcon={characterIcon}
                  playerName={x.user.fullName}
                  playerFirstName={x.user.firstName}
                  playerLastName={x.user.lastName}
                  playerHasPreferredName={!!x.user.preferredName}
                  playerId={x.user.id}
                  userOrganization={x.user.userOrganization}
                  onClick={onSearchResultClick}
                />
              );
            }

            return null;
          })}

        {!isDirty && !fetching && data && data.searchPlayer.length === 0 && (
          <div className="p-2 w-56">No Results :(</div>
        )}
        {!isDirty && fetching && <div className="p-2 w-56">Searching...</div>}
        {canExecuteSearch && (
          <div className="p-2 w-56">Press Enter to execute search</div>
        )}
      </DropdownPanel>
    </div>
  );
};

export default PlayerSearch;
