import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updateItemCrafting } from 'src/graphql/mutations/items.graphql';
import {
  IUpdateItemCraftingMutation,
  IUpdateItemCraftingMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { useMutation } from 'urql';
import { useNavigate } from '@tanstack/react-router';
import { IPropsWithOnUpdate } from '../../types';

export type TItemCrafting = {
  id: number;
  craftingMindCost?: number | null;
  craftingTimeInMinute?: number | null;
  craftingSkills?: string | null;
  craftingZone?: string | null;
  craftingComponents?: {
    id: number;
    amount: number;
    component: {
      id: number;
      name: string;
    };
  }[];
};
interface IProps extends IPropsWithOnUpdate {
  id: number;
  itemCraftings: TItemCrafting[];
}

const ItemCrafting: FC<IProps> = ({ id, itemCraftings, onUpdate }) => {
  const navigate = useNavigate();
  const itemCrafting = itemCraftings[0];
  const {
    register,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      craftingMindCost: null as number | null,
      craftingTimeInMinute: null as number | null,
      craftingSkills: null as string | null,
      craftingZone: null as string | null,
    },
  });
  const {
    craftingMindCost,
    craftingTimeInMinute,
    craftingSkills,
    craftingZone,
  } = watch();

  const [ , update ] = useMutation<
    IUpdateItemCraftingMutation,
    IUpdateItemCraftingMutationVariables
  >(updateItemCrafting);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;
    if (!itemCrafting) return;

    onUpdate({ status: 'busy' });
    update({
      itemCraftingId: itemCrafting.id,
      craftingMindCost: Number(craftingMindCost),
      craftingTimeInMinute: Number(craftingTimeInMinute),
      craftingSkills,
      craftingZone,
    }).then(res => {
      if (res.data?.updateItemCrafting?.itemCrafting) {
        onUpdate({ status: 'success' });
      }

      if (res.data?.updateItemCrafting?.error) {
        onUpdate({ status: 'error' });
      }
    });
  }, [
    update,
    isDirty,
    itemCrafting,
    craftingMindCost,
    craftingTimeInMinute,
    craftingSkills,
    craftingZone,
    onUpdate,
  ]);

  useEffect(() => {
    if (itemCrafting) {
      reset({
        craftingMindCost: itemCrafting.craftingMindCost,
        craftingTimeInMinute: itemCrafting.craftingTimeInMinute,
        craftingSkills: itemCrafting.craftingSkills,
        craftingZone: itemCrafting.craftingZone,
      });
    }
  }, [ itemCrafting, reset ]);

  if (!itemCrafting) return <td colSpan={5} />;

  return (
    <>
      <td>
        <input
          className="text-right border-0 bg-transparent w-full p-1"
          type="text"
          {...register('craftingMindCost', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-right border-0 bg-transparent w-full p-1"
          type="text"
          {...register('craftingTimeInMinute', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="border-0 bg-transparent w-full p-1"
          type="text"
          {...register('craftingSkills', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="border-0 bg-transparent w-full p-1"
          type="text"
          {...register('craftingZone', { onBlur: handleUpdate })}
        />
      </td>
      <td className="midtone-box">
        <button
          type="button"
          className="w-full p-1"
          onClick={() => {
            navigate({ to: '.', search: x => ({ ...x, item_id: id }) });
          }}
        >
          {itemCrafting.craftingComponents?.map(craftingComponent => (
            <div
              key={craftingComponent.id}
              className="flex items-center gap-1 whitespace-nowrap overflow-hidden"
            >
              <div>{craftingComponent.amount}</div>
              <div>{craftingComponent.component.name}</div>
            </div>
          ))}
        </button>
      </td>
    </>
  );
};

export default ItemCrafting;
