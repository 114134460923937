import {
  faCheck,
  faChevronDown,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ChangeHandler, useForm, useFormContext } from 'react-hook-form';
import { useOutsideClickRef } from 'rooks';
import Tab from '../0200_tab';
import DropdownPanel from '../0100_dropdown_panel';
import Loading from '../0100_loading';
import Input from '../0100_input';

interface IProps {
  name: string;
  options: {
    value: number | string;
    label: string;
    customRender?: ReactNode;
    isLocked?: boolean;
  }[];
  customSort?: boolean;
  defaultLabel?: string;
  selectedValue: number | string | null;
  isLocked?: boolean;
  isUpdating?: boolean;
  isUpdated?: boolean;
  placeholder?: string;
  rounding?: string;
  width?: string;
  withSearch?: boolean;
  minimumElementsForSearch?: number;
  onBlur?: ChangeHandler;
  onChange?: ChangeHandler;
  onUpdate?: () => void;
  onSelectionChange?: (value: number | string) => void;
  onSelectOpen?: () => void;
  onSelectClose?: () => void;
}

export interface IBaseSelectProps extends Omit<IProps, 'options'> {}

const BaseSelect: FC<IProps> = forwardRef(
  (
    {
      customSort = false,
      defaultLabel = 'Select an Option',
      isLocked = false,
      isUpdated = false,
      isUpdating = false,
      name,
      options,
      placeholder = 'Search...',
      rounding = 'rounded-xs',
      selectedValue,
      width = 'w-48',
      withSearch = false,
      minimumElementsForSearch = 7,
      onUpdate,
      onSelectionChange,
      onSelectOpen,
      onSelectClose,
    },
    ref,
  ) => {
    const [ isExpanded, setIsExpanded ] = useState(false);
    const { setValue } = useFormContext();
    const [ focusRef ] = useOutsideClickRef(() => setIsExpanded(false));
    const { register, setFocus, watch } = useForm({
      defaultValues: { query: '' },
    });
    const { query } = watch();
    const index = useMemo(
      () =>
        Math.max(
          0,
          options.findIndex(x => x.value === selectedValue),
        ),
      [ options, selectedValue ],
    );
    const filteredOptions =
      query.length > 0
        ? options.filter(
            x =>
              x.value === selectedValue ||
              String(x.label).toLowerCase().includes(query.toLowerCase()),
          )
        : options;

    const onChange = useCallback(
      ({
        value,
        isLocked = false,
        propagate = false,
        collapse = false,
      }: {
        value: number | string;
        isLocked?: boolean;
        propagate?: boolean;
        collapse?: boolean;
      }) => {
        if (!isLocked) {
          setValue(name, value, {
            shouldDirty: true,
            shouldValidate: true,
          });

          onSelectionChange?.(value);

          if (propagate) {
            onUpdate?.();
          }

          if (collapse) {
            setTimeout(() => setIsExpanded(false), 200);
          }
        }
      },
      [ setValue, name, onSelectionChange, onUpdate ],
    );

    const onDirectionalArrow = useCallback(
      (dir: 'up' | 'down') => {
        const newIndex = Math.min(
          options.length - 1,
          Math.max(0, dir === 'up' ? index - 1 : index + 1),
        );

        if (!options[newIndex].isLocked) {
          onChange({ value: options[newIndex].value });
        }
      },
      [ options, index, onChange ],
    );

    const onKeyCharacter = useCallback(
      (x: string) => {
        const targetValue = options.find(y =>
          y.label.toLowerCase().startsWith(x),
        )?.value;

        if (targetValue) {
          onChange({ value: targetValue });
        }
      },
      [ options, onChange ],
    );

    useEffect(() => {
      if (isExpanded) {
        onSelectOpen?.();
        setTimeout(() => {
          setFocus('query');
        }, 200);
      } else {
        onSelectClose?.();
      }
    }, [ isExpanded, onSelectClose, onSelectOpen, setFocus ]);

    return (
      <div ref={focusRef}>
        <div className="flex items-center gap-2">
          <button
            type="button"
            className={clsx(
              'flex justify-between py-1 px-2 dark-box border border-juno-gray-700',
              width,
              rounding,
              isLocked && 'opacity-50',
            )}
            onClick={() => !isLocked && setIsExpanded(x => !x)}
            onKeyDown={x => {
              switch (x.key) {
                case 'ArrowDown':
                  return onDirectionalArrow('down');
                case 'ArrowUp':
                  return onDirectionalArrow('up');
                case 'ArrowRight':
                  return setIsExpanded(true);
                case 'ArrowLeft':
                  return setIsExpanded(false);
                case 'Enter':
                  x.preventDefault();
                  setTimeout(() => setIsExpanded(false), 200);
                  return onUpdate?.();
                default:
                  return onKeyCharacter(x.key);
              }
            }}
          >
            <div className="whitespace-nowrap overflow-hidden mr-2">
              {options.find(x => x.value === selectedValue)?.label ||
                defaultLabel}
            </div>
            <FontAwesomeIcon
              icon={isLocked ? faLock : faChevronDown}
              className={clsx(
                'transition-transform duration-300 py-1',
                isExpanded && '-scale-y-100',
              )}
            />
          </button>
          {isUpdating && <Loading size="small" />}
          {isUpdated && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <DropdownPanel
          isExpanded={isExpanded}
          className={clsx(
            'mt-2 border-juno-gray-700',
            width,
            isExpanded && 'border',
          )}
        >
          {withSearch && options.length > minimumElementsForSearch && (
            <div className="w-full p-2 border-b border-juno-gray-700 sticky top-0 gray-box z-10">
              <Input
                fullWidth
                placeholder={placeholder}
                {...register('query')}
              />
            </div>
          )}
          {filteredOptions
            .sort((a, b) => {
              if (customSort) {
                return 0;
              }

              if (a.isLocked === b.isLocked) {
                return a.label.localeCompare(b.label);
              }
              return a.isLocked ? 1 : -1;
            })
            .map(x => (
              <Tab
                key={x.value}
                label={x.customRender ?? x.label}
                highlightMode="vertical"
                isActive={x.value === selectedValue}
                isLocked={x.isLocked}
                tabIndex={-1}
                className={
                  x.value === selectedValue ? 'text-shadow' : 'opacity-75'
                }
                onClick={() => {
                  onChange({
                    value: x.value,
                    isLocked: x.isLocked,
                    propagate: true,
                    collapse: true,
                  });
                }}
              />
            ))}
        </DropdownPanel>
      </div>
    );
  },
);

export default BaseSelect;
