import { useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';

const useTicketFilter = () => {
  const navigate = useNavigate();
  const { print_ticket_ids: ticketIds } = useSearch({ strict: false });
  const arrifiedTicketIds = useMemo(
    () =>
      ticketIds
        ? String(decodeURIComponent(ticketIds))
            .split(',')
            .map(x => Number(x))
        : [],
    [ ticketIds ],
  );
  const handleChange = useCallback(
    (ticketId: number) => {
      if (ticketIds === undefined) {
        navigate({
          to: '.',
          search: x => ({
            ...x,
            print_ticket_ids: String(ticketId),
          }),
        });
      } else if (arrifiedTicketIds.includes(ticketId)) {
        const nextState = arrifiedTicketIds.filter(x => x !== ticketId);
        navigate({
          to: '.',
          search: x => ({
            ...x,
            print_ticket_ids:
              nextState.length === 0 ? undefined : nextState.join(','),
          }),
        });
      } else {
        navigate({
          to: '.',
          search: x => ({
            ...x,
            print_ticket_ids: arrifiedTicketIds.concat(ticketId).join(','),
          }),
        });
      }
    },

    [ arrifiedTicketIds, navigate, ticketIds ],
  );
  const handleRemoveFilter = useCallback(() => {
    navigate({ to: '.', search: x => ({ ...x, print_ticket_ids: undefined }) });
  }, [ navigate ]);

  return {
    ticketIds: arrifiedTicketIds,
    handleChange,
    handleRemoveFilter,
  };
};

export default useTicketFilter;
