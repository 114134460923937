import { useMemo } from 'react';
import { LinkComponentProps, useLocation } from '@tanstack/react-router';

const useTargetPlayerSection = () => {
  const { pathname } = useLocation();
  const playerSection =
    pathname.match(
      /(attendance|corrective_actions|memberships|perks|referrals)/,
    )?.[1] ?? '*';

  const targetPlayerSection: LinkComponentProps<'a'>['to'] = useMemo(() => {
    switch (playerSection) {
      case 'attendance':
        return '/$organizationSlug/players/$playerId/attendance';
      case 'corrective_actions':
        return '/$organizationSlug/players/$playerId/corrective_actions';
      case 'memberships':
        return '/$organizationSlug/players/$playerId/memberships';
      case 'perks':
        return '/$organizationSlug/players/$playerId/perks';
      case 'referrals':
        return '/$organizationSlug/players/$playerId/referrals';
      default:
        return '/$organizationSlug/players/$playerId/*';
    }
  }, [ playerSection ]);

  return { targetPlayerSection };
};

export default useTargetPlayerSection;
