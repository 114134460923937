import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useLinkProps,
  useLocation,
  useParams,
  useSearch,
} from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const EventHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, eventId } = useParams({ strict: false });
  const {
    player_id: playerId,
    character_id: characterId,
    checkout_section: checkoutSection,
  } = useSearch({ strict: false });
  const { isPermitted: canAssistEvent } = usePermission({
    action: 'assists_event',
    eventId: Number(eventId),
  });
  const { isPermitted: canAssistLogistics } = usePermission({
    action: 'assists_logistics',
    eventId: Number(eventId),
  });
  const { isPermitted: canAssistDirectorship } = usePermission({
    action: 'assists_directorship',
    eventId: Number(eventId),
  });
  const { isPermitted: canAssistRegistration } = usePermission({
    action: 'checkin_character',
    eventId: Number(eventId),
  });
  const { isPermitted: canManageEvent } = usePermission({
    action: 'manage_event',
    eventId: Number(eventId),
  });
  // const { isPermitted: canAssistMod } = usePermission({
  //   action: 'assists_mod',
  //   eventId: Number( eventId ),
  // });

  const linkParams = {
    organizationSlug: String(organizationSlug),
    eventId: String(eventId),
  };
  const characterSearchParams = {
    player_id: Number(playerId),
    character_id: Number(characterId),
  };
  const playerSearchParams = {
    player_id: Number(playerId),
  };

  const linkToBasics = useLinkProps({
    to: '/$organizationSlug/events/$eventId/basics',
    params: linkParams,
  });
  const linkToTickets = useLinkProps({
    to: '/$organizationSlug/events/$eventId/tickets',
    params: linkParams,
  });
  const linkToShifts = useLinkProps({
    to: '/$organizationSlug/events/$eventId/shifts',
    params: linkParams,
  });
  const linkToWarRoom = useLinkProps({
    to: '/$organizationSlug/events/$eventId/war_room',
    params: linkParams,
  });
  const linkToCheckin = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: linkParams,
  });
  const linkToPrintouts = useLinkProps({
    to: '/$organizationSlug/events/$eventId/printouts',
    params: linkParams,
  });
  const linkToBlueprints = useLinkProps({
    to: '/$organizationSlug/events/$eventId/blueprints/$',
    params: linkParams,
  });
  const linkToLogistics = useLinkProps({
    to: '/$organizationSlug/events/$eventId/logistics',
    params: linkParams,
  });
  const linkToTransactionsAudit = useLinkProps({
    to: '/$organizationSlug/events/$eventId/transactions_audit',
    params: linkParams,
  });
  const linkToCheckout = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
  });
  const linkToCharacterBuild = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...characterSearchParams, checkout_section: 'build' },
  });
  const linkToCharacterJournal = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...characterSearchParams, checkout_section: 'journal' },
  });
  const linkToCharacterXpAudit = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...characterSearchParams, checkout_section: 'xp_audit' },
  });
  const linkToCharacterPrintout = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...characterSearchParams, checkout_section: 'printout' },
  });
  const linkToPlayerBio = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...playerSearchParams, checkout_section: 'bio' },
  });
  const linkToPlayerMemberships = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...playerSearchParams, checkout_section: 'memberships' },
  });
  const linkToPlayerPerks = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...playerSearchParams, checkout_section: 'perks' },
  });
  const linkToPlayerCorrectiveActions = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...playerSearchParams, checkout_section: 'corrective_actions' },
  });
  const linkToPlayerReferrals = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: linkParams,
    search: { ...playerSearchParams, checkout_section: 'referrals' },
  });

  const eventSection =
    pathname.match(
      /(basics|blueprints|checkout|logistics|printouts|shifts|tickets|transactions_audit|war_room)/,
    )?.[1] ?? 'checkin';

  if (!canAssistEvent) return null;

  return (
    <ResponsiveTabGroup label={startCase(eventSection)}>
      {canManageEvent && (
        <Tab label="Basics" to={linkToBasics.href} highlightMode="responsive" />
      )}
      {canAssistRegistration && (
        <Tab
          label="Tickets"
          to={linkToTickets.href}
          highlightMode="responsive"
        />
      )}
      {canAssistEvent && (
        <Tab label="Shifts" to={linkToShifts.href} highlightMode="responsive" />
      )}
      {canAssistDirectorship && (
        <Tab
          label="War Room"
          to={linkToWarRoom.href}
          highlightMode="responsive"
        />
      )}
      {canAssistRegistration && (
        <Tab
          label="Checkin"
          to={linkToCheckin.href}
          highlightMode="responsive"
          isActive={eventSection === 'checkin'}
        />
      )}
      {canManageEvent && (
        <Tab
          label="Printouts"
          to={linkToPrintouts.href}
          highlightMode="responsive"
        />
      )}
      {canAssistLogistics && (
        <>
          <Tab
            label="Blueprints"
            to={linkToBlueprints.href}
            highlightMode="responsive"
            isActive={eventSection === 'blueprints'}
          />
          <Tab
            label="LIT"
            to={linkToLogistics.href}
            highlightMode="responsive"
          />
          <Tab
            label="LUNA"
            to={linkToTransactionsAudit.href}
            highlightMode="responsive"
          />
        </>
      )}
      {canManageEvent && (
        <>
          <Tab
            label={
              eventSection === 'checkout' && playerId ? (
                <div className="flex items-center gap-2">
                  <div>Checkout</div>
                  <FontAwesomeIcon icon={faCaretRight} />
                </div>
              ) : (
                'Checkout'
              )
            }
            to={linkToCheckout.href}
            highlightMode="responsive"
            isActive={eventSection === 'checkout'}
          />
          {eventSection === 'checkout' && playerId && characterId && (
            <>
              <Tab
                label="Build"
                to={linkToCharacterBuild.href}
                highlightMode="responsive"
                isActive={!checkoutSection || checkoutSection === 'build'}
              />
              <Tab
                label="Journal"
                to={linkToCharacterJournal.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'journal'}
              />
              <Tab
                label="XP Audit"
                to={linkToCharacterXpAudit.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'xp_audit'}
              />
              <Tab
                label="Printout"
                to={linkToCharacterPrintout.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'printout'}
              />
            </>
          )}
          {eventSection === 'checkout' && playerId && !characterId && (
            <>
              <Tab
                label="Bio"
                to={linkToPlayerBio.href}
                highlightMode="responsive"
                isActive={!checkoutSection || checkoutSection === 'bio'}
              />
              <Tab
                label="Memberships"
                to={linkToPlayerMemberships.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'memberships'}
              />
              <Tab
                label="Perks"
                to={linkToPlayerPerks.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'perks'}
              />
              <Tab
                label="Corrective Actions"
                to={linkToPlayerCorrectiveActions.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'corrective_actions'}
              />
              <Tab
                label="Referrals"
                to={linkToPlayerReferrals.href}
                highlightMode="responsive"
                isActive={checkoutSection === 'referrals'}
              />
            </>
          )}
        </>
      )}
    </ResponsiveTabGroup>
  );
};

export default EventHeader;
