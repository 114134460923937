import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMatch, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';

const OrganizationHeader: FC = () => {
  const { organizationSlug } = useParams({ strict: false });
  const match = useMatch({ from: '/$organizationSlug/', shouldThrow: false });
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });

  if (!match) return null;
  if (!canCreateNewPlayer) return null;

  return (
    <ResponsiveTabGroup label="Actions">
      {canCreateNewPlayer && (
        <Tab
          label={
            <div>
              <FontAwesomeIcon icon={faPlus} className="pr-2" />
              Create New Player
            </div>
          }
          to={`/${organizationSlug}/players/new`}
          highlightMode="responsive"
        />
      )}
    </ResponsiveTabGroup>
  );
};

export default OrganizationHeader;
