import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';

const OrganizationSettingsHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug } = useParams({ strict: false });

  const isSettingsSection = pathname.includes('settings');
  const subSection = pathname.match(/(special_dates)/)?.[1] ?? 'special_dates';

  const linkToSpecialDates = useLinkProps({
    to: '/$organizationSlug/settings/special_dates',
    params: { organizationSlug: String(organizationSlug) },
  });

  if (!isSettingsSection) return null;

  return (
    <ResponsiveTabGroup label={startCase(subSection)}>
      <Tab
        label="Special Dates"
        highlightMode="responsive"
        to={linkToSpecialDates.href}
        isActive={subSection === 'special_dates'}
      />
    </ResponsiveTabGroup>
  );
};

export default OrganizationSettingsHeader;
