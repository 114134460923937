import {
  faChevronUp,
  faDollar,
  faHiking,
  faHome,
  faPencil,
  faPlus,
  faTicket,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { FC } from 'react';
import Currency from 'src/components/0100_currency';
import {
  IEventAttendeeKindEnum,
  IInvoiceChangeKindEnum,
  ITicketDiscountEnum,
} from 'src/graphql/types';

export type TAttendanceInvoice = {
  id: number;
  kind: IEventAttendeeKindEnum;
  paid: boolean;
  attending: boolean;
  buildGrowth?: number | null;
  baseCost: number;
  extraXpCost: number;
  totalCost: number;
  user: {
    id: number;
    name: string;
  };
  ticket: {
    id: number;
    label: string;
    discountType: ITicketDiscountEnum;
  };
  change?: {
    kind: IInvoiceChangeKindEnum;
    gameKind?: string | null;
    paid?: string | null;
    attending?: string | null;
    ticketLabel?: string | null;
    buildGrowth?: string | null;
  } | null;
};

interface IProps extends TAttendanceInvoice {
  rowIndex: number;
}

const AttendanceInvoice: FC<IProps> = ({
  id,
  rowIndex,
  kind,
  paid,
  attending,
  buildGrowth,
  baseCost,
  extraXpCost,
  totalCost,
  user,
  ticket,
  change,
}) => {
  const { organizationSlug } = useParams({ strict: false });
  const grantsXp =
    (kind === IEventAttendeeKindEnum.HomeGame && paid) || (paid && attending);
  return (
    <>
      <tr>
        <td className="p-1 border border-juno-gray-700 text-right">
          {rowIndex + 1}
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700 text-right',
            !grantsXp && 'opacity-50',
          )}
        >
          {id}
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700 text-center',
            !grantsXp && 'opacity-50',
          )}
        >
          {change?.kind === IInvoiceChangeKindEnum.Addition ? (
            <FontAwesomeIcon icon={faPlus} className="fa-fw" />
          ) : change?.kind === IInvoiceChangeKindEnum.Removal ? (
            <FontAwesomeIcon icon={faTimes} className="fa-fw" />
          ) : (
            <FontAwesomeIcon icon={faPencil} className="fa-fw" />
          )}
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700 text-center',
            !grantsXp && 'opacity-50',
          )}
        >
          {kind === IEventAttendeeKindEnum.HomeGame ? (
            <FontAwesomeIcon icon={faHome} />
          ) : (
            <FontAwesomeIcon icon={faHiking} />
          )}
        </td>
        <td
          className={clsx(
            'p-1 border border-l-4 border-juno-gray-700 w-[256px] min-w-[256px] max-w-[256px] whitespace-nowrap overflow-hidden',
            ticket.discountType === ITicketDiscountEnum.FullCompensation &&
              'border-l-juno-purple-400',
            ticket.discountType === ITicketDiscountEnum.BaseCompensation &&
              'border-l-juno-orange-400',
            !grantsXp && 'opacity-50',
          )}
        >
          {ticket.label}
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700 text-right',
            !grantsXp && 'opacity-50',
          )}
        >
          <Link
            to="/$organizationSlug/players/$playerId/*"
            params={{
              organizationSlug: String(organizationSlug),
              playerId: String(user.id),
            }}
            className="underline"
            target={`_player-${user.id}`}
          >
            {user.id}
          </Link>
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700 w-[256px] min-w-[256px] max-w-[256px] whitespace-nowrap overflow-hidden',
            !grantsXp && 'opacity-50',
          )}
        >
          {user.name}
        </td>
        <td
          className={clsx(
            'p-1 border border-juno-gray-700',
            !grantsXp && 'opacity-50',
          )}
        >
          {(buildGrowth ?? 0) <= 3 ? (
            <div className="h-full flex items-center gap-1">
              {Array.from({ length: buildGrowth ?? 0 }).map((_, i) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={clsx(
                    'h-2 w-2 rounded-full brightness-150',
                    grantsXp && buildGrowth === 1 && 'bg-juno-green-400',
                    grantsXp && buildGrowth === 2 && 'bg-juno-cyan-400',
                    grantsXp && buildGrowth === 3 && 'bg-juno-purple-400',
                    !grantsXp && 'bg-juno-gray-700',
                  )}
                />
              ))}
            </div>
          ) : (
            <div className="text-right">{buildGrowth ?? 0}</div>
          )}
        </td>
        <td className="p-1 border border-juno-gray-700 text-center">
          {paid && <FontAwesomeIcon icon={faDollar} className="fa-fw" />}
        </td>
        <td className="p-1 border border-juno-gray-700 text-center">
          {kind !== IEventAttendeeKindEnum.HomeGame && attending && (
            <FontAwesomeIcon icon={faHiking} className="fa-fw" />
          )}
          {kind === IEventAttendeeKindEnum.HomeGame && paid && (
            <FontAwesomeIcon icon={faHome} className="fa-fw" />
          )}
        </td>
        <td className="p-1 border border-juno-gray-700 text-right">
          <Currency value={baseCost} />
        </td>
        <td className="p-1 border border-juno-gray-700 text-right">
          <Currency value={extraXpCost} />
        </td>
        <td className="p-1 border border-juno-gray-700 text-right">
          <Currency value={totalCost} />
        </td>
      </tr>
      {(change?.attending ||
        change?.paid ||
        change?.ticketLabel ||
        change?.gameKind ||
        change?.buildGrowth) && (
        <tr>
          <td colSpan={2} className="border border-juno-gray-700" />
          <td colSpan={10} className="p-1 border border-juno-gray-700">
            <div className="flex items-center gap-2">
              {change.gameKind && <span>Res: {change.gameKind}</span>}
              {change.paid && (
                <span>
                  <FontAwesomeIcon icon={faDollar} className="fa-fw pr-1" />
                  {change.paid}
                </span>
              )}
              {change.attending && (
                <span>
                  <FontAwesomeIcon
                    icon={
                      kind === IEventAttendeeKindEnum.HomeGame
                        ? faHome
                        : faHiking
                    }
                    className="fa-fw pr-1"
                  />
                  {change.attending}
                </span>
              )}
              {change.ticketLabel && (
                <span>
                  <FontAwesomeIcon icon={faTicket} className="fa-fw pr-1" />
                  {change.ticketLabel}
                </span>
              )}
              {change.buildGrowth && (
                <span>
                  <FontAwesomeIcon icon={faChevronUp} className="fa-fw pr-1" />
                  {change.buildGrowth}
                </span>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default AttendanceInvoice;
