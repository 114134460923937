import { useParams } from '@tanstack/react-router';
import { getEventsInLocation } from 'src/graphql/queries/branches.graphql';
import {
  IGetEventsInLocationQuery,
  IGetEventsInLocationQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import { useQuery } from 'urql';

const useEventsInLocation = ({ locationId }: { locationId: number }) => {
  const { branchId } = useParams({ strict: false });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventsInLocationQuery,
    IGetEventsInLocationQueryVariables
  >({
    query: getEventsInLocation,
    variables: { branchId: Number(branchId), locationId },
    requestPolicy: 'network-only',
    pause: true,
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    events: data?.branch?.location?.events ?? [],
  };
};

export default useEventsInLocation;
