import { startCase } from 'lodash';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import lineageData from 'src/components/0200_lineage_dropdown/data';
import useLineageVariant from 'src/components/0500_character_builders/dystopia_rising/hooks/useLineageVariant';
import useSkillFinder from 'src/components/0500_character_builders/dystopia_rising/hooks/useSkillFinder';
import { faiths } from 'src/components/0400_faith_select';

const Bio: FC = () => {
  const { watch } = useFormContext();
  const { getSkill } = useSkillFinder();

  const { name, lineageId, strainId, variant, faithId, xpEarned, xpUsed } =
    watch();

  const lineage = lineageData.find(x => x.id === lineageId);
  const strain = lineage?.strains.find(x => x.id === strainId);
  const faith = faiths.find(x => x.value === faithId);
  const { advantage, disadvantage } = useLineageVariant();

  const bm = getSkill('Black Market')?.positions?.length ?? 0;
  const mi = getSkill('Murder Inc')?.positions?.length ?? 0;

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2} className="py-1">
            Character
          </th>
        </tr>
      </thead>
      <tbody className="grid-1-2">
        <tr>
          <td>Name</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>Faith</td>
          <td>{faith?.label}</td>
        </tr>
        <tr>
          <td>Strain</td>
          <td>{`${lineage?.name}::${strain?.name}`}</td>
        </tr>
        <tr>
          <td>Variant</td>
          <td>{startCase(variant)}</td>
        </tr>
        {advantage && (
          <tr>
            <td>Advantage</td>
            <td>{advantage}</td>
          </tr>
        )}
        {disadvantage && (
          <tr>
            <td>Disadvantage</td>
            <td>{disadvantage}</td>
          </tr>
        )}
        {bm + mi > 0 && (
          <tr>
            <td>Reputation</td>

            <td>
              <div className="flex justify-between w-full text-xs">
                {bm > 0 && <div className="w-full">Black Market: {bm}</div>}
                {mi > 0 && <div className="w-full">Murder Inc: {mi}</div>}
              </div>
            </td>
          </tr>
        )}
        <tr>
          <td>XP</td>
          <td className="text-right">{`${xpUsed}/${xpEarned}`}</td>
        </tr>
        <tr>
          <td>Available</td>
          <td className="text-right">{`${xpEarned - xpUsed}`}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Bio;
