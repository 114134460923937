import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ICharacterFellowship,
  IFellowship,
  IFellowshipKindEnum,
} from 'src/graphql/types';

const rows = 3;

interface IProps {
  type: IFellowshipKindEnum;
}

const title = {
  [IFellowshipKindEnum.Disease]: 'Diseases',
  [IFellowshipKindEnum.Society]: 'Society Memberships',
};

type TCharacterFellowship = Pick<
  ICharacterFellowship,
  'id' | 'rank' | 'removedAt'
> & {
  fellowship: Pick<IFellowship, 'id' | 'name' | 'kind'>;
};

const Fellowships: FC<IProps> = ({ type }) => {
  const { watch } = useFormContext();
  const { characterFellowships } = watch();
  const filteredCharacterFellowships = (
    characterFellowships as TCharacterFellowship[]
  ).filter(x => x.fellowship.kind === type);

  const actualRows = Math.max(rows, filteredCharacterFellowships.length);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>{title[type]}</th>
        </tr>
      </thead>
      <tbody className="grid-4-1 numeric-last">
        {[ ...Array(actualRows) ].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            {filteredCharacterFellowships[i] ? (
              <>
                <td>{filteredCharacterFellowships[i].fellowship.name}</td>
                <td>{filteredCharacterFellowships[i].rank + 1}</td>
              </>
            ) : (
              <>
                <td className="text-transparent">_</td>
                <td className="text-transparent">_</td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Fellowships;
