import { FC } from 'react';

export interface ITooltipProps {
  name: string;
  value: number;
}

const Tooltip: FC<ITooltipProps> = ({ name, value }) => (
  <div className="midtone-box text-juno-gray-50 p-2 rounded-xs">
    {name}: {value}
  </div>
);

export default Tooltip;
