import { useEffect } from 'react';
import { UseQueryExecute } from 'urql';
import { useParams } from '@tanstack/react-router';
import useAuth from './auths/useAuth';

interface IProps {
  refetch: UseQueryExecute;
}
const useRootUserRevalidation = ({ refetch }: IProps) => {
  const { isRootUserValidated } = useAuth();
  const {
    organizationSlug,
    eventId,
    playerId,
    _splat: itemId,
  } = useParams({
    strict: false,
  });

  useEffect(() => {
    if (isRootUserValidated) {
      refetch();
    }
  }, [
    isRootUserValidated,
    refetch,
    organizationSlug,
    eventId,
    playerId,
    itemId,
  ]);
};

export default useRootUserRevalidation;
