import { useQuery } from 'urql';
import {
  IGetEventAttendeeQuery,
  IGetEventAttendeeQueryVariables,
} from 'src/graphql/queries/eventAttendee.graphql.types';
import { getEventAttendee } from 'src/graphql/queries/eventAttendee.graphql';
import { useParams, useSearch } from '@tanstack/react-router';
import useRootUser from '../players/useRootUser';
import usePermission from '../permissions/usePermissions';
import useRootUserRevalidation from '../useRootUserRevalidation';
import usePlayerWithCharactersInOrganization from '../players/usePlayerWithCharactersInOrganization';

const useEventAttendee = () => {
  const {
    rootUser: { id },
  } = useRootUser();
  const { eventId } = useParams({ strict: false });
  const { player_id: playerIdFromURL } = useSearch({ strict: false });
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number(eventId),
  });

  const playerId = canAssistCheckin ? Number(playerIdFromURL ?? id) : id;
  const { player, characters } = usePlayerWithCharactersInOrganization({
    playerId,
  });

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventAttendeeQuery,
    IGetEventAttendeeQueryVariables
  >({
    query: getEventAttendee,
    variables: {
      eventId: Number(eventId),
      playerId,
    },
    requestPolicy: 'network-only',
    pause: Number(eventId) === 0 || !playerId,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    event: data?.event,
    eventAttendee: data?.event?.eventAttendee,
    player,
    characters,
  };
};

export default useEventAttendee;
