import { useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback } from 'react';

const useSideSelect = () => {
  const { sides } = useSearch({ strict: false });
  const navigate = useNavigate();
  const handleChange = useCallback(
    ({ front, back }: { front: boolean; back: boolean }) => {
      if (front && back) {
        navigate({ to: '.', search: x => ({ ...x, sides: 'both' }) });
      } else if (!front && back) {
        navigate({ to: '.', search: x => ({ ...x, sides: 'back' }) });
      } else {
        navigate({ to: '.', search: x => ({ ...x, sides: 'front' }) });
      }
    },
    [ navigate ],
  );

  const printFront = !sides || [ 'front', 'both' ].includes(sides ?? '');
  const printBack = [ 'back', 'both' ].includes(sides ?? '');
  const printBoth = printFront && printBack;

  return {
    printFront,
    printBack,
    printBoth,
    handleChange,
  };
};

export default useSideSelect;
