import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { startCase } from 'lodash';
import { IEventConfig } from 'src/graphql/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import Collapsible from 'src/components/0100_collapsible';
import Ticket, { TTicket } from './Ticket';
import NewTicket from './NewTicket';
import { TOnFilterChange } from './Ticket/TicketAttendees';
import { TAttendee } from './Ticket/TicketAttendees/Attendee';

interface IProps {
  expandedTicketIds: number[];
  name: string;
  adjustableShift: boolean;
  config?: IEventConfig;
  tickets: TTicket[];
  attendees: TAttendee[];
  onExpand: (id: number) => void;
  onFilterChange?: ({
    identifier,
    length,
    hasSearchQuery,
  }: TOnFilterChange) => void;
}

const boxStyleMap: Record<string, string> = {
  new_player_restricted: 'green-box',
  unrestricted: 'cyan-box',
  assistant_restricted: 'orange-box',
  employee_restricted: 'purple-box',
};

const borderColorMap: Record<string, string> = {
  new_player_restricted: 'border-juno-green-200',
  unrestricted: 'border-juno-cyan-200',
  assistant_restricted: 'border-juno-orange-200',
  employee_restricted: 'border-juno-purple-200',
};

const TicketGroup: FC<IProps> = ({
  expandedTicketIds,
  name,
  adjustableShift,
  config,
  tickets,
  attendees,
  onExpand,
  onFilterChange,
}) => {
  const { watch } = useFormContext();
  const { showRevenue } = watch();
  const [ isNewTicketPanelOpen, setIsNewTicketPanelOpen ] = useState(false);
  const [ hasSearchQuery, setHasSearchQuery ] = useState(false);
  const [ searchMatches, setSearchMatches ] = useState<{ [key: string]: number }>(
    {},
  );
  const filteredAttendeesLength = useMemo(
    () => Object.values(searchMatches).reduce((a, b) => a + b, 0),
    [ searchMatches ],
  );
  const hasAttendees =
    !hasSearchQuery || (hasSearchQuery && filteredAttendeesLength > 0);
  const niceName = startCase(name).replace('Restricted', '');
  const handleFilterChange = useCallback(
    ({ identifier, length, hasSearchQuery }: TOnFilterChange) => {
      setHasSearchQuery(hasSearchQuery);
      setSearchMatches(x => ({
        ...x,
        [identifier ?? '']: length,
      }));
    },
    [],
  );

  useEffect(() => {
    onFilterChange?.({
      identifier: name,
      length: filteredAttendeesLength,
      hasSearchQuery,
    });
  }, [ filteredAttendeesLength, hasSearchQuery, name, onFilterChange ]);

  return (
    <>
      <tr
        className={clsx(
          hasAttendees ? 'saturate-100 opacity-100' : 'saturate-50 opacity-50',
        )}
      >
        <td colSpan={showRevenue ? 8 : 4}>
          <button
            type="button"
            className={clsx(
              'py-2 px-4 flex items-center justify-between w-full',
              boxStyleMap[name] ?? 'midtone-box',
            )}
            onClick={() => setIsNewTicketPanelOpen(x => !x)}
          >
            <div>{niceName === 'Assistant ' ? 'Guide' : niceName}</div>
            <FontAwesomeIcon
              icon={faPlus}
              className={clsx(
                'transition-all',
                isNewTicketPanelOpen && 'rotate-[225deg]',
              )}
            />
          </button>
          <Collapsible
            isExpanded={isNewTicketPanelOpen}
            classNames={{
              parent: {
                steadyState: `mb-2 ${borderColorMap[name] ?? 'border-juno-gray-700'}`,
                expanded: 'border-b-2',
                collapsed: 'border-b-0',
              },
            }}
          >
            <NewTicket
              adjustableShift={adjustableShift}
              config={config}
              name={name}
            />
          </Collapsible>
        </td>
      </tr>

      {tickets.length > 0 ? (
        tickets
          .sort((a, b) => a.id - b.id)
          .map(ticket => (
            <Ticket
              key={ticket.id}
              ticket={ticket}
              attendees={attendees.filter(x => x.ticket.id === ticket.id)}
              isExpanded={expandedTicketIds.includes(ticket.id)}
              extraXpCost={Number(config?.extraXpCost)}
              onExpand={() => onExpand(ticket.id)}
              onFilterChange={handleFilterChange}
            />
          ))
      ) : (
        <tr>
          <td
            colSpan={showRevenue ? 8 : 4}
            className="pb-2 px-2 text-juno-gray-700"
          >
            No Tickets Available
          </td>
        </tr>
      )}
    </>
  );
};

export default TicketGroup;
