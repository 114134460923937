import { format, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import lineageData from 'src/components/0200_lineage_dropdown/data';
import useXp from 'src/components/0500_character_builders/dystopia_rising/hooks/useXp';

const Stats: FC = () => {
  const { watch } = useFormContext();
  const { lineageId, fractures, stats, latestLifecycleGainsThisYear } = watch();
  const { statsCost } = useXp();
  const mindPenalty = useMemo(() => {
    switch (fractures.length ?? 0) {
      case 0:
        return null;
      case 1:
        return 5;
      case 2:
        return 15;
      case 3:
        return 30;
      default:
        return 50;
    }
  }, [ fractures.length ]);

  const lineage = lineageData.find(x => x.id === lineageId);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2} className="py-1">
            Stats
            {statsCost > 0 && ` (${statsCost}XP)`}
          </th>
        </tr>
      </thead>
      <tbody className="grid-1-2 numeric-last">
        <tr>
          <td>Body</td>
          <td>
            {lineage && lineageId > 0
              ? `${lineage.body} + ${stats.body} = ${lineage.body + stats.body}`
              : '0'}
          </td>
        </tr>
        <tr>
          <td>Mind</td>
          <td>
            {lineage && lineageId > 0
              ? `${lineage.mind} + ${stats.mind}${mindPenalty ? ` - ${mindPenalty}` : ''} = ${lineage.mind + stats.mind - (mindPenalty ?? 0)}`
              : '0'}
          </td>
        </tr>
        <tr>
          <td>Resolve</td>
          <td>
            {'3 + '}
            {stats.resolve}
            {` = ${3 + stats.resolve}`}
          </td>
        </tr>
        <tr>
          <td>Infection</td>
          <td>{`${stats.currentLife}/${stats.lifeCapacity}`}</td>
        </tr>
        <tr>
          <td>Gains</td>
          <td>
            {latestLifecycleGainsThisYear
              .sort((a: string, b: string) => a.localeCompare(b))
              .map((x: string) => format(parseISO(x), 'MMM d'))
              .join(', ')}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Stats;
