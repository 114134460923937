import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import AugmentedInput from 'src/components/0200_augmented_input';
import Modal from 'src/components/0300_modal';
import { ILoginPayload } from 'src/hooks/auths/types';
import useAuth from 'src/hooks/auths/useAuth';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';

interface IProps {
  isOpen: boolean;
  isValidated?: boolean;
  isFetching?: boolean;
  error?: string | null;
  onClose: () => void;
  onLogin: (payload: ILoginPayload) => void;
}

const LoginModal: FC<IProps> = ({
  isOpen,
  isValidated,
  isFetching,
  error,
  onClose,
  onLogin,
}) => {
  const { openModal } = useAuth();
  const { buttonState } = useButtonStates();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { username: '', password: '' },
  });
  const {
    getValues,
    register,
    reset,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = methods;

  const handleLogin = useCallback(
    () => isValid && onLogin(getValues()),
    [ isValid, onLogin, getValues ],
  );

  useEffect(() => {
    if (isValidated) {
      reset();
    }
  }, [ isValidated, reset ]);

  useEffect(() => {
    if (isOpen) {
      setFocus('username');
    }
  }, [ setFocus, isOpen ]);

  return (
    <Modal title="Sign In" isOpen={isOpen}>
      <FormProvider {...methods}>
        <AugmentedInput
          required
          title="Email Address"
          placeholder="Or Player ID"
          error={errors.username?.message}
          {...register('username', {
            required: { value: true, message: 'Cannot be blank' },
            minLength: 1,
          })}
        />
        <AugmentedInput
          required
          title="Password"
          type="password"
          error={errors.password?.message}
          placeholder="Sshhh..."
          onEnter={handleLogin}
          {...register('password', {
            required: { value: true, message: 'Cannot be blank' },
            minLength: 1,
          })}
        />

        <div className="flex">
          <div className="w-2/5" />
          <div className="w-3/5">
            <button
              type="button"
              className="pl-1 text-juno-gray-200 hover:text-juno-gray-50 hover:underline cursor-pointer"
              onClick={() =>
                openModal({
                  modal: 'forgotPassword',
                  args: { username: getValues('username') },
                })
              }
            >
              Forgot Password?
            </button>
          </div>
        </div>

        {error && (
          <div className="text-juno-orange-200 text-center py-2">{error}</div>
        )}

        <div className="flex justify-end p-2 pr-4 pb-4 w-full">
          <Button
            defaultLabel="Cancel"
            onClick={() => {
              reset();
              onClose();
            }}
          />
          <Button
            className="ml-2"
            defaultLabel="Sign In"
            state={buttonState({
              isHighlight: true,
              isSuccessful: isValidated,
              isDirty,
              isFetching,
              isValid,
            })}
            stateLabel={{
              loading: 'Signing In...',
              success: 'Signed In!',
            }}
            onClick={handleLogin}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};

export default LoginModal;
