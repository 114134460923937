import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Slider from 'rc-slider';
import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import Title from 'src/components/0100_title';
import BaseSelect from 'src/components/0300_base_select';
import { ITicket, ITicketDiscountEnum } from 'src/graphql/types';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import usePermission from 'src/hooks/permissions/usePermissions';

type TTicket = Pick<ITicket, 'restrictionType' | 'identifier'>;

const restrictionTypeSort = [
  'new_player_restricted',
  'unrestricted',
  'assistant_restricted',
  'employee_restricted',
];

const sortFunctor = (a: TTicket, b: TTicket) => {
  if (a.restrictionType === b.restrictionType) {
    return a.identifier - b.identifier;
  }

  return (
    restrictionTypeSort.indexOf(a.restrictionType) -
    restrictionTypeSort.indexOf(b.restrictionType)
  );
};

const TicketSelector: FC = () => {
  const { event, eventAttendee } = useEventAttendee();
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number(event?.id),
  });
  const { register, setValue, watch } = useFormContext();
  const { buildGrowth, ticketId } = watch();
  const config = event?.config;
  const selectedTicket = event?.tickets.find(x => x.id === ticketId);
  const extraXpCost =
    selectedTicket?.discountType === ITicketDiscountEnum.FullCompensation
      ? 0
      : (config?.extraXpCost ?? 0);
  const extraXpCharge = buildGrowth * extraXpCost;

  const humanizeShiftResponsibilities = useCallback(
    (x: number, label?: string) => {
      if (x === 0) return '';
      if (label?.match(/\d+hr?/i)) return '';

      const text = [
        x * (config?.shiftMultiplier ?? 1),
        config?.shiftUnit[0].toUpperCase() ?? '',
      ].join('');

      return `[${text}]`;
    },
    [ config ],
  );

  return (
    <div className="grid">
      <Title variant="heading">
        <div className="text-2xl flex justify-between">
          <div>Ticket</div>
          {ticketId && (
            <div>{`$${selectedTicket?.cost ?? eventAttendee?.ticket.cost}`}</div>
          )}
        </div>
      </Title>
      {event?.tickets ? (
        <BaseSelect
          customSort
          withSearch
          isLocked={!!eventAttendee && !canAssistCheckin}
          selectedValue={ticketId}
          defaultLabel={
            eventAttendee && ticketId
              ? `$${eventAttendee.ticket.cost} ${eventAttendee.ticket.label}`
              : 'Select a Ticket'
          }
          width="w-full"
          options={event?.tickets.sort(sortFunctor).map(ticket => ({
            label: [
              `$${ticket.cost}`,
              ticket.label,
              `${humanizeShiftResponsibilities(ticket.shiftResponsibilities, ticket.label)}`,
            ].join(' '),
            value: ticket.id,
            isLocked: ticket.fullyBooked,
          }))}
          {...register('ticketId')}
        />
      ) : (
        <div>
          <Loading />
        </div>
      )}
      {(config?.baseBuild ?? 0) > 0 && (
        <ResponseBox type="info" withIcon={faCirclePlus}>
          {`This Game adds +${config?.baseBuild}XP`}
        </ResponseBox>
      )}

      <Title variant="heading">
        <div className="text-2xl flex justify-between">
          <div>Extra XP</div>
          {extraXpCharge > 0 && (
            <div>{`$${buildGrowth * (config?.extraXpCost ?? 0)}`}</div>
          )}
        </div>
      </Title>
      {event ? (
        <div className="grid gap-2">
          <div className="pt-2">Applies to all Checked-in Characters</div>
          <div className="flex items-center gap-4">
            <div className="px-1 w-full">
              <Slider
                dots
                disabled={!!eventAttendee && !canAssistCheckin}
                min={0}
                max={event.config.maxExtraXp}
                value={buildGrowth}
                onChange={x =>
                  setValue('buildGrowth', x, { shouldDirty: true })
                }
              />
            </div>
            <div className="w-16 min-w-16 max-w-16 whitespace-nowrap text-right">
              {buildGrowth === 0 ? '0XP' : `+${buildGrowth}XP`}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </div>
  );
};

export default TicketSelector;
