import { getCharacterLifecycles } from 'src/graphql/queries/characters.graphql';
import {
  IGetCharacterLifecyclesQuery,
  IGetCharacterLifecyclesQueryVariables,
} from 'src/graphql/queries/characters.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useCharacterLifecycles = ({
  playerId,
  characterId,
}: {
  playerId: number;
  characterId: number;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetCharacterLifecyclesQuery,
    IGetCharacterLifecyclesQueryVariables
  >({
    query: getCharacterLifecycles,
    variables: { playerId, characterId },
    requestPolicy: 'cache-and-network',
    pause: !(playerId > 0 && characterId > 0),
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    lifecycles: data?.user?.character?.lifecycles,
  };
};

export default useCharacterLifecycles;
