import { Theme } from '@nivo/core';

const theme: Theme = {
  text: {
    fill: '#fff',
    fontFamily: 'Dax',
    fontSize: 14,
  },
  crosshair: {
    line: {
      stroke: '#ccc',
    },
  },
  grid: {
    line: {
      stroke: '#444',
      strokeWidth: 1,
    },
  },
  tooltip: {
    container: {
      background: '#333',
    },
  },
  axis: {
    legend: {
      text: {
        fontSize: 14,
      },
    },
    ticks: {
      text: {
        fontSize: 12,
        fill: '#ddd',
      },
    },
  },
  labels: {
    text: {
      fontSize: 12,
    },
  },
};

export default theme;
