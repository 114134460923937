import { FC, PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

interface IProps {
  title?: string | ReactNode;
  variant?: 'glass' | 'green-glass' | 'heading';
}

const Title: FC<PropsWithChildren<IProps>> = ({
  title,
  children,
  variant = 'glass',
}) => (
  <div
    className={clsx(
      variant === 'glass' && 'py-4 mb-4 glass-pane text-center',
      variant === 'heading' && 'mt-4 mb-2 border-b border-juno-gray-200',
    )}
  >
    <div className={clsx('text-2xl', variant === 'glass' && 'md:text-4xl')}>
      {title}
    </div>
    <div>{children}</div>
  </div>
);

export default Title;
