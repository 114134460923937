import { useParams } from '@tanstack/react-router';
import { getEventTransactions } from 'src/graphql/queries/events.graphql';
import {
  IGetEventTransactionsQuery,
  IGetEventTransactionsQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useEventTransactions = () => {
  const { eventId } = useParams({ strict: false });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventTransactionsQuery,
    IGetEventTransactionsQueryVariables
  >({
    query: getEventTransactions,
    variables: { eventId: Number(eventId) },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    transactions: data?.event?.itemTransactions || [],
  };
};

export default useEventTransactions;
