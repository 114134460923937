import { useEffect, type FC, type PropsWithChildren } from 'react';
import useAuth from 'src/hooks/auths/useAuth';
import { useLocation } from '@tanstack/react-router';
import AuthenticationModal from './AuthenticationModal';
import CheckinEditorModal from '../EventLayer/CheckinEditorModal';

const pathsBypassingModal = [
  '/',
  '/calendar',
  '/dystopia-rising',
  '/dystopia-rising/branches',
  '/dystopia-rising/characters',
  '/dystopia-rising/events',
];

const AuthenticationLayer: FC<PropsWithChildren> = ({ children }) => {
  const { closeModal, openModal, revalidateToken, isRootUserValidated } =
    useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    revalidateToken();
  }, [ revalidateToken ]);

  useEffect(() => {
    if (isRootUserValidated) {
      closeModal();
    } else if (!pathsBypassingModal.includes(pathname)) {
      openModal({ modal: 'login' });
    }
  }, [ closeModal, isRootUserValidated, openModal, pathname ]);

  return (
    <div>
      <AuthenticationModal />
      <CheckinEditorModal />
      {children}
    </div>
  );
};

export default AuthenticationLayer;
