import { FC } from 'react';
import { IPropsWithOnUpdate } from '../../types';
import SingleItemEditor from '../SingleItemEditor';

type TOutput = {
  id: number;
  name: string;
  metadata: {
    mechanics?: string | null;
    requirementsToUse?: string | null;
    uses?: string | null;
  };
};

export type TItemCraftingOutputMechanics = {
  id: number;
  craftingFinalProducts?: {
    id: number;
    finalProduct: TOutput & {
      childItemClassifications: {
        id: number;
        childItem: TOutput;
      }[];
    };
  }[];
};

interface IProps extends IPropsWithOnUpdate {
  id: number;
  baseMechanics?: string | null;
  itemCraftings: TItemCraftingOutputMechanics[];
}

const Mechanics: FC<IProps> = ({
  id,
  baseMechanics,
  itemCraftings,
  onUpdate,
}) => {
  const craftingFinalProducts = itemCraftings
    ? itemCraftings[0]?.craftingFinalProducts
    : null;
  const finalProduct = craftingFinalProducts
    ? craftingFinalProducts[0]?.finalProduct
    : null;
  const actualProducts = finalProduct
    ? finalProduct.childItemClassifications.length > 0
      ? finalProduct.childItemClassifications.map(x => x.childItem)
      : [ finalProduct ]
    : null;

  if (!actualProducts)
    return (
      <td>
        <SingleItemEditor
          itemId={id}
          field="mechanics"
          initialValue={baseMechanics}
          onUpdate={onUpdate}
        />
      </td>
    );

  return (
    <td>
      {actualProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(product => (
          <SingleItemEditor
            key={product.id}
            itemId={product.id}
            field="mechanics"
            initialValue={product.metadata.mechanics}
            onUpdate={onUpdate}
          />
        ))}
    </td>
  );
};

export default Mechanics;
