import { FC, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faLock,
  faTimes,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';
import clsx from 'clsx';

import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import Loading from 'src/components/0100_loading';
import useCharacterBuilderStore from 'src/stores/characterBuilder';
import useCharacter from 'src/hooks/characters/useCharacter';
import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';

const CharacterHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, playerId, characterId } = useParams({
    strict: false,
  });
  const { actions, isSafetyLockActive, isSaving, isSaved, error } =
    useCharacterBuilderStore();
  const { canDisableSafetyLock, canEditSensitiveData } = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  const isCharacterSection = pathname.includes('characters');
  const characterSubSection =
    pathname.match(/(build|inventory|journal|xp_audit|printout)/)?.[1] ??
    'build';

  const showLock = characterSubSection === 'build';
  const linkParams = {
    organizationSlug: String(organizationSlug),
    playerId: String(playerId),
    characterId: String(characterId),
  };
  const linkToBuild = useLinkProps({
    to: '/$organizationSlug/players/$playerId/characters/$characterId/*',
    params: linkParams,
  });
  const linkToInventory = useLinkProps({
    to: '/$organizationSlug/players/$playerId/characters/$characterId/inventory',
    params: linkParams,
  });
  const linkToJournal = useLinkProps({
    to: '/$organizationSlug/players/$playerId/characters/$characterId/journal',
    params: linkParams,
  });
  const linkToXpAudit = useLinkProps({
    to: '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit',
    params: linkParams,
  });
  const linkToPrintout = useLinkProps({
    to: '/$organizationSlug/players/$playerId/characters/$characterId/printout',
    params: linkParams,
  });

  useEffect(() => {
    actions.lock();
  }, [ characterId, actions ]);

  if (!isCharacterSection || !characterId) return null;

  return (
    <div className="flex items-center gap-4">
      {isSaving && <Loading size="small" />}
      {isSaved && <FontAwesomeIcon icon={faCheck} />}
      {error && <FontAwesomeIcon icon={faTimes} className="animate-pulse" />}
      {showLock && (
        <button
          type="button"
          disabled={!canDisableSafetyLock}
          onClick={() => canDisableSafetyLock && actions.toggleLock()}
        >
          <FontAwesomeIcon
            icon={isSafetyLockActive ? faLock : faUnlock}
            className={clsx(
              canDisableSafetyLock
                ? 'text-juno-gray-50 cursor-pointer'
                : 'text-juno-gray-700 cursor-not-allowed',
            )}
          />
        </button>
      )}
      <ResponsiveTabGroup label={startCase(characterSubSection)}>
        <Tab
          label="Build"
          to={linkToBuild.href}
          highlightMode="responsive"
          isActive={characterSubSection === 'build'}
        />
        <Tab
          label="Inventory"
          to={linkToInventory.href}
          highlightMode="responsive"
        />
        <Tab
          label="Journal"
          to={linkToJournal.href}
          highlightMode="responsive"
        />
        <Tab
          label="XP Audit"
          to={linkToXpAudit.href}
          highlightMode="responsive"
        />
        {canEditSensitiveData && (
          <Tab
            label="Printout"
            to={linkToPrintout.href}
            highlightMode="responsive"
          />
        )}
      </ResponsiveTabGroup>
    </div>
  );
};

export default CharacterHeader;
