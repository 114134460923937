import { format } from 'date-fns';
import { getEvents } from 'src/graphql/queries/events.graphql';
import {
  IGetEventsQuery,
  IGetEventsQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

interface IProps {
  start: Date;
  end: Date;
  organizationIds?: number[];
  branchIds?: number[];
  pause?: boolean;
}

const useEvents = ({
  start,
  end,
  organizationIds,
  branchIds,
  pause,
}: IProps) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventsQuery,
    IGetEventsQueryVariables
  >({
    query: getEvents,
    variables: {
      from: format(start, 'yyyy-MM-dd'),
      upto: format(end, 'yyyy-MM-dd'),
      organizationIds,
      branchIds,
    },
    requestPolicy: 'cache-and-network',
    pause,
  });

  useRootUserRevalidation({ refetch });

  return { events: data?.events || [], error, fetching: fetching || stale };
};

export default useEvents;
