import { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { faChevronDown, faHammer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePermission from 'src/hooks/permissions/usePermissions';
import SidebarLink from 'src/components/0100_sidebar_link';
import Collapsible from 'src/components/0100_collapsible';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import {
  Link,
  LinkComponentProps,
  useLocation,
  useMatch,
  useParams,
} from '@tanstack/react-router';

const OrganizationSidebar: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, branchId } = useParams({ strict: false });
  const { isPermitted: canUseNetworkTools } = usePermission({
    action: 'network_tools',
  });
  const { branches } = useOrganizationBranches({ owned: true });
  const [ isExpanded, setIsExpanded ] = useState(false);
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });
  const { register, watch } = useForm({ defaultValues: { branchQuery: '' }});
  const { branchQuery } = watch();
  const normalizedBranchQuery = branchQuery.trim().toLowerCase();
  const linkParams = { organizationSlug: String(organizationSlug) };
  const organizationRoute = useMatch({
    from: '/$organizationSlug/',
    shouldThrow: false,
  });

  const branchSection =
    pathname.match(
      /(basics|locations|attendance|caps|corrective_actions)/,
    )?.[1] ?? 'basics';

  const targetBranchSection: LinkComponentProps<'a'>['to'] = useMemo(() => {
    switch (branchSection) {
      case 'locations':
        return '/$organizationSlug/branches/$branchId/locations';
      case 'attendance':
        return '/$organizationSlug/branches/$branchId/attendance';
      case 'caps':
        return '/$organizationSlug/branches/$branchId/caps';
      case 'corrective_actions':
        return '/$organizationSlug/branches/$branchId/corrective_actions';
      default:
        return '/$organizationSlug/branches/$branchId/*';
    }
  }, [ branchSection ]);

  if (!organizationSlug) return null;
  if (!canUseNetworkTools) return null;

  return (
    <div className="gray-box-angled">
      <button
        type="button"
        className="flex justify-between w-full px-2 py-1 text-left font-bold"
        onClick={() => setIsExpanded(x => !x)}
      >
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faHammer} className="fa-fw" /> Network Tools
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(
            'px-2 mt-1 transition-transform duration-300',
            isExpanded && '-scale-y-100',
          )}
        />
      </button>
      <Collapsible
        isExpanded={isExpanded}
        classNames={{ parent: { steadyState: 'gray-box-angled gap-2' }}}
      >
        <div
          className={clsx(
            'border-l-4 transition-all duration-300',
            organizationRoute || branchId
              ? 'border-juno-gray-200'
              : 'border-transparent hover:border-juno-gray-200',
          )}
        >
          <Link
            to="/$organizationSlug"
            params={{ organizationSlug: String(organizationSlug) }}
            className={clsx(
              'border-l-4 py-1 pl-1 transition-all duration-300 flex items-center',
              organizationRoute
                ? 'text-juno-gray-50'
                : 'border-transparent hover:border-juno-gray-200 hover:text-shadow hover:text-juno-gray-50 text-juno-gray-200',
            )}
          >
            <div>Branches Overview</div>
          </Link>
          <Collapsible isExpanded={!!organizationRoute || !!branchId}>
            {branches.length > 4 && (
              <div className="w-full py-1 pl-2 pr-2">
                <Input
                  fullWidth
                  placeholder="Search Branch"
                  {...register('branchQuery')}
                />
              </div>
            )}
            <div className="max-h-[50vh] overflow-auto">
              {branches
                .filter(x =>
                  normalizedBranchQuery.length === 0
                    ? x
                    : x.name.toLowerCase().includes(normalizedBranchQuery) ||
                      x.shorthand
                        .toLowerCase()
                        .includes(normalizedBranchQuery) ||
                      x.region.toLowerCase().includes(normalizedBranchQuery),
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(branch => (
                  <SidebarLink
                    key={branch.id}
                    label={branch.name}
                    to={targetBranchSection}
                    params={{ ...linkParams, branchId: String(branch.id) }}
                    className="pl-3"
                  />
                ))}
            </div>
          </Collapsible>
        </div>

        {canManageOrganization && (
          <SidebarLink
            label="Invoices"
            to="/$organizationSlug/invoice"
            params={linkParams}
          />
        )}

        <SidebarLink
          label="Mechanics"
          to="/$organizationSlug/mechanics"
          params={linkParams}
        />

        <SidebarLink
          label="Reports"
          to="/$organizationSlug/reports"
          params={linkParams}
        />

        <SidebarLink
          label="Settings"
          to="/$organizationSlug/settings/special_dates"
          params={linkParams}
        />

        <SidebarLink
          label="Statistics"
          to="/$organizationSlug/statistics"
          params={linkParams}
        />
      </Collapsible>
    </div>
  );
};

export default OrganizationSidebar;
