import { useMatch, useNavigate, useSearch } from '@tanstack/react-router';
import { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'src/components/0300_modal';
import DystopiaRisingCheckinForm from 'src/components/0500_checkin_forms/dystopia_rising';
import useEventAttendees from 'src/hooks/events/useEventAttendees';
import useEventTickets from 'src/hooks/events/useEventTickets';

const CheckinEditorModal: FC = () => {
  const navigate = useNavigate();
  const isTicketingSection = useMatch({
    from: '/$organizationSlug/events/$eventId/tickets',
    shouldThrow: false,
  });
  const { player_id: playerId } = useSearch({ strict: false });
  const { refetch: refetchAttendees } = useEventAttendees({});
  const { refetch: refetchTickets } = useEventTickets({});
  const [ isOpen, setIsOpen ] = useState(false);
  const closeModal = useCallback(() => {
    navigate({ to: '.', search: x => ({ ...x, player_id: undefined }) });
  }, [ navigate ]);

  useEffect(() => {
    if (isTicketingSection) {
      setIsOpen(Number(playerId) > 0);
    }
  }, [ isTicketingSection, playerId ]);

  return (
    <Modal
      withCloseButton
      title="Edit Checkin"
      type="actionable"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <div className="overflow-auto max-h-[66vh] px-2 md:px-8">
        <DystopiaRisingCheckinForm
          forceSingleLine
          onMutationSuccess={() => {
            refetchAttendees();
            refetchTickets();
          }}
        />
      </div>
    </Modal>
  );
};

export default CheckinEditorModal;
