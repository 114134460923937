import { createFileRoute, Outlet } from '@tanstack/react-router';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import useBranch from 'src/hooks/organizations/branches/useBranch';

const Branch = () => {
  const { data } = useBranch({});

  return (
    <div>
      <Title title={data?.branch?.name ?? <Loading />} />
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute('/$organizationSlug/branches/$branchId')({
  component: Branch,
});
