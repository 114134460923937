import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import { FC, forwardRef } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {
  owned?: boolean;
  defaultValue?: string;
}

const BranchSelect: FC<IProps> = forwardRef(
  ({ owned = false, defaultValue, ...props }, ref) => {
    const { branches } = useOrganizationBranches({ owned });
    const { homeBranch: rootUserHomeBranch } = useRootUser();

    if (owned && branches.length === 0) {
      return (
        <BaseSelect
          isLocked
          options={[
            {
              value: 0,
              label: defaultValue ?? rootUserHomeBranch?.shorthand ?? '',
            },
          ]}
          {...props}
          selectedValue={0}
        />
      );
    }

    return (
      <BaseSelect
        withSearch
        options={branches.map(x => ({
          value: x.id,
          label: `${x.shorthand} - ${x.name}`,
        }))}
        {...props}
      />
    );
  },
);

export default BranchSelect;
