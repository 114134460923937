import { useParams, useSearch } from '@tanstack/react-router';

const usePlayerIdFromUrl = () => {
  const { playerId: playerIdFromPathname } = useParams({ strict: false });
  const playerIdFromSearchParams = useSearch({
    strict: false,
    select: x => x.player_id,
  });

  return {
    playerId: playerIdFromSearchParams ?? playerIdFromPathname,
  };
};

export default usePlayerIdFromUrl;
