import { FC, useEffect } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import usePreferences from 'src/hooks/preferences/usePreferences';
import usePermission from 'src/hooks/permissions/usePermissions';
import PlayerSearch from 'src/components/0400_player_search';
import { useParams } from '@tanstack/react-router';
import PlayerSidebar from './PlayerSidebar';
import CreateNewPlayerSidebar from './CreateNewPlayerSidebar';
import CreateNewEventSidebar from './CreateNewEventSidebar';
import CurrentEventSidebar from './CurrentEventSidebar';
import OrganizationSidebar from './OrganizationSidebar';
import EventCalendarSidebar from './EventCalendarSidebar';
import CheckoutSidebar from './CheckoutSidebar';
import BlueprintSidebar from './BlueprintSidebar';

const Sidebar: FC = () => {
  const { inspectedPlayerId, setInspectedPlayerId } = usePreferences();
  const { rootUserId } = useRootUser();
  const { organizationSlug, playerId } = useParams({ strict: false });
  const { isPermitted: canSearchPlayers } = usePermission({
    action: 'search_player',
  });

  useEffect(() => {
    if (playerId && Number(playerId) !== rootUserId) {
      setInspectedPlayerId(Number(playerId));
    }
  }, [ playerId, rootUserId, setInspectedPlayerId ]);

  if (!rootUserId) return <EventCalendarSidebar />;
  if (!organizationSlug) return null;

  return (
    <>
      <OrganizationSidebar />

      <PlayerSidebar key={rootUserId} playerId={rootUserId} />
      {canSearchPlayers && (
        <div className="p-2 border-l-4 border-transparent hover:border-juno-gray-200 focus-within:border-juno-gray-200">
          <PlayerSearch
            title="Search Players"
            placeholder="Search Players..."
          />
        </div>
      )}
      {inspectedPlayerId > 0 && Number(inspectedPlayerId) !== rootUserId && (
        <PlayerSidebar
          isOtherPlayer
          key={inspectedPlayerId}
          dismissable={Number(playerId) !== inspectedPlayerId}
          playerId={Number(inspectedPlayerId)}
        />
      )}
      <CreateNewPlayerSidebar />
      <BlueprintSidebar />
      <EventCalendarSidebar />
      <CreateNewEventSidebar />
      <CurrentEventSidebar />
      <CheckoutSidebar />
    </>
  );
};

export default Sidebar;
