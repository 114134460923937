import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBefore, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import CollapsibleSection from 'src/components/0200_collapsible_section';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import { assignBranchLocation } from 'src/graphql/mutations/branches.graphql';
import {
  IAssignBranchLocationMutation,
  IAssignBranchLocationMutationVariables,
} from 'src/graphql/mutations/branches.graphql.types';
import useEventsInLocation from 'src/hooks/organizations/branches/useEventsInLocation';
import { useMutation } from 'urql';
import { useParams } from '@tanstack/react-router';
import Event from './Event';

interface IProps {
  locationId: number;
  existingEventsSize: number;
}

const EventsInLocation: FC<IProps> = ({ existingEventsSize, locationId }) => {
  const { branchId } = useParams({ strict: false });
  const [ error, setError ] = useState<string | null>(null);
  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ isExpanded, setIsExpanded ] = useState(false);
  const { events, fetching, refetch } = useEventsInLocation({ locationId });
  const [ destroyResult, destroy ] = useMutation<
    IAssignBranchLocationMutation,
    IAssignBranchLocationMutationVariables
  >(assignBranchLocation);

  const handleDestroy = useCallback(() => {
    destroy({ branchId: Number(branchId), locationId, forRemoval: true }).then(
      res => {
        if (res.data?.assignBranchLocation?.error) {
          setError(res.data.assignBranchLocation.error);
        }

        if (res.data?.assignBranchLocation?.branch) {
          setIsDeleted(true);
        }
      },
    );
  }, [ branchId, destroy, locationId ]);

  useEffect(() => {
    if (isExpanded) {
      refetch();
    }
  }, [ isExpanded, refetch ]);

  return (
    <AnimatePresence>
      {!isDeleted && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <CollapsibleSection
            isExpanded={isExpanded}
            title={`${existingEventsSize === 0 ? 'No' : existingEventsSize} Games`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {!fetching ? (
              <div>
                {events.length > 0 ? (
                  events
                    .sort((a, b) =>
                      isBefore(parseISO(a.startsAt), parseISO(b.startsAt))
                        ? 1
                        : -1,
                    )
                    .map(x => <Event key={x.id} {...x} />)
                ) : (
                  <button
                    type="button"
                    className="flex justify-between items-center w-full p-2 transition-all duration-300 text-juno-orange-200 hover:text-juno-orange-400 hover:text-shadow"
                    onClick={handleDestroy}
                  >
                    {destroyResult.fetching
                      ? 'Detaching...'
                      : 'Detach this Location'}
                    {destroyResult.fetching ? (
                      <Loading size="small" />
                    ) : (
                      <FontAwesomeIcon icon={faTrash} />
                    )}
                  </button>
                )}
              </div>
            ) : (
              <div className="p-2">
                <Loading size="small" />
              </div>
            )}
          </CollapsibleSection>
          {error && <ResponseBox type="error">{error}</ResponseBox>}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EventsInLocation;
