import { Link, LinkProps } from '@tanstack/react-router';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IProps extends LinkProps {
  label?: string;
  className?: string;
}

const SidebarLink: FC<PropsWithChildren<IProps>> = ({
  label,
  children,
  className,
  ...props
}) => (
  <Link
    className={clsx(
      'block px-2 py-1 text-left w-full border-l-4 transition-all duration-300 ease-in-out overflow-hidden',
      className,
    )}
    activeProps={{
      className: 'border-juno-gray-50 text-juno-gray-50 text-shadow',
    }}
    inactiveProps={{
      className:
        'border-transparent hover:border-juno-gray-200 text-juno-gray-200 hover:text-juno-gray-50 hover:text-shadow',
    }}
    {...props}
  >
    {children || label}
  </Link>
);
export default SidebarLink;
