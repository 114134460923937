import { FC, useRef, useState, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import Modal from 'src/components/0300_modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useKey, useOutsideClickRef } from 'rooks';
import DropdownPanel from 'src/components/0100_dropdown_panel';
import clsx from 'clsx';
import { useVirtualizer } from '@tanstack/react-virtual';
import useItemsWithDetails from 'src/hooks/items/useItemsWithDetails';
import {
  Link,
  useNavigate,
  useParams,
  useSearch,
} from '@tanstack/react-router';
import ItemEditor from '../ItemView/ItemEditor';
import TableRow from './TableRow';
import viewPacks, { TViewPackKeys } from './utils/viewPacks';
import TableHeader from './TableHeader';

import './index.css';

const TableView: FC = () => {
  const navigate = useNavigate();
  const { organizationSlug } = useParams({ strict: false });
  const parentRef = useRef<HTMLDivElement>(null);
  const [ , startTransition ] = useTransition();
  const { item_id: modalizedItemId } = useSearch({ strict: false });

  const [ widths, setWidths ] = useState<Record<string, number>>({
    craftingMindCost: 64,
    craftingTime: 64,
    craftingSkills: 192,
    craftingZone: 192,
    craftingComponents: 192,

    mechanics: 256,
    requirementsToUse: 128,
    stack: 64,
    kind: 128,
    outputName: 128,
    lifetimeAmount: 64,
    uses: 128,

    benedictionDuration: 128,
    benedictionTarget: 128,
    benedictionLocation: 128,
    benedictionEquipmentsForUse: 128,
    benedictionRoleplayDuration: 128,
    benedictionRoleplayDescription: 192,
    benedictionActivationRequirement: 192,

    notes: 128,

    reproductionTime: 64,
    reproductionCost: 64,
    reproductionSkills: 192,
    reproductionMaterials: 192,
  });
  const [ isGearExpanded, setIsGearExpanded ] = useState(false);
  const [ isSearchFocused, setIsSearchFocused ] = useState(false);
  const [ ref ] = useOutsideClickRef(() => setIsGearExpanded(false));

  const [ views, setViews ] = useState<Record<TViewPackKeys, boolean>>({
    benediction: false,
    itemCraftingFinalProducts: false,
    requirementsToUse: false,
    itemCraftings: true,
    mechanics: false,
    notes: false,
    itemReproductions: false,
  });
  const activeViews = Object.entries(views).filter(x => x[1]);
  const activeCategories = activeViews
    .map(([ key ]) => key as TViewPackKeys)
    .flat();

  const activeHeaders = activeViews
    .map(([ key ]) => viewPacks.find(x => x.id === key)?.columns)
    .flat();
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const { items: filteredItems, fetching } = useItemsWithDetails({
    keyword: query,
  });

  const virtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
    overscan: 20,
  });

  useKey('Escape', () => {
    navigate({ to: '.', search: x => ({ ...x, item_id: undefined }) });
  });

  return (
    <div ref={parentRef}>
      <div
        className="flex justify-start"
        style={{ height: `${virtualizer.getTotalSize()}px` }}
      >
        <table className="depository-tabular table-fixed">
          <thead>
            <tr className="sticky top-0 z-9 gray-box">
              <th ref={ref} className="sticky left-0 gray-box">
                <div className="flex items-center gap-2">
                  <div className="w-full grow relative h-8 min-w-[180px]">
                    <div
                      className={clsx(
                        'transition-all',
                        isSearchFocused &&
                          'absolute w-[204px] sm:w-[272px] z-10 gray-box',
                      )}
                    >
                      <Input
                        fullWidth
                        title="Filter by Name"
                        placeholder={
                          filteredItems.length === 0
                            ? 'Fetching...'
                            : `Search Blueprints...`
                        }
                        isFetching={fetching}
                        {...register('query')}
                        onFocus={() => setIsSearchFocused(true)}
                        onBlur={() => setIsSearchFocused(false)}
                      />
                    </div>
                  </div>
                  <button
                    type="button"
                    className="shrink w-8 text-center pt-0.5"
                    onClick={() => setIsGearExpanded(x => !x)}
                  >
                    <FontAwesomeIcon icon={faGear} />
                  </button>
                </div>
                <DropdownPanel isTransparent isExpanded={isGearExpanded}>
                  <div className="border border-juno-gray-700 grid mt-1 text-left gray-box rounded-xs">
                    <div className="midtone-box p-2 border-b border-juno-gray-700">
                      Toggle Column Headers
                    </div>
                    {Object.values(viewPacks).map(view => (
                      <button
                        key={view.id}
                        type="button"
                        className={clsx(
                          'text-left px-2 py-1 border-l-4 transition-all',
                          views[view.id]
                            ? 'font-bold text-juno-gray-50 border-juno-gray-50 midtone-box'
                            : 'font-normal text-juno-gray-700 hover:text-juno-gray-50 border-transparent hover:border-juno-gray-200 hover:midtone-box',
                        )}
                        onClick={() =>
                          startTransition(() => {
                            setViews(x => ({
                              ...x,
                              [view.id]: !x[view.id],
                            }));
                          })
                        }
                      >
                        {view.name}
                      </button>
                    ))}
                  </div>
                </DropdownPanel>
              </th>
              <TableHeader
                activeHeaders={activeHeaders}
                widths={widths}
                onDrag={({ id, width }) =>
                  setWidths(x => ({ ...x, [id]: width }))
                }
              />
              <th />
            </tr>
          </thead>
          <tbody>
            {filteredItems.length > 0 ? (
              virtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = filteredItems[virtualRow.index];

                return (
                  <TableRow
                    key={row.id}
                    {...row}
                    activeCategories={activeCategories}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                    }}
                    onExpand={() => {
                      if (modalizedItemId && modalizedItemId === row.id) {
                        navigate({
                          to: '.',
                          search: x => ({ ...x, item_id: undefined }),
                        });
                      } else {
                        navigate({
                          to: '.',
                          search: x => ({ ...x, item_id: row.id }),
                        });
                      }
                    }}
                  />
                );
              })
            ) : (
              <tr>
                <td className="p-1 opacity-50">No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        withCloseButton
        isOpen={!!modalizedItemId}
        title="Edit Item"
        leftTitle={
          <Link
            to="/$organizationSlug/blueprints/$"
            params={{
              organizationSlug: String(organizationSlug),
              _splat: String(modalizedItemId),
            }}
            search={{ print: true }}
            target={`_item-${modalizedItemId}`}
          >
            <FontAwesomeIcon icon={faPrint} className="fa-fw" />
          </Link>
        }
        marginTop="mt-[10vh]"
        onClose={() => {
          navigate({
            to: '.',
            search: x => ({ ...x, item_id: undefined }),
          });
        }}
      >
        {modalizedItemId && (
          <div className="max-h-[75vh] overflow-auto">
            <ItemEditor />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default TableView;
