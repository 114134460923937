import { isBefore, parseISO } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faBan,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { IItemKindEnum } from 'src/graphql/types';
import clsx from 'clsx';
import { TTransactableItem } from '../LineItem';
import InventoryItem from '../InventoryItem';

export type TInventory = {
  itemId: number;
  registeredItemId?: number | null;
  stack: number;
  expiresAt?: string;
  item: {
    id: number;
    name: string;
    kind: IItemKindEnum;
  };
  registeredItem?: {
    id: number;
    description: string;
  } | null;
};

interface IProps {
  character?: {
    id: number;
    name: string;
  } | null;
  user?: {
    id: number;
    fullName: string;
  } | null;
  inventories: TInventory[];
  itemsToBranch?: TTransactableItem[];
  maxH?: string;
  onCollect?: (props: TTransactableItem) => void;
}

const Inventories: FC<IProps> = ({
  user,
  character,
  inventories,
  itemsToBranch = [],
  maxH,
  onCollect,
}) => {
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const normalizedQuery = query.trim().toLowerCase();
  const [ showZeroStack, setShowZeroStack ] = useState(false);
  const isFiltered = normalizedQuery.length > 0;
  const filteredInventories = useMemo(() => {
    if (!isFiltered) return inventories;

    return inventories.filter(x =>
      x.item.name.toLowerCase().includes(normalizedQuery),
    );
  }, [ isFiltered, inventories, normalizedQuery ]);
  const isOnList = useCallback(
    ({
      itemId,
      expiresAt,
      registeredItemId,
    }: {
      itemId: number;
      expiresAt?: string;
      registeredItemId?: number;
    }) =>
      itemsToBranch.some(
        x =>
          x.itemId === itemId &&
          x.expiresAt === expiresAt &&
          x.registeredItemId === registeredItemId,
      ),
    [ itemsToBranch ],
  );

  if (!character) return null;

  return (
    <div className="gray-box border-t border-juno-gray-700 min-w-[240px]">
      <div className="midtone-box p-2">
        <div className="flex items-center justify-between">
          <div>
            <div className="text-xs uppercase text-juno-gray-200">
              #{user?.id} {user?.fullName}
            </div>
            <div>{character?.name}</div>
          </div>
          {inventories.length > 0 && (
            <button type="button" onClick={() => setShowZeroStack(x => !x)}>
              <FontAwesomeIcon
                icon={showZeroStack ? faEye : faEyeSlash}
                className="fa-fw pr-2"
              />
            </button>
          )}
        </div>
      </div>
      {inventories.length > 0 ? (
        <div>
          <div className="p-2">
            <Input
              fullWidth
              placeholder="Search Inventory..."
              {...register('query')}
            />
          </div>
          <div className={clsx('overflow-y-auto', maxH)}>
            {filteredInventories
              .filter(x => (showZeroStack ? x : x.stack !== 0))
              .sort((a, b) => {
                if (a.item.name === b.item.name) {
                  return isBefore(
                    parseISO(a.expiresAt ?? '9999-12-31'),
                    parseISO(b.expiresAt ?? '9999-12-31'),
                  )
                    ? -1
                    : 1;
                }

                return a.item.name.localeCompare(b.item.name);
              })
              .map(inventory => (
                <button
                  key={[
                    inventory.itemId,
                    inventory.registeredItemId,
                    inventory.expiresAt,
                  ].join('-')}
                  className={clsx(
                    'border-b border-juno-gray-700 p-2 w-full text-left hover:brightness-125 transition-all duration-300 relative group',

                    inventory.stack === 0 &&
                      'opacity-50 gray-box hover:brightness-100',
                    isOnList({
                      itemId: inventory.itemId,
                      registeredItemId: inventory.registeredItemId ?? undefined,
                      expiresAt: inventory.expiresAt,
                    }) && 'cyan-box-angled hover:brightness-100',
                  )}
                  type="button"
                  disabled={
                    inventory.stack <= 0 ||
                    isOnList({
                      itemId: inventory.itemId,
                      registeredItemId: inventory.registeredItemId ?? undefined,
                      expiresAt: inventory.expiresAt,
                    })
                  }
                  onClick={() =>
                    inventory.stack > 0 &&
                    onCollect?.({
                      itemId: inventory.itemId,
                      itemName: inventory.item.name,
                      itemKind: inventory.item.kind,
                      registeredItemId: inventory.registeredItemId ?? undefined,
                      registeredItemDescription:
                        inventory.registeredItem?.description,
                      expiresAt: inventory.expiresAt,
                      stack: 1,
                      limit: inventory.stack,
                      nonce: [
                        inventory.itemId,
                        inventory.expiresAt,
                        inventory.registeredItemId,
                      ].join('-'),
                    })
                  }
                >
                  <div className="flex justify-between">
                    <InventoryItem
                      itemName={inventory.item.name}
                      itemKind={inventory.item.kind}
                      expiresAt={inventory.expiresAt}
                      registeredItemDescription={
                        inventory.registeredItem?.description
                      }
                    />

                    <div>{inventory.stack}</div>
                  </div>
                  {onCollect && (
                    <div className="absolute bottom-0 right-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
                      {isOnList({
                        itemId: inventory.itemId,
                        registeredItemId:
                          inventory.registeredItemId ?? undefined,
                        expiresAt: inventory.expiresAt,
                      }) ? (
                        <div className="cyan-box p-1 px-4 rounded-t text-xs uppercase">
                          Already in list
                        </div>
                      ) : (
                        <div className="cyan-box-angled py-1 px-4 rounded-t flex items-center">
                          {inventory.stack > 0 ? (
                            <>
                              <span className="text-xs uppercase">Collect</span>
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="fa-fw pl-2"
                              />
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon
                                icon={faBan}
                                className="fa-fw pr-2"
                              />
                              <span className="text-xs uppercase">Empty</span>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </button>
              ))}
            {isFiltered && filteredInventories.length === 0 && (
              <div className="p-2 text-juno-gray-200">
                No matching Items found.
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="p-2 text-juno-gray-700">Inventory is empty.</div>
      )}
    </div>
  );
};

export default Inventories;
