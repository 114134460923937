import { Link, useLinkProps, useParams } from '@tanstack/react-router';
import { FC } from 'react';

interface IProps {
  id: number;
  section?: 'perks';
}

const Player: FC<IProps> = ({ id, section }) => {
  const { organizationSlug } = useParams({ strict: false });
  const linkToPlayer = useLinkProps({
    to:
      section === 'perks'
        ? '/$organizationSlug/players/$playerId/perks'
        : '/$organizationSlug/players/$playerId/*',
    params: {
      organizationSlug: String(organizationSlug),
      playerId: String(id),
    },
  });

  return (
    <Link className="underline" to={linkToPlayer.href}>
      {id}
    </Link>
  );
};

export default Player;
