import { gql } from 'urql';

export const createItem = gql`
  mutation CreateItem(
    $name: String!
    $kind: ItemKindEnum!
    $grade: ItemGradeEnum!
    $lifetimeAmount: Int
    $mechanics: String
    $notes: String
    $printHeader: String
    $requirementsToUse: String
    $uses: String
    $durationOfEffect: String
    $validTargetDescription: String
    $locationOfUse: String
    $equipmentRequiredForUse: String
    $durationOfRoleplay: String
    $descriptionOfRoleplay: String
    $activationRequirement: String
  ) {
    createItem(
      input: {
        name: $name
        kind: $kind
        grade: $grade
        lifetimeAmount: $lifetimeAmount
        mechanics: $mechanics
        notes: $notes
        printHeader: $printHeader
        requirementsToUse: $requirementsToUse
        uses: $uses
        durationOfEffect: $durationOfEffect
        validTargetDescription: $validTargetDescription
        locationOfUse: $locationOfUse
        equipmentRequiredForUse: $equipmentRequiredForUse
        durationOfRoleplay: $durationOfRoleplay
        descriptionOfRoleplay: $descriptionOfRoleplay
        activationRequirement: $activationRequirement
      }
    ) {
      error
      item {
        id
        name
        kind
        grade
        lifetimeAmount
        updatedAt
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
      }
    }
  }
`;

export const createItemClassification = gql`
  mutation CreateItemClassification($parentItemId: Int!, $childItemId: Int!) {
    createItemClassification(
      input: { parentItemId: $parentItemId, childItemId: $childItemId }
    ) {
      error
      item {
        id
        childItemClassifications {
          id
          childItem {
            id
            kind
            name
          }
        }
      }
    }
  }
`;

export const createItemCrafting = gql`
  mutation CreateItemCrafting($blueprintId: Int!) {
    createItemCrafting(input: { blueprintId: $blueprintId }) {
      error
      item {
        id
        itemCraftings {
          id
          craftingTimeInMinute
          craftingMindCost
          craftingZone
          craftingSkills
        }
      }
    }
  }
`;

export const createItemCraftingComponent = gql`
  mutation CreateItemCraftingComponent(
    $itemCraftingId: Int!
    $componentId: Int!
  ) {
    createItemCraftingComponent(
      input: { itemCraftingId: $itemCraftingId, componentId: $componentId }
    ) {
      error
      itemCrafting {
        id
        craftingComponents {
          id
          amount
          component {
            id
            name
            kind
            grade
          }
        }
      }
    }
  }
`;

export const createItemCraftingFinalProduct = gql`
  mutation CreateItemCraftingFinalProduct(
    $itemCraftingId: Int!
    $finalProductId: Int!
  ) {
    createItemCraftingFinalProduct(
      input: {
        itemCraftingId: $itemCraftingId
        finalProductId: $finalProductId
      }
    ) {
      error
      itemCrafting {
        id
        craftingFinalProducts {
          id
          stack
          finalProduct {
            id
            name
            kind
            grade
          }
        }
      }
    }
  }
`;

export const createItemReproduction = gql`
  mutation CreateItemReproduction($itemId: Int!) {
    createItemReproduction(input: { itemId: $itemId }) {
      error
      item {
        id
        itemReproductions {
          id
          reproductionTimeInMinute
          reproductionMindCost
          reproductionSkills
          metadata {
            notes
          }
        }
      }
    }
  }
`;

export const createItemReproductionComponent = gql`
  mutation CreateItemReproductionComponent(
    $itemReproductionId: Int!
    $componentId: Int!
  ) {
    createItemReproductionComponent(
      input: {
        itemReproductionId: $itemReproductionId
        componentId: $componentId
      }
    ) {
      error
      itemReproduction {
        id
        itemReproductionComponents {
          id
          amount
          item {
            id
            name
            kind
          }
        }
      }
    }
  }
`;

export const updateItem = gql`
  mutation UpdateItem(
    $itemId: Int!
    $name: String
    $kind: ItemKindEnum
    $grade: ItemGradeEnum
    $lifetimeAmount: Int
    $visibility: ItemVisibilityEnum
    $validFrom: ISO8601DateTime
    $validUntil: ISO8601DateTime
    $mechanics: String
    $notes: String
    $printHeader: String
    $requirementsToUse: String
    $uses: String
    $durationOfEffect: String
    $validTargetDescription: String
    $locationOfUse: String
    $equipmentRequiredForUse: String
    $durationOfRoleplay: String
    $descriptionOfRoleplay: String
    $activationRequirement: String
  ) {
    updateItem(
      input: {
        itemId: $itemId
        name: $name
        kind: $kind
        grade: $grade
        lifetimeAmount: $lifetimeAmount
        visibility: $visibility
        validFrom: $validFrom
        validUntil: $validUntil
        mechanics: $mechanics
        notes: $notes
        printHeader: $printHeader
        requirementsToUse: $requirementsToUse
        uses: $uses
        durationOfEffect: $durationOfEffect
        validTargetDescription: $validTargetDescription
        locationOfUse: $locationOfUse
        equipmentRequiredForUse: $equipmentRequiredForUse
        durationOfRoleplay: $durationOfRoleplay
        descriptionOfRoleplay: $descriptionOfRoleplay
        activationRequirement: $activationRequirement
      }
    ) {
      error
      item {
        id
        name
        kind
        grade
        lifetimeAmount
        visibility
        validFrom
        validUntil
        updatedAt
        metadata {
          mechanics
          notes
          printHeader
          requirementsToUse
          uses
          durationOfEffect
          validTargetDescription
          locationOfUse
          equipmentRequiredForUse
          durationOfRoleplay
          descriptionOfRoleplay
          activationRequirement
        }
      }
    }
  }
`;

export const updateItemCrafting = gql`
  mutation UpdateItemCrafting(
    $itemCraftingId: Int!
    $craftingTimeInMinute: Int
    $craftingMindCost: Int
    $craftingZone: String
    $craftingSkills: String
  ) {
    updateItemCrafting(
      input: {
        itemCraftingId: $itemCraftingId
        craftingTimeInMinute: $craftingTimeInMinute
        craftingMindCost: $craftingMindCost
        craftingZone: $craftingZone
        craftingSkills: $craftingSkills
      }
    ) {
      error
      itemCrafting {
        id
        craftingTimeInMinute
        craftingMindCost
        craftingZone
        craftingSkills
      }
    }
  }
`;

export const updateItemCraftingComponent = gql`
  mutation UpdateItemCraftingComponent(
    $itemCraftingComponentId: Int!
    $amount: Int
  ) {
    updateItemCraftingComponent(
      input: {
        itemCraftingComponentId: $itemCraftingComponentId
        amount: $amount
      }
    ) {
      error
      craftingComponent {
        id
        amount
      }
    }
  }
`;

export const updateItemCraftingFinalProduct = gql`
  mutation UpdateItemCraftingFinalProduct(
    $itemCraftingFinalProductId: Int!
    $stack: Int
  ) {
    updateItemCraftingFinalProduct(
      input: {
        itemCraftingFinalProductId: $itemCraftingFinalProductId
        stack: $stack
      }
    ) {
      error
      craftingFinalProduct {
        id
        stack
      }
    }
  }
`;

export const updateItemReproduction = gql`
  mutation UpdateItemReproduction(
    $itemReproductionId: Int!
    $reproductionTimeInMinute: Int
    $reproductionMindCost: Int
    $reproductionSkills: String
    $notes: String
  ) {
    updateItemReproduction(
      input: {
        itemReproductionId: $itemReproductionId
        reproductionTimeInMinute: $reproductionTimeInMinute
        reproductionMindCost: $reproductionMindCost
        reproductionSkills: $reproductionSkills
        notes: $notes
      }
    ) {
      error
      itemReproduction {
        id
        reproductionTimeInMinute
        reproductionMindCost
        reproductionSkills
        metadata {
          notes
        }
      }
    }
  }
`;

export const updateItemReproductionComponent = gql`
  mutation UpdateItemReproductionComponent(
    $itemReproductionComponentId: Int!
    $amount: Int
  ) {
    updateItemReproductionComponent(
      input: {
        itemReproductionComponentId: $itemReproductionComponentId
        amount: $amount
      }
    ) {
      error
      itemReproductionComponent {
        id
        amount
      }
    }
  }
`;

export const destroyItemClassification = gql`
  mutation DestroyItemClassification($itemClassificationId: Int!) {
    destroyItemClassification(
      input: { itemClassificationId: $itemClassificationId }
    ) {
      error
      itemClassification {
        id
      }
    }
  }
`;

export const destroyItemCrafting = gql`
  mutation DestroyItemCrafting($itemCraftingId: Int!) {
    destroyItemCrafting(input: { itemCraftingId: $itemCraftingId }) {
      error
      itemCrafting {
        id
      }
    }
  }
`;

export const destroyItemCraftingComponent = gql`
  mutation DestroyItemCraftingComponent($itemCraftingComponentId: Int!) {
    destroyItemCraftingComponent(
      input: { itemCraftingComponentId: $itemCraftingComponentId }
    ) {
      error
      craftingComponent {
        id
      }
    }
  }
`;

export const destroyItemCraftingFinalProduct = gql`
  mutation DestroyItemCraftingFinalProduct($itemCraftingFinalProductId: Int!) {
    destroyItemCraftingFinalProduct(
      input: { itemCraftingFinalProductId: $itemCraftingFinalProductId }
    ) {
      error
      craftingFinalProduct {
        id
      }
    }
  }
`;

export const destroyItemReproduction = gql`
  mutation DestroyItemReproduction($itemReproductionId: Int!) {
    destroyItemReproduction(
      input: { itemReproductionId: $itemReproductionId }
    ) {
      error
      itemReproduction {
        id
      }
    }
  }
`;
export const destroyItemReproductionComponent = gql`
  mutation DestroyItemReproductionComponent(
    $itemReproductionComponentId: Int!
  ) {
    destroyItemReproductionComponent(
      input: { itemReproductionComponentId: $itemReproductionComponentId }
    ) {
      error
      itemReproductionComponent {
        id
      }
    }
  }
`;
