import { getItemKinds } from 'src/graphql/queries/items.graphql';
import {
  IGetItemKindsQuery,
  IGetItemKindsQueryVariables,
} from 'src/graphql/queries/items.graphql.types';
import { useQuery } from 'urql';

const useItemKinds = () => {
  const [{ data }] = useQuery<IGetItemKindsQuery, IGetItemKindsQueryVariables>({
    query: getItemKinds,
    requestPolicy: 'cache-first',
  });

  return {
    data,
    itemKinds: data?.organization.itemKinds ?? [],
  };
};

export default useItemKinds;
