import { FC } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import DystopiaRisingStatsStatistics from 'src/components/statistics/dystopia_rising/StatsStatistics';
import DystopiaRisingSkillsStatistics from 'src/components/statistics/dystopia_rising/SkillsStatistics';
import useOrganizationStatistics, {
  TStats,
  TSkills,
  TLores,
  TResidencies,
  TReputations,
} from 'src/hooks/organizations/useOrganizationStatistics';
import { IStatisticsTypeEnum } from 'src/graphql/types';
import { useParams } from '@tanstack/react-router';
import ParticipantControl from './ParticipantControl';

const WarRoom: FC = () => {
  const { eventId } = useParams({ strict: false });

  const { isPermitted: canAccessWarRoom } = usePermission({
    action: 'assists_directorship',
    eventId: Number(eventId),
  });

  const { statistics: statsStatistics, stale: fetchingStats } =
    useOrganizationStatistics({
      type: IStatisticsTypeEnum.Stats,
      eventId: Number(eventId),
    });
  const { statistics: loresStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Lores,
    eventId: Number(eventId),
  });
  const { statistics: residenciesStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Residencies,
    eventId: Number(eventId),
  });
  const { statistics: skillsStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Skills,
    eventId: Number(eventId),
  });
  const { statistics: reputationsStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Reputations,
    eventId: Number(eventId),
  });

  if (!canAccessWarRoom) return null;

  return (
    <div className="grid gap-2 p-2 pb-8">
      <ParticipantControl />
      <DystopiaRisingStatsStatistics
        statsStatistics={(statsStatistics as TStats)[0]}
        fetchingStats={fetchingStats}
        loresStatistics={loresStatistics as TLores}
        residenciesStatistics={residenciesStatistics as TResidencies}
        skillsStatistics={skillsStatistics as TSkills}
      />
      <DystopiaRisingSkillsStatistics
        skillsStatistics={skillsStatistics as TSkills}
        reputationsStatistics={reputationsStatistics as TReputations}
      />
    </div>
  );
};

export default WarRoom;
