import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import skillData, { TProfession } from '../SkillBuilder/data';
import { TSkill } from '../types';

const useSkillFinder = () => {
  const formContext = useFormContext();
  const { watch } = formContext;
  const { skills, showAcquiredOnly } = watch();

  const skillsFinder = useCallback(
    ({ category, isAberrant }: { category: string; isAberrant: boolean }) =>
      (skills as TSkill[]).filter(skill => {
        const data = skillData.find(
          data => data.name === skill.name && !data.isProfession,
        );

        if (!data) return false;

        return (
          data.category === category &&
          data.isAberrant === isAberrant &&
          !data.isPairwise
        );
      }),
    [ skills ],
  );

  const professionsFinder = useCallback(
    () =>
      (skills as TSkill[]).filter(
        skill =>
          skill.level > 0 &&
          !!skillData.find(x => x.name === skill.name && x.isProfession),
      ),
    [ skills ],
  );

  const professionSkillsFinder = useCallback(
    ({ category, isAberrant }: { category?: string; isAberrant?: boolean }) =>
      (skills as TSkill[]).filter(skill => {
        const professions = skillData.filter(
          x =>
            x.isProfession &&
            (category === undefined ? true : x.category === category) &&
            (isAberrant === undefined ? true : x.isAberrant === isAberrant),
        ) as TProfession[];

        return professions
          .map(x => x.skills.map(y => y.name))
          .flat()
          .includes(skill.name);
      }),
    [ skills ],
  );

  const getSkill = useCallback(
    (skillName: string) =>
      skills.find((x: TSkill) => x.name === skillName) as TSkill | undefined,
    [ skills ],
  );

  const getSkillLevel = useCallback(
    (skillName: string) => Number(getSkill(skillName)?.level || 0),
    [ getSkill ],
  );

  const maybeFilterSkill = useCallback(
    (skillName: string) =>
      showAcquiredOnly ? getSkillLevel(skillName) > 0 : true,
    [ getSkillLevel, showAcquiredOnly ],
  );

  const isBasicSkill = useCallback(
    (skillName: string) =>
      skillData.find(x => x.name === skillName)?.isProfession === false,
    [],
  );

  const isProfession = useCallback(
    (skillName: string) =>
      skillData.find(x => x.name === skillName)?.isProfession,
    [],
  );

  const isProfessionSkill = useCallback(
    (professionSkillName: string) =>
      !!skillData.find(
        x =>
          x.isProfession && x.skills.some(x => x.name === professionSkillName),
      ),
    [],
  );

  const getProfession = useCallback(
    (professionSkillName: string) =>
      skillData.find(
        x =>
          x.isProfession && x.skills.some(x => x.name === professionSkillName),
      )?.name,
    [],
  );

  return {
    getProfession,
    getSkill,
    getSkillLevel,
    isBasicSkill,
    isProfession,
    isProfessionSkill,
    maybeFilterSkill,
    professionsFinder,
    professionSkillsFinder,
    skillsFinder,
  };
};

export default useSkillFinder;
