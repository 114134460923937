import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import Title from 'src/components/0100_title';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import PlayerSearch from 'src/components/0400_player_search';
import {
  createPlayerReferral,
  destroyPlayerReferral,
} from 'src/graphql/mutations/players.graphql';
import {
  ICreatePlayerReferralMutation,
  ICreatePlayerReferralMutationVariables,
  IDestroyPlayerReferralMutation,
  IDestroyPlayerReferralMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import usePlayerReferrals from 'src/hooks/players/usePlayerReferrals';
import { useMutation } from 'urql';

const Referrals: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const [ referralError, setReferralError ] = useState<string | null>(null);
  const [ invitationError, setInvitationError ] = useState<string | null>(null);
  const [ isReferralBusy, setIsReferralBusy ] = useState(false);
  const [ isInvitationBusy, setIsInvitationBusy ] = useState(false);
  const { isPermitted: canCreateReferral } = usePermission({
    action: 'create_referral',
  });
  const { referrals, invitations, fetching } = usePlayerReferrals({
    playerId: Number(playerId),
  });
  const [ , createReferral ] = useMutation<
    ICreatePlayerReferralMutation,
    ICreatePlayerReferralMutationVariables
  >(createPlayerReferral);
  const [ , destroyReferral ] = useMutation<
    IDestroyPlayerReferralMutation,
    IDestroyPlayerReferralMutationVariables
  >(destroyPlayerReferral);

  const handleCreate = useCallback(
    ({
      counterPartyId,
      predicate,
    }: {
      counterPartyId: number;
      predicate: string;
    }) => {
      if (predicate === 'referred') {
        setReferralError(null);
        setIsReferralBusy(true);
      } else {
        setInvitationError(null);
        setIsInvitationBusy(true);
      }

      createReferral({
        playerId: Number(playerId),
        counterPartyId,
        predicate,
      }).then(res => {
        if (predicate === 'referred') {
          setIsReferralBusy(false);
        } else {
          setIsInvitationBusy(false);
        }
        if (res.data?.createReferral?.error) {
          if (predicate === 'referred') {
            setReferralError(res.data?.createReferral?.error);
          } else {
            setInvitationError(res.data?.createReferral?.error);
          }
        }
      });
    },
    [ createReferral, playerId ],
  );

  const handleDestroy = useCallback(
    ({ referralId, predicate }: { referralId: number; predicate: string }) => {
      if (predicate === 'referred') {
        setReferralError(null);
        setIsReferralBusy(true);
      } else {
        setInvitationError(null);
        setIsInvitationBusy(true);
      }

      destroyReferral({
        playerId: Number(playerId),
        referralId,
      }).then(() => {
        if (predicate === 'referred') {
          setIsReferralBusy(false);
        } else {
          setIsInvitationBusy(false);
        }
      });
    },
    [ destroyReferral, playerId ],
  );

  useEffect(() => {
    if (fetching) {
      setIsReferralBusy(true);
      setIsInvitationBusy(true);
    } else {
      setIsReferralBusy(false);
      setIsInvitationBusy(false);
    }
  }, [ fetching ]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <Title title="Referrals" variant="heading" />
        <div>
          Use this section to record New Players that this Player has invited to
          DR.
          <br />
          Please do NOT add Referrals from 2.0.
        </div>
        {canCreateReferral && (
          <div className="flex w-full py-2 items-center gap-4">
            <div className="w-full max-w-[256px]">
              <PlayerSearch
                playersOnly
                placeholder="Search Newbie"
                onSearchResultClick={({ playerId: newbieId }) =>
                  handleCreate({
                    counterPartyId: Number(newbieId),
                    predicate: 'referred',
                  })
                }
              />
            </div>

            {isReferralBusy && <Loading size="small" />}
          </div>
        )}
        <div>
          {referralError && (
            <ResponseBox type="error">{referralError}</ResponseBox>
          )}
        </div>
        <table>
          <tbody>
            {referrals.map(referral => (
              <tr key={referral.id}>
                <td className="px-4 py-2 align-top text-right">{`#${referral.newbie.id}`}</td>
                <td className="w-full px-4 py-2">
                  <div>{referral.newbie.fullName}</div>
                  <div className="text-sm text-juno-gray-200">
                    {format(parseISO(referral.createdAt), 'yyyy-MM-dd')}
                  </div>
                </td>
                <td>
                  {canCreateReferral && (
                    <ConfirmDropdown
                      onConfirm={() =>
                        handleDestroy({
                          referralId: referral.id,
                          predicate: 'referred',
                        })
                      }
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <Title title="Invitation" variant="heading" />
        <div>
          Use this section to record who invited this Player to DR.
          <br />
          Please do NOT add Invitations from 2.0.
        </div>
        {canCreateReferral && (
          <div className="flex w-full py-2 items-center gap-4">
            <div className="w-full max-w-[256px]">
              <PlayerSearch
                playersOnly
                isDisabled={invitations.length > 0}
                placeholder={
                  invitations.length > 0
                    ? 'Invitation Exists'
                    : 'Search Inviter'
                }
                onSearchResultClick={({ playerId: inviterId }) =>
                  handleCreate({
                    counterPartyId: Number(inviterId),
                    predicate: 'invited_by',
                  })
                }
              />
            </div>

            {isInvitationBusy && <Loading size="small" />}
          </div>
        )}
        <div>
          {invitationError && (
            <ResponseBox type="error">{invitationError}</ResponseBox>
          )}
        </div>
        <table>
          <tbody>
            {invitations.map(invitation => (
              <tr key={invitation.id}>
                <td className="px-4 py-2 align-top text-right">{`#${invitation.inviter.id}`}</td>
                <td className="w-full px-4 py-2">
                  <div>{invitation.inviter.fullName}</div>
                  <div className="text-sm text-juno-gray-200">
                    {format(parseISO(invitation.createdAt), 'yyyy-MM-dd')}
                  </div>
                </td>
                <td>
                  {canCreateReferral && (
                    <ConfirmDropdown
                      onConfirm={() =>
                        handleDestroy({
                          referralId: invitation.id,
                          predicate: 'invited_by',
                        })
                      }
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Referrals;
