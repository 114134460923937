import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createFileRoute, Outlet, useParams } from '@tanstack/react-router';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOutsideClickRef } from 'rooks';
import Input from 'src/components/0100_input';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import { updateCharacter } from 'src/graphql/mutations/characters.graphql';
import {
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import useCharacter from 'src/hooks/characters/useCharacter';
import { useMutation } from 'urql';

const Character: FC = () => {
  const [ isEditingName, setIsEditingName ] = useState(false);
  const { playerId, characterId } = useParams({ strict: false });
  const { player, character, canEdit } = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });
  const {
    register,
    reset,
    setFocus,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >(updateCharacter);
  const name = watch('name');
  const [ ref ] = useOutsideClickRef(() => setIsEditingName(false));

  const handleUpdate = useCallback(() => {
    if (isDirty && name.trim().length > 0) {
      update({
        characterId: Number(characterId),
        name: name.trim(),
      }).then(res => {
        if (res.data?.updateCharacterData?.character) {
          reset({ name: res.data.updateCharacterData.character.name });
          setIsEditingName(false);
        }
      });
    } else {
      reset({ name: character?.name });
    }
  }, [ character?.name, characterId, isDirty, name, reset, update ]);

  useEffect(() => {
    if (character?.name) {
      reset({ name: character.name });
    }
  }, [ character?.name, reset ]);

  return (
    <div>
      <Title>
        <div className="flex justify-center items-center text-base gap-4">
          {player && `#${player.id} - ${player.fullName}`}
          {updateResult.fetching && <Loading size="small" />}
          {updateResult.data?.updateCharacterData?.character && (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </div>
        <div className="flex w-full justify-center min-h-[40px] sm:min-h-[48px] text-2xl md:text-4xl">
          {isEditingName ? (
            <div ref={ref} className="w-full">
              <Input
                fullWidth
                className="text-center"
                {...register('name', {
                  minLength: 1,
                  required: true,
                })}
                onBlur={handleUpdate}
                onEnter={handleUpdate}
              />
            </div>
          ) : (
            <button
              disabled={!canEdit}
              type="button"
              className="flex items-center gap-4"
              onClick={() => {
                if (canEdit) {
                  setIsEditingName(true);
                  setTimeout(() => setFocus('name'), 50);
                }
              }}
            >
              <div className="py-1">{character && character.name}</div>
              {canEdit && (
                <FontAwesomeIcon icon={faPencil} className="text-base" />
              )}
            </button>
          )}
        </div>
      </Title>
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId',
)({
  component: Character,
});
