import { faDollar, faHiking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import Currency from 'src/components/0100_currency';
import { IEventAttendeeKindEnum } from 'src/graphql/types';
import AttendanceInvoice, { TAttendanceInvoice } from './AttendanceInvoice';

interface IProps {
  attendances: TAttendanceInvoice[];
}

const Formatted: FC<IProps> = ({ attendances }) => (
  <table className="border border-juno-gray-700 border-collapse w-full">
    <thead>
      <tr>
        <th className="text-center p-1 border border-juno-gray-700">#</th>
        <th className="text-center p-1 border border-juno-gray-700">ID</th>
        <th className="text-center p-1 border border-juno-gray-700">Chg</th>
        <th className="text-center p-1 border border-juno-gray-700">Res</th>
        <th className="text-center p-1 border border-l-4 border-juno-gray-700">
          Ticket
        </th>
        <th className="text-center p-1 border border-juno-gray-700" colSpan={2}>
          Player
        </th>

        <th className="text-center p-1 border border-juno-gray-700">+XP</th>
        <th className="text-center p-1 border border-juno-gray-700">
          <FontAwesomeIcon icon={faDollar} className="fa-fw" />
        </th>
        <th className="text-center p-1 border border-juno-gray-700">
          <FontAwesomeIcon icon={faHiking} className="fa-fw" />
        </th>
        <th className="text-center p-1 border border-juno-gray-700">Base</th>
        <th className="text-center p-1 border border-juno-gray-700">+XP</th>
        <th className="text-center p-1 border border-juno-gray-700">Sub</th>
      </tr>
    </thead>
    <tbody>
      {attendances
        .sort((a, b) => {
          if (a.change?.kind === b.change?.kind) {
            if (a.ticket.label === b.ticket.label) {
              return a.user.name.localeCompare(b.user.name);
            }

            return a.ticket.label.localeCompare(b.ticket.label);
          }

          return (a.change?.kind ?? '').localeCompare(b.change?.kind ?? '');
        })
        .map((x, i) => (
          <AttendanceInvoice key={x.id} {...x} rowIndex={i} />
        ))}
      <tr>
        <td colSpan={7} />
        <td className="p-1 text-right border border-juno-gray-700">
          {attendances
            .filter(
              x =>
                (x.kind === IEventAttendeeKindEnum.HomeGame && x.paid) ||
                (x.paid && x.attending),
            )
            .map(x => x.buildGrowth ?? 0)
            .reduce((a, b) => a + b, 0)}
        </td>
        <td colSpan={2} />
        <td className="p-1 text-right border border-juno-gray-700">
          <Currency
            value={attendances.map(x => x.baseCost).reduce((a, b) => a + b, 0)}
          />
        </td>
        <td className="p-1 text-right border border-juno-gray-700">
          <Currency
            value={attendances
              .map(x => x.extraXpCost)
              .reduce((a, b) => a + b, 0)}
          />
        </td>
        <td className="p-1 pl-0 text-right border border-juno-gray-700">
          <Currency
            value={attendances.map(x => x.totalCost).reduce((a, b) => a + b, 0)}
          />
        </td>
      </tr>
    </tbody>
  </table>
);

export default Formatted;
