import { useParams } from '@tanstack/react-router';
import { useCallback, useEffect } from 'react';
import { logBlueprintAction } from 'src/graphql/mutations/events.graphql';
import {
  ILogBluePrintActionMutation,
  ILogBluePrintActionMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { IBlueprintActionEnum } from 'src/graphql/types';
import { useMutation } from 'urql';

const usePrintingLog = ({
  copies,
  fingerprints,
}: {
  copies: number;
  fingerprints: string[];
}) => {
  const { eventId, _splat: itemId } = useParams({ strict: false });
  const [ , mutate ] = useMutation<
    ILogBluePrintActionMutation,
    ILogBluePrintActionMutationVariables
  >(logBlueprintAction);

  const logPrint = useCallback(() => {
    mutate({
      blueprintId: Number(itemId),
      eventId: Number(eventId),
      action: IBlueprintActionEnum.Print,
      copies,
      fingerprints,
    });
  }, [ copies, mutate, eventId, itemId, fingerprints ]);

  useEffect(() => {
    window.addEventListener('afterprint', logPrint);
    return () => {
      window.removeEventListener('afterprint', logPrint);
    };
  }, [ logPrint ]);
};

export default usePrintingLog;
