import { cacheExchange } from '@urql/exchange-graphcache';
import {
  Resolver as GraphCacheResolver,
  UpdateResolver as GraphCacheUpdateResolver,
  OptimisticMutationResolver as GraphCacheOptimisticMutationResolver,
} from '@urql/exchange-graphcache';

import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: any; output: any };
  ISO8601DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

/** Autogenerated input type of AssignBranchLocationMutation */
export type IAssignBranchLocationMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  locationId: Scalars['Int']['input'];
};

/** Autogenerated return type of AssignBranchLocationMutation. */
export type IAssignBranchLocationMutationPayload = {
  __typename?: 'AssignBranchLocationMutationPayload';
  branch?: Maybe<IBranch>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AssignBranchOwnershipMutation */
export type IAssignBranchOwnershipMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  playerId: Scalars['Int']['input'];
};

/** Autogenerated return type of AssignBranchOwnershipMutation. */
export type IAssignBranchOwnershipMutationPayload = {
  __typename?: 'AssignBranchOwnershipMutationPayload';
  branch?: Maybe<IBranch>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of AssignTemporaryManagerMutation */
export type IAssignTemporaryManagerMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  playerId: Scalars['Int']['input'];
};

/** Autogenerated return type of AssignTemporaryManagerMutation. */
export type IAssignTemporaryManagerMutationPayload = {
  __typename?: 'AssignTemporaryManagerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

export enum IAssistantFlagEnum {
  Directorship = 'directorship',
  Logistics = 'logistics',
  ModManager = 'mod_manager',
  NewPlayerAssistant = 'new_player_assistant',
  Registration = 'registration',
}

export type IAttendanceInvoice = {
  __typename?: 'AttendanceInvoice';
  attending: Scalars['Boolean']['output'];
  baseCost: Scalars['Float']['output'];
  buildGrowth?: Maybe<Scalars['Int']['output']>;
  change?: Maybe<IInvoiceChange>;
  extraXpCost: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  kind: IEventAttendeeKindEnum;
  paid: Scalars['Boolean']['output'];
  ticket: ITicketInvoice;
  totalCost: Scalars['Float']['output'];
  user: IUserInvoice;
};

export type IAttendeeCharacter = {
  __typename?: 'AttendeeCharacter';
  attendance: IAttendeeCharacterAttendanceEnum;
  buildGrowth: Scalars['Int']['output'];
  cap?: Maybe<ICap>;
  character: ICharacter;
  id: Scalars['Int']['output'];
  payment: IAttendeeCharacterPaymentEnum;
  printRequestStatus: IAttendeeCharacterPrintRequestStatusEnum;
  ticket: ITicket;
};

export enum IAttendeeCharacterAttendanceEnum {
  Attending = 'attending',
  NonAttending = 'non_attending',
}

export enum IAttendeeCharacterPaymentEnum {
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export enum IAttendeeCharacterPrintRequestStatusEnum {
  PrintDeclined = 'print_declined',
  PrintRequested = 'print_requested',
}

export type IAttendeeShift = {
  __typename?: 'AttendeeShift';
  id: Scalars['Int']['output'];
  player: IUser;
  shift: IShift;
  status: IAttendeeShiftStatusEnum;
};

export enum IAttendeeShiftStatusEnum {
  Fulfilled = 'fulfilled',
  Unfulfilled = 'unfulfilled',
}

export enum IBlueprintActionEnum {
  Print = 'print',
}

export type IBranch = {
  __typename?: 'Branch';
  activePlayers?: Maybe<Array<IUserOrganization>>;
  branchAssistants: Array<IUserOrganization>;
  branchOwners: Array<IBranchOwner>;
  caps?: Maybe<Array<ICap>>;
  correctiveActions?: Maybe<Array<ICorrectiveAction>>;
  district?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoice?: Maybe<IInvoice>;
  location?: Maybe<ILocation>;
  locations: Array<ILocation>;
  name: Scalars['String']['output'];
  organization: IOrganization;
  region: Scalars['String']['output'];
  registrationUrl?: Maybe<Scalars['String']['output']>;
  shorthand: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type IBranchLocationArgs = {
  locationId: Scalars['Int']['input'];
};

export type IBranchOwner = {
  __typename?: 'BranchOwner';
  id: Scalars['Int']['output'];
  user: IUser;
};

export type ICap = {
  __typename?: 'Cap';
  amount: Scalars['Int']['output'];
  branch: IBranch;
  createdAt: Scalars['ISO8601DateTime']['output'];
  grantor: IUser;
  id: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  state: ICapStateEnum;
  user: IUser;
};

export enum ICapStateEnum {
  Active = 'active',
  Removed = 'removed',
}

export type ICharacter = {
  __typename?: 'Character';
  alive: Scalars['Boolean']['output'];
  approvedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  body: Scalars['Int']['output'];
  buildEarned: Scalars['Int']['output'];
  buildUsed: Scalars['Int']['output'];
  characterFellowships: Array<ICharacterFellowship>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentLife: Scalars['Int']['output'];
  experimentalCharacter?: Maybe<ICharacter>;
  faithId: Scalars['Int']['output'];
  fractures: Array<IFracture>;
  id: Scalars['Int']['output'];
  infection: Scalars['Int']['output'];
  inventories: Array<IInventory>;
  latestLifecycleGainsThisYear: Array<Scalars['ISO8601DateTime']['output']>;
  legacyHomeGameCount: Scalars['Int']['output'];
  lifeCapacity: Scalars['Int']['output'];
  lifeGain: Scalars['Int']['output'];
  lifeLoss: Scalars['Int']['output'];
  lifecycles: Array<ILifecycle>;
  lineageId: Scalars['Int']['output'];
  loreIds: Array<Scalars['Int']['output']>;
  maxLores: Scalars['Int']['output'];
  mind: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  organization: IOrganization;
  playerNotes?: Maybe<Scalars['String']['output']>;
  resolve: Scalars['Int']['output'];
  retiredCharacter?: Maybe<ICharacter>;
  retirementPaths: IRetirementPaths;
  skills: Array<ICharacterSkill>;
  staffNotes?: Maybe<Scalars['String']['output']>;
  status: ICharacterStatusEnum;
  strainId: Scalars['Int']['output'];
  totalHomeGameCount: Scalars['Int']['output'];
  user: IUser;
  variant: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  xpAudits: Array<IXpAudit>;
};

export type ICharacterFracturesArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ICharacterConfig = {
  __typename?: 'CharacterConfig';
  configurableSkills?: Maybe<Array<ISkillMechanic>>;
  configurableStats?: Maybe<Array<IStatMechanic>>;
  faiths?: Maybe<Array<IFaith>>;
  id: Scalars['String']['output'];
  lineages?: Maybe<Array<ILineage>>;
  lores?: Maybe<Array<ILore>>;
  skills?: Maybe<Array<ISkill>>;
  strains?: Maybe<Array<IStrain>>;
};

export type ICharacterFellowship = {
  __typename?: 'CharacterFellowship';
  character: ICharacter;
  fellowship: IFellowship;
  id: Scalars['Int']['output'];
  rank: Scalars['Int']['output'];
  removedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type ICharacterFellowshipInput = {
  fellowshipId?: InputMaybe<Scalars['Int']['input']>;
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type ICharacterSkill = {
  __typename?: 'CharacterSkill';
  characterSkillId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  positions?: Maybe<Array<Scalars['Int']['output']>>;
};

export type ICharacterSkillInput = {
  id: Scalars['Int']['input'];
  level: Scalars['Int']['input'];
  positions?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ICharacterStatusEnum {
  Active = 'active',
  Experimental = 'experimental',
  Inactive = 'inactive',
  Retired = 'retired',
  Staged = 'staged',
}

export type ICheckinCharacterInput = {
  capConversion?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

/** Autogenerated input type of CheckinCharacterMutation */
export type ICheckinCharacterMutationInput = {
  characters?: InputMaybe<Array<ICheckinCharacterInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventAttendeeId: Scalars['Int']['input'];
};

/** Autogenerated return type of CheckinCharacterMutation. */
export type ICheckinCharacterMutationPayload = {
  __typename?: 'CheckinCharacterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventAttendee?: Maybe<IEventAttendee>;
};

/** Autogenerated input type of CheckinMutation */
export type ICheckinMutationInput = {
  buildGrowth?: InputMaybe<Scalars['Int']['input']>;
  characters?: InputMaybe<Array<ICheckinCharacterInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  inviterId?: InputMaybe<Scalars['Int']['input']>;
  isAttending?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  playerId?: InputMaybe<Scalars['Int']['input']>;
  printRequestOnCharacterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  referralInfo?: InputMaybe<Scalars['String']['input']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ticketId: Scalars['Int']['input'];
};

/** Autogenerated return type of CheckinMutation. */
export type ICheckinMutationPayload = {
  __typename?: 'CheckinMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventAttendee?: Maybe<IEventAttendee>;
};

/** Autogenerated input type of CopyCharacterBuildMutation */
export type ICopyCharacterBuildMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  sourceCharacterId: Scalars['Int']['input'];
  targetCharacterId: Scalars['Int']['input'];
};

/** Autogenerated return type of CopyCharacterBuildMutation. */
export type ICopyCharacterBuildMutationPayload = {
  __typename?: 'CopyCharacterBuildMutationPayload';
  character?: Maybe<ICharacter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

export type ICorrectiveAction = {
  __typename?: 'CorrectiveAction';
  branch: IBranch;
  createdAt: Scalars['ISO8601Date']['output'];
  endsAt?: Maybe<Scalars['ISO8601Date']['output']>;
  id: Scalars['Int']['output'];
  issuer: IUser;
  kind: ICorrectiveActionKindEnum;
  reason?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['ISO8601Date']['output']>;
  user: IUser;
};

export enum ICorrectiveActionKindEnum {
  RefusalOfService = 'refusal_of_service',
  Suspension = 'suspension',
  Violation = 'violation',
  Warning = 'warning',
}

export type ICraftableItem = {
  __typename?: 'CraftableItem';
  id: Scalars['Int']['output'];
  kind: IItemKindEnum;
  metadata: IItemMetadata;
  name: Scalars['String']['output'];
};

export type ICraftingComponent = {
  __typename?: 'CraftingComponent';
  amount: Scalars['Int']['output'];
  component: IItem;
  id: Scalars['Int']['output'];
  itemCrafting: IItemCrafting;
};

export type ICraftingFinalProduct = {
  __typename?: 'CraftingFinalProduct';
  finalProduct: IItem;
  id: Scalars['Int']['output'];
  stack: Scalars['Int']['output'];
};

/** Autogenerated input type of CreateCapMutation */
export type ICreateCapMutationInput = {
  amount: Scalars['Int']['input'];
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateCapMutation. */
export type ICreateCapMutationPayload = {
  __typename?: 'CreateCapMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateCharacterFellowshipMutation */
export type ICreateCharacterFellowshipMutationInput = {
  characterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fellowshipId: Scalars['Int']['input'];
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of CreateCharacterFellowshipMutation. */
export type ICreateCharacterFellowshipMutationPayload = {
  __typename?: 'CreateCharacterFellowshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  fellowship?: Maybe<IFellowship>;
};

/** Autogenerated input type of CreateCharacterMutation */
export type ICreateCharacterMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateCharacterMutation. */
export type ICreateCharacterMutationPayload = {
  __typename?: 'CreateCharacterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateCorrectiveActionMutation */
export type ICreateCorrectiveActionMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  kind: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  startsAt: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateCorrectiveActionMutation. */
export type ICreateCorrectiveActionMutationPayload = {
  __typename?: 'CreateCorrectiveActionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateEventLifecycleMutation */
export type ICreateEventLifecycleMutationInput = {
  characterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  kind: ILifecycleKindEnum;
};

/** Autogenerated return type of CreateEventLifecycleMutation. */
export type ICreateEventLifecycleMutationPayload = {
  __typename?: 'CreateEventLifecycleMutationPayload';
  character?: Maybe<ICharacter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateEventMutation */
export type ICreateEventMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt: Scalars['String']['input'];
  kind?: InputMaybe<IEventKindEnum>;
  locationId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  registrationOpensAt?: InputMaybe<Scalars['String']['input']>;
  startsAt: Scalars['String']['input'];
};

/** Autogenerated return type of CreateEventMutation. */
export type ICreateEventMutationPayload = {
  __typename?: 'CreateEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of CreateEventShiftMutation */
export type ICreateEventShiftMutationInput = {
  baseRatio: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  kind: IShiftKindEnum;
  label: Scalars['String']['input'];
};

/** Autogenerated return type of CreateEventShiftMutation. */
export type ICreateEventShiftMutationPayload = {
  __typename?: 'CreateEventShiftMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of CreateEventTicketMutation */
export type ICreateEventTicketMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cost: Scalars['Int']['input'];
  eventId: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  restrictionType: ITicketRestrictionEnum;
  shiftResponsibilities: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateEventTicketMutation. */
export type ICreateEventTicketMutationPayload = {
  __typename?: 'CreateEventTicketMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of CreateFellowshipMutation */
export type ICreateFellowshipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  kind: IFellowshipKindEnum;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of CreateFellowshipMutation. */
export type ICreateFellowshipMutationPayload = {
  __typename?: 'CreateFellowshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<IOrganization>;
};

/** Autogenerated input type of CreateItemClassificationMutation */
export type ICreateItemClassificationMutationInput = {
  childItemId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  parentItemId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateItemClassificationMutation. */
export type ICreateItemClassificationMutationPayload = {
  __typename?: 'CreateItemClassificationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<IItem>;
};

/** Autogenerated input type of CreateItemCraftingComponentMutation */
export type ICreateItemCraftingComponentMutationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  componentId: Scalars['Int']['input'];
  itemCraftingId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateItemCraftingComponentMutation. */
export type ICreateItemCraftingComponentMutationPayload = {
  __typename?: 'CreateItemCraftingComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemCrafting?: Maybe<IItemCrafting>;
};

/** Autogenerated input type of CreateItemCraftingFinalProductMutation */
export type ICreateItemCraftingFinalProductMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  finalProductId: Scalars['Int']['input'];
  itemCraftingId: Scalars['Int']['input'];
  stack?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of CreateItemCraftingFinalProductMutation. */
export type ICreateItemCraftingFinalProductMutationPayload = {
  __typename?: 'CreateItemCraftingFinalProductMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemCrafting?: Maybe<IItemCrafting>;
};

/** Autogenerated input type of CreateItemCraftingMutation */
export type ICreateItemCraftingMutationInput = {
  blueprintId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateItemCraftingMutation. */
export type ICreateItemCraftingMutationPayload = {
  __typename?: 'CreateItemCraftingMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<IItem>;
};

/** Autogenerated input type of CreateItemMutation */
export type ICreateItemMutationInput = {
  activationRequirement?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  descriptionOfRoleplay?: InputMaybe<Scalars['String']['input']>;
  durationOfEffect?: InputMaybe<Scalars['String']['input']>;
  durationOfRoleplay?: InputMaybe<Scalars['String']['input']>;
  equipmentRequiredForUse?: InputMaybe<Scalars['String']['input']>;
  grade: IItemGradeEnum;
  kind: IItemKindEnum;
  lifetimeAmount?: InputMaybe<Scalars['Int']['input']>;
  locationOfUse?: InputMaybe<Scalars['String']['input']>;
  mechanics?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  printHeader?: InputMaybe<Scalars['String']['input']>;
  requirementsToUse?: InputMaybe<Scalars['String']['input']>;
  uses?: InputMaybe<Scalars['String']['input']>;
  validTargetDescription?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateItemMutation. */
export type ICreateItemMutationPayload = {
  __typename?: 'CreateItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<IItem>;
};

/** Autogenerated input type of CreateItemReproductionComponentMutation */
export type ICreateItemReproductionComponentMutationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  componentId: Scalars['Int']['input'];
  itemReproductionId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateItemReproductionComponentMutation. */
export type ICreateItemReproductionComponentMutationPayload = {
  __typename?: 'CreateItemReproductionComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemReproduction?: Maybe<IItemReproduction>;
};

/** Autogenerated input type of CreateItemReproductionMutation */
export type ICreateItemReproductionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateItemReproductionMutation. */
export type ICreateItemReproductionMutationPayload = {
  __typename?: 'CreateItemReproductionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<IItem>;
};

/** Autogenerated input type of CreateItemTransactionMutation */
export type ICreateItemTransactionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  repeats?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  transactions: Array<IItemTransactionInput>;
};

/** Autogenerated return type of CreateItemTransactionMutation. */
export type ICreateItemTransactionMutationPayload = {
  __typename?: 'CreateItemTransactionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemTransactions?: Maybe<Array<IItemTransaction>>;
};

/** Autogenerated input type of CreateLocationMutation */
export type ICreateLocationMutationInput = {
  branchId: Scalars['Int']['input'];
  city: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  region: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

/** Autogenerated return type of CreateLocationMutation. */
export type ICreateLocationMutationPayload = {
  __typename?: 'CreateLocationMutationPayload';
  branch?: Maybe<IBranch>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of CreateMembershipMutation */
export type ICreateMembershipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateMembershipMutation. */
export type ICreateMembershipMutationPayload = {
  __typename?: 'CreateMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateModMutation */
export type ICreateModMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  modSectionId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  participationLimit?: InputMaybe<Scalars['Int']['input']>;
  reputationConstraints?: InputMaybe<Array<ICharacterSkillInput>>;
  skillConstraints?: InputMaybe<Array<ICharacterSkillInput>>;
  timeframe?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateModMutation. */
export type ICreateModMutationPayload = {
  __typename?: 'CreateModMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  modSection?: Maybe<IModSection>;
};

/** Autogenerated input type of CreateModSectionMutation */
export type ICreateModSectionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  signupLimit: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateModSectionMutation. */
export type ICreateModSectionMutationPayload = {
  __typename?: 'CreateModSectionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of CreateNewPlayerMutation */
export type ICreateNewPlayerMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  preferredName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CreateNewPlayerMutation. */
export type ICreateNewPlayerMutationPayload = {
  __typename?: 'CreateNewPlayerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateReferralMutation */
export type ICreateReferralMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  counterPartyId: Scalars['Int']['input'];
  predicate: Scalars['String']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of CreateReferralMutation. */
export type ICreateReferralMutationPayload = {
  __typename?: 'CreateReferralMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of CreateSpecialDateMutation */
export type ICreateSpecialDateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endsAt: Scalars['String']['input'];
  kind: ISpecialDateKindEnum;
  startsAt: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

/** Autogenerated return type of CreateSpecialDateMutation. */
export type ICreateSpecialDateMutationPayload = {
  __typename?: 'CreateSpecialDateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<IOrganization>;
};

/** Autogenerated input type of DestroyAttendeeCharacterMutation */
export type IDestroyAttendeeCharacterMutationInput = {
  attendeeCharacterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DestroyAttendeeCharacterMutation. */
export type IDestroyAttendeeCharacterMutationPayload = {
  __typename?: 'DestroyAttendeeCharacterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventAttendee?: Maybe<IEventAttendee>;
};

/** Autogenerated input type of DestroyAuthTokenMutation */
export type IDestroyAuthTokenMutationInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of DestroyAuthTokenMutation. */
export type IDestroyAuthTokenMutationPayload = {
  __typename?: 'DestroyAuthTokenMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyCharacterMutation */
export type IDestroyCharacterMutationInput = {
  characterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyCharacterMutation. */
export type IDestroyCharacterMutationPayload = {
  __typename?: 'DestroyCharacterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of DestroyCorrectiveActionMutation */
export type IDestroyCorrectiveActionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctiveActionId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyCorrectiveActionMutation. */
export type IDestroyCorrectiveActionMutationPayload = {
  __typename?: 'DestroyCorrectiveActionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctiveAction?: Maybe<ICorrectiveAction>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyEventAttendeeMutation */
export type IDestroyEventAttendeeMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventAttendeeId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyEventAttendeeMutation. */
export type IDestroyEventAttendeeMutationPayload = {
  __typename?: 'DestroyEventAttendeeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventAttendee?: Maybe<IEventAttendee>;
};

/** Autogenerated input type of DestroyEventShiftMutation */
export type IDestroyEventShiftMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  shiftId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyEventShiftMutation. */
export type IDestroyEventShiftMutationPayload = {
  __typename?: 'DestroyEventShiftMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of DestroyEventTicketMutation */
export type IDestroyEventTicketMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ticketId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyEventTicketMutation. */
export type IDestroyEventTicketMutationPayload = {
  __typename?: 'DestroyEventTicketMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<ITicket>;
};

/** Autogenerated input type of DestroyItemClassificationMutation */
export type IDestroyItemClassificationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemClassificationId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemClassificationMutation. */
export type IDestroyItemClassificationMutationPayload = {
  __typename?: 'DestroyItemClassificationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemClassification?: Maybe<IItemClassification>;
};

/** Autogenerated input type of DestroyItemCraftingComponentMutation */
export type IDestroyItemCraftingComponentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemCraftingComponentId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemCraftingComponentMutation. */
export type IDestroyItemCraftingComponentMutationPayload = {
  __typename?: 'DestroyItemCraftingComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  craftingComponent?: Maybe<ICraftingComponent>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyItemCraftingFinalProductMutation */
export type IDestroyItemCraftingFinalProductMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemCraftingFinalProductId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemCraftingFinalProductMutation. */
export type IDestroyItemCraftingFinalProductMutationPayload = {
  __typename?: 'DestroyItemCraftingFinalProductMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  craftingFinalProduct?: Maybe<ICraftingFinalProduct>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of DestroyItemCraftingMutation */
export type IDestroyItemCraftingMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemCraftingId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemCraftingMutation. */
export type IDestroyItemCraftingMutationPayload = {
  __typename?: 'DestroyItemCraftingMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemCrafting?: Maybe<IItemCrafting>;
};

/** Autogenerated input type of DestroyItemReproductionComponentMutation */
export type IDestroyItemReproductionComponentMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemReproductionComponentId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemReproductionComponentMutation. */
export type IDestroyItemReproductionComponentMutationPayload = {
  __typename?: 'DestroyItemReproductionComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemReproductionComponent?: Maybe<IItemReproductionComponent>;
};

/** Autogenerated input type of DestroyItemReproductionMutation */
export type IDestroyItemReproductionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemReproductionId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyItemReproductionMutation. */
export type IDestroyItemReproductionMutationPayload = {
  __typename?: 'DestroyItemReproductionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemReproduction?: Maybe<IItemReproduction>;
};

/** Autogenerated input type of DestroyLocationMutation */
export type IDestroyLocationMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyLocationMutation. */
export type IDestroyLocationMutationPayload = {
  __typename?: 'DestroyLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  location?: Maybe<ILocation>;
};

/** Autogenerated input type of DestroyMembershipMutation */
export type IDestroyMembershipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  membershipId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyMembershipMutation. */
export type IDestroyMembershipMutationPayload = {
  __typename?: 'DestroyMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  membership?: Maybe<IMembership>;
};

/** Autogenerated input type of DestroyReferralMutation */
export type IDestroyReferralMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  referralId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroyReferralMutation. */
export type IDestroyReferralMutationPayload = {
  __typename?: 'DestroyReferralMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of DestroySpecialDateMutation */
export type IDestroySpecialDateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  specialDateId: Scalars['Int']['input'];
};

/** Autogenerated return type of DestroySpecialDateMutation. */
export type IDestroySpecialDateMutationPayload = {
  __typename?: 'DestroySpecialDateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  specialDate?: Maybe<ISpecialDate>;
};

export type IError = {
  __typename?: 'Error';
  error?: Maybe<Scalars['String']['output']>;
};

export type IEvent = {
  __typename?: 'Event';
  activeCorrectiveActions: Array<ICorrectiveAction>;
  activeMembership?: Maybe<Scalars['Boolean']['output']>;
  branch: IBranch;
  canSelfCheckin: Scalars['Boolean']['output'];
  checkinableCharacters?: Maybe<Array<ICharacter>>;
  config: IEventConfig;
  description?: Maybe<Scalars['String']['output']>;
  endsAt: Scalars['ISO8601DateTime']['output'];
  eventAttendee?: Maybe<IEventAttendee>;
  eventAttendees?: Maybe<Array<IEventAttendee>>;
  eventAttendeesSize?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isEventAssistant?: Maybe<Scalars['Boolean']['output']>;
  isEventManager?: Maybe<Scalars['Boolean']['output']>;
  isEventRegistrar?: Maybe<Scalars['Boolean']['output']>;
  isHomeGame?: Maybe<Scalars['Boolean']['output']>;
  isVirtual: Scalars['Boolean']['output'];
  itemTransactions?: Maybe<Array<IItemTransaction>>;
  kind: IEventKindEnum;
  location: ILocation;
  maximumCheckinableCharacters?: Maybe<Scalars['Int']['output']>;
  modDescription?: Maybe<Scalars['String']['output']>;
  modMaxSignup?: Maybe<Scalars['Int']['output']>;
  modSections: Array<IModSection>;
  modSignupOpensAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  name: Scalars['String']['output'];
  nextEvent?: Maybe<IEvent>;
  prevEvent?: Maybe<IEvent>;
  printouts?: Maybe<Array<IEventAttendee>>;
  registrationOpensAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  revenue?: Maybe<IEventRevenue>;
  shiftKinds: Array<IShiftKindEnum>;
  shifts: Array<IShift>;
  startsAt: Scalars['ISO8601DateTime']['output'];
  temporaryManagers: Array<ITemporaryManager>;
  ticketStatistics?: Maybe<Array<ITicket>>;
  tickets: Array<ITicket>;
  ticketsBaseConfig?: Maybe<ITicketsBaseConfig>;
};

export type IEventActiveCorrectiveActionsArgs = {
  playerId: Scalars['Int']['input'];
};

export type IEventActiveMembershipArgs = {
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventCanSelfCheckinArgs = {
  playerId: Scalars['Int']['input'];
};

export type IEventCheckinableCharactersArgs = {
  playerId: Scalars['Int']['input'];
};

export type IEventEventAttendeeArgs = {
  eventAttendeeId?: InputMaybe<Scalars['Int']['input']>;
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventEventAttendeesArgs = {
  ticketId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventIsHomeGameArgs = {
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventMaximumCheckinableCharactersArgs = {
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventPrintoutsArgs = {
  homeGame?: InputMaybe<Scalars['Boolean']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  printRequested?: InputMaybe<Scalars['Boolean']['input']>;
  ticketIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IEventShiftsArgs = {
  withFulfillments?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IEventTicketsArgs = {
  editMode?: InputMaybe<Scalars['Boolean']['input']>;
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IEventAttendee = {
  __typename?: 'EventAttendee';
  attendeeCharacters: Array<IAttendeeCharacter>;
  attendeeShifts: Array<IAttendeeShift>;
  attending: Scalars['Boolean']['output'];
  buildAwarded: Scalars['Boolean']['output'];
  buildGrowth: Scalars['Int']['output'];
  checkedInCharactersCount: Scalars['Int']['output'];
  checkinApproved: Scalars['Boolean']['output'];
  event: IEvent;
  id: Scalars['Int']['output'];
  inviter?: Maybe<IUser>;
  kind: IEventAttendeeKindEnum;
  paid: Scalars['Boolean']['output'];
  referralInfo?: Maybe<Scalars['String']['output']>;
  shifts: Array<IShift>;
  ticket: ITicket;
  ticketId: Scalars['Int']['output'];
  user: IUser;
};

export enum IEventAttendeeKindEnum {
  HomeGame = 'home_game',
  TravelAsHomeGame = 'travel_as_home_game',
  TravelGame = 'travel_game',
}

export type IEventConfig = {
  __typename?: 'EventConfig';
  baseBuild: Scalars['Int']['output'];
  considersTravelersLocal: Scalars['Boolean']['output'];
  extraXpCost: Scalars['Float']['output'];
  maxCurveXp: Scalars['Int']['output'];
  maxExtraXp: Scalars['Int']['output'];
  maxExtraXpLimit: Scalars['Int']['output'];
  maxShift: Scalars['Int']['output'];
  shiftKinds: Array<IShiftKindEnum>;
  shiftMultiplier: Scalars['Int']['output'];
  shiftUnit: Scalars['String']['output'];
};

export type IEventInvoice = {
  __typename?: 'EventInvoice';
  attendances: Array<IAttendanceInvoice>;
  baseCompensationTicketCount: Scalars['Int']['output'];
  billableAmount: Scalars['Float']['output'];
  endsAt: Scalars['ISO8601DateTime']['output'];
  extraXpSold: Scalars['Int']['output'];
  fullCompensationTicketCount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCurrentCycle: Scalars['Boolean']['output'];
  isLegacy: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startsAt: Scalars['ISO8601DateTime']['output'];
  ticketsSold: Scalars['Int']['output'];
};

export enum IEventKindEnum {
  Live = 'live',
  National = 'national',
  NationalPremiere = 'national_premiere',
  Premiere = 'premiere',
  Regular = 'regular',
}

export type IEventRevenue = {
  __typename?: 'EventRevenue';
  aggregates: IRevenueAggregates;
  itemizedLineItems: Array<ITicketRevenue>;
};

export type IFaith = {
  __typename?: 'Faith';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type IFellowship = {
  __typename?: 'Fellowship';
  characterFellowships: Array<ICharacterFellowship>;
  characters: Array<ICharacter>;
  charactersCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  kind: IFellowshipKindEnum;
  name: Scalars['String']['output'];
  organization: IOrganization;
  removedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IFellowshipInput = {
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum IFellowshipKindEnum {
  Disease = 'disease',
  Society = 'society',
}

export type IFracture = {
  __typename?: 'Fracture';
  character: ICharacter;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  removedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type IFractureInput = {
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of FulfillShiftMutation */
export type IFulfillShiftMutationInput = {
  attendeeShiftId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isFulfilled: Scalars['Boolean']['input'];
};

/** Autogenerated return type of FulfillShiftMutation. */
export type IFulfillShiftMutationPayload = {
  __typename?: 'FulfillShiftMutationPayload';
  attendeeShift?: Maybe<IAttendeeShift>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of GenerateAuthTokenMutation */
export type IGenerateAuthTokenMutationInput = {
  browser?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  hardware?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** Autogenerated return type of GenerateAuthTokenMutation. */
export type IGenerateAuthTokenMutationPayload = {
  __typename?: 'GenerateAuthTokenMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

export type IInventory = {
  __typename?: 'Inventory';
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  item: IItem;
  itemId: Scalars['Int']['output'];
  registeredItem?: Maybe<IRegisteredItem>;
  registeredItemId?: Maybe<Scalars['Int']['output']>;
  stack: Scalars['Int']['output'];
};

export type IInvoice = {
  __typename?: 'Invoice';
  additionalXpBuybackCount: Scalars['Int']['output'];
  additionalXpCount: Scalars['Int']['output'];
  billableAmount: Scalars['Float']['output'];
  events: Array<IEventInvoice>;
  liveEventTicketBuybackCount: Scalars['Int']['output'];
  liveEventTicketCount: Scalars['Int']['output'];
  virtualEventTicketBuybackCount: Scalars['Int']['output'];
  virtualEventTicketCount: Scalars['Int']['output'];
};

export type IInvoiceChange = {
  __typename?: 'InvoiceChange';
  attending?: Maybe<Scalars['String']['output']>;
  buildGrowth?: Maybe<Scalars['String']['output']>;
  gameKind?: Maybe<Scalars['String']['output']>;
  kind: IInvoiceChangeKindEnum;
  paid?: Maybe<Scalars['String']['output']>;
  ticketId?: Maybe<Scalars['String']['output']>;
  ticketLabel?: Maybe<Scalars['String']['output']>;
};

export enum IInvoiceChangeKindEnum {
  Addition = 'addition',
  Modification = 'modification',
  Removal = 'removal',
}

export type IItem = {
  __typename?: 'Item';
  blueprintForCraftings: Array<IItem>;
  childItemClassifications: Array<IItemClassification>;
  grade: IItemGradeEnum;
  id: Scalars['Int']['output'];
  itemCraftings: Array<IItemCrafting>;
  itemReproductions: Array<IItemReproduction>;
  kind: IItemKindEnum;
  lifetimeAmount?: Maybe<Scalars['Int']['output']>;
  lifetimeUnit: IItemLifetimeUnitEnum;
  metadata: IItemMetadata;
  name: Scalars['String']['output'];
  parts: Array<ICraftingComponent>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  validFrom?: Maybe<Scalars['ISO8601DateTime']['output']>;
  validUntil?: Maybe<Scalars['ISO8601DateTime']['output']>;
  version: Scalars['Int']['output'];
  visibility: IItemVisibilityEnum;
};

export type IItemClassification = {
  __typename?: 'ItemClassification';
  childItem: IItem;
  id: Scalars['Int']['output'];
};

export type IItemCrafting = {
  __typename?: 'ItemCrafting';
  blueprint: IItem;
  craftingComponents: Array<ICraftingComponent>;
  craftingFinalProducts: Array<ICraftingFinalProduct>;
  craftingMindCost?: Maybe<Scalars['Int']['output']>;
  craftingSkills?: Maybe<Scalars['String']['output']>;
  craftingTimeInMinute?: Maybe<Scalars['Int']['output']>;
  craftingZone?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export enum IItemGradeEnum {
  Basic = 'basic',
  Master = 'master',
  Proficient = 'proficient',
  Ungraded = 'ungraded',
}

export enum IItemKindEnum {
  Abstract = 'abstract',
  Armor = 'armor',
  ArmorNecrology = 'armor_necrology',
  Benediction = 'benediction',
  Blueprint = 'blueprint',
  BoltActionFlorentine = 'bolt_action_florentine',
  BoltActionNecrology = 'bolt_action_necrology',
  BoltActionOneHanded = 'bolt_action_one_handed',
  BoltActionTwoHanded = 'bolt_action_two_handed',
  Bow = 'bow',
  BuyList = 'buy_list',
  Conversion = 'conversion',
  CraftingInput = 'crafting_input',
  CraftingOutput = 'crafting_output',
  CraftingResource = 'crafting_resource',
  CulinaryProcedure = 'culinary_procedure',
  Currency = 'currency',
  DeathBrew = 'death_brew',
  EngineeredSpace = 'engineered_space',
  EngineeredSpaceNecrology = 'engineered_space_necrology',
  ExoticWeapon = 'exotic_weapon',
  Foraged = 'foraged',
  GenericItem = 'generic_item',
  Gizmo = 'gizmo',
  GizmoNecrology = 'gizmo_necrology',
  Gun = 'gun',
  HallucinogenicBrew = 'hallucinogenic_brew',
  HealingBrew = 'healing_brew',
  HealingMeal = 'healing_meal',
  InjectedBrew = 'injected_brew',
  IntoxicationBrew = 'intoxication_brew',
  Knucks = 'knucks',
  MeleeFlorentine = 'melee_florentine',
  MeleeSmall = 'melee_small',
  MeleeSmallNecrology = 'melee_small_necrology',
  MeleeStandard = 'melee_standard',
  MeleeTwoHanded = 'melee_two_handed',
  Metal = 'metal',
  MindBrew = 'mind_brew',
  NamedHerb = 'named_herb',
  National = 'national',
  NaturalResource = 'natural_resource',
  NecrologyBrew = 'necrology_brew',
  NecrologyEngineeredItem = 'necrology_engineered_item',
  NecrologyResource = 'necrology_resource',
  OtherBrew = 'other_brew',
  OtherMeal = 'other_meal',
  Others = 'others',
  PoisonApplied = 'poison_applied',
  PoisonBrew = 'poison_brew',
  Procedure = 'procedure',
  Produce = 'produce',
  RecoveryBrew = 'recovery_brew',
  RecoveryMeal = 'recovery_meal',
  RoomAugment = 'room_augment',
  Salvaged = 'salvaged',
  Service = 'service',
  Shield = 'shield',
  ShieldLarge = 'shield_large',
  ShieldNecrology = 'shield_necrology',
  SpecificAugment = 'specific_augment',
  Startup = 'startup',
  Taxonomy = 'taxonomy',
  ThrownWeapon = 'thrown_weapon',
  TradeResource = 'trade_resource',
  Transactable = 'transactable',
  Trap = 'trap',
  UnnamedHerb = 'unnamed_herb',
  UnnamedScrap = 'unnamed_scrap',
  Vehicle = 'vehicle',
  VehicleAirship = 'vehicle_airship',
  VehicleAugment = 'vehicle_augment',
  VehicleWatercraft = 'vehicle_watercraft',
  Voucher = 'voucher',
}

export enum IItemLifetimeUnitEnum {
  Day = 'day',
  Month = 'month',
}

export type IItemMetadata = {
  __typename?: 'ItemMetadata';
  activationRequirement?: Maybe<Scalars['String']['output']>;
  craftableItems?: Maybe<Array<ICraftableItem>>;
  descriptionOfRoleplay?: Maybe<Scalars['String']['output']>;
  durationOfEffect?: Maybe<Scalars['String']['output']>;
  durationOfRoleplay?: Maybe<Scalars['String']['output']>;
  equipmentRequiredForUse?: Maybe<Scalars['String']['output']>;
  locationOfUse?: Maybe<Scalars['String']['output']>;
  mechanics?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  printHeader?: Maybe<Scalars['String']['output']>;
  requirementsToUse?: Maybe<Scalars['String']['output']>;
  uses?: Maybe<Scalars['String']['output']>;
  validTargetDescription?: Maybe<Scalars['String']['output']>;
};

export type IItemReproduction = {
  __typename?: 'ItemReproduction';
  id: Scalars['Int']['output'];
  itemReproductionComponents: Array<IItemReproductionComponent>;
  metadata: IItemReproductionMetadata;
  reproductionMindCost?: Maybe<Scalars['Int']['output']>;
  reproductionSkills?: Maybe<Scalars['String']['output']>;
  reproductionTimeInMinute?: Maybe<Scalars['Int']['output']>;
};

export type IItemReproductionComponent = {
  __typename?: 'ItemReproductionComponent';
  amount: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  item: IItem;
};

export type IItemReproductionMetadata = {
  __typename?: 'ItemReproductionMetadata';
  notes?: Maybe<Scalars['String']['output']>;
};

export type IItemTransaction = {
  __typename?: 'ItemTransaction';
  character: ICharacter;
  childTransactions: Array<IItemTransaction>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['Int']['output'];
  item: IItem;
  postie: IUser;
  registeredItem?: Maybe<IRegisteredItem>;
  source?: Maybe<Scalars['String']['output']>;
  stack: Scalars['Int']['output'];
  status: IItemTransactionStatusEnum;
  transactionPartner?: Maybe<ICharacter>;
};

export type IItemTransactionInput = {
  characterId: Scalars['Int']['input'];
  expiresAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  itemId: Scalars['Int']['input'];
  registeredItemDescription?: InputMaybe<Scalars['String']['input']>;
  registeredItemId?: InputMaybe<Scalars['Int']['input']>;
  stack?: InputMaybe<Scalars['Int']['input']>;
  transactionPartnerId?: InputMaybe<Scalars['Int']['input']>;
};

export enum IItemTransactionStatusEnum {
  InProgress = 'in_progress',
  Invalidated = 'invalidated',
  Legit = 'legit',
}

export enum IItemVisibilityEnum {
  PubliclyVisible = 'publicly_visible',
  Restricted = 'restricted',
}

export type ILifecycle = {
  __typename?: 'Lifecycle';
  createdAt: Scalars['ISO8601DateTime']['output'];
  deletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event: IEvent;
  id: Scalars['Int']['output'];
  kind: ILifecycleKindEnum;
};

export enum ILifecycleKindEnum {
  Gain = 'gain',
  Loss = 'loss',
  Purchase = 'purchase',
}

export type ILineage = {
  __typename?: 'Lineage';
  body: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  infection: Scalars['Int']['output'];
  mind: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  resolve: Scalars['Int']['output'];
};

export type ILocation = {
  __typename?: 'Location';
  city: Scalars['String']['output'];
  events?: Maybe<Array<IEvent>>;
  existingEventsSize?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  region: IRegionEnum;
  streetAddress: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type ILocationEventsArgs = {
  branchId: Scalars['Int']['input'];
};

export type ILocationExistingEventsSizeArgs = {
  branchId: Scalars['Int']['input'];
};

/** Autogenerated input type of LogBlueprintActionMutation */
export type ILogBlueprintActionMutationInput = {
  action: IBlueprintActionEnum;
  blueprintId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copies: Scalars['Int']['input'];
  eventId: Scalars['Int']['input'];
  fingerprints: Array<Scalars['String']['input']>;
};

/** Autogenerated return type of LogBlueprintActionMutation. */
export type ILogBlueprintActionMutationPayload = {
  __typename?: 'LogBlueprintActionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ILore = {
  __typename?: 'Lore';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type IMembership = {
  __typename?: 'Membership';
  createdAt: Scalars['ISO8601DateTime']['output'];
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  grantingBranch?: Maybe<IBranch>;
  grantor?: Maybe<IUser>;
  id: Scalars['Int']['output'];
  organization: IOrganization;
  startsAt: Scalars['ISO8601DateTime']['output'];
  user: IUser;
};

export type IMod = {
  __typename?: 'Mod';
  constraint?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  modSection: IModSection;
  name: Scalars['String']['output'];
  participationLimit: Scalars['Int']['output'];
  timeframe?: Maybe<Scalars['String']['output']>;
};

export type IModSection = {
  __typename?: 'ModSection';
  id: Scalars['Int']['output'];
  mods: Array<IMod>;
  name: Scalars['String']['output'];
  signupLimit: Scalars['Int']['output'];
};

export type IMutation = {
  __typename?: 'Mutation';
  assignBranchLocation?: Maybe<IAssignBranchLocationMutationPayload>;
  assignBranchOwnership?: Maybe<IAssignBranchOwnershipMutationPayload>;
  assignTemporaryManager?: Maybe<IAssignTemporaryManagerMutationPayload>;
  checkin?: Maybe<ICheckinMutationPayload>;
  checkinCharacter?: Maybe<ICheckinCharacterMutationPayload>;
  copyCharacterBuild?: Maybe<ICopyCharacterBuildMutationPayload>;
  createCap?: Maybe<ICreateCapMutationPayload>;
  createCharacter?: Maybe<ICreateCharacterMutationPayload>;
  createCharacterFellowship?: Maybe<ICreateCharacterFellowshipMutationPayload>;
  createCorrectiveAction?: Maybe<ICreateCorrectiveActionMutationPayload>;
  createEvent?: Maybe<ICreateEventMutationPayload>;
  createEventLifecycle?: Maybe<ICreateEventLifecycleMutationPayload>;
  createEventShift?: Maybe<ICreateEventShiftMutationPayload>;
  createEventTicket?: Maybe<ICreateEventTicketMutationPayload>;
  createFellowship?: Maybe<ICreateFellowshipMutationPayload>;
  createItem?: Maybe<ICreateItemMutationPayload>;
  createItemClassification?: Maybe<ICreateItemClassificationMutationPayload>;
  createItemCrafting?: Maybe<ICreateItemCraftingMutationPayload>;
  createItemCraftingComponent?: Maybe<ICreateItemCraftingComponentMutationPayload>;
  createItemCraftingFinalProduct?: Maybe<ICreateItemCraftingFinalProductMutationPayload>;
  createItemReproduction?: Maybe<ICreateItemReproductionMutationPayload>;
  createItemReproductionComponent?: Maybe<ICreateItemReproductionComponentMutationPayload>;
  createItemTransaction?: Maybe<ICreateItemTransactionMutationPayload>;
  createLocation?: Maybe<ICreateLocationMutationPayload>;
  createMembership?: Maybe<ICreateMembershipMutationPayload>;
  createMod?: Maybe<ICreateModMutationPayload>;
  createModSection?: Maybe<ICreateModSectionMutationPayload>;
  createNewPlayer?: Maybe<ICreateNewPlayerMutationPayload>;
  createReferral?: Maybe<ICreateReferralMutationPayload>;
  createSpecialDate?: Maybe<ICreateSpecialDateMutationPayload>;
  destroyAttendeeCharacter?: Maybe<IDestroyAttendeeCharacterMutationPayload>;
  destroyAuthToken?: Maybe<IDestroyAuthTokenMutationPayload>;
  destroyCharacter?: Maybe<IDestroyCharacterMutationPayload>;
  destroyCorrectiveAction?: Maybe<IDestroyCorrectiveActionMutationPayload>;
  destroyEventAttendee?: Maybe<IDestroyEventAttendeeMutationPayload>;
  destroyEventShift?: Maybe<IDestroyEventShiftMutationPayload>;
  destroyEventTicket?: Maybe<IDestroyEventTicketMutationPayload>;
  destroyItemClassification?: Maybe<IDestroyItemClassificationMutationPayload>;
  destroyItemCrafting?: Maybe<IDestroyItemCraftingMutationPayload>;
  destroyItemCraftingComponent?: Maybe<IDestroyItemCraftingComponentMutationPayload>;
  destroyItemCraftingFinalProduct?: Maybe<IDestroyItemCraftingFinalProductMutationPayload>;
  destroyItemReproduction?: Maybe<IDestroyItemReproductionMutationPayload>;
  destroyItemReproductionComponent?: Maybe<IDestroyItemReproductionComponentMutationPayload>;
  destroyLocation?: Maybe<IDestroyLocationMutationPayload>;
  destroyMembership?: Maybe<IDestroyMembershipMutationPayload>;
  destroyReferral?: Maybe<IDestroyReferralMutationPayload>;
  destroySpecialDate?: Maybe<IDestroySpecialDateMutationPayload>;
  fulfillShift?: Maybe<IFulfillShiftMutationPayload>;
  generateAuthToken?: Maybe<IGenerateAuthTokenMutationPayload>;
  logBlueprintAction?: Maybe<ILogBlueprintActionMutationPayload>;
  requestPasswordReset?: Maybe<IRequestPasswordResetMutationPayload>;
  resetPassword?: Maybe<IResetPasswordMutationPayload>;
  retireCharacter?: Maybe<IRetireCharacterMutationPayload>;
  updateBranch?: Maybe<IUpdateBranchMutationPayload>;
  updateCap?: Maybe<IUpdateCapMutationPayload>;
  updateCharacterData?: Maybe<IUpdateCharacterDataMutationPayload>;
  updateCharacterFellowship?: Maybe<IUpdateCharacterFellowshipMutationPayload>;
  updateCorrectiveAction?: Maybe<IUpdateCorrectiveActionMutationPayload>;
  updateEvent?: Maybe<IUpdateEventMutationPayload>;
  updateEventAttendee?: Maybe<IUpdateEventAttendeeMutationPayload>;
  updateEventLifecycle?: Maybe<IUpdateEventLifecycleMutationPayload>;
  updateEventShift?: Maybe<IUpdateEventShiftMutationPayload>;
  updateEventTicket?: Maybe<IUpdateEventTicketMutationPayload>;
  updateFellowship?: Maybe<IUpdateFellowshipMutationPayload>;
  updateItem?: Maybe<IUpdateItemMutationPayload>;
  updateItemCrafting?: Maybe<IUpdateItemCraftingMutationPayload>;
  updateItemCraftingComponent?: Maybe<IUpdateItemCraftingComponentMutationPayload>;
  updateItemCraftingFinalProduct?: Maybe<IUpdateItemCraftingFinalProductMutationPayload>;
  updateItemReproduction?: Maybe<IUpdateItemReproductionMutationPayload>;
  updateItemReproductionComponent?: Maybe<IUpdateItemReproductionComponentMutationPayload>;
  updateItemTransaction?: Maybe<IUpdateItemTransactionMutationPayload>;
  updateLocation?: Maybe<IUpdateLocationMutationPayload>;
  updateMembership?: Maybe<IUpdateMembershipMutationPayload>;
  updateMod?: Maybe<IUpdateModMutationPayload>;
  updateModSection?: Maybe<IUpdateModSectionMutationPayload>;
  updateOrganization?: Maybe<IUpdateOrganizationMutationPayload>;
  updatePassword?: Maybe<IUpdatePasswordMutationPayload>;
  updatePlayerBranchOwnership?: Maybe<IUpdatePlayerBranchOwnershipMutationPayload>;
  updatePlayerData?: Maybe<IUpdatePlayerDataMutationPayload>;
  updatePlayerOrganizationData?: Maybe<IUpdatePlayerOrganizationDataMutationPayload>;
  updateSpecialDate?: Maybe<IUpdateSpecialDateMutationPayload>;
};

export type IMutationAssignBranchLocationArgs = {
  input: IAssignBranchLocationMutationInput;
};

export type IMutationAssignBranchOwnershipArgs = {
  input: IAssignBranchOwnershipMutationInput;
};

export type IMutationAssignTemporaryManagerArgs = {
  input: IAssignTemporaryManagerMutationInput;
};

export type IMutationCheckinArgs = {
  input: ICheckinMutationInput;
};

export type IMutationCheckinCharacterArgs = {
  input: ICheckinCharacterMutationInput;
};

export type IMutationCopyCharacterBuildArgs = {
  input: ICopyCharacterBuildMutationInput;
};

export type IMutationCreateCapArgs = {
  input: ICreateCapMutationInput;
};

export type IMutationCreateCharacterArgs = {
  input: ICreateCharacterMutationInput;
};

export type IMutationCreateCharacterFellowshipArgs = {
  input: ICreateCharacterFellowshipMutationInput;
};

export type IMutationCreateCorrectiveActionArgs = {
  input: ICreateCorrectiveActionMutationInput;
};

export type IMutationCreateEventArgs = {
  input: ICreateEventMutationInput;
};

export type IMutationCreateEventLifecycleArgs = {
  input: ICreateEventLifecycleMutationInput;
};

export type IMutationCreateEventShiftArgs = {
  input: ICreateEventShiftMutationInput;
};

export type IMutationCreateEventTicketArgs = {
  input: ICreateEventTicketMutationInput;
};

export type IMutationCreateFellowshipArgs = {
  input: ICreateFellowshipMutationInput;
};

export type IMutationCreateItemArgs = {
  input: ICreateItemMutationInput;
};

export type IMutationCreateItemClassificationArgs = {
  input: ICreateItemClassificationMutationInput;
};

export type IMutationCreateItemCraftingArgs = {
  input: ICreateItemCraftingMutationInput;
};

export type IMutationCreateItemCraftingComponentArgs = {
  input: ICreateItemCraftingComponentMutationInput;
};

export type IMutationCreateItemCraftingFinalProductArgs = {
  input: ICreateItemCraftingFinalProductMutationInput;
};

export type IMutationCreateItemReproductionArgs = {
  input: ICreateItemReproductionMutationInput;
};

export type IMutationCreateItemReproductionComponentArgs = {
  input: ICreateItemReproductionComponentMutationInput;
};

export type IMutationCreateItemTransactionArgs = {
  input: ICreateItemTransactionMutationInput;
};

export type IMutationCreateLocationArgs = {
  input: ICreateLocationMutationInput;
};

export type IMutationCreateMembershipArgs = {
  input: ICreateMembershipMutationInput;
};

export type IMutationCreateModArgs = {
  input: ICreateModMutationInput;
};

export type IMutationCreateModSectionArgs = {
  input: ICreateModSectionMutationInput;
};

export type IMutationCreateNewPlayerArgs = {
  input: ICreateNewPlayerMutationInput;
};

export type IMutationCreateReferralArgs = {
  input: ICreateReferralMutationInput;
};

export type IMutationCreateSpecialDateArgs = {
  input: ICreateSpecialDateMutationInput;
};

export type IMutationDestroyAttendeeCharacterArgs = {
  input: IDestroyAttendeeCharacterMutationInput;
};

export type IMutationDestroyAuthTokenArgs = {
  input: IDestroyAuthTokenMutationInput;
};

export type IMutationDestroyCharacterArgs = {
  input: IDestroyCharacterMutationInput;
};

export type IMutationDestroyCorrectiveActionArgs = {
  input: IDestroyCorrectiveActionMutationInput;
};

export type IMutationDestroyEventAttendeeArgs = {
  input: IDestroyEventAttendeeMutationInput;
};

export type IMutationDestroyEventShiftArgs = {
  input: IDestroyEventShiftMutationInput;
};

export type IMutationDestroyEventTicketArgs = {
  input: IDestroyEventTicketMutationInput;
};

export type IMutationDestroyItemClassificationArgs = {
  input: IDestroyItemClassificationMutationInput;
};

export type IMutationDestroyItemCraftingArgs = {
  input: IDestroyItemCraftingMutationInput;
};

export type IMutationDestroyItemCraftingComponentArgs = {
  input: IDestroyItemCraftingComponentMutationInput;
};

export type IMutationDestroyItemCraftingFinalProductArgs = {
  input: IDestroyItemCraftingFinalProductMutationInput;
};

export type IMutationDestroyItemReproductionArgs = {
  input: IDestroyItemReproductionMutationInput;
};

export type IMutationDestroyItemReproductionComponentArgs = {
  input: IDestroyItemReproductionComponentMutationInput;
};

export type IMutationDestroyLocationArgs = {
  input: IDestroyLocationMutationInput;
};

export type IMutationDestroyMembershipArgs = {
  input: IDestroyMembershipMutationInput;
};

export type IMutationDestroyReferralArgs = {
  input: IDestroyReferralMutationInput;
};

export type IMutationDestroySpecialDateArgs = {
  input: IDestroySpecialDateMutationInput;
};

export type IMutationFulfillShiftArgs = {
  input: IFulfillShiftMutationInput;
};

export type IMutationGenerateAuthTokenArgs = {
  input: IGenerateAuthTokenMutationInput;
};

export type IMutationLogBlueprintActionArgs = {
  input: ILogBlueprintActionMutationInput;
};

export type IMutationRequestPasswordResetArgs = {
  input: IRequestPasswordResetMutationInput;
};

export type IMutationResetPasswordArgs = {
  input: IResetPasswordMutationInput;
};

export type IMutationRetireCharacterArgs = {
  input: IRetireCharacterMutationInput;
};

export type IMutationUpdateBranchArgs = {
  input: IUpdateBranchMutationInput;
};

export type IMutationUpdateCapArgs = {
  input: IUpdateCapMutationInput;
};

export type IMutationUpdateCharacterDataArgs = {
  input: IUpdateCharacterDataMutationInput;
};

export type IMutationUpdateCharacterFellowshipArgs = {
  input: IUpdateCharacterFellowshipMutationInput;
};

export type IMutationUpdateCorrectiveActionArgs = {
  input: IUpdateCorrectiveActionMutationInput;
};

export type IMutationUpdateEventArgs = {
  input: IUpdateEventMutationInput;
};

export type IMutationUpdateEventAttendeeArgs = {
  input: IUpdateEventAttendeeMutationInput;
};

export type IMutationUpdateEventLifecycleArgs = {
  input: IUpdateEventLifecycleMutationInput;
};

export type IMutationUpdateEventShiftArgs = {
  input: IUpdateEventShiftMutationInput;
};

export type IMutationUpdateEventTicketArgs = {
  input: IUpdateEventTicketMutationInput;
};

export type IMutationUpdateFellowshipArgs = {
  input: IUpdateFellowshipMutationInput;
};

export type IMutationUpdateItemArgs = {
  input: IUpdateItemMutationInput;
};

export type IMutationUpdateItemCraftingArgs = {
  input: IUpdateItemCraftingMutationInput;
};

export type IMutationUpdateItemCraftingComponentArgs = {
  input: IUpdateItemCraftingComponentMutationInput;
};

export type IMutationUpdateItemCraftingFinalProductArgs = {
  input: IUpdateItemCraftingFinalProductMutationInput;
};

export type IMutationUpdateItemReproductionArgs = {
  input: IUpdateItemReproductionMutationInput;
};

export type IMutationUpdateItemReproductionComponentArgs = {
  input: IUpdateItemReproductionComponentMutationInput;
};

export type IMutationUpdateItemTransactionArgs = {
  input: IUpdateItemTransactionMutationInput;
};

export type IMutationUpdateLocationArgs = {
  input: IUpdateLocationMutationInput;
};

export type IMutationUpdateMembershipArgs = {
  input: IUpdateMembershipMutationInput;
};

export type IMutationUpdateModArgs = {
  input: IUpdateModMutationInput;
};

export type IMutationUpdateModSectionArgs = {
  input: IUpdateModSectionMutationInput;
};

export type IMutationUpdateOrganizationArgs = {
  input: IUpdateOrganizationMutationInput;
};

export type IMutationUpdatePasswordArgs = {
  input: IUpdatePasswordMutationInput;
};

export type IMutationUpdatePlayerBranchOwnershipArgs = {
  input: IUpdatePlayerBranchOwnershipMutationInput;
};

export type IMutationUpdatePlayerDataArgs = {
  input: IUpdatePlayerDataMutationInput;
};

export type IMutationUpdatePlayerOrganizationDataArgs = {
  input: IUpdatePlayerOrganizationDataMutationInput;
};

export type IMutationUpdateSpecialDateArgs = {
  input: IUpdateSpecialDateMutationInput;
};

export type IOrganization = {
  __typename?: 'Organization';
  armorUpgradeComponents: Array<ICraftingComponent>;
  branch: IBranch;
  branches: Array<IBranch>;
  characterConfig?: Maybe<ICharacterConfig>;
  configs?: Maybe<IOrganizationConfig>;
  fellowship?: Maybe<IFellowship>;
  fellowships?: Maybe<Array<IFellowship>>;
  id: Scalars['Int']['output'];
  item?: Maybe<IItem>;
  itemGrades: Array<IItemGradeEnum>;
  itemKinds: Array<IItemKindEnum>;
  items: Array<IItem>;
  lastEventAttendanceSnapshotOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  lastEventDiffOn?: Maybe<Scalars['ISO8601DateTime']['output']>;
  memberships?: Maybe<Array<IMembership>>;
  name: Scalars['String']['output'];
  rebirthedCharacters?: Maybe<Array<ICharacter>>;
  role: IOrganizationRoleEnum;
  shorthand: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  specialDates?: Maybe<Array<ISpecialDate>>;
  statistics?: Maybe<IStatisticsResult>;
  timelines?: Maybe<Array<ITimeline>>;
};

export type IOrganizationBranchesArgs = {
  owned?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IOrganizationFellowshipArgs = {
  fellowshipId: Scalars['Int']['input'];
};

export type IOrganizationFellowshipsArgs = {
  kind: IFellowshipKindEnum;
};

export type IOrganizationItemArgs = {
  id: Scalars['Int']['input'];
};

export type IOrganizationItemsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<IItemKindEnum>;
  versions: Array<Scalars['Int']['input']>;
};

export type IOrganizationMembershipsArgs = {
  on?: InputMaybe<Scalars['String']['input']>;
};

export type IOrganizationStatisticsArgs = {
  eventId?: InputMaybe<Scalars['Int']['input']>;
  isLocal?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  printRequested?: InputMaybe<Scalars['Boolean']['input']>;
  type: IStatisticsTypeEnum;
};

export type IOrganizationConfig = {
  __typename?: 'OrganizationConfig';
  charactersActiveCountMax: Scalars['Int']['output'];
  charactersCountMax: Scalars['Int']['output'];
  charactersCountStaffMax: Scalars['Int']['output'];
  createFellowshipsOnDemand: Scalars['Boolean']['output'];
  fellowshipAlias: Scalars['String']['output'];
  fellowshipsEventTracking: Scalars['Boolean']['output'];
  fellowshipsMaxRank: Scalars['Int']['output'];
  fellowshipsMinRank: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export enum IOrganizationRoleEnum {
  Admin = 'admin',
  Assistant = 'assistant',
  Employee = 'employee',
  Regular = 'regular',
  Unset = 'unset',
}

export type IPermission = {
  __typename?: 'Permission';
  entity: IUser;
  permitted: Scalars['Boolean']['output'];
};

export type IQuery = {
  __typename?: 'Query';
  branch?: Maybe<IBranch>;
  event?: Maybe<IEvent>;
  events: Array<IEvent>;
  locations: Array<ILocation>;
  memberships: Array<IMembership>;
  organization: IOrganization;
  organizationRoles: Array<IOrganizationRoleEnum>;
  organizations: Array<IOrganization>;
  permission: IPermission;
  resetPasswordTokenStatus: Scalars['Boolean']['output'];
  searchPlayer: Array<ISearchPlayerResult>;
  temporaryTokenStatus: Scalars['Boolean']['output'];
  tokenId?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<IUser>;
};

export type IQueryBranchArgs = {
  branchId: Scalars['Int']['input'];
};

export type IQueryEventArgs = {
  eventId: Scalars['Int']['input'];
};

export type IQueryEventsArgs = {
  branchIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  upto?: InputMaybe<Scalars['String']['input']>;
};

export type IQueryMembershipsArgs = {
  branchIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IQueryOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryPermissionArgs = {
  action: Scalars['String']['input'];
  branchId?: InputMaybe<Scalars['Int']['input']>;
  characterId?: InputMaybe<Scalars['Int']['input']>;
  eventId?: InputMaybe<Scalars['Int']['input']>;
  playerId?: InputMaybe<Scalars['Int']['input']>;
};

export type IQueryResetPasswordTokenStatusArgs = {
  token: Scalars['String']['input'];
};

export type IQuerySearchPlayerArgs = {
  accessLevelFilters?: InputMaybe<Array<IOrganizationRoleEnum>>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  playersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
};

export type IQueryUserArgs = {
  userId: Scalars['Int']['input'];
};

export enum IRegionEnum {
  Ab = 'AB',
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  As = 'AS',
  Atl = 'ATL',
  Az = 'AZ',
  Bc = 'BC',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Fm = 'FM',
  Ga = 'GA',
  Gu = 'GU',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Mb = 'MB',
  Md = 'MD',
  Me = 'ME',
  Mh = 'MH',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Ms = 'MS',
  Mt = 'MT',
  Na = 'NA',
  National = 'NATIONAL',
  Nb = 'NB',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nl = 'NL',
  Nm = 'NM',
  Noca = 'NOCA',
  Ns = 'NS',
  Nt = 'NT',
  Nu = 'NU',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  On = 'ON',
  Or = 'OR',
  Pa = 'PA',
  Pe = 'PE',
  Pnw = 'PNW',
  Pr = 'PR',
  Psw = 'PSW',
  Pw = 'PW',
  Qc = 'QC',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Sk = 'SK',
  Socal = 'SOCAL',
  Tn = 'TN',
  To = 'TO',
  Tx = 'TX',
  Unspecified = 'UNSPECIFIED',
  Ut = 'UT',
  Va = 'VA',
  Vi = 'VI',
  Virtual = 'VIRTUAL',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY',
  Yt = 'YT',
}

export type IRegisteredItem = {
  __typename?: 'RegisteredItem';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

/** Autogenerated input type of RequestPasswordResetMutation */
export type IRequestPasswordResetMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

/** Autogenerated return type of RequestPasswordResetMutation. */
export type IRequestPasswordResetMutationPayload = {
  __typename?: 'RequestPasswordResetMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ResetPasswordMutation */
export type IResetPasswordMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

/** Autogenerated return type of ResetPasswordMutation. */
export type IResetPasswordMutationPayload = {
  __typename?: 'ResetPasswordMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

export type IResidenciesStatistics = {
  __typename?: 'ResidenciesStatistics';
  eventId?: Maybe<Scalars['Int']['output']>;
  type: IStatisticsTypeEnum;
  values: Array<IResidencyStatistics>;
};

export type IResidencyStatistics = {
  __typename?: 'ResidencyStatistics';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  shorthand: Scalars['String']['output'];
};

/** Autogenerated input type of RetireCharacterMutation */
export type IRetireCharacterMutationInput = {
  characterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RetireCharacterMutation. */
export type IRetireCharacterMutationPayload = {
  __typename?: 'RetireCharacterMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

export type IRetirementPaths = {
  __typename?: 'RetirementPaths';
  alive: Array<IRetirementXp>;
  dead: Array<IRetirementXp>;
};

export type IRetirementXp = {
  __typename?: 'RetirementXp';
  amount: Scalars['Int']['output'];
  earnedOn?: Maybe<Scalars['ISO8601Date']['output']>;
};

export type IRevenueAggregates = {
  __typename?: 'RevenueAggregates';
  attendeesPaid: Scalars['Int']['output'];
  attendeesTotal: Scalars['Int']['output'];
  revenue: Scalars['Float']['output'];
  revenueDiscounted: Scalars['Float']['output'];
  revenueLiable: Scalars['Float']['output'];
};

export type ISearchPlayerResult = ICharacter | IError | IUser;

export type IShift = {
  __typename?: 'Shift';
  adjustableExpansionStrategy: Scalars['Boolean']['output'];
  attendeeShifts?: Maybe<Array<IAttendeeShift>>;
  autoExpand: Scalars['Boolean']['output'];
  baseRatio?: Maybe<Scalars['Int']['output']>;
  eventId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  identifier?: Maybe<Scalars['Int']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  isFullyBooked: Scalars['Boolean']['output'];
  kind: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  playersCount?: Maybe<Scalars['Int']['output']>;
};

export enum IShiftKindEnum {
  Casting = 'casting',
  Cleanup = 'cleanup',
  Special = 'special',
}

export type ISkill = {
  __typename?: 'Skill';
  category?: Maybe<Scalars['String']['output']>;
  dep?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  innate?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  requiresResolve?: Maybe<Scalars['Boolean']['output']>;
  singleTier?: Maybe<Scalars['Boolean']['output']>;
  specialization?: Maybe<Scalars['String']['output']>;
  tier?: Maybe<Scalars['Int']['output']>;
};

export type ISkillMechanic = {
  __typename?: 'SkillMechanic';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type ISkillStatistics = {
  __typename?: 'SkillStatistics';
  count: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
};

export type ISkillsStatistics = {
  __typename?: 'SkillsStatistics';
  eventId?: Maybe<Scalars['Int']['output']>;
  type: IStatisticsTypeEnum;
  values: Array<ISkillStatistics>;
};

export type ISpecialDate = {
  __typename?: 'SpecialDate';
  endsAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['Int']['output'];
  kind: ISpecialDateKindEnum;
  startsAt: Scalars['ISO8601DateTime']['output'];
  title: Scalars['String']['output'];
};

export enum ISpecialDateKindEnum {
  CalendarBlock = 'calendar_block',
}

export type IStatMechanic = {
  __typename?: 'StatMechanic';
  id: Scalars['String']['output'];
  max?: Maybe<Scalars['Int']['output']>;
  min: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type IStatStatistics = {
  __typename?: 'StatStatistics';
  body: Array<Array<Scalars['Int']['output']>>;
  buildEarned: Array<Array<Scalars['Int']['output']>>;
  buildUsed: Array<Array<Scalars['Int']['output']>>;
  currentLife: Array<Array<Scalars['Int']['output']>>;
  faithId: Array<Array<Scalars['Int']['output']>>;
  mind: Array<Array<Scalars['Int']['output']>>;
  resolve: Array<Array<Scalars['Int']['output']>>;
};

export type IStatisticsResult =
  | IResidenciesStatistics
  | ISkillsStatistics
  | IStatsStatistics;

export enum IStatisticsTypeEnum {
  Lores = 'lores',
  Reputations = 'reputations',
  Residencies = 'residencies',
  Skills = 'skills',
  Stats = 'stats',
}

export type IStatsStatistics = {
  __typename?: 'StatsStatistics';
  eventId?: Maybe<Scalars['Int']['output']>;
  type: IStatisticsTypeEnum;
  values: Array<IStatStatistics>;
};

export type IStrain = {
  __typename?: 'Strain';
  id: Scalars['Int']['output'];
  lineageId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ITemporaryManager = {
  __typename?: 'TemporaryManager';
  event: IEvent;
  id: Scalars['Int']['output'];
  user: IUser;
};

export type ITemporaryToken = {
  __typename?: 'TemporaryToken';
  browser?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  hardware?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export type ITicket = {
  __typename?: 'Ticket';
  cost: Scalars['Int']['output'];
  discountType: ITicketDiscountEnum;
  eventAttendeesCount?: Maybe<Scalars['Int']['output']>;
  eventId: Scalars['Int']['output'];
  fixedLimit?: Maybe<Scalars['Boolean']['output']>;
  fullyBooked: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  identifier: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  passType: ITicketPassEnum;
  restrictionType: ITicketRestrictionEnum;
  shiftResponsibilities: Scalars['Int']['output'];
};

export enum ITicketDiscountEnum {
  BaseCompensation = 'base_compensation',
  FullCompensation = 'full_compensation',
  FullLiability = 'full_liability',
  LegacyNoDiscount = 'legacy_no_discount',
}

export type ITicketInvoice = {
  __typename?: 'TicketInvoice';
  discountType: ITicketDiscountEnum;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export enum ITicketPassEnum {
  DayPass = 'day_pass',
  EventPass = 'event_pass',
  NonAttendingPass = 'non_attending_pass',
  NpcPass = 'npc_pass',
  TravelingDirectorPass = 'traveling_director_pass',
}

export enum ITicketRestrictionEnum {
  AssistantRestricted = 'assistant_restricted',
  EmployeeRestricted = 'employee_restricted',
  NewPlayerRestricted = 'new_player_restricted',
  Unrestricted = 'unrestricted',
}

export type ITicketRevenue = {
  __typename?: 'TicketRevenue';
  attendeesPaid: Scalars['Int']['output'];
  attendeesTotal?: Maybe<Scalars['Int']['output']>;
  cost: Scalars['Float']['output'];
  discountType?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  revenue: Scalars['Float']['output'];
  revenueDiscounted: Scalars['Float']['output'];
  revenueLiable: Scalars['Float']['output'];
  subitems?: Maybe<Array<ITicketRevenue>>;
};

export type ITicketsBaseConfig = {
  __typename?: 'TicketsBaseConfig';
  discountTypes: Array<ITicketDiscountEnum>;
  passTypes: Array<ITicketPassEnum>;
  restrictionTypes: Array<ITicketRestrictionEnum>;
};

export type ITimeline = {
  __typename?: 'Timeline';
  attendance: Scalars['Int']['output'];
  eventId: Scalars['Int']['output'];
  eventName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  local: Scalars['Boolean']['output'];
  shorthand: Scalars['String']['output'];
  timecode: Scalars['ISO8601Date']['output'];
  virtual: Scalars['Boolean']['output'];
};

/** Autogenerated input type of UpdateBranchMutation */
export type IUpdateBranchMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationUrl?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBranchMutation. */
export type IUpdateBranchMutationPayload = {
  __typename?: 'UpdateBranchMutationPayload';
  branch?: Maybe<IBranch>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateCapMutation */
export type IUpdateCapMutationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  capId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<ICapStateEnum>;
};

/** Autogenerated return type of UpdateCapMutation. */
export type IUpdateCapMutationPayload = {
  __typename?: 'UpdateCapMutationPayload';
  cap?: Maybe<ICap>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateCharacterDataMutation */
export type IUpdateCharacterDataMutationInput = {
  body?: InputMaybe<Scalars['Int']['input']>;
  characterFellowship?: InputMaybe<ICharacterFellowshipInput>;
  characterId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deathCount?: InputMaybe<Scalars['Int']['input']>;
  faithId?: InputMaybe<Scalars['Int']['input']>;
  fracture?: InputMaybe<IFractureInput>;
  infection?: InputMaybe<Scalars['Int']['input']>;
  legacyHomeGameCount?: InputMaybe<Scalars['Int']['input']>;
  loreIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  mind?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  playerNotes?: InputMaybe<Scalars['String']['input']>;
  resolve?: InputMaybe<Scalars['Int']['input']>;
  skills?: InputMaybe<Array<ICharacterSkillInput>>;
  staffNotes?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ICharacterStatusEnum>;
  strainId?: InputMaybe<Scalars['Int']['input']>;
  variant?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCharacterDataMutation. */
export type IUpdateCharacterDataMutationPayload = {
  __typename?: 'UpdateCharacterDataMutationPayload';
  character?: Maybe<ICharacter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateCharacterFellowshipMutation */
export type IUpdateCharacterFellowshipMutationInput = {
  characterFellowshipId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateCharacterFellowshipMutation. */
export type IUpdateCharacterFellowshipMutationPayload = {
  __typename?: 'UpdateCharacterFellowshipMutationPayload';
  characterFellowship?: Maybe<ICharacterFellowship>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateCorrectiveActionMutation */
export type IUpdateCorrectiveActionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  correctiveActionId: Scalars['Int']['input'];
  endsAt?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateCorrectiveActionMutation. */
export type IUpdateCorrectiveActionMutationPayload = {
  __typename?: 'UpdateCorrectiveActionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  correctiveAction?: Maybe<ICorrectiveAction>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateEventAttendeeMutation */
export type IUpdateEventAttendeeMutationInput = {
  buildGrowth?: InputMaybe<Scalars['Int']['input']>;
  characters?: InputMaybe<Array<ICheckinCharacterInput>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventAttendeeId: Scalars['Int']['input'];
  inviterId?: InputMaybe<Scalars['Int']['input']>;
  isAttending?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  printRequestOnCharacterIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  referralInfo?: InputMaybe<Scalars['String']['input']>;
  shiftIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ticketId?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateEventAttendeeMutation. */
export type IUpdateEventAttendeeMutationPayload = {
  __typename?: 'UpdateEventAttendeeMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  eventAttendee?: Maybe<IEventAttendee>;
};

/** Autogenerated input type of UpdateEventLifecycleMutation */
export type IUpdateEventLifecycleMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  lifecycleId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateEventLifecycleMutation. */
export type IUpdateEventLifecycleMutationPayload = {
  __typename?: 'UpdateEventLifecycleMutationPayload';
  character?: Maybe<ICharacter>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateEventMutation */
export type IUpdateEventMutationInput = {
  baseBuild?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  considersTravelersLocal?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['Int']['input'];
  extraXpCost?: InputMaybe<Scalars['Float']['input']>;
  kind?: InputMaybe<IEventKindEnum>;
  locationId?: InputMaybe<Scalars['Int']['input']>;
  maxExtraXp?: InputMaybe<Scalars['Int']['input']>;
  modDescription?: InputMaybe<Scalars['String']['input']>;
  modMaxSignup?: InputMaybe<Scalars['Int']['input']>;
  modSignupOpensAt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationOpensAt?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateEventMutation. */
export type IUpdateEventMutationPayload = {
  __typename?: 'UpdateEventMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  event?: Maybe<IEvent>;
};

/** Autogenerated input type of UpdateEventShiftMutation */
export type IUpdateEventShiftMutationInput = {
  autoExpand?: InputMaybe<Scalars['Boolean']['input']>;
  baseRatio?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  shiftId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateEventShiftMutation. */
export type IUpdateEventShiftMutationPayload = {
  __typename?: 'UpdateEventShiftMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  shift?: Maybe<IShift>;
};

/** Autogenerated input type of UpdateEventTicketMutation */
export type IUpdateEventTicketMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cost?: InputMaybe<Scalars['Int']['input']>;
  discountType?: InputMaybe<ITicketDiscountEnum>;
  label?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  passType?: InputMaybe<ITicketPassEnum>;
  restrictionType?: InputMaybe<ITicketRestrictionEnum>;
  shiftResponsibilities?: InputMaybe<Scalars['Int']['input']>;
  ticketId: Scalars['Int']['input'];
  unlimited?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of UpdateEventTicketMutation. */
export type IUpdateEventTicketMutationPayload = {
  __typename?: 'UpdateEventTicketMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<ITicket>;
};

/** Autogenerated input type of UpdateFellowshipMutation */
export type IUpdateFellowshipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fellowshipId: Scalars['Int']['input'];
  forRemoval?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateFellowshipMutation. */
export type IUpdateFellowshipMutationPayload = {
  __typename?: 'UpdateFellowshipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  fellowship?: Maybe<IFellowship>;
};

/** Autogenerated input type of UpdateItemCraftingComponentMutation */
export type IUpdateItemCraftingComponentMutationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemCraftingComponentId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateItemCraftingComponentMutation. */
export type IUpdateItemCraftingComponentMutationPayload = {
  __typename?: 'UpdateItemCraftingComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  craftingComponent?: Maybe<ICraftingComponent>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateItemCraftingFinalProductMutation */
export type IUpdateItemCraftingFinalProductMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemCraftingFinalProductId: Scalars['Int']['input'];
  stack?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateItemCraftingFinalProductMutation. */
export type IUpdateItemCraftingFinalProductMutationPayload = {
  __typename?: 'UpdateItemCraftingFinalProductMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  craftingFinalProduct?: Maybe<ICraftingFinalProduct>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateItemCraftingMutation */
export type IUpdateItemCraftingMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  craftingMindCost?: InputMaybe<Scalars['Int']['input']>;
  craftingSkills?: InputMaybe<Scalars['String']['input']>;
  craftingTimeInMinute?: InputMaybe<Scalars['Int']['input']>;
  craftingZone?: InputMaybe<Scalars['String']['input']>;
  itemCraftingId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateItemCraftingMutation. */
export type IUpdateItemCraftingMutationPayload = {
  __typename?: 'UpdateItemCraftingMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemCrafting?: Maybe<IItemCrafting>;
};

/** Autogenerated input type of UpdateItemMutation */
export type IUpdateItemMutationInput = {
  activationRequirement?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  descriptionOfRoleplay?: InputMaybe<Scalars['String']['input']>;
  durationOfEffect?: InputMaybe<Scalars['String']['input']>;
  durationOfRoleplay?: InputMaybe<Scalars['String']['input']>;
  equipmentRequiredForUse?: InputMaybe<Scalars['String']['input']>;
  grade?: InputMaybe<IItemGradeEnum>;
  itemId: Scalars['Int']['input'];
  kind?: InputMaybe<IItemKindEnum>;
  lifetimeAmount?: InputMaybe<Scalars['Int']['input']>;
  locationOfUse?: InputMaybe<Scalars['String']['input']>;
  mechanics?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  printHeader?: InputMaybe<Scalars['String']['input']>;
  requirementsToUse?: InputMaybe<Scalars['String']['input']>;
  uses?: InputMaybe<Scalars['String']['input']>;
  validFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  validTargetDescription?: InputMaybe<Scalars['String']['input']>;
  validUntil?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  visibility?: InputMaybe<IItemVisibilityEnum>;
};

/** Autogenerated return type of UpdateItemMutation. */
export type IUpdateItemMutationPayload = {
  __typename?: 'UpdateItemMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  item?: Maybe<IItem>;
};

/** Autogenerated input type of UpdateItemReproductionComponentMutation */
export type IUpdateItemReproductionComponentMutationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemReproductionComponentId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateItemReproductionComponentMutation. */
export type IUpdateItemReproductionComponentMutationPayload = {
  __typename?: 'UpdateItemReproductionComponentMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemReproductionComponent?: Maybe<IItemReproductionComponent>;
};

/** Autogenerated input type of UpdateItemReproductionMutation */
export type IUpdateItemReproductionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  itemReproductionId: Scalars['Int']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  reproductionMindCost?: InputMaybe<Scalars['Int']['input']>;
  reproductionSkills?: InputMaybe<Scalars['String']['input']>;
  reproductionTimeInMinute?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateItemReproductionMutation. */
export type IUpdateItemReproductionMutationPayload = {
  __typename?: 'UpdateItemReproductionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemReproduction?: Maybe<IItemReproduction>;
};

/** Autogenerated input type of UpdateItemTransactionMutation */
export type IUpdateItemTransactionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  itemTransactionId: Scalars['Int']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IItemTransactionStatusEnum>;
};

/** Autogenerated return type of UpdateItemTransactionMutation. */
export type IUpdateItemTransactionMutationPayload = {
  __typename?: 'UpdateItemTransactionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  itemTransaction?: Maybe<IItemTransaction>;
};

/** Autogenerated input type of UpdateLocationMutation */
export type IUpdateLocationMutationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateLocationMutation. */
export type IUpdateLocationMutationPayload = {
  __typename?: 'UpdateLocationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  location?: Maybe<ILocation>;
};

/** Autogenerated input type of UpdateMembershipMutation */
export type IUpdateMembershipMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  membershipId: Scalars['Int']['input'];
  startsAt: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateMembershipMutation. */
export type IUpdateMembershipMutationPayload = {
  __typename?: 'UpdateMembershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  membership?: Maybe<IMembership>;
};

/** Autogenerated input type of UpdateModMutation */
export type IUpdateModMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  modId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  participationLimit?: InputMaybe<Scalars['Int']['input']>;
  reputationConstraints?: InputMaybe<Array<ICharacterSkillInput>>;
  skillConstraints?: InputMaybe<Array<ICharacterSkillInput>>;
  timeframe?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateModMutation. */
export type IUpdateModMutationPayload = {
  __typename?: 'UpdateModMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  mod?: Maybe<IMod>;
};

/** Autogenerated input type of UpdateModSectionMutation */
export type IUpdateModSectionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  modSectionId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  signupLimit?: InputMaybe<Scalars['Int']['input']>;
};

/** Autogenerated return type of UpdateModSectionMutation. */
export type IUpdateModSectionMutationPayload = {
  __typename?: 'UpdateModSectionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  modSection?: Maybe<IModSection>;
};

/** Autogenerated input type of UpdateOrganizationMutation */
export type IUpdateOrganizationMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fellowship?: InputMaybe<IFellowshipInput>;
  organizationId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdateOrganizationMutation. */
export type IUpdateOrganizationMutationPayload = {
  __typename?: 'UpdateOrganizationMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<IOrganization>;
};

/** Autogenerated input type of UpdatePasswordMutation */
export type IUpdatePasswordMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

/** Autogenerated return type of UpdatePasswordMutation. */
export type IUpdatePasswordMutationPayload = {
  __typename?: 'UpdatePasswordMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdatePlayerBranchOwnershipMutation */
export type IUpdatePlayerBranchOwnershipMutationInput = {
  branchId: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  isOwned: Scalars['Boolean']['input'];
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePlayerBranchOwnershipMutation. */
export type IUpdatePlayerBranchOwnershipMutationPayload = {
  __typename?: 'UpdatePlayerBranchOwnershipMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UpdatePlayerDataMutation */
export type IUpdatePlayerDataMutationInput = {
  assistantFlags?: InputMaybe<Array<IAssistantFlagEnum>>;
  branchId?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  preferredName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<IOrganizationRoleEnum>;
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePlayerDataMutation. */
export type IUpdatePlayerDataMutationPayload = {
  __typename?: 'UpdatePlayerDataMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UpdatePlayerOrganizationDataMutation */
export type IUpdatePlayerOrganizationDataMutationInput = {
  branchId?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['Int']['input'];
  role?: InputMaybe<IOrganizationRoleEnum>;
  userId: Scalars['Int']['input'];
};

/** Autogenerated return type of UpdatePlayerOrganizationDataMutation. */
export type IUpdatePlayerOrganizationDataMutationPayload = {
  __typename?: 'UpdatePlayerOrganizationDataMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  user?: Maybe<IUser>;
};

/** Autogenerated input type of UpdateSpecialDateMutation */
export type IUpdateSpecialDateMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<ISpecialDateKindEnum>;
  specialDateId: Scalars['Int']['input'];
  startsAt?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateSpecialDateMutation. */
export type IUpdateSpecialDateMutationPayload = {
  __typename?: 'UpdateSpecialDateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  specialDate?: Maybe<ISpecialDate>;
};

export type IUser = {
  __typename?: 'User';
  assistantFlags: Array<IAssistantFlagEnum>;
  caps: Array<ICap>;
  character?: Maybe<ICharacter>;
  characters: Array<ICharacter>;
  correctiveActions: Array<ICorrectiveAction>;
  emailAddress: Scalars['String']['output'];
  eventAttendances: Array<IEventAttendee>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invitations?: Maybe<Array<IUserReferral>>;
  lastMembership?: Maybe<IMembership>;
  lastName: Scalars['String']['output'];
  maxActiveCharactersCount: Scalars['Int']['output'];
  maxCharactersCount: Scalars['Int']['output'];
  membershipActiveDuringEvent?: Maybe<Scalars['Boolean']['output']>;
  memberships: Array<IMembership>;
  notes?: Maybe<Scalars['String']['output']>;
  organizations: Array<IOrganization>;
  ownedBranches: Array<IBranch>;
  preferredName?: Maybe<Scalars['String']['output']>;
  recentEventAttendances: Array<IEventAttendee>;
  referrals: Array<IUserReferral>;
  roleInOrganization: IOrganizationRoleEnum;
  temporaryTokens: Array<ITemporaryToken>;
  totalCapsForEvent: Scalars['Int']['output'];
  upcomingEvents: Array<IEventAttendee>;
  userOrganization?: Maybe<IUserOrganization>;
  userOrganizations: Array<IUserOrganization>;
};

export type IUserAssistantFlagsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserCapsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserCharacterArgs = {
  characterId: Scalars['Int']['input'];
};

export type IUserCharactersArgs = {
  checkinableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IUserEventAttendancesArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  upto?: InputMaybe<Scalars['String']['input']>;
};

export type IUserInvitationsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserLastMembershipArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserMembershipActiveDuringEventArgs = {
  eventId: Scalars['Int']['input'];
};

export type IUserMembershipsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserReferralsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserRoleInOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserTotalCapsForEventArgs = {
  eventId: Scalars['Int']['input'];
};

export type IUserUpcomingEventsArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserUserOrganizationArgs = {
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};

export type IUserInvoice = {
  __typename?: 'UserInvoice';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type IUserOrganization = {
  __typename?: 'UserOrganization';
  assistantFlags: Array<IAssistantFlagEnum>;
  branch: IBranch;
  branchId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  organizationId: Scalars['Int']['output'];
  role: IOrganizationRoleEnum;
  user: IUser;
};

export type IUserReferral = {
  __typename?: 'UserReferral';
  createdAt: Scalars['ISO8601Date']['output'];
  id: Scalars['Int']['output'];
  inviter: IUser;
  newbie: IUser;
};

export type IXpAudit = {
  __typename?: 'XpAudit';
  applicableOn?: Maybe<Scalars['ISO8601Date']['output']>;
  awardedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  branchTransferId?: Maybe<Scalars['Int']['output']>;
  destinationBranch?: Maybe<Scalars['String']['output']>;
  eventId?: Maybe<Scalars['Int']['output']>;
  eventName?: Maybe<Scalars['String']['output']>;
  extraBuildId?: Maybe<Scalars['Int']['output']>;
  homeGameEventsIndex?: Maybe<Scalars['Int']['output']>;
  kind: Scalars['String']['output'];
  originBranch?: Maybe<Scalars['String']['output']>;
  xpAddOn?: Maybe<Scalars['Int']['output']>;
  xpAwarded?: Maybe<Scalars['Int']['output']>;
};

export type WithTypename<T extends { __typename?: any }> = Partial<T> & {
  __typename: NonNullable<T['__typename']>;
};

export type GraphCacheKeysConfig = {
  AssignBranchLocationMutationPayload?: (
    data: WithTypename<IAssignBranchLocationMutationPayload>,
  ) => null | string;
  AssignBranchOwnershipMutationPayload?: (
    data: WithTypename<IAssignBranchOwnershipMutationPayload>,
  ) => null | string;
  AssignTemporaryManagerMutationPayload?: (
    data: WithTypename<IAssignTemporaryManagerMutationPayload>,
  ) => null | string;
  AttendanceInvoice?: (data: WithTypename<IAttendanceInvoice>) => null | string;
  AttendeeCharacter?: (data: WithTypename<IAttendeeCharacter>) => null | string;
  AttendeeShift?: (data: WithTypename<IAttendeeShift>) => null | string;
  Branch?: (data: WithTypename<IBranch>) => null | string;
  BranchOwner?: (data: WithTypename<IBranchOwner>) => null | string;
  Cap?: (data: WithTypename<ICap>) => null | string;
  Character?: (data: WithTypename<ICharacter>) => null | string;
  CharacterConfig?: (data: WithTypename<ICharacterConfig>) => null | string;
  CharacterFellowship?: (
    data: WithTypename<ICharacterFellowship>,
  ) => null | string;
  CharacterSkill?: (data: WithTypename<ICharacterSkill>) => null | string;
  CheckinCharacterMutationPayload?: (
    data: WithTypename<ICheckinCharacterMutationPayload>,
  ) => null | string;
  CheckinMutationPayload?: (
    data: WithTypename<ICheckinMutationPayload>,
  ) => null | string;
  CopyCharacterBuildMutationPayload?: (
    data: WithTypename<ICopyCharacterBuildMutationPayload>,
  ) => null | string;
  CorrectiveAction?: (data: WithTypename<ICorrectiveAction>) => null | string;
  CraftableItem?: (data: WithTypename<ICraftableItem>) => null | string;
  CraftingComponent?: (data: WithTypename<ICraftingComponent>) => null | string;
  CraftingFinalProduct?: (
    data: WithTypename<ICraftingFinalProduct>,
  ) => null | string;
  CreateCapMutationPayload?: (
    data: WithTypename<ICreateCapMutationPayload>,
  ) => null | string;
  CreateCharacterFellowshipMutationPayload?: (
    data: WithTypename<ICreateCharacterFellowshipMutationPayload>,
  ) => null | string;
  CreateCharacterMutationPayload?: (
    data: WithTypename<ICreateCharacterMutationPayload>,
  ) => null | string;
  CreateCorrectiveActionMutationPayload?: (
    data: WithTypename<ICreateCorrectiveActionMutationPayload>,
  ) => null | string;
  CreateEventLifecycleMutationPayload?: (
    data: WithTypename<ICreateEventLifecycleMutationPayload>,
  ) => null | string;
  CreateEventMutationPayload?: (
    data: WithTypename<ICreateEventMutationPayload>,
  ) => null | string;
  CreateEventShiftMutationPayload?: (
    data: WithTypename<ICreateEventShiftMutationPayload>,
  ) => null | string;
  CreateEventTicketMutationPayload?: (
    data: WithTypename<ICreateEventTicketMutationPayload>,
  ) => null | string;
  CreateFellowshipMutationPayload?: (
    data: WithTypename<ICreateFellowshipMutationPayload>,
  ) => null | string;
  CreateItemClassificationMutationPayload?: (
    data: WithTypename<ICreateItemClassificationMutationPayload>,
  ) => null | string;
  CreateItemCraftingComponentMutationPayload?: (
    data: WithTypename<ICreateItemCraftingComponentMutationPayload>,
  ) => null | string;
  CreateItemCraftingFinalProductMutationPayload?: (
    data: WithTypename<ICreateItemCraftingFinalProductMutationPayload>,
  ) => null | string;
  CreateItemCraftingMutationPayload?: (
    data: WithTypename<ICreateItemCraftingMutationPayload>,
  ) => null | string;
  CreateItemMutationPayload?: (
    data: WithTypename<ICreateItemMutationPayload>,
  ) => null | string;
  CreateItemReproductionComponentMutationPayload?: (
    data: WithTypename<ICreateItemReproductionComponentMutationPayload>,
  ) => null | string;
  CreateItemReproductionMutationPayload?: (
    data: WithTypename<ICreateItemReproductionMutationPayload>,
  ) => null | string;
  CreateItemTransactionMutationPayload?: (
    data: WithTypename<ICreateItemTransactionMutationPayload>,
  ) => null | string;
  CreateLocationMutationPayload?: (
    data: WithTypename<ICreateLocationMutationPayload>,
  ) => null | string;
  CreateMembershipMutationPayload?: (
    data: WithTypename<ICreateMembershipMutationPayload>,
  ) => null | string;
  CreateModMutationPayload?: (
    data: WithTypename<ICreateModMutationPayload>,
  ) => null | string;
  CreateModSectionMutationPayload?: (
    data: WithTypename<ICreateModSectionMutationPayload>,
  ) => null | string;
  CreateNewPlayerMutationPayload?: (
    data: WithTypename<ICreateNewPlayerMutationPayload>,
  ) => null | string;
  CreateReferralMutationPayload?: (
    data: WithTypename<ICreateReferralMutationPayload>,
  ) => null | string;
  CreateSpecialDateMutationPayload?: (
    data: WithTypename<ICreateSpecialDateMutationPayload>,
  ) => null | string;
  DestroyAttendeeCharacterMutationPayload?: (
    data: WithTypename<IDestroyAttendeeCharacterMutationPayload>,
  ) => null | string;
  DestroyAuthTokenMutationPayload?: (
    data: WithTypename<IDestroyAuthTokenMutationPayload>,
  ) => null | string;
  DestroyCharacterMutationPayload?: (
    data: WithTypename<IDestroyCharacterMutationPayload>,
  ) => null | string;
  DestroyCorrectiveActionMutationPayload?: (
    data: WithTypename<IDestroyCorrectiveActionMutationPayload>,
  ) => null | string;
  DestroyEventAttendeeMutationPayload?: (
    data: WithTypename<IDestroyEventAttendeeMutationPayload>,
  ) => null | string;
  DestroyEventShiftMutationPayload?: (
    data: WithTypename<IDestroyEventShiftMutationPayload>,
  ) => null | string;
  DestroyEventTicketMutationPayload?: (
    data: WithTypename<IDestroyEventTicketMutationPayload>,
  ) => null | string;
  DestroyItemClassificationMutationPayload?: (
    data: WithTypename<IDestroyItemClassificationMutationPayload>,
  ) => null | string;
  DestroyItemCraftingComponentMutationPayload?: (
    data: WithTypename<IDestroyItemCraftingComponentMutationPayload>,
  ) => null | string;
  DestroyItemCraftingFinalProductMutationPayload?: (
    data: WithTypename<IDestroyItemCraftingFinalProductMutationPayload>,
  ) => null | string;
  DestroyItemCraftingMutationPayload?: (
    data: WithTypename<IDestroyItemCraftingMutationPayload>,
  ) => null | string;
  DestroyItemReproductionComponentMutationPayload?: (
    data: WithTypename<IDestroyItemReproductionComponentMutationPayload>,
  ) => null | string;
  DestroyItemReproductionMutationPayload?: (
    data: WithTypename<IDestroyItemReproductionMutationPayload>,
  ) => null | string;
  DestroyLocationMutationPayload?: (
    data: WithTypename<IDestroyLocationMutationPayload>,
  ) => null | string;
  DestroyMembershipMutationPayload?: (
    data: WithTypename<IDestroyMembershipMutationPayload>,
  ) => null | string;
  DestroyReferralMutationPayload?: (
    data: WithTypename<IDestroyReferralMutationPayload>,
  ) => null | string;
  DestroySpecialDateMutationPayload?: (
    data: WithTypename<IDestroySpecialDateMutationPayload>,
  ) => null | string;
  Error?: (data: WithTypename<IError>) => null | string;
  Event?: (data: WithTypename<IEvent>) => null | string;
  EventAttendee?: (data: WithTypename<IEventAttendee>) => null | string;
  EventConfig?: (data: WithTypename<IEventConfig>) => null | string;
  EventInvoice?: (data: WithTypename<IEventInvoice>) => null | string;
  EventRevenue?: (data: WithTypename<IEventRevenue>) => null | string;
  Faith?: (data: WithTypename<IFaith>) => null | string;
  Fellowship?: (data: WithTypename<IFellowship>) => null | string;
  Fracture?: (data: WithTypename<IFracture>) => null | string;
  FulfillShiftMutationPayload?: (
    data: WithTypename<IFulfillShiftMutationPayload>,
  ) => null | string;
  GenerateAuthTokenMutationPayload?: (
    data: WithTypename<IGenerateAuthTokenMutationPayload>,
  ) => null | string;
  Inventory?: (data: WithTypename<IInventory>) => null | string;
  Invoice?: (data: WithTypename<IInvoice>) => null | string;
  InvoiceChange?: (data: WithTypename<IInvoiceChange>) => null | string;
  Item?: (data: WithTypename<IItem>) => null | string;
  ItemClassification?: (
    data: WithTypename<IItemClassification>,
  ) => null | string;
  ItemCrafting?: (data: WithTypename<IItemCrafting>) => null | string;
  ItemMetadata?: (data: WithTypename<IItemMetadata>) => null | string;
  ItemReproduction?: (data: WithTypename<IItemReproduction>) => null | string;
  ItemReproductionComponent?: (
    data: WithTypename<IItemReproductionComponent>,
  ) => null | string;
  ItemReproductionMetadata?: (
    data: WithTypename<IItemReproductionMetadata>,
  ) => null | string;
  ItemTransaction?: (data: WithTypename<IItemTransaction>) => null | string;
  Lifecycle?: (data: WithTypename<ILifecycle>) => null | string;
  Lineage?: (data: WithTypename<ILineage>) => null | string;
  Location?: (data: WithTypename<ILocation>) => null | string;
  LogBlueprintActionMutationPayload?: (
    data: WithTypename<ILogBlueprintActionMutationPayload>,
  ) => null | string;
  Lore?: (data: WithTypename<ILore>) => null | string;
  Membership?: (data: WithTypename<IMembership>) => null | string;
  Mod?: (data: WithTypename<IMod>) => null | string;
  ModSection?: (data: WithTypename<IModSection>) => null | string;
  Organization?: (data: WithTypename<IOrganization>) => null | string;
  OrganizationConfig?: (
    data: WithTypename<IOrganizationConfig>,
  ) => null | string;
  Permission?: (data: WithTypename<IPermission>) => null | string;
  RegisteredItem?: (data: WithTypename<IRegisteredItem>) => null | string;
  RequestPasswordResetMutationPayload?: (
    data: WithTypename<IRequestPasswordResetMutationPayload>,
  ) => null | string;
  ResetPasswordMutationPayload?: (
    data: WithTypename<IResetPasswordMutationPayload>,
  ) => null | string;
  ResidenciesStatistics?: (
    data: WithTypename<IResidenciesStatistics>,
  ) => null | string;
  ResidencyStatistics?: (
    data: WithTypename<IResidencyStatistics>,
  ) => null | string;
  RetireCharacterMutationPayload?: (
    data: WithTypename<IRetireCharacterMutationPayload>,
  ) => null | string;
  RetirementPaths?: (data: WithTypename<IRetirementPaths>) => null | string;
  RetirementXp?: (data: WithTypename<IRetirementXp>) => null | string;
  RevenueAggregates?: (data: WithTypename<IRevenueAggregates>) => null | string;
  Shift?: (data: WithTypename<IShift>) => null | string;
  Skill?: (data: WithTypename<ISkill>) => null | string;
  SkillMechanic?: (data: WithTypename<ISkillMechanic>) => null | string;
  SkillStatistics?: (data: WithTypename<ISkillStatistics>) => null | string;
  SkillsStatistics?: (data: WithTypename<ISkillsStatistics>) => null | string;
  SpecialDate?: (data: WithTypename<ISpecialDate>) => null | string;
  StatMechanic?: (data: WithTypename<IStatMechanic>) => null | string;
  StatStatistics?: (data: WithTypename<IStatStatistics>) => null | string;
  StatsStatistics?: (data: WithTypename<IStatsStatistics>) => null | string;
  Strain?: (data: WithTypename<IStrain>) => null | string;
  TemporaryManager?: (data: WithTypename<ITemporaryManager>) => null | string;
  TemporaryToken?: (data: WithTypename<ITemporaryToken>) => null | string;
  Ticket?: (data: WithTypename<ITicket>) => null | string;
  TicketInvoice?: (data: WithTypename<ITicketInvoice>) => null | string;
  TicketRevenue?: (data: WithTypename<ITicketRevenue>) => null | string;
  TicketsBaseConfig?: (data: WithTypename<ITicketsBaseConfig>) => null | string;
  Timeline?: (data: WithTypename<ITimeline>) => null | string;
  UpdateBranchMutationPayload?: (
    data: WithTypename<IUpdateBranchMutationPayload>,
  ) => null | string;
  UpdateCapMutationPayload?: (
    data: WithTypename<IUpdateCapMutationPayload>,
  ) => null | string;
  UpdateCharacterDataMutationPayload?: (
    data: WithTypename<IUpdateCharacterDataMutationPayload>,
  ) => null | string;
  UpdateCharacterFellowshipMutationPayload?: (
    data: WithTypename<IUpdateCharacterFellowshipMutationPayload>,
  ) => null | string;
  UpdateCorrectiveActionMutationPayload?: (
    data: WithTypename<IUpdateCorrectiveActionMutationPayload>,
  ) => null | string;
  UpdateEventAttendeeMutationPayload?: (
    data: WithTypename<IUpdateEventAttendeeMutationPayload>,
  ) => null | string;
  UpdateEventLifecycleMutationPayload?: (
    data: WithTypename<IUpdateEventLifecycleMutationPayload>,
  ) => null | string;
  UpdateEventMutationPayload?: (
    data: WithTypename<IUpdateEventMutationPayload>,
  ) => null | string;
  UpdateEventShiftMutationPayload?: (
    data: WithTypename<IUpdateEventShiftMutationPayload>,
  ) => null | string;
  UpdateEventTicketMutationPayload?: (
    data: WithTypename<IUpdateEventTicketMutationPayload>,
  ) => null | string;
  UpdateFellowshipMutationPayload?: (
    data: WithTypename<IUpdateFellowshipMutationPayload>,
  ) => null | string;
  UpdateItemCraftingComponentMutationPayload?: (
    data: WithTypename<IUpdateItemCraftingComponentMutationPayload>,
  ) => null | string;
  UpdateItemCraftingFinalProductMutationPayload?: (
    data: WithTypename<IUpdateItemCraftingFinalProductMutationPayload>,
  ) => null | string;
  UpdateItemCraftingMutationPayload?: (
    data: WithTypename<IUpdateItemCraftingMutationPayload>,
  ) => null | string;
  UpdateItemMutationPayload?: (
    data: WithTypename<IUpdateItemMutationPayload>,
  ) => null | string;
  UpdateItemReproductionComponentMutationPayload?: (
    data: WithTypename<IUpdateItemReproductionComponentMutationPayload>,
  ) => null | string;
  UpdateItemReproductionMutationPayload?: (
    data: WithTypename<IUpdateItemReproductionMutationPayload>,
  ) => null | string;
  UpdateItemTransactionMutationPayload?: (
    data: WithTypename<IUpdateItemTransactionMutationPayload>,
  ) => null | string;
  UpdateLocationMutationPayload?: (
    data: WithTypename<IUpdateLocationMutationPayload>,
  ) => null | string;
  UpdateMembershipMutationPayload?: (
    data: WithTypename<IUpdateMembershipMutationPayload>,
  ) => null | string;
  UpdateModMutationPayload?: (
    data: WithTypename<IUpdateModMutationPayload>,
  ) => null | string;
  UpdateModSectionMutationPayload?: (
    data: WithTypename<IUpdateModSectionMutationPayload>,
  ) => null | string;
  UpdateOrganizationMutationPayload?: (
    data: WithTypename<IUpdateOrganizationMutationPayload>,
  ) => null | string;
  UpdatePasswordMutationPayload?: (
    data: WithTypename<IUpdatePasswordMutationPayload>,
  ) => null | string;
  UpdatePlayerBranchOwnershipMutationPayload?: (
    data: WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>,
  ) => null | string;
  UpdatePlayerDataMutationPayload?: (
    data: WithTypename<IUpdatePlayerDataMutationPayload>,
  ) => null | string;
  UpdatePlayerOrganizationDataMutationPayload?: (
    data: WithTypename<IUpdatePlayerOrganizationDataMutationPayload>,
  ) => null | string;
  UpdateSpecialDateMutationPayload?: (
    data: WithTypename<IUpdateSpecialDateMutationPayload>,
  ) => null | string;
  User?: (data: WithTypename<IUser>) => null | string;
  UserInvoice?: (data: WithTypename<IUserInvoice>) => null | string;
  UserOrganization?: (data: WithTypename<IUserOrganization>) => null | string;
  UserReferral?: (data: WithTypename<IUserReferral>) => null | string;
  XpAudit?: (data: WithTypename<IXpAudit>) => null | string;
};

export type GraphCacheResolvers = {
  Query?: {
    branch?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryBranchArgs,
      WithTypename<IBranch> | string
    >;
    event?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryEventArgs,
      WithTypename<IEvent> | string
    >;
    events?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryEventsArgs,
      Array<WithTypename<IEvent> | string>
    >;
    locations?: GraphCacheResolver<
      WithTypename<IQuery>,
      Record<string, never>,
      Array<WithTypename<ILocation> | string>
    >;
    memberships?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryMembershipsArgs,
      Array<WithTypename<IMembership> | string>
    >;
    organization?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryOrganizationArgs,
      WithTypename<IOrganization> | string
    >;
    organizationRoles?: GraphCacheResolver<
      WithTypename<IQuery>,
      Record<string, never>,
      Array<IOrganizationRoleEnum | string>
    >;
    organizations?: GraphCacheResolver<
      WithTypename<IQuery>,
      Record<string, never>,
      Array<WithTypename<IOrganization> | string>
    >;
    permission?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryPermissionArgs,
      WithTypename<IPermission> | string
    >;
    resetPasswordTokenStatus?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryResetPasswordTokenStatusArgs,
      Scalars['Boolean'] | string
    >;
    searchPlayer?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQuerySearchPlayerArgs,
      Array<WithTypename<ISearchPlayerResult> | string>
    >;
    temporaryTokenStatus?: GraphCacheResolver<
      WithTypename<IQuery>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    tokenId?: GraphCacheResolver<
      WithTypename<IQuery>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IQuery>,
      IQueryUserArgs,
      WithTypename<IUser> | string
    >;
  };
  AssignBranchLocationMutationPayload?: {
    branch?: GraphCacheResolver<
      WithTypename<IAssignBranchLocationMutationPayload>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IAssignBranchLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IAssignBranchLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AssignBranchOwnershipMutationPayload?: {
    branch?: GraphCacheResolver<
      WithTypename<IAssignBranchOwnershipMutationPayload>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IAssignBranchOwnershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IAssignBranchOwnershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  AssignTemporaryManagerMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IAssignTemporaryManagerMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IAssignTemporaryManagerMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<IAssignTemporaryManagerMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  AttendanceInvoice?: {
    attending?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    baseCost?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    buildGrowth?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    change?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      WithTypename<IInvoiceChange> | string
    >;
    extraXpCost?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      IEventAttendeeKindEnum | string
    >;
    paid?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    ticket?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      WithTypename<ITicketInvoice> | string
    >;
    totalCost?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IAttendanceInvoice>,
      Record<string, never>,
      WithTypename<IUserInvoice> | string
    >;
  };
  AttendeeCharacter?: {
    attendance?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      IAttendeeCharacterAttendanceEnum | string
    >;
    buildGrowth?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    cap?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      WithTypename<ICap> | string
    >;
    character?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    payment?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      IAttendeeCharacterPaymentEnum | string
    >;
    printRequestStatus?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      IAttendeeCharacterPrintRequestStatusEnum | string
    >;
    ticket?: GraphCacheResolver<
      WithTypename<IAttendeeCharacter>,
      Record<string, never>,
      WithTypename<ITicket> | string
    >;
  };
  AttendeeShift?: {
    id?: GraphCacheResolver<
      WithTypename<IAttendeeShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    player?: GraphCacheResolver<
      WithTypename<IAttendeeShift>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    shift?: GraphCacheResolver<
      WithTypename<IAttendeeShift>,
      Record<string, never>,
      WithTypename<IShift> | string
    >;
    status?: GraphCacheResolver<
      WithTypename<IAttendeeShift>,
      Record<string, never>,
      IAttendeeShiftStatusEnum | string
    >;
  };
  Branch?: {
    activePlayers?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<IUserOrganization> | string>
    >;
    branchAssistants?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<IUserOrganization> | string>
    >;
    branchOwners?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<IBranchOwner> | string>
    >;
    caps?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<ICap> | string>
    >;
    correctiveActions?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<ICorrectiveAction> | string>
    >;
    district?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    invoice?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      WithTypename<IInvoice> | string
    >;
    location?: GraphCacheResolver<
      WithTypename<IBranch>,
      IBranchLocationArgs,
      WithTypename<ILocation> | string
    >;
    locations?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Array<WithTypename<ILocation> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
    region?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    registrationUrl?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    shorthand?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    website?: GraphCacheResolver<
      WithTypename<IBranch>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  BranchOwner?: {
    id?: GraphCacheResolver<
      WithTypename<IBranchOwner>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IBranchOwner>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  Cap?: {
    amount?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    branch?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    grantor?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    reason?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    state?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      ICapStateEnum | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICap>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  Character?: {
    alive?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    approvedAt?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    body?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    buildEarned?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    buildUsed?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    characterFellowships?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<WithTypename<ICharacterFellowship> | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    currentLife?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    experimentalCharacter?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    faithId?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fractures?: GraphCacheResolver<
      WithTypename<ICharacter>,
      ICharacterFracturesArgs,
      Array<WithTypename<IFracture> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    infection?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    inventories?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<WithTypename<IInventory> | string>
    >;
    latestLifecycleGainsThisYear?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<Scalars['ISO8601DateTime'] | string>
    >;
    legacyHomeGameCount?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lifeCapacity?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lifeGain?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lifeLoss?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lifecycles?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<WithTypename<ILifecycle> | string>
    >;
    lineageId?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    loreIds?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<Scalars['Int'] | string>
    >;
    maxLores?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    mind?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
    playerNotes?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    resolve?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    retiredCharacter?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    retirementPaths?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      WithTypename<IRetirementPaths> | string
    >;
    skills?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<WithTypename<ICharacterSkill> | string>
    >;
    staffNotes?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      ICharacterStatusEnum | string
    >;
    strainId?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    totalHomeGameCount?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    variant?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    version?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    xpAudits?: GraphCacheResolver<
      WithTypename<ICharacter>,
      Record<string, never>,
      Array<WithTypename<IXpAudit> | string>
    >;
  };
  CharacterConfig?: {
    configurableSkills?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<ISkillMechanic> | string>
    >;
    configurableStats?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<IStatMechanic> | string>
    >;
    faiths?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<IFaith> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    lineages?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<ILineage> | string>
    >;
    lores?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<ILore> | string>
    >;
    skills?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<ISkill> | string>
    >;
    strains?: GraphCacheResolver<
      WithTypename<ICharacterConfig>,
      Record<string, never>,
      Array<WithTypename<IStrain> | string>
    >;
  };
  CharacterFellowship?: {
    character?: GraphCacheResolver<
      WithTypename<ICharacterFellowship>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    fellowship?: GraphCacheResolver<
      WithTypename<ICharacterFellowship>,
      Record<string, never>,
      WithTypename<IFellowship> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICharacterFellowship>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    rank?: GraphCacheResolver<
      WithTypename<ICharacterFellowship>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    removedAt?: GraphCacheResolver<
      WithTypename<ICharacterFellowship>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
  };
  CharacterSkill?: {
    characterSkillId?: GraphCacheResolver<
      WithTypename<ICharacterSkill>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICharacterSkill>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    level?: GraphCacheResolver<
      WithTypename<ICharacterSkill>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    positions?: GraphCacheResolver<
      WithTypename<ICharacterSkill>,
      Record<string, never>,
      Array<Scalars['Int'] | string>
    >;
  };
  CheckinCharacterMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICheckinCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICheckinCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<ICheckinCharacterMutationPayload>,
      Record<string, never>,
      WithTypename<IEventAttendee> | string
    >;
  };
  CheckinMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICheckinMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICheckinMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<ICheckinMutationPayload>,
      Record<string, never>,
      WithTypename<IEventAttendee> | string
    >;
  };
  CopyCharacterBuildMutationPayload?: {
    character?: GraphCacheResolver<
      WithTypename<ICopyCharacterBuildMutationPayload>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICopyCharacterBuildMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICopyCharacterBuildMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CorrectiveAction?: {
    branch?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    endsAt?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    issuer?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      ICorrectiveActionKindEnum | string
    >;
    reason?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    startsAt?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICorrectiveAction>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CraftableItem?: {
    id?: GraphCacheResolver<
      WithTypename<ICraftableItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<ICraftableItem>,
      Record<string, never>,
      IItemKindEnum | string
    >;
    metadata?: GraphCacheResolver<
      WithTypename<ICraftableItem>,
      Record<string, never>,
      WithTypename<IItemMetadata> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ICraftableItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CraftingComponent?: {
    amount?: GraphCacheResolver<
      WithTypename<ICraftingComponent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    component?: GraphCacheResolver<
      WithTypename<ICraftingComponent>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICraftingComponent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    itemCrafting?: GraphCacheResolver<
      WithTypename<ICraftingComponent>,
      Record<string, never>,
      WithTypename<IItemCrafting> | string
    >;
  };
  CraftingFinalProduct?: {
    finalProduct?: GraphCacheResolver<
      WithTypename<ICraftingFinalProduct>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ICraftingFinalProduct>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    stack?: GraphCacheResolver<
      WithTypename<ICraftingFinalProduct>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  CreateCapMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateCapMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateCapMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateCapMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateCharacterFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    fellowship?: GraphCacheResolver<
      WithTypename<ICreateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      WithTypename<IFellowship> | string
    >;
  };
  CreateCharacterMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateCharacterMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateCorrectiveActionMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateEventLifecycleMutationPayload?: {
    character?: GraphCacheResolver<
      WithTypename<ICreateEventLifecycleMutationPayload>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateEventLifecycleMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateEventLifecycleMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CreateEventMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateEventMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateEventMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<ICreateEventMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  CreateEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<ICreateEventShiftMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  CreateEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<ICreateEventTicketMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  CreateFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<ICreateFellowshipMutationPayload>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
  };
  CreateItemClassificationMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemClassificationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemClassificationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<ICreateItemClassificationMutationPayload>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  CreateItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemCrafting?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      WithTypename<IItemCrafting> | string
    >;
  };
  CreateItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemCrafting?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      WithTypename<IItemCrafting> | string
    >;
  };
  CreateItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<ICreateItemCraftingMutationPayload>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  CreateItemMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<ICreateItemMutationPayload>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  CreateItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemReproduction?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      WithTypename<IItemReproduction> | string
    >;
  };
  CreateItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<ICreateItemReproductionMutationPayload>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  CreateItemTransactionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateItemTransactionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateItemTransactionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemTransactions?: GraphCacheResolver<
      WithTypename<ICreateItemTransactionMutationPayload>,
      Record<string, never>,
      Array<WithTypename<IItemTransaction> | string>
    >;
  };
  CreateLocationMutationPayload?: {
    branch?: GraphCacheResolver<
      WithTypename<ICreateLocationMutationPayload>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  CreateMembershipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateMembershipMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateModMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateModMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateModMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    modSection?: GraphCacheResolver<
      WithTypename<ICreateModMutationPayload>,
      Record<string, never>,
      WithTypename<IModSection> | string
    >;
  };
  CreateModSectionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateModSectionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateModSectionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<ICreateModSectionMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  CreateNewPlayerMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateNewPlayerMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateNewPlayerMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateNewPlayerMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateReferralMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateReferralMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateReferralMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ICreateReferralMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  CreateSpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ICreateSpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ICreateSpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<ICreateSpecialDateMutationPayload>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
  };
  DestroyAttendeeCharacterMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyAttendeeCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyAttendeeCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<IDestroyAttendeeCharacterMutationPayload>,
      Record<string, never>,
      WithTypename<IEventAttendee> | string
    >;
  };
  DestroyAuthTokenMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyAuthTokenMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyAuthTokenMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DestroyCharacterMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IDestroyCharacterMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  DestroyCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    correctiveAction?: GraphCacheResolver<
      WithTypename<IDestroyCorrectiveActionMutationPayload>,
      Record<string, never>,
      WithTypename<ICorrectiveAction> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DestroyEventAttendeeMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyEventAttendeeMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyEventAttendeeMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<IDestroyEventAttendeeMutationPayload>,
      Record<string, never>,
      WithTypename<IEventAttendee> | string
    >;
  };
  DestroyEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<IDestroyEventShiftMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  DestroyEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ticket?: GraphCacheResolver<
      WithTypename<IDestroyEventTicketMutationPayload>,
      Record<string, never>,
      WithTypename<ITicket> | string
    >;
  };
  DestroyItemClassificationMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemClassificationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemClassificationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemClassification?: GraphCacheResolver<
      WithTypename<IDestroyItemClassificationMutationPayload>,
      Record<string, never>,
      WithTypename<IItemClassification> | string
    >;
  };
  DestroyItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftingComponent?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingComponentMutationPayload>,
      Record<string, never>,
      WithTypename<ICraftingComponent> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DestroyItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftingFinalProduct?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      WithTypename<ICraftingFinalProduct> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  DestroyItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemCrafting?: GraphCacheResolver<
      WithTypename<IDestroyItemCraftingMutationPayload>,
      Record<string, never>,
      WithTypename<IItemCrafting> | string
    >;
  };
  DestroyItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemReproductionComponent?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionComponentMutationPayload>,
      Record<string, never>,
      WithTypename<IItemReproductionComponent> | string
    >;
  };
  DestroyItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemReproduction?: GraphCacheResolver<
      WithTypename<IDestroyItemReproductionMutationPayload>,
      Record<string, never>,
      WithTypename<IItemReproduction> | string
    >;
  };
  DestroyLocationMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    location?: GraphCacheResolver<
      WithTypename<IDestroyLocationMutationPayload>,
      Record<string, never>,
      WithTypename<ILocation> | string
    >;
  };
  DestroyMembershipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    membership?: GraphCacheResolver<
      WithTypename<IDestroyMembershipMutationPayload>,
      Record<string, never>,
      WithTypename<IMembership> | string
    >;
  };
  DestroyReferralMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroyReferralMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroyReferralMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IDestroyReferralMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  DestroySpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IDestroySpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IDestroySpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    specialDate?: GraphCacheResolver<
      WithTypename<IDestroySpecialDateMutationPayload>,
      Record<string, never>,
      WithTypename<ISpecialDate> | string
    >;
  };
  Error?: {
    error?: GraphCacheResolver<
      WithTypename<IError>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Event?: {
    activeCorrectiveActions?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventActiveCorrectiveActionsArgs,
      Array<WithTypename<ICorrectiveAction> | string>
    >;
    activeMembership?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventActiveMembershipArgs,
      Scalars['Boolean'] | string
    >;
    branch?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    canSelfCheckin?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventCanSelfCheckinArgs,
      Scalars['Boolean'] | string
    >;
    checkinableCharacters?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventCheckinableCharactersArgs,
      Array<WithTypename<ICharacter> | string>
    >;
    config?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<IEventConfig> | string
    >;
    description?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    endsAt?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventEventAttendeeArgs,
      WithTypename<IEventAttendee> | string
    >;
    eventAttendees?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventEventAttendeesArgs,
      Array<WithTypename<IEventAttendee> | string>
    >;
    eventAttendeesSize?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    isEventAssistant?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isEventManager?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isEventRegistrar?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isHomeGame?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventIsHomeGameArgs,
      Scalars['Boolean'] | string
    >;
    isVirtual?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    itemTransactions?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Array<WithTypename<IItemTransaction> | string>
    >;
    kind?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      IEventKindEnum | string
    >;
    location?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<ILocation> | string
    >;
    maximumCheckinableCharacters?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventMaximumCheckinableCharactersArgs,
      Scalars['Int'] | string
    >;
    modDescription?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    modMaxSignup?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    modSections?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Array<WithTypename<IModSection> | string>
    >;
    modSignupOpensAt?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    nextEvent?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
    prevEvent?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
    printouts?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventPrintoutsArgs,
      Array<WithTypename<IEventAttendee> | string>
    >;
    registrationOpensAt?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    revenue?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<IEventRevenue> | string
    >;
    shiftKinds?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Array<IShiftKindEnum | string>
    >;
    shifts?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventShiftsArgs,
      Array<WithTypename<IShift> | string>
    >;
    startsAt?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    temporaryManagers?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Array<WithTypename<ITemporaryManager> | string>
    >;
    ticketStatistics?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      Array<WithTypename<ITicket> | string>
    >;
    tickets?: GraphCacheResolver<
      WithTypename<IEvent>,
      IEventTicketsArgs,
      Array<WithTypename<ITicket> | string>
    >;
    ticketsBaseConfig?: GraphCacheResolver<
      WithTypename<IEvent>,
      Record<string, never>,
      WithTypename<ITicketsBaseConfig> | string
    >;
  };
  EventAttendee?: {
    attendeeCharacters?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Array<WithTypename<IAttendeeCharacter> | string>
    >;
    attendeeShifts?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Array<WithTypename<IAttendeeShift> | string>
    >;
    attending?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    buildAwarded?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    buildGrowth?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    checkedInCharactersCount?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    checkinApproved?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    inviter?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      IEventAttendeeKindEnum | string
    >;
    paid?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    referralInfo?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    shifts?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Array<WithTypename<IShift> | string>
    >;
    ticket?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      WithTypename<ITicket> | string
    >;
    ticketId?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IEventAttendee>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  EventConfig?: {
    baseBuild?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    considersTravelersLocal?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    extraXpCost?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    maxCurveXp?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    maxExtraXp?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    maxExtraXpLimit?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    maxShift?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    shiftKinds?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Array<IShiftKindEnum | string>
    >;
    shiftMultiplier?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    shiftUnit?: GraphCacheResolver<
      WithTypename<IEventConfig>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  EventInvoice?: {
    attendances?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Array<WithTypename<IAttendanceInvoice> | string>
    >;
    baseCompensationTicketCount?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    billableAmount?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    endsAt?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    extraXpSold?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fullCompensationTicketCount?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    isCurrentCycle?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isLegacy?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isVirtual?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    startsAt?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    ticketsSold?: GraphCacheResolver<
      WithTypename<IEventInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  EventRevenue?: {
    aggregates?: GraphCacheResolver<
      WithTypename<IEventRevenue>,
      Record<string, never>,
      WithTypename<IRevenueAggregates> | string
    >;
    itemizedLineItems?: GraphCacheResolver<
      WithTypename<IEventRevenue>,
      Record<string, never>,
      Array<WithTypename<ITicketRevenue> | string>
    >;
  };
  Faith?: {
    id?: GraphCacheResolver<
      WithTypename<IFaith>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IFaith>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Fellowship?: {
    characterFellowships?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Array<WithTypename<ICharacterFellowship> | string>
    >;
    characters?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Array<WithTypename<ICharacter> | string>
    >;
    charactersCount?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      IFellowshipKindEnum | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
    removedAt?: GraphCacheResolver<
      WithTypename<IFellowship>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
  };
  Fracture?: {
    character?: GraphCacheResolver<
      WithTypename<IFracture>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<IFracture>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IFracture>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IFracture>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    removedAt?: GraphCacheResolver<
      WithTypename<IFracture>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
  };
  FulfillShiftMutationPayload?: {
    attendeeShift?: GraphCacheResolver<
      WithTypename<IFulfillShiftMutationPayload>,
      Record<string, never>,
      WithTypename<IAttendeeShift> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IFulfillShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IFulfillShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  GenerateAuthTokenMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IGenerateAuthTokenMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IGenerateAuthTokenMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    token?: GraphCacheResolver<
      WithTypename<IGenerateAuthTokenMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IGenerateAuthTokenMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  Inventory?: {
    expiresAt?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    itemId?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    registeredItem?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      WithTypename<IRegisteredItem> | string
    >;
    registeredItemId?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    stack?: GraphCacheResolver<
      WithTypename<IInventory>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Invoice?: {
    additionalXpBuybackCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    additionalXpCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    billableAmount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    events?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Array<WithTypename<IEventInvoice> | string>
    >;
    liveEventTicketBuybackCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    liveEventTicketCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    virtualEventTicketBuybackCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    virtualEventTicketCount?: GraphCacheResolver<
      WithTypename<IInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  InvoiceChange?: {
    attending?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    buildGrowth?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    gameKind?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      IInvoiceChangeKindEnum | string
    >;
    paid?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ticketId?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ticketLabel?: GraphCacheResolver<
      WithTypename<IInvoiceChange>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Item?: {
    blueprintForCraftings?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Array<WithTypename<IItem> | string>
    >;
    childItemClassifications?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Array<WithTypename<IItemClassification> | string>
    >;
    grade?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      IItemGradeEnum | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    itemCraftings?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Array<WithTypename<IItemCrafting> | string>
    >;
    itemReproductions?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Array<WithTypename<IItemReproduction> | string>
    >;
    kind?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      IItemKindEnum | string
    >;
    lifetimeAmount?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lifetimeUnit?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      IItemLifetimeUnitEnum | string
    >;
    metadata?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      WithTypename<IItemMetadata> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    parts?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Array<WithTypename<ICraftingComponent> | string>
    >;
    updatedAt?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    validFrom?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    validUntil?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    version?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    visibility?: GraphCacheResolver<
      WithTypename<IItem>,
      Record<string, never>,
      IItemVisibilityEnum | string
    >;
  };
  ItemClassification?: {
    childItem?: GraphCacheResolver<
      WithTypename<IItemClassification>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IItemClassification>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ItemCrafting?: {
    blueprint?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    craftingComponents?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Array<WithTypename<ICraftingComponent> | string>
    >;
    craftingFinalProducts?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Array<WithTypename<ICraftingFinalProduct> | string>
    >;
    craftingMindCost?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    craftingSkills?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftingTimeInMinute?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    craftingZone?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IItemCrafting>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ItemMetadata?: {
    activationRequirement?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftableItems?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Array<WithTypename<ICraftableItem> | string>
    >;
    descriptionOfRoleplay?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    durationOfEffect?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    durationOfRoleplay?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    equipmentRequiredForUse?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    locationOfUse?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    mechanics?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    notes?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    printHeader?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    requirementsToUse?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    uses?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    validTargetDescription?: GraphCacheResolver<
      WithTypename<IItemMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ItemReproduction?: {
    id?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    itemReproductionComponents?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      Array<WithTypename<IItemReproductionComponent> | string>
    >;
    metadata?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      WithTypename<IItemReproductionMetadata> | string
    >;
    reproductionMindCost?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    reproductionSkills?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    reproductionTimeInMinute?: GraphCacheResolver<
      WithTypename<IItemReproduction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  ItemReproductionComponent?: {
    amount?: GraphCacheResolver<
      WithTypename<IItemReproductionComponent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IItemReproductionComponent>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<IItemReproductionComponent>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  ItemReproductionMetadata?: {
    notes?: GraphCacheResolver<
      WithTypename<IItemReproductionMetadata>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ItemTransaction?: {
    character?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    childTransactions?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Array<WithTypename<IItemTransaction> | string>
    >;
    createdAt?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expiresAt?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
    postie?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    registeredItem?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      WithTypename<IRegisteredItem> | string
    >;
    source?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    stack?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    status?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      IItemTransactionStatusEnum | string
    >;
    transactionPartner?: GraphCacheResolver<
      WithTypename<IItemTransaction>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
  };
  Lifecycle?: {
    createdAt?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    deletedAt?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<ILifecycle>,
      Record<string, never>,
      ILifecycleKindEnum | string
    >;
  };
  Lineage?: {
    body?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    infection?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    mind?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    resolve?: GraphCacheResolver<
      WithTypename<ILineage>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Location?: {
    city?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    events?: GraphCacheResolver<
      WithTypename<ILocation>,
      ILocationEventsArgs,
      Array<WithTypename<IEvent> | string>
    >;
    existingEventsSize?: GraphCacheResolver<
      WithTypename<ILocation>,
      ILocationExistingEventsSizeArgs,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    region?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      IRegionEnum | string
    >;
    streetAddress?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    zipCode?: GraphCacheResolver<
      WithTypename<ILocation>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  LogBlueprintActionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<ILogBlueprintActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<ILogBlueprintActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    success?: GraphCacheResolver<
      WithTypename<ILogBlueprintActionMutationPayload>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  Lore?: {
    id?: GraphCacheResolver<
      WithTypename<ILore>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ILore>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Membership?: {
    createdAt?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    endsAt?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    grantingBranch?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    grantor?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
    startsAt?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IMembership>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  Mod?: {
    constraint?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['JSON'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    modSection?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      WithTypename<IModSection> | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    participationLimit?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    timeframe?: GraphCacheResolver<
      WithTypename<IMod>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ModSection?: {
    id?: GraphCacheResolver<
      WithTypename<IModSection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    mods?: GraphCacheResolver<
      WithTypename<IModSection>,
      Record<string, never>,
      Array<WithTypename<IMod> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<IModSection>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    signupLimit?: GraphCacheResolver<
      WithTypename<IModSection>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Organization?: {
    armorUpgradeComponents?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<WithTypename<ICraftingComponent> | string>
    >;
    branch?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    branches?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationBranchesArgs,
      Array<WithTypename<IBranch> | string>
    >;
    characterConfig?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      WithTypename<ICharacterConfig> | string
    >;
    configs?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      WithTypename<IOrganizationConfig> | string
    >;
    fellowship?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationFellowshipArgs,
      WithTypename<IFellowship> | string
    >;
    fellowships?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationFellowshipsArgs,
      Array<WithTypename<IFellowship> | string>
    >;
    id?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationItemArgs,
      WithTypename<IItem> | string
    >;
    itemGrades?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<IItemGradeEnum | string>
    >;
    itemKinds?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<IItemKindEnum | string>
    >;
    items?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationItemsArgs,
      Array<WithTypename<IItem> | string>
    >;
    lastEventAttendanceSnapshotOn?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    lastEventDiffOn?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    memberships?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationMembershipsArgs,
      Array<WithTypename<IMembership> | string>
    >;
    name?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    rebirthedCharacters?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<WithTypename<ICharacter> | string>
    >;
    role?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      IOrganizationRoleEnum | string
    >;
    shorthand?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    slug?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    specialDates?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<WithTypename<ISpecialDate> | string>
    >;
    statistics?: GraphCacheResolver<
      WithTypename<IOrganization>,
      IOrganizationStatisticsArgs,
      WithTypename<IStatisticsResult> | string
    >;
    timelines?: GraphCacheResolver<
      WithTypename<IOrganization>,
      Record<string, never>,
      Array<WithTypename<ITimeline> | string>
    >;
  };
  OrganizationConfig?: {
    charactersActiveCountMax?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    charactersCountMax?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    charactersCountStaffMax?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    createFellowshipsOnDemand?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    fellowshipAlias?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    fellowshipsEventTracking?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    fellowshipsMaxRank?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fellowshipsMinRank?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IOrganizationConfig>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Permission?: {
    entity?: GraphCacheResolver<
      WithTypename<IPermission>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    permitted?: GraphCacheResolver<
      WithTypename<IPermission>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  RegisteredItem?: {
    description?: GraphCacheResolver<
      WithTypename<IRegisteredItem>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IRegisteredItem>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  RequestPasswordResetMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IRequestPasswordResetMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IRequestPasswordResetMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  ResetPasswordMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IResetPasswordMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IResetPasswordMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    token?: GraphCacheResolver<
      WithTypename<IResetPasswordMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IResetPasswordMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  ResidenciesStatistics?: {
    eventId?: GraphCacheResolver<
      WithTypename<IResidenciesStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<IResidenciesStatistics>,
      Record<string, never>,
      IStatisticsTypeEnum | string
    >;
    values?: GraphCacheResolver<
      WithTypename<IResidenciesStatistics>,
      Record<string, never>,
      Array<WithTypename<IResidencyStatistics> | string>
    >;
  };
  ResidencyStatistics?: {
    count?: GraphCacheResolver<
      WithTypename<IResidencyStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IResidencyStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IResidencyStatistics>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    shorthand?: GraphCacheResolver<
      WithTypename<IResidencyStatistics>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  RetireCharacterMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IRetireCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IRetireCharacterMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IRetireCharacterMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  RetirementPaths?: {
    alive?: GraphCacheResolver<
      WithTypename<IRetirementPaths>,
      Record<string, never>,
      Array<WithTypename<IRetirementXp> | string>
    >;
    dead?: GraphCacheResolver<
      WithTypename<IRetirementPaths>,
      Record<string, never>,
      Array<WithTypename<IRetirementXp> | string>
    >;
  };
  RetirementXp?: {
    amount?: GraphCacheResolver<
      WithTypename<IRetirementXp>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    earnedOn?: GraphCacheResolver<
      WithTypename<IRetirementXp>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
  };
  RevenueAggregates?: {
    attendeesPaid?: GraphCacheResolver<
      WithTypename<IRevenueAggregates>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    attendeesTotal?: GraphCacheResolver<
      WithTypename<IRevenueAggregates>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    revenue?: GraphCacheResolver<
      WithTypename<IRevenueAggregates>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    revenueDiscounted?: GraphCacheResolver<
      WithTypename<IRevenueAggregates>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    revenueLiable?: GraphCacheResolver<
      WithTypename<IRevenueAggregates>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
  };
  Shift?: {
    adjustableExpansionStrategy?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    attendeeShifts?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Array<WithTypename<IAttendeeShift> | string>
    >;
    autoExpand?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    baseRatio?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    eventId?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    identifier?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    isEnabled?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    isFullyBooked?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    limit?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    playersCount?: GraphCacheResolver<
      WithTypename<IShift>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  Skill?: {
    category?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    dep?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    description?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    innate?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    requiresResolve?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    singleTier?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    specialization?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    tier?: GraphCacheResolver<
      WithTypename<ISkill>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  SkillMechanic?: {
    id?: GraphCacheResolver<
      WithTypename<ISkillMechanic>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<ISkillMechanic>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    value?: GraphCacheResolver<
      WithTypename<ISkillMechanic>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  SkillStatistics?: {
    count?: GraphCacheResolver<
      WithTypename<ISkillStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ISkillStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    level?: GraphCacheResolver<
      WithTypename<ISkillStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  SkillsStatistics?: {
    eventId?: GraphCacheResolver<
      WithTypename<ISkillsStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<ISkillsStatistics>,
      Record<string, never>,
      IStatisticsTypeEnum | string
    >;
    values?: GraphCacheResolver<
      WithTypename<ISkillsStatistics>,
      Record<string, never>,
      Array<WithTypename<ISkillStatistics> | string>
    >;
  };
  SpecialDate?: {
    endsAt?: GraphCacheResolver<
      WithTypename<ISpecialDate>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ISpecialDate>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<ISpecialDate>,
      Record<string, never>,
      ISpecialDateKindEnum | string
    >;
    startsAt?: GraphCacheResolver<
      WithTypename<ISpecialDate>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    title?: GraphCacheResolver<
      WithTypename<ISpecialDate>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  StatMechanic?: {
    id?: GraphCacheResolver<
      WithTypename<IStatMechanic>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    max?: GraphCacheResolver<
      WithTypename<IStatMechanic>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    min?: GraphCacheResolver<
      WithTypename<IStatMechanic>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IStatMechanic>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  StatStatistics?: {
    body?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    buildEarned?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    buildUsed?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    currentLife?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    faithId?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    mind?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
    resolve?: GraphCacheResolver<
      WithTypename<IStatStatistics>,
      Record<string, never>,
      Array<Array<Scalars['Int'] | string>>
    >;
  };
  StatsStatistics?: {
    eventId?: GraphCacheResolver<
      WithTypename<IStatsStatistics>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    type?: GraphCacheResolver<
      WithTypename<IStatsStatistics>,
      Record<string, never>,
      IStatisticsTypeEnum | string
    >;
    values?: GraphCacheResolver<
      WithTypename<IStatsStatistics>,
      Record<string, never>,
      Array<WithTypename<IStatStatistics> | string>
    >;
  };
  Strain?: {
    id?: GraphCacheResolver<
      WithTypename<IStrain>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    lineageId?: GraphCacheResolver<
      WithTypename<IStrain>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IStrain>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TemporaryManager?: {
    event?: GraphCacheResolver<
      WithTypename<ITemporaryManager>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITemporaryManager>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<ITemporaryManager>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  TemporaryToken?: {
    browser?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    expiresAt?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    hardware?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    ipAddress?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    os?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    token?: GraphCacheResolver<
      WithTypename<ITemporaryToken>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  Ticket?: {
    cost?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    discountType?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      ITicketDiscountEnum | string
    >;
    eventAttendeesCount?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    eventId?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    fixedLimit?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    fullyBooked?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    identifier?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    limit?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    passType?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      ITicketPassEnum | string
    >;
    restrictionType?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      ITicketRestrictionEnum | string
    >;
    shiftResponsibilities?: GraphCacheResolver<
      WithTypename<ITicket>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
  TicketInvoice?: {
    discountType?: GraphCacheResolver<
      WithTypename<ITicketInvoice>,
      Record<string, never>,
      ITicketDiscountEnum | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITicketInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<ITicketInvoice>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  TicketRevenue?: {
    attendeesPaid?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    attendeesTotal?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    cost?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    discountType?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    label?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    revenue?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    revenueDiscounted?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    revenueLiable?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Scalars['Float'] | string
    >;
    subitems?: GraphCacheResolver<
      WithTypename<ITicketRevenue>,
      Record<string, never>,
      Array<WithTypename<ITicketRevenue> | string>
    >;
  };
  TicketsBaseConfig?: {
    discountTypes?: GraphCacheResolver<
      WithTypename<ITicketsBaseConfig>,
      Record<string, never>,
      Array<ITicketDiscountEnum | string>
    >;
    passTypes?: GraphCacheResolver<
      WithTypename<ITicketsBaseConfig>,
      Record<string, never>,
      Array<ITicketPassEnum | string>
    >;
    restrictionTypes?: GraphCacheResolver<
      WithTypename<ITicketsBaseConfig>,
      Record<string, never>,
      Array<ITicketRestrictionEnum | string>
    >;
  };
  Timeline?: {
    attendance?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    eventId?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    eventName?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    local?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
    shorthand?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    timecode?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    virtual?: GraphCacheResolver<
      WithTypename<ITimeline>,
      Record<string, never>,
      Scalars['Boolean'] | string
    >;
  };
  UpdateBranchMutationPayload?: {
    branch?: GraphCacheResolver<
      WithTypename<IUpdateBranchMutationPayload>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateBranchMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateBranchMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateCapMutationPayload?: {
    cap?: GraphCacheResolver<
      WithTypename<IUpdateCapMutationPayload>,
      Record<string, never>,
      WithTypename<ICap> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateCapMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateCapMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateCharacterDataMutationPayload?: {
    character?: GraphCacheResolver<
      WithTypename<IUpdateCharacterDataMutationPayload>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateCharacterDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateCharacterDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateCharacterFellowshipMutationPayload?: {
    characterFellowship?: GraphCacheResolver<
      WithTypename<IUpdateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      WithTypename<ICharacterFellowship> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateCharacterFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    correctiveAction?: GraphCacheResolver<
      WithTypename<IUpdateCorrectiveActionMutationPayload>,
      Record<string, never>,
      WithTypename<ICorrectiveAction> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateCorrectiveActionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateEventAttendeeMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateEventAttendeeMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateEventAttendeeMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendee?: GraphCacheResolver<
      WithTypename<IUpdateEventAttendeeMutationPayload>,
      Record<string, never>,
      WithTypename<IEventAttendee> | string
    >;
  };
  UpdateEventLifecycleMutationPayload?: {
    character?: GraphCacheResolver<
      WithTypename<IUpdateEventLifecycleMutationPayload>,
      Record<string, never>,
      WithTypename<ICharacter> | string
    >;
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateEventLifecycleMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateEventLifecycleMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateEventMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateEventMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateEventMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    event?: GraphCacheResolver<
      WithTypename<IUpdateEventMutationPayload>,
      Record<string, never>,
      WithTypename<IEvent> | string
    >;
  };
  UpdateEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateEventShiftMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    shift?: GraphCacheResolver<
      WithTypename<IUpdateEventShiftMutationPayload>,
      Record<string, never>,
      WithTypename<IShift> | string
    >;
  };
  UpdateEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateEventTicketMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    ticket?: GraphCacheResolver<
      WithTypename<IUpdateEventTicketMutationPayload>,
      Record<string, never>,
      WithTypename<ITicket> | string
    >;
  };
  UpdateFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateFellowshipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    fellowship?: GraphCacheResolver<
      WithTypename<IUpdateFellowshipMutationPayload>,
      Record<string, never>,
      WithTypename<IFellowship> | string
    >;
  };
  UpdateItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftingComponent?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      WithTypename<ICraftingComponent> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    craftingFinalProduct?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      WithTypename<ICraftingFinalProduct> | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingFinalProductMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdateItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemCrafting?: GraphCacheResolver<
      WithTypename<IUpdateItemCraftingMutationPayload>,
      Record<string, never>,
      WithTypename<IItemCrafting> | string
    >;
  };
  UpdateItemMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    item?: GraphCacheResolver<
      WithTypename<IUpdateItemMutationPayload>,
      Record<string, never>,
      WithTypename<IItem> | string
    >;
  };
  UpdateItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemReproductionComponent?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionComponentMutationPayload>,
      Record<string, never>,
      WithTypename<IItemReproductionComponent> | string
    >;
  };
  UpdateItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemReproduction?: GraphCacheResolver<
      WithTypename<IUpdateItemReproductionMutationPayload>,
      Record<string, never>,
      WithTypename<IItemReproduction> | string
    >;
  };
  UpdateItemTransactionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateItemTransactionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateItemTransactionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    itemTransaction?: GraphCacheResolver<
      WithTypename<IUpdateItemTransactionMutationPayload>,
      Record<string, never>,
      WithTypename<IItemTransaction> | string
    >;
  };
  UpdateLocationMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateLocationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    location?: GraphCacheResolver<
      WithTypename<IUpdateLocationMutationPayload>,
      Record<string, never>,
      WithTypename<ILocation> | string
    >;
  };
  UpdateMembershipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateMembershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    membership?: GraphCacheResolver<
      WithTypename<IUpdateMembershipMutationPayload>,
      Record<string, never>,
      WithTypename<IMembership> | string
    >;
  };
  UpdateModMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateModMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateModMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    mod?: GraphCacheResolver<
      WithTypename<IUpdateModMutationPayload>,
      Record<string, never>,
      WithTypename<IMod> | string
    >;
  };
  UpdateModSectionMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateModSectionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateModSectionMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    modSection?: GraphCacheResolver<
      WithTypename<IUpdateModSectionMutationPayload>,
      Record<string, never>,
      WithTypename<IModSection> | string
    >;
  };
  UpdateOrganizationMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateOrganizationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateOrganizationMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organization?: GraphCacheResolver<
      WithTypename<IUpdateOrganizationMutationPayload>,
      Record<string, never>,
      WithTypename<IOrganization> | string
    >;
  };
  UpdatePasswordMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdatePasswordMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdatePasswordMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UpdatePlayerBranchOwnershipMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  UpdatePlayerDataMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdatePlayerDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdatePlayerDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IUpdatePlayerDataMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  UpdatePlayerOrganizationDataMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdatePlayerOrganizationDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdatePlayerOrganizationDataMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IUpdatePlayerOrganizationDataMutationPayload>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  UpdateSpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheResolver<
      WithTypename<IUpdateSpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    error?: GraphCacheResolver<
      WithTypename<IUpdateSpecialDateMutationPayload>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    specialDate?: GraphCacheResolver<
      WithTypename<IUpdateSpecialDateMutationPayload>,
      Record<string, never>,
      WithTypename<ISpecialDate> | string
    >;
  };
  User?: {
    assistantFlags?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserAssistantFlagsArgs,
      Array<IAssistantFlagEnum | string>
    >;
    caps?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserCapsArgs,
      Array<WithTypename<ICap> | string>
    >;
    character?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserCharacterArgs,
      WithTypename<ICharacter> | string
    >;
    characters?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserCharactersArgs,
      Array<WithTypename<ICharacter> | string>
    >;
    correctiveActions?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<ICorrectiveAction> | string>
    >;
    emailAddress?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventAttendances?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserEventAttendancesArgs,
      Array<WithTypename<IEventAttendee> | string>
    >;
    firstName?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    fullName?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    invitations?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserInvitationsArgs,
      Array<WithTypename<IUserReferral> | string>
    >;
    lastMembership?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserLastMembershipArgs,
      WithTypename<IMembership> | string
    >;
    lastName?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    maxActiveCharactersCount?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    maxCharactersCount?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    membershipActiveDuringEvent?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserMembershipActiveDuringEventArgs,
      Scalars['Boolean'] | string
    >;
    memberships?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserMembershipsArgs,
      Array<WithTypename<IMembership> | string>
    >;
    notes?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    organizations?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<IOrganization> | string>
    >;
    ownedBranches?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<IBranch> | string>
    >;
    preferredName?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    recentEventAttendances?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<IEventAttendee> | string>
    >;
    referrals?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserReferralsArgs,
      Array<WithTypename<IUserReferral> | string>
    >;
    roleInOrganization?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserRoleInOrganizationArgs,
      IOrganizationRoleEnum | string
    >;
    temporaryTokens?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<ITemporaryToken> | string>
    >;
    totalCapsForEvent?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserTotalCapsForEventArgs,
      Scalars['Int'] | string
    >;
    upcomingEvents?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserUpcomingEventsArgs,
      Array<WithTypename<IEventAttendee> | string>
    >;
    userOrganization?: GraphCacheResolver<
      WithTypename<IUser>,
      IUserUserOrganizationArgs,
      WithTypename<IUserOrganization> | string
    >;
    userOrganizations?: GraphCacheResolver<
      WithTypename<IUser>,
      Record<string, never>,
      Array<WithTypename<IUserOrganization> | string>
    >;
  };
  UserInvoice?: {
    id?: GraphCacheResolver<
      WithTypename<IUserInvoice>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    name?: GraphCacheResolver<
      WithTypename<IUserInvoice>,
      Record<string, never>,
      Scalars['String'] | string
    >;
  };
  UserOrganization?: {
    assistantFlags?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      Array<IAssistantFlagEnum | string>
    >;
    branch?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      WithTypename<IBranch> | string
    >;
    branchId?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    organizationId?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    role?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      IOrganizationRoleEnum | string
    >;
    user?: GraphCacheResolver<
      WithTypename<IUserOrganization>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  UserReferral?: {
    createdAt?: GraphCacheResolver<
      WithTypename<IUserReferral>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    id?: GraphCacheResolver<
      WithTypename<IUserReferral>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    inviter?: GraphCacheResolver<
      WithTypename<IUserReferral>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
    newbie?: GraphCacheResolver<
      WithTypename<IUserReferral>,
      Record<string, never>,
      WithTypename<IUser> | string
    >;
  };
  XpAudit?: {
    applicableOn?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['ISO8601Date'] | string
    >;
    awardedAt?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['ISO8601DateTime'] | string
    >;
    branchTransferId?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    destinationBranch?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    eventId?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    eventName?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    extraBuildId?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    homeGameEventsIndex?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    kind?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    originBranch?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['String'] | string
    >;
    xpAddOn?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
    xpAwarded?: GraphCacheResolver<
      WithTypename<IXpAudit>,
      Record<string, never>,
      Scalars['Int'] | string
    >;
  };
};

export type GraphCacheOptimisticUpdaters = {
  assignBranchLocation?: GraphCacheOptimisticMutationResolver<
    IMutationAssignBranchLocationArgs,
    Maybe<WithTypename<IAssignBranchLocationMutationPayload>>
  >;
  assignBranchOwnership?: GraphCacheOptimisticMutationResolver<
    IMutationAssignBranchOwnershipArgs,
    Maybe<WithTypename<IAssignBranchOwnershipMutationPayload>>
  >;
  assignTemporaryManager?: GraphCacheOptimisticMutationResolver<
    IMutationAssignTemporaryManagerArgs,
    Maybe<WithTypename<IAssignTemporaryManagerMutationPayload>>
  >;
  checkin?: GraphCacheOptimisticMutationResolver<
    IMutationCheckinArgs,
    Maybe<WithTypename<ICheckinMutationPayload>>
  >;
  checkinCharacter?: GraphCacheOptimisticMutationResolver<
    IMutationCheckinCharacterArgs,
    Maybe<WithTypename<ICheckinCharacterMutationPayload>>
  >;
  copyCharacterBuild?: GraphCacheOptimisticMutationResolver<
    IMutationCopyCharacterBuildArgs,
    Maybe<WithTypename<ICopyCharacterBuildMutationPayload>>
  >;
  createCap?: GraphCacheOptimisticMutationResolver<
    IMutationCreateCapArgs,
    Maybe<WithTypename<ICreateCapMutationPayload>>
  >;
  createCharacter?: GraphCacheOptimisticMutationResolver<
    IMutationCreateCharacterArgs,
    Maybe<WithTypename<ICreateCharacterMutationPayload>>
  >;
  createCharacterFellowship?: GraphCacheOptimisticMutationResolver<
    IMutationCreateCharacterFellowshipArgs,
    Maybe<WithTypename<ICreateCharacterFellowshipMutationPayload>>
  >;
  createCorrectiveAction?: GraphCacheOptimisticMutationResolver<
    IMutationCreateCorrectiveActionArgs,
    Maybe<WithTypename<ICreateCorrectiveActionMutationPayload>>
  >;
  createEvent?: GraphCacheOptimisticMutationResolver<
    IMutationCreateEventArgs,
    Maybe<WithTypename<ICreateEventMutationPayload>>
  >;
  createEventLifecycle?: GraphCacheOptimisticMutationResolver<
    IMutationCreateEventLifecycleArgs,
    Maybe<WithTypename<ICreateEventLifecycleMutationPayload>>
  >;
  createEventShift?: GraphCacheOptimisticMutationResolver<
    IMutationCreateEventShiftArgs,
    Maybe<WithTypename<ICreateEventShiftMutationPayload>>
  >;
  createEventTicket?: GraphCacheOptimisticMutationResolver<
    IMutationCreateEventTicketArgs,
    Maybe<WithTypename<ICreateEventTicketMutationPayload>>
  >;
  createFellowship?: GraphCacheOptimisticMutationResolver<
    IMutationCreateFellowshipArgs,
    Maybe<WithTypename<ICreateFellowshipMutationPayload>>
  >;
  createItem?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemArgs,
    Maybe<WithTypename<ICreateItemMutationPayload>>
  >;
  createItemClassification?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemClassificationArgs,
    Maybe<WithTypename<ICreateItemClassificationMutationPayload>>
  >;
  createItemCrafting?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemCraftingArgs,
    Maybe<WithTypename<ICreateItemCraftingMutationPayload>>
  >;
  createItemCraftingComponent?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemCraftingComponentArgs,
    Maybe<WithTypename<ICreateItemCraftingComponentMutationPayload>>
  >;
  createItemCraftingFinalProduct?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemCraftingFinalProductArgs,
    Maybe<WithTypename<ICreateItemCraftingFinalProductMutationPayload>>
  >;
  createItemReproduction?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemReproductionArgs,
    Maybe<WithTypename<ICreateItemReproductionMutationPayload>>
  >;
  createItemReproductionComponent?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemReproductionComponentArgs,
    Maybe<WithTypename<ICreateItemReproductionComponentMutationPayload>>
  >;
  createItemTransaction?: GraphCacheOptimisticMutationResolver<
    IMutationCreateItemTransactionArgs,
    Maybe<WithTypename<ICreateItemTransactionMutationPayload>>
  >;
  createLocation?: GraphCacheOptimisticMutationResolver<
    IMutationCreateLocationArgs,
    Maybe<WithTypename<ICreateLocationMutationPayload>>
  >;
  createMembership?: GraphCacheOptimisticMutationResolver<
    IMutationCreateMembershipArgs,
    Maybe<WithTypename<ICreateMembershipMutationPayload>>
  >;
  createMod?: GraphCacheOptimisticMutationResolver<
    IMutationCreateModArgs,
    Maybe<WithTypename<ICreateModMutationPayload>>
  >;
  createModSection?: GraphCacheOptimisticMutationResolver<
    IMutationCreateModSectionArgs,
    Maybe<WithTypename<ICreateModSectionMutationPayload>>
  >;
  createNewPlayer?: GraphCacheOptimisticMutationResolver<
    IMutationCreateNewPlayerArgs,
    Maybe<WithTypename<ICreateNewPlayerMutationPayload>>
  >;
  createReferral?: GraphCacheOptimisticMutationResolver<
    IMutationCreateReferralArgs,
    Maybe<WithTypename<ICreateReferralMutationPayload>>
  >;
  createSpecialDate?: GraphCacheOptimisticMutationResolver<
    IMutationCreateSpecialDateArgs,
    Maybe<WithTypename<ICreateSpecialDateMutationPayload>>
  >;
  destroyAttendeeCharacter?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyAttendeeCharacterArgs,
    Maybe<WithTypename<IDestroyAttendeeCharacterMutationPayload>>
  >;
  destroyAuthToken?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyAuthTokenArgs,
    Maybe<WithTypename<IDestroyAuthTokenMutationPayload>>
  >;
  destroyCharacter?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyCharacterArgs,
    Maybe<WithTypename<IDestroyCharacterMutationPayload>>
  >;
  destroyCorrectiveAction?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyCorrectiveActionArgs,
    Maybe<WithTypename<IDestroyCorrectiveActionMutationPayload>>
  >;
  destroyEventAttendee?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyEventAttendeeArgs,
    Maybe<WithTypename<IDestroyEventAttendeeMutationPayload>>
  >;
  destroyEventShift?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyEventShiftArgs,
    Maybe<WithTypename<IDestroyEventShiftMutationPayload>>
  >;
  destroyEventTicket?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyEventTicketArgs,
    Maybe<WithTypename<IDestroyEventTicketMutationPayload>>
  >;
  destroyItemClassification?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemClassificationArgs,
    Maybe<WithTypename<IDestroyItemClassificationMutationPayload>>
  >;
  destroyItemCrafting?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemCraftingArgs,
    Maybe<WithTypename<IDestroyItemCraftingMutationPayload>>
  >;
  destroyItemCraftingComponent?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemCraftingComponentArgs,
    Maybe<WithTypename<IDestroyItemCraftingComponentMutationPayload>>
  >;
  destroyItemCraftingFinalProduct?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemCraftingFinalProductArgs,
    Maybe<WithTypename<IDestroyItemCraftingFinalProductMutationPayload>>
  >;
  destroyItemReproduction?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemReproductionArgs,
    Maybe<WithTypename<IDestroyItemReproductionMutationPayload>>
  >;
  destroyItemReproductionComponent?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyItemReproductionComponentArgs,
    Maybe<WithTypename<IDestroyItemReproductionComponentMutationPayload>>
  >;
  destroyLocation?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyLocationArgs,
    Maybe<WithTypename<IDestroyLocationMutationPayload>>
  >;
  destroyMembership?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyMembershipArgs,
    Maybe<WithTypename<IDestroyMembershipMutationPayload>>
  >;
  destroyReferral?: GraphCacheOptimisticMutationResolver<
    IMutationDestroyReferralArgs,
    Maybe<WithTypename<IDestroyReferralMutationPayload>>
  >;
  destroySpecialDate?: GraphCacheOptimisticMutationResolver<
    IMutationDestroySpecialDateArgs,
    Maybe<WithTypename<IDestroySpecialDateMutationPayload>>
  >;
  fulfillShift?: GraphCacheOptimisticMutationResolver<
    IMutationFulfillShiftArgs,
    Maybe<WithTypename<IFulfillShiftMutationPayload>>
  >;
  generateAuthToken?: GraphCacheOptimisticMutationResolver<
    IMutationGenerateAuthTokenArgs,
    Maybe<WithTypename<IGenerateAuthTokenMutationPayload>>
  >;
  logBlueprintAction?: GraphCacheOptimisticMutationResolver<
    IMutationLogBlueprintActionArgs,
    Maybe<WithTypename<ILogBlueprintActionMutationPayload>>
  >;
  requestPasswordReset?: GraphCacheOptimisticMutationResolver<
    IMutationRequestPasswordResetArgs,
    Maybe<WithTypename<IRequestPasswordResetMutationPayload>>
  >;
  resetPassword?: GraphCacheOptimisticMutationResolver<
    IMutationResetPasswordArgs,
    Maybe<WithTypename<IResetPasswordMutationPayload>>
  >;
  retireCharacter?: GraphCacheOptimisticMutationResolver<
    IMutationRetireCharacterArgs,
    Maybe<WithTypename<IRetireCharacterMutationPayload>>
  >;
  updateBranch?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateBranchArgs,
    Maybe<WithTypename<IUpdateBranchMutationPayload>>
  >;
  updateCap?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateCapArgs,
    Maybe<WithTypename<IUpdateCapMutationPayload>>
  >;
  updateCharacterData?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateCharacterDataArgs,
    Maybe<WithTypename<IUpdateCharacterDataMutationPayload>>
  >;
  updateCharacterFellowship?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateCharacterFellowshipArgs,
    Maybe<WithTypename<IUpdateCharacterFellowshipMutationPayload>>
  >;
  updateCorrectiveAction?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateCorrectiveActionArgs,
    Maybe<WithTypename<IUpdateCorrectiveActionMutationPayload>>
  >;
  updateEvent?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateEventArgs,
    Maybe<WithTypename<IUpdateEventMutationPayload>>
  >;
  updateEventAttendee?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateEventAttendeeArgs,
    Maybe<WithTypename<IUpdateEventAttendeeMutationPayload>>
  >;
  updateEventLifecycle?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateEventLifecycleArgs,
    Maybe<WithTypename<IUpdateEventLifecycleMutationPayload>>
  >;
  updateEventShift?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateEventShiftArgs,
    Maybe<WithTypename<IUpdateEventShiftMutationPayload>>
  >;
  updateEventTicket?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateEventTicketArgs,
    Maybe<WithTypename<IUpdateEventTicketMutationPayload>>
  >;
  updateFellowship?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateFellowshipArgs,
    Maybe<WithTypename<IUpdateFellowshipMutationPayload>>
  >;
  updateItem?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemArgs,
    Maybe<WithTypename<IUpdateItemMutationPayload>>
  >;
  updateItemCrafting?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemCraftingArgs,
    Maybe<WithTypename<IUpdateItemCraftingMutationPayload>>
  >;
  updateItemCraftingComponent?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemCraftingComponentArgs,
    Maybe<WithTypename<IUpdateItemCraftingComponentMutationPayload>>
  >;
  updateItemCraftingFinalProduct?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemCraftingFinalProductArgs,
    Maybe<WithTypename<IUpdateItemCraftingFinalProductMutationPayload>>
  >;
  updateItemReproduction?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemReproductionArgs,
    Maybe<WithTypename<IUpdateItemReproductionMutationPayload>>
  >;
  updateItemReproductionComponent?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemReproductionComponentArgs,
    Maybe<WithTypename<IUpdateItemReproductionComponentMutationPayload>>
  >;
  updateItemTransaction?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateItemTransactionArgs,
    Maybe<WithTypename<IUpdateItemTransactionMutationPayload>>
  >;
  updateLocation?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateLocationArgs,
    Maybe<WithTypename<IUpdateLocationMutationPayload>>
  >;
  updateMembership?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateMembershipArgs,
    Maybe<WithTypename<IUpdateMembershipMutationPayload>>
  >;
  updateMod?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateModArgs,
    Maybe<WithTypename<IUpdateModMutationPayload>>
  >;
  updateModSection?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateModSectionArgs,
    Maybe<WithTypename<IUpdateModSectionMutationPayload>>
  >;
  updateOrganization?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateOrganizationArgs,
    Maybe<WithTypename<IUpdateOrganizationMutationPayload>>
  >;
  updatePassword?: GraphCacheOptimisticMutationResolver<
    IMutationUpdatePasswordArgs,
    Maybe<WithTypename<IUpdatePasswordMutationPayload>>
  >;
  updatePlayerBranchOwnership?: GraphCacheOptimisticMutationResolver<
    IMutationUpdatePlayerBranchOwnershipArgs,
    Maybe<WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>>
  >;
  updatePlayerData?: GraphCacheOptimisticMutationResolver<
    IMutationUpdatePlayerDataArgs,
    Maybe<WithTypename<IUpdatePlayerDataMutationPayload>>
  >;
  updatePlayerOrganizationData?: GraphCacheOptimisticMutationResolver<
    IMutationUpdatePlayerOrganizationDataArgs,
    Maybe<WithTypename<IUpdatePlayerOrganizationDataMutationPayload>>
  >;
  updateSpecialDate?: GraphCacheOptimisticMutationResolver<
    IMutationUpdateSpecialDateArgs,
    Maybe<WithTypename<IUpdateSpecialDateMutationPayload>>
  >;
};

export type GraphCacheUpdaters = {
  Query?: {
    branch?: GraphCacheUpdateResolver<
      { branch: Maybe<WithTypename<IBranch>> },
      IQueryBranchArgs
    >;
    event?: GraphCacheUpdateResolver<
      { event: Maybe<WithTypename<IEvent>> },
      IQueryEventArgs
    >;
    events?: GraphCacheUpdateResolver<
      { events: Array<WithTypename<IEvent>> },
      IQueryEventsArgs
    >;
    locations?: GraphCacheUpdateResolver<
      { locations: Array<WithTypename<ILocation>> },
      Record<string, never>
    >;
    memberships?: GraphCacheUpdateResolver<
      { memberships: Array<WithTypename<IMembership>> },
      IQueryMembershipsArgs
    >;
    organization?: GraphCacheUpdateResolver<
      { organization: WithTypename<IOrganization> },
      IQueryOrganizationArgs
    >;
    organizationRoles?: GraphCacheUpdateResolver<
      { organizationRoles: Array<IOrganizationRoleEnum> },
      Record<string, never>
    >;
    organizations?: GraphCacheUpdateResolver<
      { organizations: Array<WithTypename<IOrganization>> },
      Record<string, never>
    >;
    permission?: GraphCacheUpdateResolver<
      { permission: WithTypename<IPermission> },
      IQueryPermissionArgs
    >;
    resetPasswordTokenStatus?: GraphCacheUpdateResolver<
      { resetPasswordTokenStatus: Scalars['Boolean'] },
      IQueryResetPasswordTokenStatusArgs
    >;
    searchPlayer?: GraphCacheUpdateResolver<
      { searchPlayer: Array<WithTypename<ISearchPlayerResult>> },
      IQuerySearchPlayerArgs
    >;
    temporaryTokenStatus?: GraphCacheUpdateResolver<
      { temporaryTokenStatus: Scalars['Boolean'] },
      Record<string, never>
    >;
    tokenId?: GraphCacheUpdateResolver<
      { tokenId: Maybe<Scalars['Int']> },
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      { user: Maybe<WithTypename<IUser>> },
      IQueryUserArgs
    >;
  };
  Mutation?: {
    assignBranchLocation?: GraphCacheUpdateResolver<
      {
        assignBranchLocation: Maybe<
          WithTypename<IAssignBranchLocationMutationPayload>
        >;
      },
      IMutationAssignBranchLocationArgs
    >;
    assignBranchOwnership?: GraphCacheUpdateResolver<
      {
        assignBranchOwnership: Maybe<
          WithTypename<IAssignBranchOwnershipMutationPayload>
        >;
      },
      IMutationAssignBranchOwnershipArgs
    >;
    assignTemporaryManager?: GraphCacheUpdateResolver<
      {
        assignTemporaryManager: Maybe<
          WithTypename<IAssignTemporaryManagerMutationPayload>
        >;
      },
      IMutationAssignTemporaryManagerArgs
    >;
    checkin?: GraphCacheUpdateResolver<
      { checkin: Maybe<WithTypename<ICheckinMutationPayload>> },
      IMutationCheckinArgs
    >;
    checkinCharacter?: GraphCacheUpdateResolver<
      {
        checkinCharacter: Maybe<WithTypename<ICheckinCharacterMutationPayload>>;
      },
      IMutationCheckinCharacterArgs
    >;
    copyCharacterBuild?: GraphCacheUpdateResolver<
      {
        copyCharacterBuild: Maybe<
          WithTypename<ICopyCharacterBuildMutationPayload>
        >;
      },
      IMutationCopyCharacterBuildArgs
    >;
    createCap?: GraphCacheUpdateResolver<
      { createCap: Maybe<WithTypename<ICreateCapMutationPayload>> },
      IMutationCreateCapArgs
    >;
    createCharacter?: GraphCacheUpdateResolver<
      { createCharacter: Maybe<WithTypename<ICreateCharacterMutationPayload>> },
      IMutationCreateCharacterArgs
    >;
    createCharacterFellowship?: GraphCacheUpdateResolver<
      {
        createCharacterFellowship: Maybe<
          WithTypename<ICreateCharacterFellowshipMutationPayload>
        >;
      },
      IMutationCreateCharacterFellowshipArgs
    >;
    createCorrectiveAction?: GraphCacheUpdateResolver<
      {
        createCorrectiveAction: Maybe<
          WithTypename<ICreateCorrectiveActionMutationPayload>
        >;
      },
      IMutationCreateCorrectiveActionArgs
    >;
    createEvent?: GraphCacheUpdateResolver<
      { createEvent: Maybe<WithTypename<ICreateEventMutationPayload>> },
      IMutationCreateEventArgs
    >;
    createEventLifecycle?: GraphCacheUpdateResolver<
      {
        createEventLifecycle: Maybe<
          WithTypename<ICreateEventLifecycleMutationPayload>
        >;
      },
      IMutationCreateEventLifecycleArgs
    >;
    createEventShift?: GraphCacheUpdateResolver<
      {
        createEventShift: Maybe<WithTypename<ICreateEventShiftMutationPayload>>;
      },
      IMutationCreateEventShiftArgs
    >;
    createEventTicket?: GraphCacheUpdateResolver<
      {
        createEventTicket: Maybe<
          WithTypename<ICreateEventTicketMutationPayload>
        >;
      },
      IMutationCreateEventTicketArgs
    >;
    createFellowship?: GraphCacheUpdateResolver<
      {
        createFellowship: Maybe<WithTypename<ICreateFellowshipMutationPayload>>;
      },
      IMutationCreateFellowshipArgs
    >;
    createItem?: GraphCacheUpdateResolver<
      { createItem: Maybe<WithTypename<ICreateItemMutationPayload>> },
      IMutationCreateItemArgs
    >;
    createItemClassification?: GraphCacheUpdateResolver<
      {
        createItemClassification: Maybe<
          WithTypename<ICreateItemClassificationMutationPayload>
        >;
      },
      IMutationCreateItemClassificationArgs
    >;
    createItemCrafting?: GraphCacheUpdateResolver<
      {
        createItemCrafting: Maybe<
          WithTypename<ICreateItemCraftingMutationPayload>
        >;
      },
      IMutationCreateItemCraftingArgs
    >;
    createItemCraftingComponent?: GraphCacheUpdateResolver<
      {
        createItemCraftingComponent: Maybe<
          WithTypename<ICreateItemCraftingComponentMutationPayload>
        >;
      },
      IMutationCreateItemCraftingComponentArgs
    >;
    createItemCraftingFinalProduct?: GraphCacheUpdateResolver<
      {
        createItemCraftingFinalProduct: Maybe<
          WithTypename<ICreateItemCraftingFinalProductMutationPayload>
        >;
      },
      IMutationCreateItemCraftingFinalProductArgs
    >;
    createItemReproduction?: GraphCacheUpdateResolver<
      {
        createItemReproduction: Maybe<
          WithTypename<ICreateItemReproductionMutationPayload>
        >;
      },
      IMutationCreateItemReproductionArgs
    >;
    createItemReproductionComponent?: GraphCacheUpdateResolver<
      {
        createItemReproductionComponent: Maybe<
          WithTypename<ICreateItemReproductionComponentMutationPayload>
        >;
      },
      IMutationCreateItemReproductionComponentArgs
    >;
    createItemTransaction?: GraphCacheUpdateResolver<
      {
        createItemTransaction: Maybe<
          WithTypename<ICreateItemTransactionMutationPayload>
        >;
      },
      IMutationCreateItemTransactionArgs
    >;
    createLocation?: GraphCacheUpdateResolver<
      { createLocation: Maybe<WithTypename<ICreateLocationMutationPayload>> },
      IMutationCreateLocationArgs
    >;
    createMembership?: GraphCacheUpdateResolver<
      {
        createMembership: Maybe<WithTypename<ICreateMembershipMutationPayload>>;
      },
      IMutationCreateMembershipArgs
    >;
    createMod?: GraphCacheUpdateResolver<
      { createMod: Maybe<WithTypename<ICreateModMutationPayload>> },
      IMutationCreateModArgs
    >;
    createModSection?: GraphCacheUpdateResolver<
      {
        createModSection: Maybe<WithTypename<ICreateModSectionMutationPayload>>;
      },
      IMutationCreateModSectionArgs
    >;
    createNewPlayer?: GraphCacheUpdateResolver<
      { createNewPlayer: Maybe<WithTypename<ICreateNewPlayerMutationPayload>> },
      IMutationCreateNewPlayerArgs
    >;
    createReferral?: GraphCacheUpdateResolver<
      { createReferral: Maybe<WithTypename<ICreateReferralMutationPayload>> },
      IMutationCreateReferralArgs
    >;
    createSpecialDate?: GraphCacheUpdateResolver<
      {
        createSpecialDate: Maybe<
          WithTypename<ICreateSpecialDateMutationPayload>
        >;
      },
      IMutationCreateSpecialDateArgs
    >;
    destroyAttendeeCharacter?: GraphCacheUpdateResolver<
      {
        destroyAttendeeCharacter: Maybe<
          WithTypename<IDestroyAttendeeCharacterMutationPayload>
        >;
      },
      IMutationDestroyAttendeeCharacterArgs
    >;
    destroyAuthToken?: GraphCacheUpdateResolver<
      {
        destroyAuthToken: Maybe<WithTypename<IDestroyAuthTokenMutationPayload>>;
      },
      IMutationDestroyAuthTokenArgs
    >;
    destroyCharacter?: GraphCacheUpdateResolver<
      {
        destroyCharacter: Maybe<WithTypename<IDestroyCharacterMutationPayload>>;
      },
      IMutationDestroyCharacterArgs
    >;
    destroyCorrectiveAction?: GraphCacheUpdateResolver<
      {
        destroyCorrectiveAction: Maybe<
          WithTypename<IDestroyCorrectiveActionMutationPayload>
        >;
      },
      IMutationDestroyCorrectiveActionArgs
    >;
    destroyEventAttendee?: GraphCacheUpdateResolver<
      {
        destroyEventAttendee: Maybe<
          WithTypename<IDestroyEventAttendeeMutationPayload>
        >;
      },
      IMutationDestroyEventAttendeeArgs
    >;
    destroyEventShift?: GraphCacheUpdateResolver<
      {
        destroyEventShift: Maybe<
          WithTypename<IDestroyEventShiftMutationPayload>
        >;
      },
      IMutationDestroyEventShiftArgs
    >;
    destroyEventTicket?: GraphCacheUpdateResolver<
      {
        destroyEventTicket: Maybe<
          WithTypename<IDestroyEventTicketMutationPayload>
        >;
      },
      IMutationDestroyEventTicketArgs
    >;
    destroyItemClassification?: GraphCacheUpdateResolver<
      {
        destroyItemClassification: Maybe<
          WithTypename<IDestroyItemClassificationMutationPayload>
        >;
      },
      IMutationDestroyItemClassificationArgs
    >;
    destroyItemCrafting?: GraphCacheUpdateResolver<
      {
        destroyItemCrafting: Maybe<
          WithTypename<IDestroyItemCraftingMutationPayload>
        >;
      },
      IMutationDestroyItemCraftingArgs
    >;
    destroyItemCraftingComponent?: GraphCacheUpdateResolver<
      {
        destroyItemCraftingComponent: Maybe<
          WithTypename<IDestroyItemCraftingComponentMutationPayload>
        >;
      },
      IMutationDestroyItemCraftingComponentArgs
    >;
    destroyItemCraftingFinalProduct?: GraphCacheUpdateResolver<
      {
        destroyItemCraftingFinalProduct: Maybe<
          WithTypename<IDestroyItemCraftingFinalProductMutationPayload>
        >;
      },
      IMutationDestroyItemCraftingFinalProductArgs
    >;
    destroyItemReproduction?: GraphCacheUpdateResolver<
      {
        destroyItemReproduction: Maybe<
          WithTypename<IDestroyItemReproductionMutationPayload>
        >;
      },
      IMutationDestroyItemReproductionArgs
    >;
    destroyItemReproductionComponent?: GraphCacheUpdateResolver<
      {
        destroyItemReproductionComponent: Maybe<
          WithTypename<IDestroyItemReproductionComponentMutationPayload>
        >;
      },
      IMutationDestroyItemReproductionComponentArgs
    >;
    destroyLocation?: GraphCacheUpdateResolver<
      { destroyLocation: Maybe<WithTypename<IDestroyLocationMutationPayload>> },
      IMutationDestroyLocationArgs
    >;
    destroyMembership?: GraphCacheUpdateResolver<
      {
        destroyMembership: Maybe<
          WithTypename<IDestroyMembershipMutationPayload>
        >;
      },
      IMutationDestroyMembershipArgs
    >;
    destroyReferral?: GraphCacheUpdateResolver<
      { destroyReferral: Maybe<WithTypename<IDestroyReferralMutationPayload>> },
      IMutationDestroyReferralArgs
    >;
    destroySpecialDate?: GraphCacheUpdateResolver<
      {
        destroySpecialDate: Maybe<
          WithTypename<IDestroySpecialDateMutationPayload>
        >;
      },
      IMutationDestroySpecialDateArgs
    >;
    fulfillShift?: GraphCacheUpdateResolver<
      { fulfillShift: Maybe<WithTypename<IFulfillShiftMutationPayload>> },
      IMutationFulfillShiftArgs
    >;
    generateAuthToken?: GraphCacheUpdateResolver<
      {
        generateAuthToken: Maybe<
          WithTypename<IGenerateAuthTokenMutationPayload>
        >;
      },
      IMutationGenerateAuthTokenArgs
    >;
    logBlueprintAction?: GraphCacheUpdateResolver<
      {
        logBlueprintAction: Maybe<
          WithTypename<ILogBlueprintActionMutationPayload>
        >;
      },
      IMutationLogBlueprintActionArgs
    >;
    requestPasswordReset?: GraphCacheUpdateResolver<
      {
        requestPasswordReset: Maybe<
          WithTypename<IRequestPasswordResetMutationPayload>
        >;
      },
      IMutationRequestPasswordResetArgs
    >;
    resetPassword?: GraphCacheUpdateResolver<
      { resetPassword: Maybe<WithTypename<IResetPasswordMutationPayload>> },
      IMutationResetPasswordArgs
    >;
    retireCharacter?: GraphCacheUpdateResolver<
      { retireCharacter: Maybe<WithTypename<IRetireCharacterMutationPayload>> },
      IMutationRetireCharacterArgs
    >;
    updateBranch?: GraphCacheUpdateResolver<
      { updateBranch: Maybe<WithTypename<IUpdateBranchMutationPayload>> },
      IMutationUpdateBranchArgs
    >;
    updateCap?: GraphCacheUpdateResolver<
      { updateCap: Maybe<WithTypename<IUpdateCapMutationPayload>> },
      IMutationUpdateCapArgs
    >;
    updateCharacterData?: GraphCacheUpdateResolver<
      {
        updateCharacterData: Maybe<
          WithTypename<IUpdateCharacterDataMutationPayload>
        >;
      },
      IMutationUpdateCharacterDataArgs
    >;
    updateCharacterFellowship?: GraphCacheUpdateResolver<
      {
        updateCharacterFellowship: Maybe<
          WithTypename<IUpdateCharacterFellowshipMutationPayload>
        >;
      },
      IMutationUpdateCharacterFellowshipArgs
    >;
    updateCorrectiveAction?: GraphCacheUpdateResolver<
      {
        updateCorrectiveAction: Maybe<
          WithTypename<IUpdateCorrectiveActionMutationPayload>
        >;
      },
      IMutationUpdateCorrectiveActionArgs
    >;
    updateEvent?: GraphCacheUpdateResolver<
      { updateEvent: Maybe<WithTypename<IUpdateEventMutationPayload>> },
      IMutationUpdateEventArgs
    >;
    updateEventAttendee?: GraphCacheUpdateResolver<
      {
        updateEventAttendee: Maybe<
          WithTypename<IUpdateEventAttendeeMutationPayload>
        >;
      },
      IMutationUpdateEventAttendeeArgs
    >;
    updateEventLifecycle?: GraphCacheUpdateResolver<
      {
        updateEventLifecycle: Maybe<
          WithTypename<IUpdateEventLifecycleMutationPayload>
        >;
      },
      IMutationUpdateEventLifecycleArgs
    >;
    updateEventShift?: GraphCacheUpdateResolver<
      {
        updateEventShift: Maybe<WithTypename<IUpdateEventShiftMutationPayload>>;
      },
      IMutationUpdateEventShiftArgs
    >;
    updateEventTicket?: GraphCacheUpdateResolver<
      {
        updateEventTicket: Maybe<
          WithTypename<IUpdateEventTicketMutationPayload>
        >;
      },
      IMutationUpdateEventTicketArgs
    >;
    updateFellowship?: GraphCacheUpdateResolver<
      {
        updateFellowship: Maybe<WithTypename<IUpdateFellowshipMutationPayload>>;
      },
      IMutationUpdateFellowshipArgs
    >;
    updateItem?: GraphCacheUpdateResolver<
      { updateItem: Maybe<WithTypename<IUpdateItemMutationPayload>> },
      IMutationUpdateItemArgs
    >;
    updateItemCrafting?: GraphCacheUpdateResolver<
      {
        updateItemCrafting: Maybe<
          WithTypename<IUpdateItemCraftingMutationPayload>
        >;
      },
      IMutationUpdateItemCraftingArgs
    >;
    updateItemCraftingComponent?: GraphCacheUpdateResolver<
      {
        updateItemCraftingComponent: Maybe<
          WithTypename<IUpdateItemCraftingComponentMutationPayload>
        >;
      },
      IMutationUpdateItemCraftingComponentArgs
    >;
    updateItemCraftingFinalProduct?: GraphCacheUpdateResolver<
      {
        updateItemCraftingFinalProduct: Maybe<
          WithTypename<IUpdateItemCraftingFinalProductMutationPayload>
        >;
      },
      IMutationUpdateItemCraftingFinalProductArgs
    >;
    updateItemReproduction?: GraphCacheUpdateResolver<
      {
        updateItemReproduction: Maybe<
          WithTypename<IUpdateItemReproductionMutationPayload>
        >;
      },
      IMutationUpdateItemReproductionArgs
    >;
    updateItemReproductionComponent?: GraphCacheUpdateResolver<
      {
        updateItemReproductionComponent: Maybe<
          WithTypename<IUpdateItemReproductionComponentMutationPayload>
        >;
      },
      IMutationUpdateItemReproductionComponentArgs
    >;
    updateItemTransaction?: GraphCacheUpdateResolver<
      {
        updateItemTransaction: Maybe<
          WithTypename<IUpdateItemTransactionMutationPayload>
        >;
      },
      IMutationUpdateItemTransactionArgs
    >;
    updateLocation?: GraphCacheUpdateResolver<
      { updateLocation: Maybe<WithTypename<IUpdateLocationMutationPayload>> },
      IMutationUpdateLocationArgs
    >;
    updateMembership?: GraphCacheUpdateResolver<
      {
        updateMembership: Maybe<WithTypename<IUpdateMembershipMutationPayload>>;
      },
      IMutationUpdateMembershipArgs
    >;
    updateMod?: GraphCacheUpdateResolver<
      { updateMod: Maybe<WithTypename<IUpdateModMutationPayload>> },
      IMutationUpdateModArgs
    >;
    updateModSection?: GraphCacheUpdateResolver<
      {
        updateModSection: Maybe<WithTypename<IUpdateModSectionMutationPayload>>;
      },
      IMutationUpdateModSectionArgs
    >;
    updateOrganization?: GraphCacheUpdateResolver<
      {
        updateOrganization: Maybe<
          WithTypename<IUpdateOrganizationMutationPayload>
        >;
      },
      IMutationUpdateOrganizationArgs
    >;
    updatePassword?: GraphCacheUpdateResolver<
      { updatePassword: Maybe<WithTypename<IUpdatePasswordMutationPayload>> },
      IMutationUpdatePasswordArgs
    >;
    updatePlayerBranchOwnership?: GraphCacheUpdateResolver<
      {
        updatePlayerBranchOwnership: Maybe<
          WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>
        >;
      },
      IMutationUpdatePlayerBranchOwnershipArgs
    >;
    updatePlayerData?: GraphCacheUpdateResolver<
      {
        updatePlayerData: Maybe<WithTypename<IUpdatePlayerDataMutationPayload>>;
      },
      IMutationUpdatePlayerDataArgs
    >;
    updatePlayerOrganizationData?: GraphCacheUpdateResolver<
      {
        updatePlayerOrganizationData: Maybe<
          WithTypename<IUpdatePlayerOrganizationDataMutationPayload>
        >;
      },
      IMutationUpdatePlayerOrganizationDataArgs
    >;
    updateSpecialDate?: GraphCacheUpdateResolver<
      {
        updateSpecialDate: Maybe<
          WithTypename<IUpdateSpecialDateMutationPayload>
        >;
      },
      IMutationUpdateSpecialDateArgs
    >;
  };
  Subscription?: {};
  AssignBranchLocationMutationPayload?: {
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchLocationMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchLocationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchLocationMutationPayload>>,
      Record<string, never>
    >;
  };
  AssignBranchOwnershipMutationPayload?: {
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
  };
  AssignTemporaryManagerMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignTemporaryManagerMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignTemporaryManagerMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAssignTemporaryManagerMutationPayload>>,
      Record<string, never>
    >;
  };
  AttendanceInvoice?: {
    attending?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    baseCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    buildGrowth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    change?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    extraXpCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    paid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    ticket?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    totalCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendanceInvoice>>,
      Record<string, never>
    >;
  };
  AttendeeCharacter?: {
    attendance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    buildGrowth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    cap?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    payment?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    printRequestStatus?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
    ticket?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeCharacter>>,
      Record<string, never>
    >;
  };
  AttendeeShift?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeShift>>,
      Record<string, never>
    >;
    player?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeShift>>,
      Record<string, never>
    >;
    shift?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeShift>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IAttendeeShift>>,
      Record<string, never>
    >;
  };
  Branch?: {
    activePlayers?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    branchAssistants?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    branchOwners?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    caps?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    correctiveActions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    district?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    invoice?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      IBranchLocationArgs
    >;
    locations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    region?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    registrationUrl?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    shorthand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
    website?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranch>>,
      Record<string, never>
    >;
  };
  BranchOwner?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranchOwner>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IBranchOwner>>,
      Record<string, never>
    >;
  };
  Cap?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    grantor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    reason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    state?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICap>>,
      Record<string, never>
    >;
  };
  Character?: {
    alive?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    approvedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    body?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    buildEarned?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    buildUsed?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    characterFellowships?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    currentLife?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    experimentalCharacter?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    faithId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    fractures?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      ICharacterFracturesArgs
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    infection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    inventories?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    latestLifecycleGainsThisYear?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    legacyHomeGameCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    lifeCapacity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    lifeGain?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    lifeLoss?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    lifecycles?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    lineageId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    loreIds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    maxLores?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    mind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    playerNotes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    resolve?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    retiredCharacter?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    retirementPaths?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    skills?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    staffNotes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    strainId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    totalHomeGameCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    variant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    version?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
    xpAudits?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacter>>,
      Record<string, never>
    >;
  };
  CharacterConfig?: {
    configurableSkills?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    configurableStats?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    faiths?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    lineages?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    lores?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    skills?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
    strains?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterConfig>>,
      Record<string, never>
    >;
  };
  CharacterFellowship?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterFellowship>>,
      Record<string, never>
    >;
    fellowship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterFellowship>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterFellowship>>,
      Record<string, never>
    >;
    rank?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterFellowship>>,
      Record<string, never>
    >;
    removedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterFellowship>>,
      Record<string, never>
    >;
  };
  CharacterSkill?: {
    characterSkillId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterSkill>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterSkill>>,
      Record<string, never>
    >;
    level?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterSkill>>,
      Record<string, never>
    >;
    positions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICharacterSkill>>,
      Record<string, never>
    >;
  };
  CheckinCharacterMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinCharacterMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinCharacterMutationPayload>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinCharacterMutationPayload>>,
      Record<string, never>
    >;
  };
  CheckinMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinMutationPayload>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICheckinMutationPayload>>,
      Record<string, never>
    >;
  };
  CopyCharacterBuildMutationPayload?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICopyCharacterBuildMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICopyCharacterBuildMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICopyCharacterBuildMutationPayload>>,
      Record<string, never>
    >;
  };
  CorrectiveAction?: {
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    endsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    issuer?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    reason?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    startsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICorrectiveAction>>,
      Record<string, never>
    >;
  };
  CraftableItem?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftableItem>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftableItem>>,
      Record<string, never>
    >;
    metadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftableItem>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftableItem>>,
      Record<string, never>
    >;
  };
  CraftingComponent?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingComponent>>,
      Record<string, never>
    >;
    component?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingComponent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingComponent>>,
      Record<string, never>
    >;
    itemCrafting?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingComponent>>,
      Record<string, never>
    >;
  };
  CraftingFinalProduct?: {
    finalProduct?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingFinalProduct>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingFinalProduct>>,
      Record<string, never>
    >;
    stack?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICraftingFinalProduct>>,
      Record<string, never>
    >;
  };
  CreateCapMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCapMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCapMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCapMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateCharacterFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
    fellowship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateCharacterMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCharacterMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateEventLifecycleMutationPayload?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateEventMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventShiftMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventShiftMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventShiftMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventTicketMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventTicketMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateEventTicketMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateFellowshipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateFellowshipMutationPayload>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateFellowshipMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemClassificationMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemClassificationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemClassificationMutationPayload>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemClassificationMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    itemCrafting?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    itemCrafting?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemMutationPayload>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    itemReproduction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateItemTransactionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
    itemTransactions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateLocationMutationPayload?: {
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateLocationMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateLocationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateLocationMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateMembershipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateMembershipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateMembershipMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateMembershipMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateModMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModMutationPayload>>,
      Record<string, never>
    >;
    modSection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateModSectionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModSectionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModSectionMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateModSectionMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateNewPlayerMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateNewPlayerMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateNewPlayerMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateNewPlayerMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateReferralMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateReferralMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateReferralMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateReferralMutationPayload>>,
      Record<string, never>
    >;
  };
  CreateSpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ICreateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyAttendeeCharacterMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyAttendeeCharacterMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyAttendeeCharacterMutationPayload>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyAttendeeCharacterMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyAuthTokenMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyAuthTokenMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyAuthTokenMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyCharacterMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCharacterMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCharacterMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCharacterMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    correctiveAction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyEventAttendeeMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventShiftMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventShiftMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventShiftMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventTicketMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventTicketMutationPayload>>,
      Record<string, never>
    >;
    ticket?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyEventTicketMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemClassificationMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemClassificationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemClassificationMutationPayload>>,
      Record<string, never>
    >;
    itemClassification?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemClassificationMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    craftingComponent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    craftingFinalProduct?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    itemCrafting?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemCraftingMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    itemReproductionComponent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    itemReproduction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyItemReproductionMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyLocationMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyLocationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyLocationMutationPayload>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyLocationMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyMembershipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyMembershipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyMembershipMutationPayload>>,
      Record<string, never>
    >;
    membership?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyMembershipMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroyReferralMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyReferralMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyReferralMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroyReferralMutationPayload>>,
      Record<string, never>
    >;
  };
  DestroySpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroySpecialDateMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroySpecialDateMutationPayload>>,
      Record<string, never>
    >;
    specialDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IDestroySpecialDateMutationPayload>>,
      Record<string, never>
    >;
  };
  Error?: {
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IError>>,
      Record<string, never>
    >;
  };
  Event?: {
    activeCorrectiveActions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventActiveCorrectiveActionsArgs
    >;
    activeMembership?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventActiveMembershipArgs
    >;
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    canSelfCheckin?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventCanSelfCheckinArgs
    >;
    checkinableCharacters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventCheckinableCharactersArgs
    >;
    config?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    endsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventEventAttendeeArgs
    >;
    eventAttendees?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventEventAttendeesArgs
    >;
    eventAttendeesSize?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    isEventAssistant?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    isEventManager?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    isEventRegistrar?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    isHomeGame?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventIsHomeGameArgs
    >;
    isVirtual?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    itemTransactions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    maximumCheckinableCharacters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventMaximumCheckinableCharactersArgs
    >;
    modDescription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    modMaxSignup?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    modSections?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    modSignupOpensAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    nextEvent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    prevEvent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    printouts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventPrintoutsArgs
    >;
    registrationOpensAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    revenue?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    shiftKinds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    shifts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventShiftsArgs
    >;
    startsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    temporaryManagers?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    ticketStatistics?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
    tickets?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      IEventTicketsArgs
    >;
    ticketsBaseConfig?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEvent>>,
      Record<string, never>
    >;
  };
  EventAttendee?: {
    attendeeCharacters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    attendeeShifts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    attending?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    buildAwarded?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    buildGrowth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    checkedInCharactersCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    checkinApproved?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    inviter?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    paid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    referralInfo?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    shifts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    ticket?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    ticketId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventAttendee>>,
      Record<string, never>
    >;
  };
  EventConfig?: {
    baseBuild?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    considersTravelersLocal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    extraXpCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    maxCurveXp?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    maxExtraXp?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    maxExtraXpLimit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    maxShift?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    shiftKinds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    shiftMultiplier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
    shiftUnit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventConfig>>,
      Record<string, never>
    >;
  };
  EventInvoice?: {
    attendances?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    baseCompensationTicketCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    billableAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    endsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    extraXpSold?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    fullCompensationTicketCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    isCurrentCycle?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    isLegacy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    isVirtual?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    startsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
    ticketsSold?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventInvoice>>,
      Record<string, never>
    >;
  };
  EventRevenue?: {
    aggregates?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventRevenue>>,
      Record<string, never>
    >;
    itemizedLineItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IEventRevenue>>,
      Record<string, never>
    >;
  };
  Faith?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFaith>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFaith>>,
      Record<string, never>
    >;
  };
  Fellowship?: {
    characterFellowships?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    characters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    charactersCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
    removedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFellowship>>,
      Record<string, never>
    >;
  };
  Fracture?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFracture>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFracture>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFracture>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFracture>>,
      Record<string, never>
    >;
    removedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFracture>>,
      Record<string, never>
    >;
  };
  FulfillShiftMutationPayload?: {
    attendeeShift?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFulfillShiftMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFulfillShiftMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IFulfillShiftMutationPayload>>,
      Record<string, never>
    >;
  };
  GenerateAuthTokenMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IGenerateAuthTokenMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IGenerateAuthTokenMutationPayload>>,
      Record<string, never>
    >;
    token?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IGenerateAuthTokenMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IGenerateAuthTokenMutationPayload>>,
      Record<string, never>
    >;
  };
  Inventory?: {
    expiresAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
    itemId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
    registeredItem?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
    registeredItemId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
    stack?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInventory>>,
      Record<string, never>
    >;
  };
  Invoice?: {
    additionalXpBuybackCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    additionalXpCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    billableAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    events?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    liveEventTicketBuybackCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    liveEventTicketCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    virtualEventTicketBuybackCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
    virtualEventTicketCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoice>>,
      Record<string, never>
    >;
  };
  InvoiceChange?: {
    attending?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    buildGrowth?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    gameKind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    paid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    ticketId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
    ticketLabel?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IInvoiceChange>>,
      Record<string, never>
    >;
  };
  Item?: {
    blueprintForCraftings?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    childItemClassifications?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    grade?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    itemCraftings?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    itemReproductions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    lifetimeAmount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    lifetimeUnit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    metadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    parts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    updatedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    validFrom?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    validUntil?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    version?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
    visibility?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItem>>,
      Record<string, never>
    >;
  };
  ItemClassification?: {
    childItem?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemClassification>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemClassification>>,
      Record<string, never>
    >;
  };
  ItemCrafting?: {
    blueprint?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingComponents?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingFinalProducts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingMindCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingSkills?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingTimeInMinute?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    craftingZone?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemCrafting>>,
      Record<string, never>
    >;
  };
  ItemMetadata?: {
    activationRequirement?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    craftableItems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    descriptionOfRoleplay?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    durationOfEffect?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    durationOfRoleplay?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    equipmentRequiredForUse?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    locationOfUse?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    mechanics?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    printHeader?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    requirementsToUse?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    uses?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
    validTargetDescription?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemMetadata>>,
      Record<string, never>
    >;
  };
  ItemReproduction?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
    itemReproductionComponents?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
    metadata?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
    reproductionMindCost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
    reproductionSkills?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
    reproductionTimeInMinute?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproduction>>,
      Record<string, never>
    >;
  };
  ItemReproductionComponent?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproductionComponent>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproductionComponent>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproductionComponent>>,
      Record<string, never>
    >;
  };
  ItemReproductionMetadata?: {
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemReproductionMetadata>>,
      Record<string, never>
    >;
  };
  ItemTransaction?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    childTransactions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    expiresAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    postie?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    registeredItem?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    source?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    stack?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    status?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
    transactionPartner?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IItemTransaction>>,
      Record<string, never>
    >;
  };
  Lifecycle?: {
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
    deletedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILifecycle>>,
      Record<string, never>
    >;
  };
  Lineage?: {
    body?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
    infection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
    mind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
    resolve?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILineage>>,
      Record<string, never>
    >;
  };
  Location?: {
    city?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
    events?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      ILocationEventsArgs
    >;
    existingEventsSize?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      ILocationExistingEventsSizeArgs
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
    region?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
    streetAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
    zipCode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILocation>>,
      Record<string, never>
    >;
  };
  LogBlueprintActionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILogBlueprintActionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILogBlueprintActionMutationPayload>>,
      Record<string, never>
    >;
    success?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILogBlueprintActionMutationPayload>>,
      Record<string, never>
    >;
  };
  Lore?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILore>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ILore>>,
      Record<string, never>
    >;
  };
  Membership?: {
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    endsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    grantingBranch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    grantor?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    startsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMembership>>,
      Record<string, never>
    >;
  };
  Mod?: {
    constraint?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    modSection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    participationLimit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
    timeframe?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IMod>>,
      Record<string, never>
    >;
  };
  ModSection?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IModSection>>,
      Record<string, never>
    >;
    mods?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IModSection>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IModSection>>,
      Record<string, never>
    >;
    signupLimit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IModSection>>,
      Record<string, never>
    >;
  };
  Organization?: {
    armorUpgradeComponents?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    branches?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationBranchesArgs
    >;
    characterConfig?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    configs?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    fellowship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationFellowshipArgs
    >;
    fellowships?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationFellowshipsArgs
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationItemArgs
    >;
    itemGrades?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    itemKinds?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    items?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationItemsArgs
    >;
    lastEventAttendanceSnapshotOn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    lastEventDiffOn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    memberships?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationMembershipsArgs
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    rebirthedCharacters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    role?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    shorthand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    slug?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    specialDates?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
    statistics?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      IOrganizationStatisticsArgs
    >;
    timelines?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganization>>,
      Record<string, never>
    >;
  };
  OrganizationConfig?: {
    charactersActiveCountMax?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    charactersCountMax?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    charactersCountStaffMax?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    createFellowshipsOnDemand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    fellowshipAlias?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    fellowshipsEventTracking?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    fellowshipsMaxRank?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    fellowshipsMinRank?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IOrganizationConfig>>,
      Record<string, never>
    >;
  };
  Permission?: {
    entity?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IPermission>>,
      Record<string, never>
    >;
    permitted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IPermission>>,
      Record<string, never>
    >;
  };
  RegisteredItem?: {
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRegisteredItem>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRegisteredItem>>,
      Record<string, never>
    >;
  };
  RequestPasswordResetMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRequestPasswordResetMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRequestPasswordResetMutationPayload>>,
      Record<string, never>
    >;
  };
  ResetPasswordMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResetPasswordMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResetPasswordMutationPayload>>,
      Record<string, never>
    >;
    token?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResetPasswordMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResetPasswordMutationPayload>>,
      Record<string, never>
    >;
  };
  ResidenciesStatistics?: {
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidenciesStatistics>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidenciesStatistics>>,
      Record<string, never>
    >;
    values?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidenciesStatistics>>,
      Record<string, never>
    >;
  };
  ResidencyStatistics?: {
    count?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidencyStatistics>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidencyStatistics>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidencyStatistics>>,
      Record<string, never>
    >;
    shorthand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IResidencyStatistics>>,
      Record<string, never>
    >;
  };
  RetireCharacterMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetireCharacterMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetireCharacterMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetireCharacterMutationPayload>>,
      Record<string, never>
    >;
  };
  RetirementPaths?: {
    alive?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetirementPaths>>,
      Record<string, never>
    >;
    dead?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetirementPaths>>,
      Record<string, never>
    >;
  };
  RetirementXp?: {
    amount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetirementXp>>,
      Record<string, never>
    >;
    earnedOn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRetirementXp>>,
      Record<string, never>
    >;
  };
  RevenueAggregates?: {
    attendeesPaid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRevenueAggregates>>,
      Record<string, never>
    >;
    attendeesTotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRevenueAggregates>>,
      Record<string, never>
    >;
    revenue?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRevenueAggregates>>,
      Record<string, never>
    >;
    revenueDiscounted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRevenueAggregates>>,
      Record<string, never>
    >;
    revenueLiable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IRevenueAggregates>>,
      Record<string, never>
    >;
  };
  Shift?: {
    adjustableExpansionStrategy?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    attendeeShifts?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    autoExpand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    baseRatio?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    identifier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    isEnabled?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    isFullyBooked?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    label?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    limit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
    playersCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IShift>>,
      Record<string, never>
    >;
  };
  Skill?: {
    category?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    dep?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    description?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    innate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    requiresResolve?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    singleTier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    specialization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
    tier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkill>>,
      Record<string, never>
    >;
  };
  SkillMechanic?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillMechanic>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillMechanic>>,
      Record<string, never>
    >;
    value?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillMechanic>>,
      Record<string, never>
    >;
  };
  SkillStatistics?: {
    count?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillStatistics>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillStatistics>>,
      Record<string, never>
    >;
    level?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillStatistics>>,
      Record<string, never>
    >;
  };
  SkillsStatistics?: {
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillsStatistics>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillsStatistics>>,
      Record<string, never>
    >;
    values?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISkillsStatistics>>,
      Record<string, never>
    >;
  };
  SpecialDate?: {
    endsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISpecialDate>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISpecialDate>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISpecialDate>>,
      Record<string, never>
    >;
    startsAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISpecialDate>>,
      Record<string, never>
    >;
    title?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ISpecialDate>>,
      Record<string, never>
    >;
  };
  StatMechanic?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatMechanic>>,
      Record<string, never>
    >;
    max?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatMechanic>>,
      Record<string, never>
    >;
    min?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatMechanic>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatMechanic>>,
      Record<string, never>
    >;
  };
  StatStatistics?: {
    body?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    buildEarned?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    buildUsed?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    currentLife?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    faithId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    mind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
    resolve?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatStatistics>>,
      Record<string, never>
    >;
  };
  StatsStatistics?: {
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatsStatistics>>,
      Record<string, never>
    >;
    type?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatsStatistics>>,
      Record<string, never>
    >;
    values?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStatsStatistics>>,
      Record<string, never>
    >;
  };
  Strain?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStrain>>,
      Record<string, never>
    >;
    lineageId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStrain>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IStrain>>,
      Record<string, never>
    >;
  };
  TemporaryManager?: {
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryManager>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryManager>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryManager>>,
      Record<string, never>
    >;
  };
  TemporaryToken?: {
    browser?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    expiresAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    hardware?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    ipAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    os?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
    token?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITemporaryToken>>,
      Record<string, never>
    >;
  };
  Ticket?: {
    cost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    discountType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    eventAttendeesCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    fixedLimit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    fullyBooked?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    identifier?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    label?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    limit?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    passType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    restrictionType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
    shiftResponsibilities?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicket>>,
      Record<string, never>
    >;
  };
  TicketInvoice?: {
    discountType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketInvoice>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketInvoice>>,
      Record<string, never>
    >;
    label?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketInvoice>>,
      Record<string, never>
    >;
  };
  TicketRevenue?: {
    attendeesPaid?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    attendeesTotal?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    cost?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    discountType?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    label?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    revenue?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    revenueDiscounted?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    revenueLiable?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
    subitems?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketRevenue>>,
      Record<string, never>
    >;
  };
  TicketsBaseConfig?: {
    discountTypes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketsBaseConfig>>,
      Record<string, never>
    >;
    passTypes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketsBaseConfig>>,
      Record<string, never>
    >;
    restrictionTypes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITicketsBaseConfig>>,
      Record<string, never>
    >;
  };
  Timeline?: {
    attendance?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    eventName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    local?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    shorthand?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    timecode?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
    virtual?: GraphCacheUpdateResolver<
      Maybe<WithTypename<ITimeline>>,
      Record<string, never>
    >;
  };
  UpdateBranchMutationPayload?: {
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateBranchMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateBranchMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateBranchMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateCapMutationPayload?: {
    cap?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCapMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCapMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCapMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateCharacterDataMutationPayload?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterDataMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterDataMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterDataMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateCharacterFellowshipMutationPayload?: {
    characterFellowship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCharacterFellowshipMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateCorrectiveActionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    correctiveAction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateCorrectiveActionMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateEventAttendeeMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
    eventAttendee?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventAttendeeMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateEventLifecycleMutationPayload?: {
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventLifecycleMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateEventMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventMutationPayload>>,
      Record<string, never>
    >;
    event?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateEventShiftMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventShiftMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventShiftMutationPayload>>,
      Record<string, never>
    >;
    shift?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventShiftMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateEventTicketMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventTicketMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventTicketMutationPayload>>,
      Record<string, never>
    >;
    ticket?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateEventTicketMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateFellowshipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateFellowshipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateFellowshipMutationPayload>>,
      Record<string, never>
    >;
    fellowship?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateFellowshipMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemCraftingComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    craftingComponent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemCraftingFinalProductMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    craftingFinalProduct?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingFinalProductMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemCraftingMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
    itemCrafting?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemCraftingMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemMutationPayload>>,
      Record<string, never>
    >;
    item?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemReproductionComponentMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
    itemReproductionComponent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionComponentMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemReproductionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
    itemReproduction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemReproductionMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateItemTransactionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
    itemTransaction?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateItemTransactionMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateLocationMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateLocationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateLocationMutationPayload>>,
      Record<string, never>
    >;
    location?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateLocationMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateMembershipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateMembershipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateMembershipMutationPayload>>,
      Record<string, never>
    >;
    membership?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateMembershipMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateModMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModMutationPayload>>,
      Record<string, never>
    >;
    mod?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateModSectionMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModSectionMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModSectionMutationPayload>>,
      Record<string, never>
    >;
    modSection?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateModSectionMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateOrganizationMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateOrganizationMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateOrganizationMutationPayload>>,
      Record<string, never>
    >;
    organization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateOrganizationMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdatePasswordMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePasswordMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePasswordMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdatePlayerBranchOwnershipMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerBranchOwnershipMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdatePlayerDataMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerDataMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerDataMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerDataMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdatePlayerOrganizationDataMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerOrganizationDataMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerOrganizationDataMutationPayload>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdatePlayerOrganizationDataMutationPayload>>,
      Record<string, never>
    >;
  };
  UpdateSpecialDateMutationPayload?: {
    clientMutationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
    error?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
    specialDate?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUpdateSpecialDateMutationPayload>>,
      Record<string, never>
    >;
  };
  User?: {
    assistantFlags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserAssistantFlagsArgs
    >;
    caps?: GraphCacheUpdateResolver<Maybe<WithTypename<IUser>>, IUserCapsArgs>;
    character?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserCharacterArgs
    >;
    characters?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserCharactersArgs
    >;
    correctiveActions?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    emailAddress?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    eventAttendances?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserEventAttendancesArgs
    >;
    firstName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    fullName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    invitations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserInvitationsArgs
    >;
    lastMembership?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserLastMembershipArgs
    >;
    lastName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    maxActiveCharactersCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    maxCharactersCount?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    membershipActiveDuringEvent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserMembershipActiveDuringEventArgs
    >;
    memberships?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserMembershipsArgs
    >;
    notes?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    organizations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    ownedBranches?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    preferredName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    recentEventAttendances?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    referrals?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserReferralsArgs
    >;
    roleInOrganization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserRoleInOrganizationArgs
    >;
    temporaryTokens?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
    totalCapsForEvent?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserTotalCapsForEventArgs
    >;
    upcomingEvents?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserUpcomingEventsArgs
    >;
    userOrganization?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      IUserUserOrganizationArgs
    >;
    userOrganizations?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUser>>,
      Record<string, never>
    >;
  };
  UserInvoice?: {
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserInvoice>>,
      Record<string, never>
    >;
    name?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserInvoice>>,
      Record<string, never>
    >;
  };
  UserOrganization?: {
    assistantFlags?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    branch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    branchId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    organizationId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    role?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
    user?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserOrganization>>,
      Record<string, never>
    >;
  };
  UserReferral?: {
    createdAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserReferral>>,
      Record<string, never>
    >;
    id?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserReferral>>,
      Record<string, never>
    >;
    inviter?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserReferral>>,
      Record<string, never>
    >;
    newbie?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IUserReferral>>,
      Record<string, never>
    >;
  };
  XpAudit?: {
    applicableOn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    awardedAt?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    branchTransferId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    destinationBranch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    eventId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    eventName?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    extraBuildId?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    homeGameEventsIndex?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    kind?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    originBranch?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    xpAddOn?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
    xpAwarded?: GraphCacheUpdateResolver<
      Maybe<WithTypename<IXpAudit>>,
      Record<string, never>
    >;
  };
};

export type GraphCacheConfig = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters;
  keys?: GraphCacheKeysConfig;
  optimistic?: GraphCacheOptimisticUpdaters;
  resolvers?: GraphCacheResolvers;
};
