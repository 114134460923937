import { FC } from 'react';
import { IBranch, IUser, IUserOrganization } from 'src/graphql/types';

export type TPlayer =
  | (Pick<
      IUser,
      'id' | 'fullName' | 'membershipActiveDuringEvent' | 'roleInOrganization'
    > & {
      userOrganization?:
        | (Pick<IUserOrganization, 'id'> & {
            branch: Pick<IBranch, 'id' | 'shorthand'>;
          })
        | null;
      totalCapsForEvent?: number;
    })
  | null;

interface IProps {
  player?: TPlayer;
}

const Player: FC<IProps> = ({ player }) => (
  <div className="text-left">
    <div className="text-2xl">
      #<span className="font-bold">{player ? `${player.id}` : '________'}</span>
    </div>
    <div>{player ? player.fullName : '________________'}</div>
    <div>
      {player
        ? `Home Branch: ${player.userOrganization?.branch.shorthand} - CAPS: ${player.totalCapsForEvent ?? 'N/A'}`
        : '____'}
    </div>
  </div>
);

export default Player;
