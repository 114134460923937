import { FC } from 'react';
import { TTransactableItem } from '../../LineItem';
import { TInventory } from '../../Inventories';

import Boolean, { TSelectedInventory } from './Boolean';
import Disjunction from './Disjunction';

interface IProps {
  requirement: TTransactableItem;
  inventories: TInventory[];
  selectedCraftingComponents: TSelectedInventory[];
  onUpdateSelectedCraftingComponent?: ({
    itemId,
    registeredItemId,
    expiresAt,
    stack,
  }: TSelectedInventory) => void;
}

const Requirement: FC<IProps> = ({
  requirement,
  inventories,
  selectedCraftingComponents,
  onUpdateSelectedCraftingComponent,
}) => {
  const isDisjunction = (requirement.options?.length ?? 0) > 0;

  return isDisjunction ? (
    <Disjunction
      requirement={requirement}
      inventories={inventories}
      selectedCraftingComponents={selectedCraftingComponents}
      onUpdateSelectedCraftingComponent={onUpdateSelectedCraftingComponent}
    />
  ) : (
    <Boolean
      requirement={requirement}
      inventories={inventories}
      selectedCraftingComponents={selectedCraftingComponents}
      onUpdateSelectedCraftingComponent={onUpdateSelectedCraftingComponent}
    />
  );
};

export default Requirement;
