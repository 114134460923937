import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { updateItem } from 'src/graphql/mutations/items.graphql';
import {
  IUpdateItemMutation,
  IUpdateItemMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { useMutation } from 'urql';
import { IPropsWithOnUpdate, TMetadata } from '../../types';

interface IProps extends IPropsWithOnUpdate {
  id: number;
  metadata: TMetadata;
}

const Benediction: FC<IProps> = ({ id, metadata, onUpdate }) => {
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      durationOfEffect: metadata.durationOfEffect,
      validTargetDescription: metadata.validTargetDescription,
      locationOfUse: metadata.locationOfUse,
      equipmentRequiredForUse: metadata.equipmentRequiredForUse,
      durationOfRoleplay: metadata.durationOfRoleplay,
      descriptionOfRoleplay: metadata.descriptionOfRoleplay,
      activationRequirement: metadata.activationRequirement,
    },
  });
  const {
    durationOfEffect,
    validTargetDescription,
    locationOfUse,
    equipmentRequiredForUse,
    durationOfRoleplay,
    descriptionOfRoleplay,
    activationRequirement,
  } = watch();

  const [ , update ] = useMutation<
    IUpdateItemMutation,
    IUpdateItemMutationVariables
  >(updateItem);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;

    onUpdate({ status: 'busy' });
    update({
      itemId: id,
      durationOfEffect,
      validTargetDescription,
      locationOfUse,
      equipmentRequiredForUse,
      durationOfRoleplay,
      descriptionOfRoleplay,
      activationRequirement,
    }).then(res => {
      if (res.data?.updateItem?.item) {
        onUpdate({ status: 'success' });
      }

      if (res.data?.updateItem?.error) {
        onUpdate({ status: 'error' });
      }
    });
  }, [
    activationRequirement,
    descriptionOfRoleplay,
    durationOfEffect,
    durationOfRoleplay,
    equipmentRequiredForUse,
    id,
    isDirty,
    locationOfUse,
    onUpdate,
    update,
    validTargetDescription,
  ]);

  useEffect(() => {
    if (metadata) {
      reset({
        durationOfEffect: metadata.durationOfEffect,
        validTargetDescription: metadata.validTargetDescription,
        locationOfUse: metadata.locationOfUse,
        equipmentRequiredForUse: metadata.equipmentRequiredForUse,
        durationOfRoleplay: metadata.durationOfRoleplay,
        descriptionOfRoleplay: metadata.descriptionOfRoleplay,
        activationRequirement: metadata.activationRequirement,
      });
    }
  }, [ metadata, reset ]);

  return (
    <>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('durationOfEffect', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('validTargetDescription', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('locationOfUse', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('equipmentRequiredForUse', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('activationRequirement', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('durationOfRoleplay', { onBlur: handleUpdate })}
        />
      </td>
      <td>
        <input
          className="text-righ border-0 bg-transparent w-full p-1"
          type="text"
          {...register('descriptionOfRoleplay', { onBlur: handleUpdate })}
        />
      </td>
    </>
  );
};

export default Benediction;
