import { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import AugmentedInput from 'src/components/0200_augmented_input';
import Modal from 'src/components/0300_modal';
import { IResetPasswordPayload } from 'src/hooks/auths/types';
import useAuth from 'src/hooks/auths/useAuth';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useResetPasswordTokenStatus from 'src/hooks/players/useResetPasswordTokenStatus';

interface IProps {
  isOpen: boolean;
  isValidated: boolean;
  isFetching: boolean;
  onClose: () => void;
  onPasswordReset: (payload: IResetPasswordPayload) => void;
}

const PasswordResetModal: FC<IProps> = ({
  isOpen,
  isValidated,
  isFetching,
  onClose,
  onPasswordReset,
}) => {
  const { resetPasswordToken } = useAuth();
  const { buttonState } = useButtonStates();
  const { isTokenValid, fetching } = useResetPasswordTokenStatus();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { password: '', confirmPassword: '' },
  });
  const {
    getValues,
    watch,
    register,
    reset,
    setFocus,
    formState: { isDirty, isValid },
  } = methods;

  const handleResetPassword = useCallback(() => {
    if (resetPasswordToken) {
      onPasswordReset({
        resetPasswordToken,
        password: getValues().password,
      });
    }
  }, [ getValues, onPasswordReset, resetPasswordToken ]);

  const watchedPasswordValue = watch('password');
  const watchedConfirmPasswordValue = watch('confirmPassword');
  const passwordConfirmMismatch = useMemo(() => {
    if (!watchedPasswordValue || !watchedConfirmPasswordValue) return null;

    return watchedPasswordValue !== watchedConfirmPasswordValue;
  }, [ watchedPasswordValue, watchedConfirmPasswordValue ]);

  const isValidAndMatching = useMemo(() => {
    if (isValid && watchedPasswordValue === watchedConfirmPasswordValue)
      return true;

    return false;
  }, [ isValid, watchedPasswordValue, watchedConfirmPasswordValue ]);

  useEffect(() => {
    if (isOpen) {
      setFocus('password');
    }
  }, [ setFocus, isOpen ]);

  return (
    <Modal title="Reset Password" isOpen={isOpen}>
      {fetching && <Loading />}
      {!fetching && !isTokenValid && (
        <>
          <ResponseBox type="error">Invalid or expired token</ResponseBox>
          <div className="flex justify-end p-2 pr-4 pb-4 w-full">
            <Button
              defaultLabel="Close"
              onClick={() => {
                reset();
                onClose();
              }}
            />
          </div>
        </>
      )}
      {!fetching && isTokenValid && (
        <>
          <FormProvider {...methods}>
            <AugmentedInput
              required
              title="Password"
              type="password"
              placeholder="Sshhh..."
              {...register('password', {
                required: { value: true, message: 'Cannot be blank' },
                minLength: 1,
              })}
            />
            <AugmentedInput
              required
              title="Confirm Password"
              type="password"
              placeholder="Second time the charm"
              error={
                passwordConfirmMismatch ? 'Passwords do not match' : undefined
              }
              onEnter={handleResetPassword}
              {...register('confirmPassword', {
                required: { value: true, message: 'Cannot be blank' },
                minLength: 1,
              })}
            />
          </FormProvider>

          <div className="flex justify-end p-2 pr-4 pb-4 w-full">
            <Button
              defaultLabel="Cancel"
              onClick={() => {
                reset();
                onClose();
              }}
            />
            <Button
              className="ml-2"
              defaultLabel="Reset"
              state={buttonState({
                isHighlight: true,
                isSuccessful: isValidated,
                isDirty,
                isFetching,
                isValid: isValidAndMatching,
              })}
              stateLabel={{
                loading: 'Signing In...',
                success: 'Signed In!',
              }}
              onClick={handleResetPassword}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default PasswordResetModal;
