import {
  faCheck,
  faSackDollar,
  faScrewdriverWrench,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';

interface IProps {
  canCommitTransaction: boolean;
  fetching: boolean;
  commitTransaction: () => void;
}

const Execution: FC<IProps> = ({
  canCommitTransaction,
  commitTransaction,
  fetching,
}) => {
  const { setValue, watch, register } = useFormContext();
  const { transactionSource, attestCrafting } = watch();
  const { buttonState } = useButtonStates();
  const isModuleReward = !attestCrafting && transactionSource.trim().length > 0;

  useEffect(() => {
    if (transactionSource.trim().length > 0) {
      setValue('attestCrafting', false);
    }
  }, [ setValue, transactionSource ]);

  return (
    <div className="grid gap-2 w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-start">
        <div
          className={clsx(
            'border border-juno-gray-700 gray-box p-4 grid gap-2 rounded-xs transition-all duration-300',
            !attestCrafting && 'opacity-75 hover:opacity-100',
          )}
        >
          <div className="border-b border-juno-gray-700 flex items-center gap-2 uppercase text-sm mb-2 mt-1">
            <FontAwesomeIcon icon={faScrewdriverWrench} />
            Attested Crafting
          </div>
          <div>
            Use this option for entering transactions that Players accrue during
            the 6 hours of crafting and production prior to the Virtual Event.
          </div>
          <button
            type="button"
            className={clsx(
              'text-left flex items-start gap-4',
              attestCrafting && 'text-shadow',
            )}
            onClick={() => setValue('attestCrafting', !attestCrafting)}
          >
            <FontAwesomeIcon
              icon={attestCrafting ? faCheck : faSquare}
              className="pt-[3px]"
            />
            <div>
              I attest that the transaction I&apos;m about to commit is a
              crafting and production activity as provided by Players&apos; Econ
              Sheet.
              <br />I hereby declare that this is <u>NOT a Module Reward</u>.
            </div>
          </button>
          <Button
            defaultLabel="Commit Transaction"
            state={buttonState({
              isDirty: true,
              isValid: canCommitTransaction && attestCrafting,
              isHighlight: true,
              isFetching: fetching,
            })}
            stateLabel={{ loading: 'Creating Transaction...' }}
            onClick={commitTransaction}
          />
        </div>
        <div
          className={clsx(
            'border border-juno-gray-700 gray-box p-4 grid gap-2 rounded-xs transition-all duration-300',
            !isModuleReward && 'opacity-75 hover:opacity-100',
          )}
        >
          <div className="border-b border-juno-gray-700 flex items-center gap-2 uppercase text-sm mb-2 mt-1">
            <FontAwesomeIcon icon={faSackDollar} />
            Module Reward
          </div>
          <div>
            Use this option for entering rewards, such as loot drops, from a
            Module. Module Rewards are <u>NOT to be carded</u> and are subject
            to resource limitations as outlined in the Virtual Play Guide.
          </div>
          <div className="w-full">
            <Input
              fullWidth
              {...register('transactionSource')}
              placeholder="Enter Module Name"
            />
          </div>
          <Button
            defaultLabel="Commit Transaction"
            state={buttonState({
              isDirty: true,
              isValid: canCommitTransaction && isModuleReward,
              isHighlight: true,
              isFetching: fetching,
            })}
            stateLabel={{ loading: 'Creating Transaction...' }}
            onClick={commitTransaction}
          />
        </div>
      </div>
    </div>
  );
};

export default Execution;
