import { FC } from 'react';
import { ScaleLoader } from 'react-spinners';
import { LoaderHeightWidthRadiusProps } from 'react-spinners/helpers/props';

interface IProps extends LoaderHeightWidthRadiusProps {
  size?: 'medium' | 'small';
}

const Loading: FC<IProps> = ({ size = 'medium', ...props }) => (
  <ScaleLoader
    color="#bbb"
    {...props}
    {...(size === 'small'
      ? { height: '16px', width: '2px', margin: '1px' }
      : {})}
  />
);

export default Loading;
