import snakecaseKeys from 'snakecase-keys';
import { useParams } from '@tanstack/react-router';
import useRootUser from './players/useRootUser';
import useTokenId from './auths/useTokenId';

type TBody = {
  [key: string]: string | number | boolean;
};

const encoder = new TextEncoder();

const useDigest = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { rootUserId } = useRootUser();
  const { tokenId } = useTokenId();

  const computeDigest = async (body: TBody) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');
    const timestamp = String(Number(new Date()));
    const message = {
      ...snakecaseKeys(body),
      organization_slug: organizationSlug,
      timestamp,
      token,
    };

    const encodedMessage = encoder.encode(JSON.stringify(message));
    const digest = await crypto.subtle.digest('SHA-256', encodedMessage);

    const hashArray = Array.from(new Uint8Array(digest));
    const hashHex = hashArray
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
    const urlEncoded = new URLSearchParams();

    urlEncoded.append('dig', hashHex);
    urlEncoded.append('org', String(organizationSlug));
    urlEncoded.append('ti', String(tokenId));
    urlEncoded.append('ts', String(timestamp));
    urlEncoded.append('uid', String(rootUserId));
    urlEncoded.append('body', JSON.stringify(body));

    return urlEncoded.toString();
  };

  return {
    computeDigest,
  };
};

export default useDigest;
