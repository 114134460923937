import { useParams } from '@tanstack/react-router';
import { useContext, useMemo } from 'react';
import RootUserContext from 'src/contexts/RootUser';

const useRootUser = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { rootUser, setRootUser } = useContext(RootUserContext);
  const { isValidated, id } = rootUser;

  const homeBranch = useMemo(
    () => rootUser.organizations.find(x => x.slug === organizationSlug)?.branch,
    [ organizationSlug, rootUser.organizations ],
  );

  return {
    rootUser,
    setRootUser,
    rootUserId: id,
    isValidated,
    homeBranch,
  };
};

export default useRootUser;
