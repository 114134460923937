import { FC } from 'react';
import useItem from 'src/hooks/items/useItem';
import clsx from 'clsx';
import Loading from 'src/components/0100_loading';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearch } from '@tanstack/react-router';
import { IItemKindEnum } from 'src/graphql/types';
import ItemCrafting from './ItemCrafting';
import Procedure from './Procedure';
import usePrintability from '../../hooks/usePrintability';
import Tangible from './Tangible';
import Label from './Label';

const ItemPrintout: FC = () => {
  const { print_label: printLabel } = useSearch({ strict: false });
  const { watch } = useFormContext();
  const { copies, fingerprints } = watch();
  const { eventId, _splat: itemId } = useParams({ strict: false });
  const { item, stale } = useItem({ itemId: Number(itemId) });
  const { isViewable, message } = usePrintability({ item });

  if (!item && stale) {
    return (
      <div className="printout non-printing">
        <Loading size="small" />
      </div>
    );
  }

  if (!isViewable) {
    return <div className="printout non-printing">{message}</div>;
  }

  if (!item) return null;

  return (
    <div>
      <div className="overflow-auto max-w-[calc(100vw-16px)]">
        <div
          className={clsx(
            'printout-container blueprint-font',
            message && 'no-print',
            !eventId && 'no-print',
          )}
        >
          {Array.from({ length: copies }).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="duplicates-control">
              {printLabel ? (
                <div>
                  <Label id={item.id} itemCrafting={item.itemCraftings[0]} />
                </div>
              ) : (
                <div>
                  {item.itemCraftings.length > 0 ? (
                    item.itemCraftings.map(itemCrafting => (
                      <ItemCrafting
                        key={itemCrafting.id}
                        {...item}
                        fingerprint={fingerprints[i]}
                        itemCrafting={itemCrafting}
                        itemReproduction={item.itemReproductions[0]}
                      />
                    ))
                  ) : item.kind === IItemKindEnum.Blueprint ? (
                    <Procedure
                      {...item}
                      fingerprint={fingerprints[i]}
                      itemReproduction={item.itemReproductions[0]}
                    />
                  ) : (
                    <Tangible {...item} />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemPrintout;
