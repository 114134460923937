import { FC } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useParams } from '@tanstack/react-router';
import ByAssignment from './ByAssignment';

const Shifts: FC = () => {
  const { eventId } = useParams({ strict: false });
  const { isPermitted: canAccessShiftPage } = usePermission({
    action: 'assists_event',
    eventId: Number(eventId),
  });

  if (!canAccessShiftPage) return null;

  return <ByAssignment />;
};

export default Shifts;
