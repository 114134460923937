import { FC } from 'react';
import useEventPrintouts from 'src/hooks/events/useEventPrintouts';
import usePermission from 'src/hooks/permissions/usePermissions';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import { useParams, useSearch } from '@tanstack/react-router';
import Results from './Results';
import Filters from './Filters';

const Printouts: FC = () => {
  const { eventId } = useParams({ strict: false });
  const {
    blank_sheet: isBlankSheet,
    print_requested: printRequested,
    print_paid: paid,
    print_local: homeGame,
    print_ticket_ids: ticketIds,
  } = useSearch({ strict: false });

  const { event, printouts, stale } = useEventPrintouts({
    eventId: Number(eventId),
    printRequested,
    paid,
    homeGame,
    ticketIds: ticketIds ? String(ticketIds).split(',').map(Number) : undefined,
  });
  const { isPermitted: canManageEvent } = usePermission({
    action: 'manage_event',
    eventId: Number(eventId),
  });

  if (!canManageEvent) return null;
  if (!event) return null;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      <div className="col-span-1">
        <Filters />
      </div>
      <div className="col-span-1 lg:col-span-2">
        {isBlankSheet ? (
          <div className="printout-container">
            <DystopiaRisingPrintout event={event} />
          </div>
        ) : (
          <Results event={event} printouts={printouts} fetching={stale} />
        )}
      </div>
    </div>
  );
};

export default Printouts;
