import { useMemo } from 'react';
import { LinkComponentProps, useLocation } from '@tanstack/react-router';

const useTargetCharacterSection = () => {
  const { pathname } = useLocation();
  const characterSection =
    pathname.match(/(inventory|journal|printout|xp_audit)/)?.[1] ?? '*';

  const targetCharacterSection: LinkComponentProps<'a'>['to'] = useMemo(() => {
    switch (characterSection) {
      case 'inventory':
        return '/$organizationSlug/players/$playerId/characters/$characterId/inventory';
      case 'journal':
        return '/$organizationSlug/players/$playerId/characters/$characterId/journal';
      case 'printout':
        return '/$organizationSlug/players/$playerId/characters/$characterId/printout';
      case 'xp_audit':
        return '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
      default:
        return '/$organizationSlug/players/$playerId/characters/$characterId/*';
    }
  }, [ characterSection ]);

  return { targetCharacterSection };
};

export default useTargetCharacterSection;
