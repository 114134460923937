import { useSearch } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingXpAudit from 'src/components/0500_xp_audits/dystopia_rising';
import useCharacterXp from 'src/hooks/characters/useCharacterXp';

const XpAudit: FC = () => {
  const { player_id: playerId, character_id: characterId } = useSearch({
    strict: false,
  });

  const characterXp = useCharacterXp({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return <DystopiaRisingXpAudit {...characterXp} />;
};

export default XpAudit;
