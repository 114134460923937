import clsx from 'clsx';
import { FC } from 'react';

interface IProps {
  visibleLevels?: number;
  level?: number;
  category?: string;
  isAberrant?: boolean;
  isPairwise?: boolean;
  count: number;
  maxCount: number;
  className?: string;
}

const color = ({
  isAberrant,
  isPairwise,
  category,
  count,
  maxCount,
}: {
  isAberrant?: boolean;
  isPairwise?: boolean;
  category?: string;
  count: number;
  maxCount: number;
}) => {
  const bgOpacity = Math.min((count * 125) / maxCount, 99).toFixed(0);

  if (count === 0) return { opacity: 0 };
  if (isPairwise)
    return {
      backgroundColor: `#b60a6f${bgOpacity}`,
    };
  if (isAberrant)
    return {
      backgroundColor: `#3391a6${bgOpacity}`,
    };
  if (category === 'default')
    return {
      backgroundColor: `#629134${bgOpacity}`,
    };
  if (category === 'development')
    return {
      backgroundColor: `#7a3b91${bgOpacity}`,
    };

  return {
    backgroundColor: `#c66d3a${bgOpacity}`,
  };
};

const TableCell: FC<IProps> = ({
  visibleLevels = 1,
  level = 0,
  isAberrant,
  isPairwise,
  category,
  count,
  maxCount,
  className,
}) => (
  <td
    className={clsx(
      'p-1 text-right border',
      visibleLevels > level
        ? 'border-juno-gray-700'
        : 'border-transparent border-y-juno-gray-700',
      className,
    )}
    style={color({
      isAberrant,
      isPairwise,
      category,
      count,
      maxCount,
    })}
  >
    {count}
  </td>
);

export default TableCell;
