import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import Textarea from 'src/components/0100_textarea';
import AugmentedInput from 'src/components/0200_augmented_input';
import { updateFellowship } from 'src/graphql/mutations/fellowships.graphql';
import {
  IUpdateFellowshipMutation,
  IUpdateFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import useOrganizationFellowship from 'src/hooks/events/useOrganizationFellowship';
import { useMutation } from 'urql';
import clsx from 'clsx';
import { IFellowshipKindEnum } from 'src/graphql/types';
import { startCase } from 'lodash';
import { Link, useParams } from '@tanstack/react-router';
import Members from './Members';

interface IProps {
  isEmbedded?: boolean;
  type: IFellowshipKindEnum;
}

const Details: FC<IProps> = ({ isEmbedded, type }) => {
  const { organizationSlug, fellowshipId } = useParams({ strict: false });
  const { fellowship, fetching } = useOrganizationFellowship({
    fellowshipId: Number(fellowshipId),
  });
  const [ updateResult, update ] = useMutation<
    IUpdateFellowshipMutation,
    IUpdateFellowshipMutationVariables
  >(updateFellowship);
  const {
    getValues,
    register,
    reset,
    resetField,
    setError,
    setFocus,
    setValue,
    formState: { dirtyFields, errors },
  } = useForm({
    defaultValues: { name: '', description: '', forRemoval: false },
  });
  const [ updatedFields, setUpdatedFields ] = useState<{
    [key: string]: boolean;
  }>({});

  const handlePartialUpdate = useCallback(() => {
    const dirtyField = Object.keys(dirtyFields)[0] as keyof typeof dirtyFields;
    setUpdatedFields(prev => ({ ...prev, [dirtyField]: false }));
    update({
      fellowshipId: Number(fellowshipId),
      [dirtyField]: getValues(dirtyField),
    }).then(res => {
      if (res.data?.updateFellowship?.error) {
        resetField(dirtyField);
        setError(dirtyField, { message: res.data.updateFellowship.error });
        setFocus(dirtyField);
      }

      if (res.data?.updateFellowship?.fellowship?.id) {
        setUpdatedFields(prev => ({ ...prev, [dirtyField]: true }));
      }
    });
  }, [
    dirtyFields,
    fellowshipId,
    getValues,
    resetField,
    setError,
    setFocus,
    update,
  ]);

  useEffect(() => {
    if (fellowship) {
      reset({
        name: fellowship.name,
        description: fellowship.description ?? '',
        forRemoval: !!fellowship.removedAt,
      });
    }
  }, [ fellowship, reset ]);

  useEffect(() => {
    setUpdatedFields({});
  }, [ fellowshipId ]);

  if (fetching) return <Loading />;

  return (
    <div
      className={clsx(
        'grid gap-4 px-2',
        isEmbedded
          ? 'border-l-4 border-juno-gray-500'
          : 'max-h-[calc(100vh-172px)] overflow-auto',
      )}
    >
      <div className="glass-pane flex justify-between items-center text-lg">
        <div className="py-1">Edit {startCase(type)}</div>
        <Link
          to={
            type === IFellowshipKindEnum.Disease
              ? '/$organizationSlug/mechanics/diseases'
              : '/$organizationSlug/mechanics/societies'
          }
          params={{
            organizationSlug: String(organizationSlug),
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Link>
      </div>
      <AugmentedInput
        displayStacked
        required
        fullWidth
        title="Name"
        isBusy={updateResult.fetching && dirtyFields.name}
        isUpdated={updatedFields.name && !dirtyFields.name}
        error={errors.name?.message?.toString()}
        {...register('name', { onBlur: handlePartialUpdate })}
        onEnter={handlePartialUpdate}
      />

      <AugmentedInput
        displayStacked
        title="Description"
        isBusy={updateResult.fetching && dirtyFields.description}
        isUpdated={updatedFields.description && !dirtyFields.description}
        error={errors.description?.message?.toString()}
      >
        <div className="w-full">
          <Textarea
            width="w-full"
            {...register('description')}
            onBlur={x => {
              setValue('description', x.target.value, { shouldDirty: true });
              handlePartialUpdate();
            }}
          />
        </div>
      </AugmentedInput>

      <div>
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <div>Status</div>
            {updateResult.fetching && dirtyFields.forRemoval && (
              <Loading size="small" />
            )}
            {updatedFields.forRemoval && !dirtyFields.forRemoval && (
              <FontAwesomeIcon icon={faCheck} />
            )}
          </div>
          <button
            type="button"
            className="underline"
            onClick={() => {
              setValue('forRemoval', !getValues('forRemoval'), {
                shouldDirty: true,
              });
              handlePartialUpdate();
            }}
          >
            {fellowship?.removedAt ? 'Inactive' : 'Active'}
          </button>
        </div>
        {errors.forRemoval?.message && (
          <ResponseBox type="error">{errors.forRemoval.message}</ResponseBox>
        )}
      </div>
      {fellowship && <Members fellowship={fellowship} type={type} />}
    </div>
  );
};

export default Details;
