import clsx from 'clsx';
import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronUp,
  faGlobeAmericas,
} from '@fortawesome/free-solid-svg-icons';
import { IEventKindEnum } from 'src/graphql/types';
import { Link, useLinkProps, useParams } from '@tanstack/react-router';
import CalendarSquare from '../0100_calendar_square';

interface IProps {
  id: number;
  name: string;
  description?: string | null;
  startsAt: Date;
  endsAt: Date;
  kind: IEventKindEnum;
  location: {
    name: string;
    streetAddress: string;
    city: string;
    region: string;
    zipCode: string;
  };
  config: {
    baseBuild: number;
    maxExtraXp: number;
    considersTravelersLocal: boolean;
  };
}

const EventCard: FC<IProps> = ({ ...event }) => {
  const { organizationSlug } = useParams({ strict: false });
  const [ isIdClicked, setIsIdClicked ] = useState(false);
  const linkProps = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: String(event.id),
    },
  });

  const handleIdClick = useCallback((id: number) => {
    navigator.clipboard.writeText(String(id));
    setIsIdClicked(true);
    setTimeout(() => setIsIdClicked(false), 2000);
  }, []);

  return (
    <Link
      to={linkProps.href}
      className={clsx(
        'border border-juno-gray-700 rounded-xs p-4 gray-box',
        'grid gap-2',
        'hover:brightness-125 transition-all duration-300',
      )}
    >
      <div className="flex justify-between items-start gap-4">
        <div>
          {event.kind !== IEventKindEnum.Live && (
            <div className="text-juno-orange-400">Legacy DR 3.0 Game</div>
          )}
          <div className="flex gap-2 items-center">
            <div className="text-xl">{event.name}</div>
            <button
              type="button"
              className="text-sm pt-[2px] under opacity-75"
              onClick={e => {
                e.preventDefault();
                handleIdClick(event.id);
              }}
            >
              {isIdClicked ? (
                <div className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faCheck} />
                  Copied
                </div>
              ) : (
                <span className="underline">#{event.id}</span>
              )}
            </button>

            {event.config.baseBuild > 0 && (
              <div className="bg-juno-green-200 text-juno-gray-50 rounded-full px-2 text-sm">{`+${event.config.baseBuild}XP`}</div>
            )}
            {event.config.maxExtraXp > 2 && (
              <div className="bg-juno-gray-50 text-juno-gray-900 rounded-full px-2 text-sm flex items-center">
                <FontAwesomeIcon icon={faChevronUp} />
                {`${event.config.maxExtraXp}XP`}
              </div>
            )}
            {event.config.considersTravelersLocal && (
              <div className="bg-juno-purple-200 border border-juno-purple-400 rounded-full px-2 flex items-center gap-1">
                <FontAwesomeIcon icon={faGlobeAmericas} />
                <div className="text-sm">Network-wide</div>
              </div>
            )}
          </div>
          <div className="opacity-75">
            <div>
              {`${format(event.startsAt, 'MMM d')} - 
            ${event.location.name}`}
            </div>

            {event.location.region !== 'VIRTUAL' && (
              <div>
                {[
                  event.location.streetAddress,
                  event.location.city,
                  event.location.region,
                  event.location.zipCode,
                ].join(', ')}
              </div>
            )}
          </div>
        </div>
        <div>
          <CalendarSquare startsAt={event.startsAt} endsAt={event.endsAt} />
        </div>
      </div>
      {event.description && (
        <div className="border-t border-juno-gray-700 pt-2 text-juno-gray-200 text-sm">
          {event.description}
        </div>
      )}
    </Link>
  );
};

export default EventCard;
