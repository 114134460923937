import { FC } from 'react';
import { TTransactableItem } from '../LineItem';
import Requirement from './Requirement';
import { TInventory } from '../Inventories';
import { TSelectedInventory } from './Requirement/Boolean';

interface IProps {
  requirements: TTransactableItem[];
  inventories: TInventory[];
  selectedCraftingComponents: TSelectedInventory[];
  onUpdateSelectedCraftingComponent?: (props: TSelectedInventory) => void;
}

const Requirements: FC<IProps> = ({
  requirements,
  inventories,
  selectedCraftingComponents,
  onUpdateSelectedCraftingComponent,
}) => (
  <div className="grid gap-3">
    {requirements.map(req => (
      <Requirement
        key={req.itemId}
        requirement={req}
        inventories={inventories.filter(inv => {
          if (req.options && req.options.length > 0) {
            return req.options.some(
              opt => opt.itemId === inv.itemId && inv.stack > 0,
            );
          }
          return inv.itemId === req.itemId && inv.stack > 0;
        })}
        selectedCraftingComponents={selectedCraftingComponents.filter(inv => {
          if (req.options && req.options.length > 0) {
            return req.options.some(opt => opt.itemId === inv.itemId);
          }

          return inv.itemId === req.itemId;
        })}
        onUpdateSelectedCraftingComponent={onUpdateSelectedCraftingComponent}
      />
    ))}
  </div>
);

export default Requirements;
