import { createFileRoute } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingBuild from 'src/components/0500_character_builders/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Builder: FC = () => {
  const character = useCharacter({ playerId: 0, characterId: 0 });

  return <DystopiaRisingBuild {...character} playerId={0} />;
};

export const Route = createFileRoute('/$organizationSlug/characters/')({
  component: Builder,
});
