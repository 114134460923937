import clsx from 'clsx';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

const rows = 5;
const penalties = [ '-5M', '-15M', '-30M', '-50M', 'Fatal' ];

const Fractures: FC = () => {
  const { watch } = useFormContext();
  const { fractures } = watch();

  return (
    <table>
      <thead>
        <tr>
          <th>Fractures {` (${fractures.length}/5)`}</th>
        </tr>
      </thead>
      <tbody>
        {[ ...Array(rows) ].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td className={clsx(i >= fractures.length && 'text-transparent')}>
              <div className="w-full flex justify-between items-center gap-2">
                <div className="whitespace-nowrap overflow-hidden">
                  {fractures[i] ?? '_'}
                </div>
                <div className="text-xs">{penalties[i]}</div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Fractures;
