import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import Loading from 'src/components/0100_loading';
import ItemCrafting, { TItemCrafting } from './ItemCrafting';
import { TViewPackKeys } from '../utils/viewPacks';
import Benediction from './Benediction';
import ItemReproduction, { TItemReproduction } from './ItemReproduction';
import Mechanics, { TItemCraftingOutputMechanics } from './Mechanics';
import UsageRequirements, {
  TItemCraftingOutputUsageRequirement,
} from './UsageRequirements';
import ItemCraftingFinalProducts, {
  TItemCraftingOutput,
} from './ItemCraftingFinalProducts';
import SingleItemEditor from './SingleItemEditor';
import { TMetadata } from '../types';

interface IProps {
  id: number;
  name: string;
  metadata: TMetadata;
  itemCraftings: (
    | TItemCrafting
    | TItemCraftingOutput
    | TItemCraftingOutputMechanics
    | TItemCraftingOutputUsageRequirement
  )[];
  itemReproductions: TItemReproduction[];
  activeCategories: TViewPackKeys[];
  style: {
    height: string;
    transform: string;
  };
  onExpand: () => void;
}

const TableRow: FC<IProps> = ({ ...props }) => {
  const [ updateState, setUpdateState ] = useState<
    null | 'success' | 'error' | 'busy'
  >(null);

  useEffect(() => {
    if (updateState) {
      setTimeout(() => setUpdateState(null), 3000);
    }
  }, [ updateState ]);

  return (
    <tr
      className="group text-juno-gray-200 hover:text-juno-gray-50 transition-all"
      style={props.style}
    >
      <td className="sticky left-0 min-w-[160px] sm:min-w-[224px] gray-box z-8">
        <SingleItemEditor
          itemId={props.id}
          field="name"
          initialValue={props.name}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      </td>

      {props.activeCategories.includes('benediction') && (
        <Benediction
          id={props.id}
          metadata={props.metadata}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      {props.activeCategories.includes('itemCraftingFinalProducts') && (
        <ItemCraftingFinalProducts
          itemCraftings={props.itemCraftings}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      {props.activeCategories.includes('requirementsToUse') && (
        <UsageRequirements
          itemCraftings={props.itemCraftings}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      {props.activeCategories.includes('itemCraftings') && (
        <ItemCrafting
          id={props.id}
          itemCraftings={props.itemCraftings}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      {props.activeCategories.includes('mechanics') && (
        <Mechanics
          id={props.id}
          baseMechanics={props.metadata.mechanics}
          itemCraftings={props.itemCraftings}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      {props.activeCategories.includes('notes') && (
        <td>
          <SingleItemEditor
            itemId={props.id}
            field="notes"
            initialValue={props.metadata.notes}
            onUpdate={({ status }) => setUpdateState(status)}
          />
        </td>
      )}

      {props.activeCategories.includes('itemReproductions') && (
        <ItemReproduction
          id={props.id}
          itemReproductions={props.itemReproductions}
          onUpdate={({ status }) => setUpdateState(status)}
        />
      )}

      <td className="text-juno-gray-700 group-hover:text-juno-gray-50 transition-all cursor-pointer p-1 w-8 min-w-[32px] text-center">
        {!updateState && (
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            onClick={props.onExpand}
          />
        )}
        {updateState === 'busy' && <Loading size="small" />}
        {updateState === 'success' && (
          <FontAwesomeIcon icon={faCheck} onClick={props.onExpand} />
        )}
        {updateState === 'error' && (
          <FontAwesomeIcon icon={faTimes} onClick={props.onExpand} />
        )}
      </td>
    </tr>
  );
};

export default TableRow;
