import { FC, useState } from 'react';
import useCharacter from 'src/hooks/characters/useCharacter';
import Title from 'src/components/0100_title';
import { isBefore, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Collapsible from 'src/components/0100_collapsible';
import Fracture from './Fracture';

interface IProps extends ReturnType<typeof useCharacter> {}

const DystopiaRisingFractures: FC<IProps> = ({
  character,
  canEditSensitiveData,
}) => {
  const [ isInactiveExpanded, setIsInactiveExpanded ] = useState(false);

  if (!character) return null;

  const activeFractures = character.fractures.filter(x => !x.removedAt);
  const inactiveFractures = character.fractures.filter(x => x.removedAt);

  return (
    <div>
      <Title variant="heading">
        <div className="flex items-end justify-between">
          <div className="text-2xl">Fractures</div>
          <div className="text-sm">{activeFractures.length} Active</div>
        </div>
      </Title>
      <div className="grid gap-2">
        <Fracture
          characterId={Number(character.id)}
          canEdit={canEditSensitiveData}
        />
        {activeFractures
          ?.sort((a, b) =>
            isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
          )
          .map(x => (
            <Fracture
              key={x.id}
              canEdit={canEditSensitiveData}
              characterId={Number(character.id)}
              fracture={x}
            />
          ))}
        {inactiveFractures.length > 0 && (
          <div>
            <Title variant="heading">
              <button
                type="button"
                className="w-full flex justify-between items-center"
                onClick={() => setIsInactiveExpanded(x => !x)}
              >
                <div>Inactive Fractures</div>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={clsx(
                    'transition-all',
                    isInactiveExpanded && '-scale-y-100',
                  )}
                />
              </button>
            </Title>
            <Collapsible isExpanded={isInactiveExpanded}>
              {inactiveFractures
                ?.sort((a, b) =>
                  isBefore(parseISO(a.createdAt), parseISO(b.createdAt))
                    ? 1
                    : -1,
                )
                .map(x => (
                  <Fracture
                    key={x.id}
                    canEdit={canEditSensitiveData}
                    characterId={Number(character.id)}
                    fracture={x}
                  />
                ))}
            </Collapsible>
          </div>
        )}
      </div>
    </div>
  );
};

export default DystopiaRisingFractures;
