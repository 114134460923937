import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';

const EventsHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, eventId } = useParams({ strict: false });
  const { isPermitted: canCreateEvent } = usePermission({
    action: 'create_event_ui',
  });
  const linkToCreateNewEvent = useLinkProps({
    to: '/$organizationSlug/events/$eventId',
    params: { organizationSlug: String(organizationSlug), eventId: 'new' },
  });
  const isEventsSection = pathname.includes('events');

  if (!isEventsSection) return null;
  if (!canCreateEvent) return null;
  if (eventId) return null;

  return (
    <ResponsiveTabGroup label="Actions">
      {canCreateEvent && (
        <Tab
          label={
            <div>
              <FontAwesomeIcon icon={faPlus} className="pr-2" />
              Create New Event
            </div>
          }
          to={linkToCreateNewEvent.href}
          highlightMode="responsive"
        />
      )}
    </ResponsiveTabGroup>
  );
};

export default EventsHeader;
