import { getPermission } from 'src/graphql/mutations/permissions.graphql';
import {
  IGetPermissionQuery,
  IGetPermissionQueryVariables,
} from 'src/graphql/mutations/permissions.graphql.types';
import { useQuery } from 'urql';
import useAuth from '../auths/useAuth';
import useRootUserRevalidation from '../useRootUserRevalidation';

interface IProps {
  action: string;
  branchId?: number;
  eventId?: number;
  playerId?: number;
  characterId?: number;
}

const usePermission = ({
  action,
  branchId,
  eventId,
  playerId,
  characterId,
}: IProps) => {
  const { isRootUserValidated } = useAuth();
  const [{ data, error, fetching }, refetch ] = useQuery<
    IGetPermissionQuery,
    IGetPermissionQueryVariables
  >({
    query: getPermission,
    variables: {
      action,
      branchId,
      eventId,
      playerId,
      characterId,
    },
    requestPolicy: 'network-only',
    pause: true,
  });

  useRootUserRevalidation({ refetch });

  return {
    isPermitted: isRootUserValidated ? data?.permission.permitted : false,
    error,
    fetching,
    refetch,
  };
};

export default usePermission;
