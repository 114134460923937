import { IItemKindEnum } from 'src/graphql/types';
import useEvent from 'src/hooks/events/useEvent';

interface IProps {
  item?: {
    id: number;
    kind: IItemKindEnum;
    metadata: {
      printHeader?: string | null;
    };
  } | null;
}

const usePrintability = ({ item }: IProps) => {
  const { event } = useEvent();

  if (!event?.id) {
    return {
      isViewable: true,
      message: 'Please use Event Blueprints to print this Blueprint.',
    };
  }
  if (!item)
    return {
      isViewable: false,
      message: 'This Item is not printable.',
    };
  if (item.kind !== IItemKindEnum.Blueprint)
    return {
      isViewable: false,
      message: 'This Item is not a Blueprint.',
    };
  if (item.metadata.printHeader?.match(/crafting zone blueprint/i))
    return {
      isViewable: true,
      message: `${item.metadata.printHeader} is not printable.`,
    };

  return { isViewable: true, message: null };
};

export default usePrintability;
