import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC } from 'react';
import { IItemKindEnum, IItemVisibilityEnum } from 'src/graphql/types';
import { Link, useParams, useSearch } from '@tanstack/react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ItemView from '../../ItemView';

interface IProps {
  items: {
    id: number;
    name: string;
    kind: IItemKindEnum;
    visibility: IItemVisibilityEnum;
    metadata: {
      printHeader?: string | null;
      craftableItems?: { kind: string }[] | null;
    };
  }[];
  printoutOnly?: boolean;
}

const ItemList: FC<IProps> = ({ items, printoutOnly }) => {
  const {
    organizationSlug,
    eventId,
    _splat: itemId,
  } = useParams({ strict: false });
  const { print: isPrintout, print_label: isPrintLabel } = useSearch({
    strict: false,
  });

  return (
    <div>
      {items
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(x => (
          <div key={x.id} id={`item-${x.id}`}>
            <Link
              to={
                eventId
                  ? '/$organizationSlug/events/$eventId/blueprints/$'
                  : '/$organizationSlug/blueprints/$'
              }
              params={{
                organizationSlug: String(organizationSlug),
                eventId: String(eventId),
                _splat: String(x.id),
              }}
              search={{
                print: isPrintout ? true : undefined,
                print_label: isPrintLabel ? true : undefined,
              }}
              className={clsx(
                'border-l-4 transition-all px-2 py-1 duration-300 flex justify-between items-start w-full',
                Number(itemId) === x.id
                  ? 'border-juno-gray-50 hover:border-juno-gray-50 text-juno-gray-50 text-shadow midtone-box'
                  : 'border-transparent hover:border-juno-gray-700 text-juno-gray-200 hover:text-juno-gray-50',
              )}
            >
              <div
                className={clsx(
                  'flex items-center gap-2',
                  x.kind === IItemKindEnum.Blueprint &&
                    'text-juno-cyan-200 brightness-150 max-w-[67vw]',
                )}
              >
                {x.visibility === IItemVisibilityEnum.Restricted && (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="fa-fw opacity-50 text-juno-gray-200"
                  />
                )}
                {x.name}
              </div>
              <div className="text-sm opacity-75 text-right flex items-center gap-2">
                {(x.metadata.craftableItems?.length ?? 0) > 0 ? (
                  <span>
                    {x.metadata.craftableItems
                      ?.map(ci => startCase(ci.kind))
                      .join(' | ')}
                  </span>
                ) : (
                  startCase(x.metadata.printHeader ?? x.kind)
                )}
              </div>
            </Link>
            {Number(itemId) === x.id && (
              <div className="block lg:hidden pt-2">
                <ItemView printoutOnly={printoutOnly} />
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ItemList;
