import { getOrganizationTimelines } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationTimelinesQuery,
  IGetOrganizationTimelinesQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { useQuery } from 'urql';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useOrganizationTimelines = () => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationTimelinesQuery,
    IGetOrganizationTimelinesQueryVariables
  >({
    query: getOrganizationTimelines,
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    data,
    timelines: data?.organization.timelines ?? [],
  };
};

export default useOrganizationTimelines;
