import { FC } from 'react';
import clsx from 'clsx';
import { TSkill, TSkillStatistic } from '../../../types';
import TableCell from '../../TableCell';

interface IProps {
  skills: TSkill[];
  skillStatistics: TSkillStatistic[];
  levels: number;
  visibleLevels?: number;
  invertAberrantSorting?: boolean;
}

const ProfessionTable: FC<IProps> = ({
  invertAberrantSorting,
  levels,
  visibleLevels = levels,
  skills,
  skillStatistics,
}) => {
  const skillIds = skills.map(x => x.id);
  const maxPopulation = Math.max(
    ...skillStatistics.filter(x => skillIds.includes(x.id)).map(x => x.count),
  );

  return skills
    .sort((a, b) => {
      if ((a.isAberrant && b.isAberrant) || (!a.isAberrant && !b.isAberrant)) {
        return a.name.localeCompare(b.name);
      }

      if (invertAberrantSorting) {
        return a.isAberrant ? -1 : 1;
      }

      return a.isAberrant ? 1 : -1;
    })
    .map(profession => (
      <>
        <tr key={`profession-${profession.id}`}>
          <td
            colSpan={levels + 1}
            className={clsx(
              'border border-juno-gray-700 p-1',
              profession.isAberrant && 'text-juno-cyan-200 brightness-125',
            )}
          >
            {profession.name}
          </td>
          <TableCell
            isAberrant={profession.isAberrant}
            category={profession.category}
            count={
              skillStatistics.find(x => x.id === profession.id)?.count ?? 0
            }
            maxCount={maxPopulation}
            className="border-l-4"
          />
        </tr>
        {profession.skills?.map(professionSkill => (
          <tr key={`profession-skill-${profession.id}-${professionSkill.id}`}>
            <td
              className={clsx(
                'border border-juno-gray-700 p-1 pl-4',
                profession.isAberrant && 'text-juno-cyan-200 brightness-125',
              )}
            >
              {professionSkill.name}
            </td>
            {Array.from({ length: levels }).map((_, i) => (
              <TableCell
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                visibleLevels={visibleLevels}
                level={i}
                isAberrant={profession.isAberrant}
                category={profession.category}
                count={
                  skillStatistics.find(
                    x => x.id === professionSkill.id && x.level === i + 1,
                  )?.count ?? 0
                }
                maxCount={maxPopulation}
              />
            ))}
            <TableCell
              isAberrant={profession.isAberrant}
              category={profession.category}
              count={skillStatistics
                .filter(x => x.id === professionSkill.id)
                .map(x => x.count)
                .reduce((a, b) => a + b, 0)}
              maxCount={maxPopulation}
              className="border-l-4"
            />
          </tr>
        ))}
      </>
    ));
};

export default ProfessionTable;
