import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingBuild from 'src/components/0500_character_builders/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';

const Builder: FC = () => {
  const { playerId, characterId } = useParams({ strict: false });
  const character = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return <DystopiaRisingBuild {...character} playerId={Number(playerId)} />;
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId/*',
)({
  component: Builder,
});
