import { Link, useLinkProps, useParams } from '@tanstack/react-router';
import { FC } from 'react';

interface IProps {
  id: number;
  name: string;
}

const Player: FC<IProps> = ({ id, name }) => {
  const { organizationSlug } = useParams({ strict: false });
  const linkToPlayer = useLinkProps({
    to: '/$organizationSlug/players/$playerId/*',
    params: {
      organizationSlug: String(organizationSlug),
      playerId: String(id),
    },
  });

  return (
    <Link
      to={linkToPlayer.href}
      className="min-w-[128px] flex items-center gap-2 w-full shrink hover:text-shadow"
    >
      <div className="min-w-[48px] text-right underline">{id}</div>
      <div className="whitespace-nowrap overflow-hidden">
        <div>{name}</div>
      </div>
    </Link>
  );
};

export default Player;
