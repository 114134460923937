import { useCallback, useEffect, useMemo, useState } from 'react';
import { getItem } from 'src/graphql/queries/items.graphql';
import { IGetItemQuery } from 'src/graphql/queries/items.graphql.types';
import { useClient } from 'urql';
import useOrganizationArmorUpgradeComponents from 'src/hooks/organizations/useOrganizationArmorUpgradeComponents';
import { TTransactableItem } from '../LineItem';
import { TSelectedInventory } from '../Requirements/Requirement/Boolean';

const useItemCrafting = () => {
  const client = useClient();
  const { components: upgradeMaterials } =
    useOrganizationArmorUpgradeComponents();
  const [ blueprintId, setBlueprintId ] = useState<number | null>(null);
  const [ craftingStack, setCraftingStack ] = useState(1);
  const [ finalProducts, setFinalProducts ] = useState<TTransactableItem[]>([]);
  const [ components, setComponents ] = useState<TTransactableItem[]>([]);
  const [ selectedCraftingComponents, setSelectedCraftingComponents ] = useState<
    TSelectedInventory[]
  >([]);
  const updateSelectedCrafingComponent = useCallback(
    ({ itemId, registeredItemId, expiresAt, stack }: TSelectedInventory) => {
      const matching = selectedCraftingComponents.find(
        x =>
          x.itemId === itemId &&
          x.registeredItemId === registeredItemId &&
          x.expiresAt === expiresAt,
      );

      if (!matching) {
        setSelectedCraftingComponents(x => [
          ...x,
          { itemId, registeredItemId, expiresAt, stack },
        ]);
      } else {
        setSelectedCraftingComponents(x =>
          x.filter(y => y !== matching).concat({ ...matching, stack }),
        );
      }
    },
    [ selectedCraftingComponents ],
  );

  const armorUpgradePoint = useMemo(
    () => Math.max(...finalProducts.map(x => x.armorUpgradePoint ?? 0)),
    [ finalProducts ],
  );

  const updateCraftingOutput = useCallback(
    ({
      nonce,
      stack,
      description,
      expiresAt,
      armorUpgradePoint,
      selectedOptionItemId,
    }: {
      nonce: string;
      stack?: number;
      description?: string | null;
      expiresAt?: string | null;
      armorUpgradePoint?: number;
      selectedOptionItemId?: number | null;
    }) => {
      if (stack) {
        setCraftingStack(stack);
      }

      setFinalProducts(prevState => {
        const matching = prevState.find(x => x.nonce === nonce);

        if (matching) {
          return prevState
            .filter(y => y.nonce !== nonce)
            .concat({
              ...matching,
              description:
                description === null
                  ? undefined
                  : (description ?? matching.description),
              expiresAt:
                expiresAt === null
                  ? undefined
                  : (expiresAt ?? matching.expiresAt),
              selectedOptionItemId:
                selectedOptionItemId === null
                  ? undefined
                  : (selectedOptionItemId ?? matching.selectedOptionItemId),
              armorUpgradePoint:
                armorUpgradePoint ?? matching.armorUpgradePoint,
            });
        }

        return prevState;
      });
    },
    [],
  );

  const craftingRequirementsSatisfied = useMemo(
    () =>
      !components
        .map(req => ({
          itemId: req.itemId,
          stack: req.stack,
          provided:
            req.options && req.options.length > 0
              ? selectedCraftingComponents
                  .filter(inv =>
                    req.options?.some(x => x.itemId === inv.itemId),
                  )
                  .map(x => x.stack)
                  .reduce((a, b) => a + b, 0)
              : selectedCraftingComponents
                  .filter(x => x.itemId === req.itemId)
                  .map(x => x.stack)
                  .reduce((a, b) => a + b, 0),
        }))
        .some(x => x.stack !== x.provided),
    [ components, selectedCraftingComponents ],
  );

  const syncBlueprintData = useCallback(
    (blueprintId: number | null, craftingStack: number) => {
      if (!blueprintId) {
        setFinalProducts([]);
        setComponents([]);
        return;
      }

      const normalizedStack = Math.max(craftingStack, 1);
      client
        .query(getItem, { itemId: blueprintId })
        .toPromise()
        .then(d => {
          const data = d.data as IGetItemQuery;
          const itemCrafting = data.organization.item?.itemCraftings[0];

          if (itemCrafting) {
            setFinalProducts(prevState =>
              itemCrafting.craftingFinalProducts.map(output => {
                const nonce = `${[ 'crafting-output', output.finalProduct.id ].join('-')}`;
                const matching = prevState.find(x => x.nonce === nonce);

                const payload = {
                  itemId: output.finalProduct.id,
                  itemKind: output.finalProduct.kind,
                  itemName: output.finalProduct.name,
                  itemLifetimeAmount:
                    output.finalProduct.lifetimeAmount ?? undefined,
                  itemLifetimeUnit: output.finalProduct.lifetimeUnit,
                  stack: craftingStack,
                  craftingStack: normalizedStack * output.stack,
                  direction: 'toCharacter',
                  nonce: `${[ 'crafting-output', output.finalProduct.id ].join('-')}`,
                  isCrafting: true,
                  options: (
                    output.finalProduct.childItemClassifications ?? []
                  ).map(x => ({
                    itemId: x.childItem.id,
                    itemKind: x.childItem.kind,
                    itemName: x.childItem.name,
                    nonce: `${[ 'crafting-output', x.childItem.id ].join('-')}`,
                    craftingStack: normalizedStack * output.stack,
                  })),
                };

                if (matching) {
                  return { ...matching, ...payload };
                }

                return payload;
              }),
            );

            setComponents(
              itemCrafting.craftingComponents
                .map(input => ({
                  itemId: input.component.id,
                  itemKind: input.component.kind,
                  itemName: input.component.name,
                  stack: normalizedStack * input.amount,
                  direction: 'toBranch',
                  nonce: `${[ 'crafting-input', input.component.id ].join('-')}`,
                  isCrafting: true,
                  options: (input.component.childItemClassifications ?? []).map(
                    x => ({
                      itemId: x.childItem.id,
                      itemKind: x.childItem.kind,
                      itemName: x.childItem.name,
                      nonce: `${[ 'crafting-input', x.childItem.id ].join('-')}`,
                    }),
                  ),
                }))
                .concat(
                  armorUpgradePoint > 0
                    ? upgradeMaterials.map(u => ({
                        itemId: u.component.id,
                        itemKind: u.component.kind,
                        itemName: u.component.name,
                        stack: u.amount * armorUpgradePoint,
                        direction: 'toBranch',
                        nonce: `upgrade-materials-${u.component.id}`,
                        isCrafting: true,
                        options: [],
                      }))
                    : [],
                ),
            );
          }
        });
    },
    [ armorUpgradePoint, client, upgradeMaterials ],
  );
  const resetCrafting = useCallback(() => {
    setBlueprintId(null);
    setSelectedCraftingComponents([]);
    setComponents([]);
    setCraftingStack(1);
    setFinalProducts([]);
  }, []);

  useEffect(() => {
    setSelectedCraftingComponents([]);
  }, [ blueprintId ]);

  useEffect(() => {
    syncBlueprintData(blueprintId, craftingStack);
  }, [ blueprintId, craftingStack, syncBlueprintData ]);

  return {
    setBlueprintId,
    setCraftingStack,
    craftingFinalProducts: finalProducts,
    craftingComponents: components,
    updateSelectedCrafingComponent,
    updateCraftingOutput,
    selectedCraftingComponents,
    craftingRequirementsSatisfied,
    resetCrafting,
  };
};

export default useItemCrafting;
