import clsx from 'clsx';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Textarea from 'src/components/0100_textarea';
import AugmentedInput from 'src/components/0200_augmented_input';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import {
  createItemReproductionComponent,
  destroyItemReproduction,
  updateItemReproduction,
} from 'src/graphql/mutations/items.graphql';
import {
  ICreateItemReproductionComponentMutation,
  ICreateItemReproductionComponentMutationVariables,
  IDestroyItemReproductionMutation,
  IDestroyItemReproductionMutationVariables,
  IUpdateItemReproductionMutation,
  IUpdateItemReproductionMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemKindEnum } from 'src/graphql/types';
import { useMutation } from 'urql';
import ItemSearch from 'src/components/0400_item_search';
import ResponseBox from 'src/components/0100_response_box';
import ReproductionComponent from './ReproductionComponent';
import useItemEditPermission from '../../../hooks/useItemEditPermission';

interface IProps {
  id: number;
  reproductionTimeInMinute?: number | null;
  reproductionMindCost?: number | null;
  reproductionSkills?: string | null;
  metadata: {
    notes?: string | null;
  };
  itemReproductionComponents?: {
    id: number;
    amount: number;
    item: {
      id: number;
      name: string;
      kind: IItemKindEnum;
    };
  }[];
}

const ItemReproduction: FC<IProps> = ({ ...props }) => {
  const { canEdit } = useItemEditPermission();
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ addComponentError, setAddComponentError ] = useState<string | null>(
    null,
  );
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      reproductionTimeInMinute: props.reproductionTimeInMinute,
      reproductionMindCost: props.reproductionMindCost,
      reproductionSkills: props.reproductionSkills,
      notes: props.metadata.notes,
    },
  });

  const {
    reproductionTimeInMinute,
    reproductionMindCost,
    reproductionSkills,
    notes,
  } = watch();

  const [ , destroy ] = useMutation<
    IDestroyItemReproductionMutation,
    IDestroyItemReproductionMutationVariables
  >(destroyItemReproduction);
  const [ , update ] = useMutation<
    IUpdateItemReproductionMutation,
    IUpdateItemReproductionMutationVariables
  >(updateItemReproduction);
  const [ , addComponent ] = useMutation<
    ICreateItemReproductionComponentMutation,
    ICreateItemReproductionComponentMutationVariables
  >(createItemReproductionComponent);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;
    update({
      itemReproductionId: props.id,
      reproductionTimeInMinute: Number(reproductionTimeInMinute),
      reproductionMindCost: Number(reproductionMindCost),
      reproductionSkills,
      notes,
    }).then(res => {
      if (res.data?.updateItemReproduction?.itemReproduction) {
        const {
          reproductionTimeInMinute,
          reproductionMindCost,
          reproductionSkills,
          metadata: { notes },
        } = res.data.updateItemReproduction.itemReproduction;
        setIsSuccessful(true);
        setTimeout(() => setIsSuccessful(false), 3000);
        reset({
          reproductionTimeInMinute,
          reproductionMindCost,
          reproductionSkills,
          notes,
        });
      }
    });
  }, [
    isDirty,
    props.id,
    reproductionTimeInMinute,
    reproductionMindCost,
    reproductionSkills,
    notes,
    reset,
    update,
  ]);

  const handleDestroy = useCallback(() => {
    if (!props.id) return;
    destroy({ itemReproductionId: props.id });
    setIsDeleting(true);
  }, [ destroy, props.id ]);

  if (isDeleting) return null;

  return (
    <div>
      <div className="flex justify-between items-center border-l-4 border-b border-juno-gray-700 midtone-box p-2">
        <div>Print Reproduction</div>
        {canEdit && <ConfirmDropdown onConfirm={handleDestroy} />}
      </div>

      <div>
        <div
          className={clsx(
            'border-l-4 border-b border-juno-gray-700 transition-all duration-500',
            isSuccessful && 'border-l-juno-green-400',
          )}
        >
          <AugmentedInput
            isLocked={!canEdit}
            title="Time (min)"
            {...register('reproductionTimeInMinute', { onBlur: handleUpdate })}
          />
          <AugmentedInput
            isLocked={!canEdit}
            title="Mind Cost"
            {...register('reproductionMindCost', { onBlur: handleUpdate })}
          />
          <AugmentedInput
            isLocked={!canEdit}
            title="Skills"
            {...register('reproductionSkills', { onBlur: handleUpdate })}
          />
          <div className="p-2">
            <AugmentedInput displayStacked title="Notes">
              <div className="w-full">
                <Textarea
                  height="h-16"
                  width="w-full"
                  disabled={!canEdit}
                  {...register('notes', { onBlur: handleUpdate })}
                />
              </div>
            </AugmentedInput>
          </div>
        </div>

        <div className="border-b border-juno-gray-700">
          <div className="border-l-4 border-l-juno-gray-700 glass-pane text-center p-2 border-b border-juno-cyan-400">
            Reproduction Components
          </div>
          {props.itemReproductionComponents
            ?.sort((a, b) => a.item.name.localeCompare(b.item.name))
            .map(x => <ReproductionComponent key={x.id} {...x} />)}
          {canEdit && (
            <div className="flex items-center gap-4 pl-2 py-2 border-l-4 border-juno-gray-700">
              <div className="whitespace-nowrap w-24">Add Input</div>
              <div className="w-full">
                <ItemSearch
                  kind={IItemKindEnum.CraftingInput}
                  onSearchResultClick={({ itemId }) => {
                    setAddComponentError(null);
                    addComponent({
                      itemReproductionId: Number(props.id),
                      componentId: itemId,
                    }).then(res => {
                      if (res.data?.createItemReproductionComponent?.error) {
                        setAddComponentError(
                          res.data.createItemReproductionComponent.error,
                        );
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          {addComponentError && (
            <ResponseBox type="error">{addComponentError}</ResponseBox>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemReproduction;
