import { FC, useMemo } from 'react';
import Title from 'src/components/0100_title';
import useOrganizationFellowships from 'src/hooks/events/useOrganizationFellowships';
import Input from 'src/components/0100_input';
import { useForm } from 'react-hook-form';
import { startCase } from 'lodash';
import { IFellowshipKindEnum } from 'src/graphql/types';
import { Link, useParams } from '@tanstack/react-router';
import Collapsible from 'src/components/0100_collapsible';
import NewFellowship from './NewFellowship';
import Fellowship from './Fellowship';
import Details from './Details';

interface IProps {
  type: IFellowshipKindEnum;
}

const title: Record<IFellowshipKindEnum, string> = {
  disease: 'Diseases',
  society: 'Societies',
};

const Fellowships: FC<IProps> = ({ type }) => {
  const { organizationSlug, fellowshipId } = useParams({ strict: false });
  const { fellowships } = useOrganizationFellowships({ type });
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const filteredFellowshipIds = useMemo(
    () =>
      query.trim().length === 0
        ? fellowships.map(x => x.id)
        : fellowships
            .filter(x => x.name.toLowerCase().includes(query.toLowerCase()))
            .map(x => x.id),
    [ fellowships, query ],
  );

  return (
    <div>
      <Title title={title[type]} />
      <div className="flex justify-between items-start gap-8">
        <div className="grid w-full">
          <Input
            fullWidth
            placeholder={`Search ${startCase(type)}...`}
            {...register('query')}
          />
          <Collapsible isExpanded={filteredFellowshipIds.length === 0}>
            <div className="opacity-50 p-3">No results found</div>
          </Collapsible>
          {fellowships
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => (
              <Collapsible
                key={x.id}
                isExpanded={filteredFellowshipIds.includes(x.id)}
              >
                <Link
                  to={
                    type === IFellowshipKindEnum.Disease
                      ? '/$organizationSlug/mechanics/diseases/$fellowshipId'
                      : '/$organizationSlug/mechanics/societies/$fellowshipId'
                  }
                  params={{
                    organizationSlug: String(organizationSlug),
                    fellowshipId: String(x.id),
                  }}
                >
                  <Fellowship
                    fellowship={x}
                    isExpanded={
                      fellowshipId ? x.id === Number(fellowshipId) : false
                    }
                    type={type}
                  />
                </Link>
              </Collapsible>
            ))}

          <div>
            <NewFellowship type={type} />
          </div>
        </div>
        <div className="w-full hidden lg:block">
          {fellowshipId && <Details type={type} />}
        </div>
      </div>
    </div>
  );
};

export default Fellowships;
