import { Link, useParams } from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC, useCallback, useState } from 'react';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import { destroyItemClassification } from 'src/graphql/mutations/items.graphql';
import {
  IDestroyItemClassificationMutation,
  IDestroyItemClassificationMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemKindEnum } from 'src/graphql/types';
import { useMutation } from 'urql';

interface IProps {
  id: number;
  childItem: {
    id: number;
    name: string;
    kind: IItemKindEnum;
  };
}

export type TItemClassification = IProps;

const ItemClassification: FC<IProps> = ({
  id,
  childItem: { id: childItemId, name, kind },
}) => {
  const [ isDeleting, setIsDeleting ] = useState(false);
  const { organizationSlug } = useParams({ strict: false });
  const [ , destroy ] = useMutation<
    IDestroyItemClassificationMutation,
    IDestroyItemClassificationMutationVariables
  >(destroyItemClassification);
  const handleDestroy = useCallback(() => {
    setIsDeleting(true);
    destroy({ itemClassificationId: id });
  }, [ destroy, id ]);

  if (isDeleting) return null;

  return (
    <div className="flex justify-between items-center p-2 border-l-4 border-b border-juno-gray-700 hover:border-juno-gray-200 hover:border-b-juno-gray-700 hover:text-shadow transition-all duration-300">
      <div>
        <Link
          to="/$organizationSlug/blueprints/$"
          params={{
            organizationSlug: String(organizationSlug),
            _splat: String(childItemId),
          }}
          className="underline"
        >
          {name}
        </Link>
        <div className="text-sm opacity-75">{startCase(kind)}</div>
      </div>
      <div>
        <ConfirmDropdown
          confirmLabel="Confirm Removal"
          align="right"
          onConfirm={handleDestroy}
        />
      </div>
    </div>
  );
};

export default ItemClassification;
