import { createFileRoute } from '@tanstack/react-router';
import { FC } from 'react';
import Title from 'src/components/0100_title';
import DystopiaRisingSkillsStatistics from 'src/components/statistics/dystopia_rising/SkillsStatistics';
import DystopiaRisingStatsStatistics from 'src/components/statistics/dystopia_rising/StatsStatistics';
import Timelines from 'src/components/statistics/dystopia_rising/Timelines';
import { IStatisticsTypeEnum } from 'src/graphql/types';
import useOrganizationStatistics, {
  TLores,
  TReputations,
  TResidencies,
  TSkills,
  TStats,
} from 'src/hooks/organizations/useOrganizationStatistics';
import usePermission from 'src/hooks/permissions/usePermissions';

const Statistics: FC = () => {
  const { isPermitted: canUseNetworkTools } = usePermission({
    action: 'network_tools',
  });
  const { statistics: statsStatistics, stale: fetchingStats } =
    useOrganizationStatistics({
      type: IStatisticsTypeEnum.Stats,
    });
  const { statistics: loresStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Lores,
  });
  const { statistics: residenciesStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Residencies,
  });
  const { statistics: skillsStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Skills,
  });
  const { statistics: reputationsStatistics } = useOrganizationStatistics({
    type: IStatisticsTypeEnum.Reputations,
  });

  if (!canUseNetworkTools) return null;

  return (
    <div>
      <Title title="Statistics" />
      <Timelines />
      <DystopiaRisingStatsStatistics
        statsStatistics={(statsStatistics as TStats)[0]}
        fetchingStats={fetchingStats}
        loresStatistics={loresStatistics as TLores}
        residenciesStatistics={residenciesStatistics as TResidencies}
        skillsStatistics={skillsStatistics as TSkills}
      />
      <DystopiaRisingSkillsStatistics
        skillsStatistics={skillsStatistics as TSkills}
        reputationsStatistics={reputationsStatistics as TReputations}
      />
    </div>
  );
};

export const Route = createFileRoute('/$organizationSlug/statistics/')({
  component: Statistics,
});
