import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import { LineSvgProps, ResponsiveLine } from '@nivo/line';
import { DefaultRawDatum, PieSvgProps, ResponsivePie } from '@nivo/pie';
import { FC, useCallback } from 'react';
import { faiths } from 'src/components/0400_faith_select';
import skillData from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/data';
import loreData from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/LoreGroup/data';
import theme from '../theme';

const commonLineProps: Omit<LineSvgProps, 'data'> = {
  animate: true,
  enableArea: true,
  enableCrosshair: true,
  enableTouchCrosshair: true,
  enableSlices: 'x',
  margin: { top: 32, right: 32, bottom: 48, left: 64 },
  xScale: { type: 'linear' },
  yScale: { type: 'linear', min: 0 },
  areaBlendMode: 'color-dodge',
  motionConfig: 'slow',
  legends: [
    {
      anchor: 'top-left',
      direction: 'row',
      itemWidth: 80,
      itemHeight: 20,
      translateY: 5,
      translateX: 20,
      symbolShape: 'circle',
    },
  ],
  theme,
};

const commonBarProps: Omit<
  BarSvgProps<BarDatum>,
  'data' | 'height' | 'width'
> = {
  margin: { top: 32, right: 32, bottom: 48, left: 64 },
  indexBy: 'statValue',
  axisLeft: {
    legend: 'Population',
    legendPosition: 'middle',
    legendOffset: -40,
  },
  labelPosition: 'end',
  labelOffset: 10,
  theme,
};

const commonPieProps: Omit<
  PieSvgProps<DefaultRawDatum>,
  'data' | 'height' | 'width'
> = {
  animate: true,
  margin: { top: 16, right: 64, bottom: 16, left: 64 },
  innerRadius: 0.5,
  padAngle: 0.5,
  cornerRadius: 4,
  activeOuterRadiusOffset: 16,
  theme,
  colors: { scheme: 'tableau10' },
  startAngle: -135,
  endAngle: 135,
  arcLabelsSkipAngle: 8,
  arcLinkLabelsSkipAngle: 16,
  arcLinkLabelsStraightLength: 0,
  arcLinkLabelsDiagonalLength: 0,
  arcLinkLabelsOffset: 4,
};

const defs = [
  linearGradientDef(
    'cyan',
    [
      { offset: 0, color: '#298397' },
      { offset: 100, color: '#3391a6' },
    ],
    { gradientTransform: 'rotate(-30 0.5 0.5)' },
  ),
  linearGradientDef(
    'green',
    [
      { offset: 0, color: '#518121' },
      { offset: 100, color: '#629134' },
    ],
    { gradientTransform: 'rotate(-30 0.5 0.5)' },
  ),
  linearGradientDef(
    'orange',
    [
      { offset: 0, color: '#c66d3a' },
      { offset: 100, color: '#d07a49' },
    ],
    { gradientTransform: 'rotate(-30 0.5 0.5)' },
  ),
  linearGradientDef(
    'purple',
    [
      { offset: 0, color: '#7a3b91' },
      { offset: 100, color: '#85479b' },
    ],
    { gradientTransform: 'rotate(-30 0.5 0.5)' },
  ),
  linearGradientDef(
    'pink',
    [
      { offset: 0, color: '#99045b' },
      { offset: 100, color: '#B60A6F' },
    ],
    { gradientTransform: 'rotate(-30 0.5 0.5)' },
  ),
];

interface IProps {
  statsStatistics: {
    body: number[][];
    mind: number[][];
    resolve: number[][];
    currentLife: number[][];
    buildEarned: number[][];
    buildUsed: number[][];
    faithId: number[][];
  };
  fetchingStats: boolean;
  loresStatistics: { id: number; count: number }[];
  residenciesStatistics: { id: number; count: number; shorthand: string }[];
  skillsStatistics: { id: number; count: number }[];
}

const DystopiaRisingStatsStatistics: FC<IProps> = ({
  statsStatistics,
  fetchingStats,
  loresStatistics,
  residenciesStatistics,
  skillsStatistics,
}) => {
  const { body, mind, resolve, currentLife, buildEarned, buildUsed, faithId } =
    statsStatistics ?? {
      body: [],
      mind: [],
      resolve: [],
      currentLife: [],
      buildEarned: [],
      buildUsed: [],
      faithId: [],
    };
  const professions = skillData.filter(x => x.isProfession);
  const color = useCallback((x: (typeof skillData)[0]) => {
    if (x.category === 'default') return 'green';
    if (x.isAberrant) return 'cyan';
    if (x.category === 'development') return 'purple';
    return 'orange';
  }, []);

  return (
    <div>
      <div className="grid md:grid-cols-2">
        <div className="h-64 sm:h-96 -mt-4 sm:-mt-0 relative max-w-[calc(100vw-16px)]">
          <div className="absolute h-full w-full">
            <div className="flex h-full items-center justify-center text-center">
              <div className="text-shadow pl-1 pt-12">
                <div>Players</div>
                <div className="text-2xl">
                  {residenciesStatistics
                    .map(x => x.count)
                    .reduce((a, b) => a + b, 0)}
                </div>
              </div>
            </div>
          </div>
          <ResponsivePie
            {...commonPieProps}
            arcLinkLabelsSkipAngle={4}
            arcLinkLabelsOffset={8}
            data={residenciesStatistics
              .sort((a, b) => b.count - a.count)
              .map(x => ({
                id: x.shorthand,
                value: x.count,
              }))}
          />
        </div>
        <div className="h-64 sm:h-96 -mt-4 sm:-mt-0 relative max-w-[calc(100vw-16px)]">
          <div className="absolute h-full w-full">
            <div className="flex h-full items-center justify-center text-center">
              <div className="text-shadow pl-1 pt-12">
                <div>Characters</div>
                <div className="text-2xl">
                  {faithId.map(x => x[1]).reduce((a, b) => a + b, 0)}
                </div>
              </div>
            </div>
          </div>
          <ResponsivePie
            {...commonPieProps}
            data={faithId
              .sort((a, b) => b[1] - a[1])
              .map(f => ({
                id: faiths.find(x => x.value === f[0])?.label ?? 'Unknown',
                value: f[1],
              }))}
          />
        </div>
      </div>
      <div className="h-64 max-w-[calc(100vw-16px)]">
        <ResponsiveLine
          {...commonLineProps}
          data={
            fetchingStats
              ? []
              : [
                  {
                    id: 'Used',
                    data: buildUsed.map(d => ({ y: d[0], x: d[1] })),
                  },
                  {
                    id: 'Earned',
                    data: buildEarned.map(d => ({ y: d[0], x: d[1] })),
                  },
                ]
          }
          axisLeft={{
            legend: 'XP',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          axisBottom={{
            legend: 'Population',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          colors={x => (x.id === 'Used' ? '#b60a6f' : '#3391a6')}
        />
      </div>
      <div className="grid gap-4 max-w-[calc(100vw-16px)]">
        <div className="h-64 max-w-[calc(100vw-16px)]">
          <ResponsiveLine
            {...commonLineProps}
            data={
              fetchingStats
                ? []
                : [
                    { id: 'Mind', data: mind.map(d => ({ y: d[0], x: d[1] })) },
                    { id: 'Body', data: body.map(d => ({ y: d[0], x: d[1] })) },
                  ]
            }
            axisLeft={{
              legend: 'Normalized Stats',
              legendPosition: 'middle',
              legendOffset: -40,
            }}
            axisBottom={{
              legend: 'Population',
              legendPosition: 'middle',
              legendOffset: 40,
            }}
            colors={x => (x.id === 'Body' ? '#c66d3a' : '#629134')}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="h-64 max-w-[calc(100vw-16px)]">
            <ResponsiveBar
              {...commonBarProps}
              data={resolve
                .sort((a, b) => a[0] - b[0])
                .map(d => ({
                  statValue: d[0],
                  Resolve: d[1],
                }))}
              keys={[ 'Resolve' ]}
              axisBottom={{
                legend: 'Resolve',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              defs={defs}
              fill={[{ match: '*', id: 'cyan' }]}
              borderRadius={4}
            />
          </div>
          <div className="h-64 max-w-[calc(100vw-16px)]">
            <ResponsiveBar
              {...commonBarProps}
              data={currentLife
                .sort((a, b) => a[0] - b[0])
                .map(d => ({
                  statValue: d[0],
                  Infection: d[1],
                }))}
              keys={[ 'Infection' ]}
              axisBottom={{
                legend: 'Current Infection',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              defs={defs}
              fill={[{ match: '*', id: 'pink' }]}
              borderRadius={4}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="h-96 max-w-[calc(100vw-16px)]">
            <ResponsiveBar
              {...commonBarProps}
              enableGridX
              data={loresStatistics
                .sort((a, b) =>
                  (
                    loreData.find(lore => lore.id === b.id)?.name ?? 'Unknown'
                  ).localeCompare(
                    loreData.find(lore => lore.id === a.id)?.name ?? 'Unknown',
                  ),
                )
                .map(x => ({
                  lore:
                    loreData.find(lore => lore.id === x.id)?.name ?? 'Unknown',
                  Population: x.count,
                }))}
              keys={[ 'Population' ]}
              indexBy="lore"
              layout="horizontal"
              axisLeft={undefined}
              axisBottom={{
                legend: 'Lore Population',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              defs={defs}
              fill={[{ match: '*', id: 'purple' }]}
              margin={{ top: 32, right: 32, bottom: 48, left: 104 }}
              enableGridY={false}
              borderRadius={4}
            />
          </div>
          <div className="h-[532px] max-w-[calc(100vw-16px)]">
            <ResponsiveBar
              {...commonBarProps}
              enableGridX
              data={professions
                .filter(x => x.category === 'default')
                .sort((a, b) => a.name.localeCompare(b.name))
                .concat(
                  professions
                    .filter(x => x.category === 'impact')
                    .sort((a, b) => {
                      if (!a.isAberrant && !b.isAberrant) {
                        return a.name.localeCompare(b.name);
                      }

                      return a.isAberrant ? 1 : -1;
                    }),
                )
                .concat(
                  professions
                    .filter(x => x.category === 'development')
                    .sort((a, b) => {
                      if (!a.isAberrant && !b.isAberrant) {
                        return a.name.localeCompare(b.name);
                      }

                      return a.isAberrant ? -1 : 1;
                    }),
                )
                .map(x => ({
                  profession: x.name,
                  Population:
                    skillsStatistics.find(s => s.id === x.id)?.count ?? 0,
                  color: color(x),
                }))
                .reverse()}
              keys={[ 'Population' ]}
              indexBy="profession"
              layout="horizontal"
              axisLeft={undefined}
              axisBottom={{
                legend: 'Profession Population',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              defs={defs}
              fill={[
                {
                  match: x => x.data.data.color === 'cyan',
                  id: 'cyan',
                },
                {
                  match: x => x.data.data.color === 'green',
                  id: 'green',
                },
                {
                  match: x => x.data.data.color === 'orange',
                  id: 'orange',
                },
                {
                  match: x => x.data.data.color === 'purple',
                  id: 'purple',
                },
              ]}
              margin={{ top: 32, right: 32, bottom: 48, left: 104 }}
              enableGridY={false}
              borderRadius={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DystopiaRisingStatsStatistics;
