import { getCharacterInventories } from 'src/graphql/queries/characters.graphql';
import {
  IGetCharacterInventoriesQuery,
  IGetCharacterInventoriesQueryVariables,
} from 'src/graphql/queries/characters.graphql.types';
import { useQuery } from 'urql';

const useCharacterInventories = ({
  playerId,
  characterId,
}: {
  playerId: number;
  characterId: number;
}) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetCharacterInventoriesQuery,
    IGetCharacterInventoriesQueryVariables
  >({
    query: getCharacterInventories,
    variables: { playerId, characterId },
    requestPolicy: 'cache-and-network',
    pause: !(playerId > 0 && characterId > 0),
  });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
    inventories: data?.user?.character?.inventories ?? [],
    user: data?.user,
    character: data?.user?.character,
  };
};

export default useCharacterInventories;
