import { FC } from 'react';
import {
  faBan,
  faPencilRuler,
  faRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, formatDistanceToNow, isFuture, isPast } from 'date-fns';
import useEvent from 'src/hooks/events/useEvent';
import { IEventWithAttendance } from 'src/hooks/events/useEventsWithAttendances';
import { Link, useLinkProps } from '@tanstack/react-router';

interface IProps {
  event: Pick<IEventWithAttendance, 'id' | 'registrationOpensAt' | 'branch'>;
  isExpanded: boolean;
}

const Actions: FC<IProps> = ({ event: propEvent, isExpanded }) => {
  const { event: fetchedEvent } = useEvent({
    id: isExpanded ? Number(propEvent.id) : null,
  });
  const linkParams = {
    organizationSlug: propEvent.branch.organization.slug,
    eventId: String(propEvent.id),
  };
  const linkToEventBasics = useLinkProps({
    to: '/$organizationSlug/events/$eventId/basics',
    params: linkParams,
  });
  const linkToEventCheckin = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: linkParams,
  });

  const event = { ...propEvent, ...fetchedEvent };
  const availableActions = {
    checkin:
      (event.registrationOpensAt &&
        isPast(event.registrationOpensAt) &&
        isFuture(event.endsAt)) ||
      event.isEventRegistrar,
    manage: event.isEventManager,
  };

  return (
    <div className="pt-2">
      <div className="px-2 pb-2 text-juno-gray-50">{event.name}</div>
      {event.registrationOpensAt && isFuture(event.registrationOpensAt) && (
        <div className="px-2 pb-2">{`Registration opens ${format(
          event.registrationOpensAt,
          'MMM d',
        )} (${formatDistanceToNow(event.registrationOpensAt, {
          addSuffix: true,
        })})`}</div>
      )}
      <div className="flex justify-center items-center gap-2 pb-3">
        {availableActions.manage && (
          <Link to={linkToEventBasics.href} className="text-juno-gray-50">
            <FontAwesomeIcon icon={faPencilRuler} />
          </Link>
        )}
        {availableActions.checkin && (
          <Link to={linkToEventCheckin.href} className="text-juno-gray-50">
            <FontAwesomeIcon icon={faRightToBracket} />
          </Link>
        )}
        {Object.values(availableActions).filter(x => x).length === 0 && (
          <FontAwesomeIcon icon={faBan} />
        )}
      </div>
    </div>
  );
};

export default Actions;
