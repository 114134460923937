import { FC, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import ResponseBox from 'src/components/0100_response_box';
import AugmentedInput from 'src/components/0200_augmented_input';
import Modal from 'src/components/0300_modal';
import useAuth from 'src/hooks/auths/useAuth';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';

interface IProps {
  isOpen: boolean;
  isFetching?: boolean;
  isRequestCompleted?: boolean;
  onPasswordRecovery: (username: string) => void;
}

const ForgotPasswordModal: FC<IProps> = ({
  isOpen,
  isFetching,
  isRequestCompleted,
  onPasswordRecovery,
}) => {
  const { openModal, modalArgs } = useAuth();
  const { buttonState } = useButtonStates();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: { username: '' },
  });
  const {
    getValues,
    setValue,
    register,
    reset,
    setFocus,
    formState: { isDirty, isValid },
  } = methods;

  const handleResetPassword = useCallback(() => {
    if (isValid) {
      onPasswordRecovery(getValues().username);
      reset();
    }
  }, [ isValid, onPasswordRecovery, getValues, reset ]);

  useEffect(() => {
    if (isOpen) {
      setFocus('username');
    }
  }, [ setFocus, isOpen ]);

  useEffect(() => {
    if (modalArgs?.username) {
      setValue('username', modalArgs.username, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [ modalArgs, setValue ]);

  return (
    <Modal title="Recover Password" isOpen={isOpen}>
      <FormProvider {...methods}>
        <AugmentedInput
          required
          title="Email Address"
          placeholder="Or Player ID"
          onEnter={handleResetPassword}
          {...register('username', {
            required: { value: true, message: 'Cannot be blank' },
            minLength: 1,
          })}
        />
        {!isDirty && isRequestCompleted && (
          <ResponseBox type="success">
            Password reset instruction has been sent to the email address on
            record. Don&apos;t forget to check your spam folder.
          </ResponseBox>
        )}
        <div className="flex justify-end p-2 pr-4 pb-4 w-full">
          <Button
            defaultLabel="Cancel"
            onClick={() => {
              reset();
              openModal({ modal: 'login' });
            }}
          />
          <Button
            className="ml-2"
            defaultLabel="Recover"
            state={buttonState({
              isHighlight: true,
              isDirty,
              isFetching,
              isValid,
            })}
            stateLabel={{
              loading: 'Processing...',
            }}
            onClick={handleResetPassword}
          />
        </div>
      </FormProvider>
    </Modal>
  );
};

export default ForgotPasswordModal;
