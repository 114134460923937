import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useCharacterBuilder from 'src/components/0500_character_builders/dystopia_rising/hooks/useCharacterBuilder';
import { TLore } from 'src/components/0500_character_builders/dystopia_rising/types';

const Lores: FC = () => {
  const { watch } = useFormContext();
  const { lores } = watch();
  const { loreCount, maxLores } = useCharacterBuilder({});
  const diff = maxLores - loreCount;

  if (loreCount === 0) {
    return null;
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            Lores
            {` (${loreCount}/${maxLores})`}
          </th>
        </tr>
      </thead>
      <tbody>
        {(lores as TLore[])
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(lore => (
            <tr key={lore.id}>
              <td>{lore.name}</td>
            </tr>
          ))}
        {[ ...Array(diff) ].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td className="text-transparent">.</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Lores;
