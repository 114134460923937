import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from 'src/components/0100_input';

const Search: FC = () => {
  const { register } = useFormContext();

  return (
    <div className="w-full flex justify-center">
      <div className="w-max-[420px]">
        <Input
          placeholder="Search Attendance..."
          {...register('searchQuery', { required: true })}
        />
      </div>
    </div>
  );
};

export default Search;
