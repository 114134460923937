import { format, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { FC } from 'react';
import { IItemKindEnum } from 'src/graphql/types';

interface IProps {
  transactionId: number;
  stack: number;
  itemName: string;
  itemKind: IItemKindEnum;
  characterName: string;
  personalization?: string;
  expiresAt: string;
}

const Entry: FC<IProps> = ({
  transactionId,
  stack,
  itemName,
  itemKind,
  characterName,
  expiresAt,
  personalization,
}) => (
  <div className="grid grid-cols-12 gap-2">
    <div className="col-span-1 text-right text-juno-gray-200">
      #{transactionId}
    </div>
    <div className="col-span-1 text-right">{stack}</div>

    <div className="col-span-4">
      <div>{itemName}</div>
      {expiresAt && (
        <div className="text-juno-gray-200">
          Expires: {format(parseISO(expiresAt), 'yyyy MMM dd')}
        </div>
      )}
      {personalization && (
        <div className="text-juno-gray-200">
          Personalization: {personalization}
        </div>
      )}
    </div>
    <div className="col-span-3">{characterName}</div>
    <div className="col-span-2">{startCase(itemKind)}</div>
  </div>
);

export default Entry;
