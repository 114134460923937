import { useParams } from '@tanstack/react-router';
import { getBranchStaffs } from 'src/graphql/queries/branches.graphql';
import {
  IGetBranchStaffsQuery,
  IGetBranchStaffsQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import useRootUserRevalidation from 'src/hooks/useRootUserRevalidation';
import { useQuery } from 'urql';

const useBranchStaffs = () => {
  const { branchId } = useParams({ strict: false });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetBranchStaffsQuery,
    IGetBranchStaffsQueryVariables
  >({
    query: getBranchStaffs,
    variables: { branchId: Number(branchId) },
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    refetch,
  };
};

export default useBranchStaffs;
