import { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import DiscreteBar from 'src/components/0100_discrete_bar';
import BasicNode from './BasicNode';
import useCharacterBuilder from '../../hooks/useCharacterBuilder';
import useNode from './hooks/useNode';
import Pairwise from './Pairwise';

interface IProps {
  id: number;
  name: string;
  category: string;
  canEdit: boolean;
  isAberrant?: boolean;
  isProfession?: boolean;
  isProfessionSkill?: boolean;
  pairwiseTo?: string[];
  underlayLevel?: number;

  onMouseOver?: (x: string) => void;
  onMouseOut?: () => void;
}

const Node: FC<IProps> = ({
  id,
  name,
  category,
  canEdit = false,
  isAberrant = false,
  isProfession = false,
  isProfessionSkill = false,
  pairwiseTo,
  underlayLevel,
  onMouseOver,
  onMouseOut,
}) => {
  const [ isHovered, setIsHovered ] = useState(false);
  const { levelCap, levelClassName, nodeClassName } = useNode({
    category,
    isAberrant,
    isProfession,
    isProfessionSkill,
  });
  const { getSkillLevel, updateSkillOrStatLevel, watch } = useCharacterBuilder({
    levelCap,
  });
  const {
    stats: { resolve, infection },
  } = watch();
  const acquiredLevel = useMemo(() => {
    switch (name) {
      case 'Resolve':
        return resolve + 3;
      case 'Infection':
        return infection + 3;
      default:
        return getSkillLevel(name);
    }
  }, [ name, resolve, infection, getSkillLevel ]);

  return (
    <div
      className="grid grid-cols-1"
      onFocus={() => null}
      onBlur={() => null}
      onMouseOver={() => {
        onMouseOver?.(name);
        setIsHovered(true);
      }}
      onMouseOut={() => {
        onMouseOut?.();
        setIsHovered(false);
      }}
    >
      <div id={name}>
        <BasicNode
          canEdit={canEdit}
          className={nodeClassName}
          name={name}
          isProfession={isProfession}
          isProfessionSkill={isProfessionSkill}
          isHovered={isHovered}
          level={acquiredLevel}
          underlayLevel={underlayLevel}
          maxLevel={levelCap}
          alwaysDisplayControl={category === 'stat'}
          onChange={(x: number) =>
            updateSkillOrStatLevel({ id, skillName: name, amount: x })
          }
        />
        <div className="h-[1px] -mt-[1px] w-8" id={`${name}-lock`} />
      </div>

      {levelCap > 1 && (
        <div>
          <DiscreteBar
            className={clsx('grid gap-0.5 mt-1 pr-2 sm:pr-2', levelClassName)}
            nodeClassName={nodeClassName}
            segment={levelCap}
            fulfilled={acquiredLevel}
          />

          {pairwiseTo && <Pairwise name={name} pairs={pairwiseTo} />}
        </div>
      )}
    </div>
  );
};

export default Node;
