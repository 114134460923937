import { useParams } from '@tanstack/react-router';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import Input from 'src/components/0100_input';
import ResponseBox from 'src/components/0100_response_box';
import { createCharacter } from 'src/graphql/mutations/characters.graphql';
import {
  ICreateCharacterMutation,
  ICreateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

const CreateNewCharacter: FC = () => {
  const { buttonState } = useButtonStates();
  const { playerId } = useParams({ strict: false });
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
  const {
    register,
    setFocus,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      name: '',
    },
  });
  const [ createResult, create ] = useMutation<
    ICreateCharacterMutation,
    ICreateCharacterMutationVariables
  >(createCharacter);

  const { name } = watch();

  const handleCreate = useCallback(() => {
    setErrorMessage(null);

    create({
      playerId: Number(playerId),
      name,
    }).then(res => {
      if (res.data?.createCharacter?.user) {
        setValue('name', '', { shouldDirty: true });
      }

      if (res.data?.createCharacter?.error) {
        setErrorMessage(res.data?.createCharacter?.error);
        setFocus('name');
      }
    });
  }, [ create, name, playerId, setFocus, setValue ]);

  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="w-full">
          <Input
            fullWidth
            placeholder="Name"
            {...register('name', { required: true })}
            onEnter={handleCreate}
          />
        </div>
        <Button
          defaultLabel="Create"
          state={buttonState({
            isHighlight: isValid,
            isDirty,
            isValid,
            isFetching: createResult.fetching,
          })}
          stateLabel={{
            loading: 'Creating...',
          }}
          onClick={handleCreate}
        />
      </div>
      {errorMessage && <ResponseBox type="error">{errorMessage}</ResponseBox>}
    </div>
  );
};

export default CreateNewCharacter;
