import { getOrganizationStatistics } from 'src/graphql/queries/organizations.graphql';
import {
  IGetOrganizationStatisticsQuery,
  IGetOrganizationStatisticsQueryVariables,
} from 'src/graphql/queries/organizations.graphql.types';
import { IStatisticsTypeEnum } from 'src/graphql/types';
import { useQuery } from 'urql';
import useParticipationParams from 'src/pages/Organization/Event/WarRoom/hooks/useParticipationParams';
import useRootUserRevalidation from '../useRootUserRevalidation';

type TStatistics = Required<
  IGetOrganizationStatisticsQuery['organization']['statistics']
>;
export type TStats = Extract<
  TStatistics,
  { __typename: 'StatsStatistics' }
>['values'];
export type TResidencies = Extract<
  TStatistics,
  { __typename: 'ResidenciesStatistics' }
>['values'];
export type TSkills = Extract<
  TStatistics,
  { __typename: 'SkillsStatistics' }
>['values'];
export type TLores = TSkills;
export type TReputations = TSkills;

const useOrganizationStatistics = ({
  eventId,
  type,
}: {
  eventId?: number;
  type: IStatisticsTypeEnum;
}) => {
  const { isPaid, printRequested, isLocal } = useParticipationParams();
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetOrganizationStatisticsQuery,
    IGetOrganizationStatisticsQueryVariables
  >({
    query: getOrganizationStatistics,
    variables: {
      eventId,
      type,
      printRequested,
      isPaid,
      isLocal,
    },
    requestPolicy: 'cache-and-network',
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    data,
    statistics: data?.organization.statistics?.values ?? [],
  };
};

export default useOrganizationStatistics;
