import { intersection } from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useEventAttendee from 'src/hooks/events/useEventAttendee';

const useCharacterChangeDetection = () => {
  const { event, eventAttendee, characters } = useEventAttendee();
  const { watch } = useFormContext();
  const { characterIds } = watch();

  const activeCharacterIds = useMemo(
    () => characters.filter(x => x.status === 'active').map(x => x.id),
    [ characters ],
  );

  const hasCharactersChanged = useMemo(() => {
    if (!event) return false;
    if (!eventAttendee) return false;
    const recordedCharacterIds = eventAttendee.attendeeCharacters.map(
      x => x.character.id,
    );

    if (event.activeMembership) {
      return intersection(recordedCharacterIds, characterIds).length !== 3;
    }

    return characterIds.length !== 1;
  }, [ characterIds, event, eventAttendee ]);

  return { activeCharacterIds, hasCharactersChanged };
};

export default useCharacterChangeDetection;
