import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingFellowships from 'src/components/0500_character_fellowships/dystopia_rising';
import DystopiaRisingFractures from 'src/components/0500_character_fractures/dystopia_rising';
import DystopiaRisingNotes from 'src/components/0500_character_notes/dystopia_rising';
import { IFellowshipKindEnum } from 'src/graphql/types';
import useCharacter from 'src/hooks/characters/useCharacter';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Journal: FC = () => {
  const { playerId, characterId } = useParams({ strict: false });
  const character = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return (
    <>
      <div className="grid lg:grid-cols-3 gap-8">
        <DystopiaRisingFractures {...character} />
        <DystopiaRisingFellowships
          {...character}
          type={IFellowshipKindEnum.Society}
          onFellowshipAssignment={() => character.refetch()}
        />
        <DystopiaRisingFellowships
          {...character}
          type={IFellowshipKindEnum.Disease}
          onFellowshipAssignment={() => character.refetch()}
        />
      </div>
      <DystopiaRisingNotes {...character} />
    </>
  );
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId/journal',
)({
  component: Journal,
});
