import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearch } from '@tanstack/react-router';
import { FC } from 'react';
import ResponseBox from 'src/components/0100_response_box';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import useRootUser from 'src/hooks/players/useRootUser';

const MembershipReminder: FC = () => {
  const { rootUserId } = useRootUser();
  const { event, player } = useEventAttendee();
  const { player_id: playerIdInURL } = useSearch({ strict: false });
  const isSelfCheckin = playerIdInURL && Number(playerIdInURL) === rootUserId;

  if (!event?.activeMembership) return null;

  return (
    <ResponseBox type="info" withIcon={faLightbulb}>
      <div>
        Active Membership: All Active Characters are eligible for check-in.
      </div>
      {isSelfCheckin && (
        <div>
          Missing Characters?{' '}
          {player?.id && (
            <Link
              className="underline"
              to="/$organizationSlug/players/$playerId/characters"
              params={{
                organizationSlug: '$organizationSlug',
                playerId: String(player.id),
              }}
            >
              {' '}
              Manage your Characters.
            </Link>
          )}
        </div>
      )}
    </ResponseBox>
  );
};

export default MembershipReminder;
