import { useNavigate, useParams } from '@tanstack/react-router';
import { FC, useCallback } from 'react';
import Calendar from 'src/components/0300_calendar';
import useCalendarNavigations from 'src/hooks/calendarNavigations/useCalendarNavigations';
import useEventsWithAttendances from 'src/hooks/events/useEventsWithAttendances';
import useRootUser from 'src/hooks/players/useRootUser';

interface IProps {
  initStart?: Date;
  initEnd?: Date;
  previousMonthsJump?: number;
}

const Event: FC<IProps> = ({ initStart, initEnd, previousMonthsJump }) => {
  const navigate = useNavigate();
  const { playerId } = useParams({ strict: false });
  const { rootUserId } = useRootUser();
  const { start, end, previous, next } = useCalendarNavigations({
    initStart,
    initEnd,
    previousMonthsJump,
  });
  const { events, fetching } = useEventsWithAttendances({
    start,
    end,
    playerId: Number(playerId || rootUserId),
    withEvents: true,
  });
  const handlePrevious = useCallback(() => {
    navigate({ to: '.', search: { start: previous, end: next }});
  }, [ navigate, next, previous ]);
  const handleNext = useCallback(() => {
    navigate({ to: '.', search: { start: previous, end: next }});
  }, [ navigate, next, previous ]);

  return (
    <Calendar
      start={start}
      end={end}
      items={events}
      isFetching={fetching}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Event;
