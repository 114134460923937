import { Link, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC } from 'react';
import { IItemKindEnum } from 'src/graphql/types';

interface IProps {
  id: number;
  kind: IItemKindEnum;
  name: string;
  blueprintForCraftings: {
    id: number;
    name: string;
  }[];
  metadata: {
    printHeader?: string | null;
    mechanics?: string | null;
    notes?: string | null;
    requirementsToUse?: string | null;
    durationOfEffect?: string | null;
    validTargetDescription?: string | null;
    locationOfUse?: string | null;
    equipmentRequiredForUse?: string | null;
    durationOfRoleplay?: string | null;
    descriptionOfRoleplay?: string | null;
    activationRequirement?: string | null;
  };
}

const Tangible: FC<IProps> = ({ ...item }) => {
  const { organizationSlug } = useParams({ strict: false });

  return (
    <div className="overflow-hidden w-[7.5in] max-w-[7.5in] text-sm">
      <table className="border-collapse w-full">
        <tbody>
          <tr>
            <td className="border border-juno-gray-700 p-1 px-2 w-[192px] max-w-[192px]">
              Item Name
            </td>
            <td className="border border-juno-gray-700 p-1 px-2">
              {item.name}
            </td>
          </tr>
          <tr>
            <td className="border border-juno-gray-700 p-1 px-2 w-[192px] max-w-[192px]">
              Item Kind
            </td>
            <td className="border border-juno-gray-700 p-1 px-2">
              {startCase(item.kind)}
            </td>
          </tr>
          <tr>
            <td className="border border-juno-gray-700 p-1 px-2 w-[192px] max-w-[192px]">
              Blueprints
            </td>
            <td className="border border-juno-gray-700 p-1 px-2">
              {item.blueprintForCraftings.length > 0 ? (
                item.blueprintForCraftings.map(x => (
                  <Link
                    key={x.id}
                    to="/$organizationSlug/blueprints/$"
                    className="underline text-juno-cyan-200 brightness-150"
                    params={{
                      organizationSlug: String(organizationSlug),
                      _splat: String(x.id),
                    }}
                  >
                    {x.name}
                  </Link>
                ))
              ) : (
                <div className="opacity-50">No blueprints available</div>
              )}
            </td>
          </tr>
          {Object.keys(item.metadata)
            .sort((a, b) => a.localeCompare(b))
            .map(key => (
              <tr key={key}>
                <td
                  className={clsx(
                    'border border-juno-gray-700 p-1 px-2 w-[192px] max-w-[192px]',
                    !item.metadata[key as keyof typeof item.metadata] &&
                      'opacity-25',
                  )}
                >
                  {startCase(key)}
                </td>
                <td className="border border-juno-gray-700 p-1 px-2 whitespace-pre-line">
                  {item.metadata[key as keyof typeof item.metadata]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tangible;
