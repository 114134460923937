import { FC } from 'react';
import clsx from 'clsx';

interface IProps {
  subtract: number;
  add: number;
  current: number;
  max: number;
}

const BidirectionalNode: FC<IProps> = ({ subtract, add, current, max }) => {
  const frac = (current * 100) / max;
  const inv = 100 - frac;

  return (
    <div>
      <div className="flex w-full justify-between items-center gap-2">
        <div
          className="text-xs text-left pl-4 text-red-400 transition-all duration-300"
          style={{ width: `calc(max(72px, ${frac}%))` }}
        >
          Loss
        </div>
        <div className="min-w-[64px] w-[64px] text-xs text-center pr-1.5">
          Current
        </div>
        <div
          className="text-xs text-right pr-4 text-lime-400 transition-all duration-300"
          style={{ width: `calc(max(72px, ${inv}%))` }}
        >
          Gain
        </div>
      </div>
      <div className="flex w-full justify-between items-center gap-2">
        <div
          className={clsx(
            'py-2 pl-4 midtone-box justify-self-end rounded-r overflow-hidden text-red-400 transition-all duration-300 chevron-left',
          )}
          style={{ width: `calc(max(72px, ${frac}%))` }}
        >
          {subtract}
        </div>
        <div className="min-w-[56px] w-[56px] text-center transition-all duration-300">{`${
          current
        }/${max}`}</div>
        <div
          className={clsx(
            'py-2 px-4 midtone-box justify-self-start text-right rounded-l overflow-hidden text-lime-400 transition-all duration-300 chevron-right',
          )}
          style={{ width: `calc(max(72px, ${inv}%))` }}
        >
          {add}
        </div>
      </div>
    </div>
  );
};

export default BidirectionalNode;
