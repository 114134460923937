import { useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback } from 'react';

const useParticipationParams = () => {
  const navigate = useNavigate();
  const {
    is_paid: isPaid,
    is_local: isLocal,
    is_participating: printRequested,
  } = useSearch({ strict: false });

  const updateFilter = useCallback(
    (
      key: 'is_paid' | 'is_participating' | 'is_local',
      value: boolean | null,
    ) => {
      navigate({ to: '.', search: x => ({ ...x, [key]: value ?? undefined }) });
    },
    [ navigate ],
  );

  return {
    isPaid,
    printRequested,
    isLocal,
    updateFilter,
  };
};

export default useParticipationParams;
