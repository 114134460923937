import { useNavigate, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import PlayerSearch from 'src/components/0400_player_search';
import DystopiaRisingCheckinForm from 'src/components/0500_checkin_forms/dystopia_rising';
import usePermission from 'src/hooks/permissions/usePermissions';

const Checkin: FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams({ strict: false });
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number(eventId),
  });

  return (
    <div className="grid gap-4">
      {canAssistCheckin && (
        <div className="flex justify-center w-full">
          <div className="w-[256px]">
            <PlayerSearch
              playersOnly
              title="Assisted Checkin"
              placeholder="Search Players..."
              onSearchResultClick={({ playerId }) =>
                navigate({ to: '.', search: { player_id: Number(playerId) }})
              }
            />
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <DystopiaRisingCheckinForm />
      </div>
    </div>
  );
};

export default Checkin;
