import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface IProps {
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const EmbossAndReproduction: FC<IProps> = ({ itemReproduction }) => (
  <div className="flex justify-between">
    <div className="w-2/5 flex justify-center py-[10px]">
      <div className="w-[192px] h-[192px] flex items-center justify-center emboss">
        <div className="-rotate-[25deg] text-xl text-center font-extrabold opacity-50">
          Emboss
          <br />
          Here
        </div>
      </div>
    </div>
    <div className="w-3/5 flex items-end">
      <div className="w-full">
        <div className="grid text-sm reproduction">
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th
                    colSpan={2}
                    className="text-left border-b border-juno-gray-700"
                  >
                    Print Reproduction
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Skills</td>
                  <td>{itemReproduction?.reproductionSkills}</td>
                </tr>
                <tr>
                  <td>Time</td>
                  <td>{itemReproduction?.reproductionTimeInMinute} minutes</td>
                </tr>
                <tr>
                  <td>Cost</td>
                  <td>{itemReproduction?.reproductionMindCost} Mind</td>
                </tr>
                <tr>
                  <td>Resource</td>
                  <td>
                    <div className="flex items-center gap-2">
                      {itemReproduction?.itemReproductionComponents.map(x => (
                        <div key={x.id} className="flex items-center">
                          <div>{x.amount}</div>
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="fa-fw -mr-[1px] text-sm"
                          />
                          <div>{x.item.name}</div>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Notes</td>
                  <td>{itemReproduction?.metadata.notes}</td>
                </tr>
              </tbody>
            </table>
            {/* <div className="flex items-center gap-2">
              <div className="w-2/5 font-normal">Print Reproduction Skills</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionSkills}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5 font-normal">Print Reproduction Time</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionTimeInMinute} minutes
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5 font-normal">Print Reproduction Cost</div>
              <div className="w-3/5">
                {itemReproduction?.reproductionMindCost} Mind
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5 font-normal">
                Print Reproduction Resource
              </div>
              <div className="w-3/5 flex gap-2">
                {itemReproduction?.itemReproductionComponents?.map( x => (
                  <div key={x.id} className="flex items-center gap-1">
                    <div>{x.amount}</div>
                    <div>{x.item.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-2/5 font-normal">Print Reproduction Notes</div>
              <div className="w-3/5">{itemReproduction?.metadata.notes}</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EmbossAndReproduction;
