import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';

const MechanicsHeader: FC = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { pathname } = useLocation();
  const isMechanicsRoute = pathname.includes('mechanics');
  const isDiseaseSection = pathname.includes('diseases');
  const linkToDiseases = useLinkProps({
    to: '/$organizationSlug/mechanics/diseases',
    params: { organizationSlug: String(organizationSlug) },
  });
  const linkToSocieties = useLinkProps({
    to: '/$organizationSlug/mechanics/societies',
    params: { organizationSlug: String(organizationSlug) },
  });

  if (!organizationSlug || !isMechanicsRoute) return null;

  return (
    <ResponsiveTabGroup label={isDiseaseSection ? 'Diseases' : 'Societies'}>
      <Tab
        label="Diseases"
        to={linkToDiseases.href}
        highlightMode="responsive"
        isActive={isDiseaseSection}
      />
      <Tab
        label="Societies"
        to={linkToSocieties.href}
        highlightMode="responsive"
        isActive={!isDiseaseSection}
      />
    </ResponsiveTabGroup>
  );
};

export default MechanicsHeader;
