import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'src/components/0100_button';
import AssistantFlagsToggle from 'src/components/0200_assistant_flags_toggle';
import AugmentedInput from 'src/components/0200_augmented_input';
import Modal from 'src/components/0300_modal';
import RoleSelect from 'src/components/0400_role_select';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useRootUser from 'src/hooks/players/useRootUser';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import EmployeeBranchOwnership from './EmployeeBranchOwnership';

interface IProps {
  canUpdateRole: boolean;
  isAssistant: boolean;
  isEmployee: boolean;
  isDirty: {
    role: boolean;
    assistantFlags: boolean;
  };
  isUpdated: { [key: string]: boolean };
  isUpdating: boolean;
  onUpdate: () => void;
}

const RoleSection: FC<IProps> = ({
  canUpdateRole,
  isAssistant,
  isEmployee,
  isDirty,
  isUpdated,
  isUpdating,
  onUpdate,
}) => {
  const { rootUserId } = useRootUser();
  const { playerId } = usePlayerIdFromUrl();
  const { buttonState } = useButtonStates();
  const {
    register,
    resetField,
    watch,
    formState: { dirtyFields },
  } = useFormContext();
  const { assistantFlags } = watch();

  const [ isConfirmationModalOpen, setIsConfirmationModalOpen ] = useState(false);

  const maybeConfirmOrUpdate = useCallback(() => {
    if (rootUserId === Number(playerId)) {
      if (dirtyFields.role) {
        setIsConfirmationModalOpen(true);
      }
    } else {
      onUpdate();
    }
  }, [ rootUserId, playerId, dirtyFields, onUpdate ]);

  useEffect(() => {
    if (!isUpdating) {
      setIsConfirmationModalOpen(false);
    }
  }, [ isUpdating ]);

  return (
    <>
      <Modal
        title="Confirm Role Change"
        type="warning"
        isOpen={isConfirmationModalOpen}
      >
        <div className="p-4 text-juno-orange-200">
          <b>WARNING: </b>
          You&apos;re demoting your own role. This will remove certain
          privileges from your account and is <u>irreversible</u>.
        </div>
        <div className="flex justify-end p-2 pr-4 pb-4 w-full">
          <Button
            defaultLabel="Cancel"
            onClick={() => {
              setIsConfirmationModalOpen(false);
              resetField('role');
            }}
          />
          <Button
            className="ml-2"
            defaultLabel="Yes, demote me"
            state={buttonState({
              isWarning: true,
              isDirty: true,
              isValid: true,
              isFetching: isUpdating,
            })}
            stateLabel={{ loading: 'Updating Role...' }}
            onClick={onUpdate}
          />
        </div>
      </Modal>

      <AugmentedInput
        key="role"
        title="Role"
        isBusy={isUpdating && isDirty.role}
        isUpdated={isUpdated.role && !isDirty.role}
      >
        <RoleSelect
          selectedValue={watch('role')}
          defaultLabel="Select a Role"
          isLocked={!canUpdateRole || watch('role') === 'admin'}
          onUpdate={maybeConfirmOrUpdate}
          {...register('role')}
        />
      </AugmentedInput>
      {isAssistant && (canUpdateRole || assistantFlags.length > 0) && (
        <AugmentedInput
          key="assistant-flags"
          title="Guide Privileges"
          isBusy={isUpdating && !!isDirty.assistantFlags}
          isUpdated={isUpdated.assistantFlags && !isDirty.assistantFlags}
        >
          <AssistantFlagsToggle
            canToggle={canUpdateRole}
            alwaysExpanded={canUpdateRole}
            className="w-48"
            activeFlags={assistantFlags}
            onUpdate={onUpdate}
            {...register('assistantFlags')}
          />
        </AugmentedInput>
      )}
      {isEmployee && <EmployeeBranchOwnership />}
    </>
  );
};

export default RoleSection;
