import { FC, useCallback, useEffect } from 'react';
import Button from 'src/components/0100_button';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import useItem from 'src/hooks/items/useItem';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useFormContext } from 'react-hook-form';
import Input from 'src/components/0100_input';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNoteSticky, faScroll } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import usePrintability from '../../hooks/usePrintability';

const PrintControl: FC = () => {
  const navigate = useNavigate();
  const { print_label: printLabel } = useSearch({ strict: false });
  const { eventId, _splat: itemId } = useParams({ strict: false });
  const { register, setValue } = useFormContext();
  const { isPermitted: canCreateItem } = usePermission({
    action: 'create_item',
  });
  const { item } = useItem({ itemId: Number(itemId) });
  const { message } = usePrintability({ item });
  const { buttonState } = useButtonStates();
  const togglePrintMode = useCallback(() => {
    navigate({
      to: '.',
      search: x => ({ ...x, print_label: printLabel ? undefined : true }),
    });
  }, [ navigate, printLabel ]);

  useEffect(() => {
    setValue('copies', 1);
  }, [ itemId, setValue ]);

  if (!eventId && !canCreateItem) return null;

  return (
    <div className="gray-box border border-juno-gray-700 p-2">
      <div className="flex items-center justify-between gap-2 w-full">
        <div className="flex items-center gap-1">
          <button type="button" onClick={togglePrintMode}>
            <FontAwesomeIcon
              icon={faNoteSticky}
              className={clsx(
                'fa-fw text-2xl transition-all duration-300',
                !printLabel && 'opacity-25',
              )}
            />
          </button>
          <button type="button" onClick={togglePrintMode}>
            <FontAwesomeIcon
              icon={faScroll}
              className={clsx(
                'fa-fw text-2xl transition-all duration-300',
                printLabel && 'opacity-25',
              )}
            />
          </button>
        </div>

        <div className="flex items-center gap-2">
          <div>Copies</div>
          <div className="w-12 flex items-center gap-2">
            <Input
              fullWidth
              className="text-right"
              type="number"
              {...register('copies')}
              onFocus={x => x.target.select()}
              onChange={x =>
                setValue(
                  'copies',
                  Math.max(1, Math.min(32, Number(x.target.value))),
                  {
                    shouldDirty: true,
                  },
                )
              }
            />
          </div>
          <div className="w-24">
            <Button
              defaultLabel="Print"
              className="w-full"
              state={buttonState({
                isHighlight: true,
                isValid: message === null,
                isDirty: true,
              })}
              onClick={() => window.print()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintControl;
