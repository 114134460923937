import { useCallback } from 'react';

const useMath = () => {
  const skillFunction = useCallback((x: number) => {
    switch (x) {
      case 1:
        return 5;
      case 2:
        return 15;
      case 3:
        return 30;
      case 4:
        return 50;
      case 5:
        return 75;
      case 6:
        return 105;
      case 7:
        return 145;
      case 8:
        return 195;
      case 9:
        return 255;
      case 10:
        return 325;
      default:
        return 0;
    }
  }, []);

  const linearFunction = useCallback(
    ({ level = 1, multiplier = 1, stacked = false }) =>
      (stacked ? ((2 + (level - 1)) * level) / 2 : level) * multiplier * 10,
    [],
  );

  const bodyMindFunction = useCallback((x: number) => {
    const seg = (index: number) => Math.min(x - index * 10, 10);

    return Array.from({ length: Math.ceil(x / 10) })
      .map((_, i) => {
        switch (i) {
          case 0:
            return seg(i) * 1;
          case 1:
            return seg(i) * 3;
          case 2:
            return seg(i) * 5;
          case 3:
            return seg(i) * 7;
          case 4:
            return seg(i) * 10;
          case 5:
            return seg(i) * 20;
          case 6:
            return seg(i) * 30;
          case 7:
            return seg(i) * 40;
          default:
            return seg(i) * 50;
        }
      })
      .reduce((a, b) => a + b, 0);
  }, []);

  return { skillFunction, linearFunction, bodyMindFunction };
};

export default useMath;
