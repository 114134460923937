import { FC } from 'react';
import { startCase } from 'lodash';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';
import usePermission from 'src/hooks/permissions/usePermissions';

const DepositoryHeader: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, eventId } = useParams({ strict: false });
  const isBlueprintRoute = pathname.includes('blueprints');
  const depositorySection = pathname.match(/(loupe|tabular)/)?.[1] ?? 'loupe';
  const linkParams = { organizationSlug: String(organizationSlug) };
  const linkToLoupe = useLinkProps({
    to: '/$organizationSlug/blueprints/$',
    params: linkParams,
  });
  const linkToTabular = useLinkProps({
    to: '/$organizationSlug/blueprints/tabular',
    params: linkParams,
  });
  const { isPermitted: canUseDepository } = usePermission({
    action: 'create_item',
  });

  if (!isBlueprintRoute) return null;
  if (eventId) return null;
  if (!canUseDepository) return null;

  return (
    <ResponsiveTabGroup label={startCase(depositorySection)}>
      <Tab
        label="Loupe"
        to={linkToLoupe.href}
        highlightMode="responsive"
        isActive={depositorySection === 'loupe'}
      />
      <Tab
        label="Tabular"
        to={linkToTabular.href}
        highlightMode="responsive"
        isActive={depositorySection === 'tabular'}
      />
    </ResponsiveTabGroup>
  );
};

export default DepositoryHeader;
