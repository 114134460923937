import { faCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import Slider from 'rc-slider';
import { FC, useCallback, useState } from 'react';
import Loading from 'src/components/0100_loading';
import { updateCharacterFellowship } from 'src/graphql/mutations/fellowships.graphql';
import {
  IUpdateCharacterFellowshipMutation,
  IUpdateCharacterFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import {
  IBranch,
  ICharacter,
  ICharacterFellowship,
  IFellowshipKindEnum,
  IUser,
  IUserOrganization,
} from 'src/graphql/types';
import { useMutation } from 'urql';

export type TCharacterFellowship = Pick<
  ICharacterFellowship,
  'id' | 'rank' | 'removedAt'
> & {
  character: Pick<ICharacter, 'id' | 'name'> & {
    user: Pick<IUser, 'id' | 'fullName'> & {
      userOrganization?:
        | (Pick<IUserOrganization, 'id'> & {
            branch: Pick<IBranch, 'id' | 'shorthand'>;
          })
        | null;
    };
  };
};

interface IProps {
  characterFellowship: TCharacterFellowship;
  type: IFellowshipKindEnum;
}

const maxRank = {
  [IFellowshipKindEnum.Disease]: 4,
  [IFellowshipKindEnum.Society]: 2,
};

const Member: FC<IProps> = ({ characterFellowship, type }) => {
  const [ isUpdated, setIsUpdated ] = useState(false);
  const [ updateResult, update ] = useMutation<
    IUpdateCharacterFellowshipMutation,
    IUpdateCharacterFellowshipMutationVariables
  >(updateCharacterFellowship);

  const handleUpdate = useCallback(
    ({ rank, forRemoval }: { rank?: number; forRemoval?: boolean }) => {
      setIsUpdated(false);
      update({
        characterFellowshipId: characterFellowship.id,
        rank,
        forRemoval,
      }).then(res => {
        if (res.data?.updateCharacterFellowship?.characterFellowship?.id) {
          setIsUpdated(true);
        }
      });
    },
    [ characterFellowship.id, update ],
  );

  return (
    <div
      className={clsx(
        'flex gap-2 items-start',
        characterFellowship.removedAt && 'opacity-50',
      )}
    >
      <div className="min-w-[64px] text-right">
        {characterFellowship.character.user.id}
        <div className="min-h-8 flex gap-1 justify-end">
          {!characterFellowship.removedAt && (
            <FontAwesomeIcon
              icon={faUserSlash}
              className="cursor-pointer"
              onClick={() => handleUpdate({ forRemoval: true })}
            />
          )}
          {updateResult.fetching && <Loading size="small" />}
          {isUpdated && <FontAwesomeIcon icon={faCheck} />}{' '}
        </div>
      </div>

      <div className="flex w-full gap-2 items-center">
        <div className="w-full">
          <div>{characterFellowship.character.user.fullName}</div>
          <div>{characterFellowship.character.name}</div>
          {characterFellowship.removedAt && (
            <div className="flex gap-1">
              {`Revoked on ${format(parseISO(characterFellowship.removedAt), 'yyyy/M/d')}.`}
              <button
                type="button"
                className="cursor-pointer underline"
                onClick={() => handleUpdate({ forRemoval: false })}
              >
                Undo
              </button>
            </div>
          )}
        </div>
        <div
          className={clsx(
            'min-w-[96px] pr-2',
            characterFellowship.removedAt && 'pointer-events-none',
          )}
        >
          <Slider
            dots
            disabled={characterFellowship.removedAt}
            value={characterFellowship.rank}
            min={0}
            max={maxRank[type]}
            onChange={x => handleUpdate({ rank: Number(x) })}
          />
        </div>
      </div>
    </div>
  );
};

export default Member;
