import {
  faArrowLeft,
  faCartPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import ItemSearch from 'src/components/0400_item_search';
import { IItemKindEnum } from 'src/graphql/types';
import ResponseBox from 'src/components/0100_response_box';
import LineItem, { TTransactableItem } from '../LineItem';
import Requirements from '../Requirements';
import { TInventory } from '../Inventories';
import Selection from '../Selection';
import InventoryItem from '../InventoryItem';
import { TSelectedInventory } from '../Requirements/Requirement/Boolean';
import CraftingBadge from './CraftingBadge';

export type TAddTransactableItem = Omit<TTransactableItem, 'nonce'> & {
  direction: 'toCharacter' | 'toBranch';
};

interface IProps {
  direction: 'toCharacter' | 'toBranch';
  itemsToCharacter?: TTransactableItem[];
  itemsToBranch?: TTransactableItem[];
  requiredCraftingComponents?: TTransactableItem[];
  selectedCraftingComponents?: TSelectedInventory[];
  craftingFinalProducts?: TTransactableItem[];
  inventories?: TInventory[];

  onUpdateSelectedCraftingComponent?: (props: TSelectedInventory) => void;
  onSetItemCrafting?: (itemId: number | null) => void;
  onUpdateCraftingOutput?: ({
    nonce,
    stack,
    description,
    expiresAt,
    armorUpgradePoint,
    selectedOptionItemId,
  }: {
    nonce: string;
    stack?: number;
    description?: string | null;
    expiresAt?: string | null;
    armorUpgradePoint?: number;
    selectedOptionItemId?: number | null;
  }) => void;
  onAddItemToCharacter?: (item: TAddTransactableItem) => void;
  onUpdateItemToCharacter?: ({
    nonce,
    stack,
    description,
    expiresAt,
    armorUpgradePoint,
  }: {
    nonce: string;
    stack?: number;
    description?: string | null;
    expiresAt?: string | null;
    armorUpgradePoint?: number;
  }) => void;
  onRemoveItemToCharacter?: (nonce: string) => void;

  onUpdateItemsToBranch?: ({
    nonce,
    stack,
  }: {
    nonce: string;
    stack: number;
  }) => void;
  onRemoveItemsToBranch?: (nonce: string) => void;
}

const OneSidedTransaction: FC<IProps> = ({
  direction,
  itemsToBranch,
  itemsToCharacter,
  craftingFinalProducts,
  requiredCraftingComponents,
  selectedCraftingComponents,
  inventories,
  onUpdateSelectedCraftingComponent,
  onUpdateItemsToBranch,
  onAddItemToCharacter,
  onUpdateItemToCharacter,
  onRemoveItemToCharacter,
  onSetItemCrafting,
  onUpdateCraftingOutput,
  onRemoveItemsToBranch,
}) => {
  const craftingBadge = useMemo(() => <CraftingBadge />, []);

  return (
    <div
      className={clsx(
        'w-full',
        direction === 'toCharacter' ? 'green-box-angled' : 'cyan-box-angled',
      )}
    >
      <div className="border-b border-juno-gray-700">
        {direction === 'toCharacter' ? (
          <div className="flex gap-2 items-center p-2">
            <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
            To Character
          </div>
        ) : (
          <div className="flex gap-2 items-center flex-row-reverse p-2">
            <FontAwesomeIcon icon={faArrowLeft} className="fa-fw rotate-180" />
            To Branch
          </div>
        )}
      </div>
      {itemsToCharacter &&
        itemsToCharacter
          .sort((a, b) => a.itemName.localeCompare(b.itemName))
          .map(x => (
            <LineItem
              key={x.nonce}
              {...x}
              onStackChange={stack =>
                onUpdateItemToCharacter?.({ nonce: x.nonce, stack })
              }
              onExpirationChange={date =>
                onUpdateItemToCharacter?.({ nonce: x.nonce, expiresAt: date })
              }
              onDescriptionChange={description =>
                onUpdateItemToCharacter?.({ nonce: x.nonce, description })
              }
              onSetArmorUpgrade={point =>
                onUpdateItemToCharacter?.({
                  nonce: x.nonce,
                  armorUpgradePoint: point,
                })
              }
              onRemove={() => onRemoveItemToCharacter?.(x.nonce)}
            />
          ))}
      {craftingFinalProducts &&
        craftingFinalProducts
          .sort((a, b) => a.itemName.localeCompare(b.itemName))
          .map(x => (
            <LineItem
              key={x.nonce}
              {...x}
              onStackChange={stack =>
                onUpdateCraftingOutput?.({ nonce: x.nonce, stack })
              }
              onExpirationChange={date =>
                onUpdateCraftingOutput?.({ nonce: x.nonce, expiresAt: date })
              }
              onDescriptionChange={description =>
                onUpdateCraftingOutput?.({ nonce: x.nonce, description })
              }
              onSetArmorUpgrade={point =>
                onUpdateCraftingOutput?.({
                  nonce: x.nonce,
                  armorUpgradePoint: point,
                })
              }
              onSelectOutputOption={itemId =>
                onUpdateCraftingOutput?.({
                  nonce: x.nonce,
                  selectedOptionItemId: itemId,
                })
              }
              onRemove={() => onSetItemCrafting?.(null)}
            />
          ))}
      {direction === 'toCharacter' ? (
        <div className="p-2">
          <div className="uppercase text-xs text-juno-gray-200">Add Items</div>
          <ItemSearch
            kind={IItemKindEnum.Transactable}
            placeholder="Search Items..."
            onSearchResultClick={({
              itemId,
              itemName,
              itemKind,
              itemLifetimeUnit,
              itemLifetimeAmount,
            }) =>
              itemKind === IItemKindEnum.Blueprint
                ? onSetItemCrafting?.(itemId)
                : onAddItemToCharacter?.({
                    itemId,
                    itemName,
                    itemKind,
                    itemLifetimeUnit,
                    itemLifetimeAmount: itemLifetimeAmount ?? undefined,
                    direction,
                  })
            }
            onRenderBadge={({ kind }) =>
              kind === IItemKindEnum.Blueprint && craftingBadge
            }
          />
        </div>
      ) : (
        <div>
          {(itemsToBranch ?? []).length === 0 ? (
            <div className="p-2">
              <ResponseBox type="success" withIcon={faCartPlus}>
                Click Items from Character&apos;s Inventory to add Items to this
                list.
              </ResponseBox>
            </div>
          ) : (
            (itemsToBranch ?? []).map(x => (
              <div
                key={x.nonce}
                className="flex items-center justify-between p-1 px-2 border-b border-juno-gray-700"
              >
                <div className="flex items-start gap-2">
                  <button
                    type="button"
                    onClick={() => onRemoveItemsToBranch?.(x.nonce)}
                  >
                    <FontAwesomeIcon icon={faTrash} className="pt-2 px-1" />
                  </button>
                  <InventoryItem
                    itemName={x.itemName}
                    itemKind={x.itemKind}
                    expiresAt={x.expiresAt}
                    registeredItemDescription={x.registeredItemDescription}
                  />
                </div>
                <div className="flex justify-end">
                  <Selection
                    stack={x.stack}
                    limit={x.limit ?? 1}
                    onAdjustSelection={stack =>
                      onUpdateItemsToBranch?.({ nonce: x.nonce, stack })
                    }
                  />
                  <div className="w-8 text-right">{x.limit}</div>
                </div>
              </div>
            ))
          )}
          {requiredCraftingComponents && (
            <Requirements
              requirements={requiredCraftingComponents}
              inventories={inventories ?? []}
              selectedCraftingComponents={selectedCraftingComponents ?? []}
              onUpdateSelectedCraftingComponent={
                onUpdateSelectedCraftingComponent
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OneSidedTransaction;
