import {
  faCheck,
  faExclamationTriangle,
  faMapPin,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import PlayerSearch from 'src/components/0400_player_search';
import useEventAttendees from 'src/hooks/events/useEventAttendees';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useMutation } from 'urql';
import {
  ICreateItemTransactionMutation,
  ICreateItemTransactionMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { createItemTransaction } from 'src/graphql/mutations/characters.graphql';
import useCharacterInventories from 'src/hooks/characters/useCharacterInventories';
import ResponseBox from 'src/components/0100_response_box';
import { compact } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import Textarea from 'src/components/0100_textarea';
import { useNavigate, useParams, useSearch } from '@tanstack/react-router';
import OneSidedTransaction from './OneSidedTransaction';
import Inventories from './Inventories';
import useItemCrafting from './hooks/useItemCrafting';
import useItemsToCharacter from './hooks/useItemsToCharacter';
import useItemsToBranch from './hooks/useItemsToBranch';
import Execution from './Execution';

const Logistics: FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams({ strict: false });
  const methods = useForm({
    defaultValues: {
      description: '',
      transactionSource: '',
      attestCrafting: false,
    },
  });
  const { register, watch, setValue } = methods;
  const { description, transactionSource } = watch();

  const { attendees, refetch } = useEventAttendees({});
  const { character_id: characterId, player_id: playerId } = useSearch({
    strict: false,
  });
  const [ successMessage, setSuccessMessage ] = useState<string | null>(null);
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null);
  const {
    user,
    character,
    inventories,
    refetch: refetchInventories,
  } = useCharacterInventories({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });
  const attendingCharacterIds = useMemo(
    () =>
      attendees
        .map(atd => atd.attendeeCharacters.map(atc => atc.character.id))
        .flat(),
    [ attendees ],
  );
  const { isPermitted: canAssistLogistics } = usePermission({
    action: 'assists_logistics',
    eventId: Number(eventId),
  });

  const {
    itemsToCharacter,
    addItemToCharacter,
    updateItemToCharacter,
    removeItemToCharacter,
    resetItemsToCharacter,
  } = useItemsToCharacter();

  const {
    itemsToBranch,
    addItemToBranch,
    updateItemToBranch,
    removeItemToBranch,
    resetItemsToBranch,
  } = useItemsToBranch();

  const {
    setBlueprintId,
    craftingComponents,
    craftingFinalProducts,
    selectedCraftingComponents,
    updateSelectedCrafingComponent,
    updateCraftingOutput,
    resetCrafting,
    craftingRequirementsSatisfied,
  } = useItemCrafting();

  const attendanceMarker = useCallback(
    ({ characterId = 0 }) => {
      if (characterId && attendingCharacterIds.includes(characterId)) {
        return <FontAwesomeIcon icon={faMapPin} className="fa-fw -mr-1.5" />;
      }

      return <FontAwesomeIcon icon={faTimes} className="fa-fw -mr-1.5" />;
    },
    [ attendingCharacterIds ],
  );

  const [ createResult, create ] = useMutation<
    ICreateItemTransactionMutation,
    ICreateItemTransactionMutationVariables
  >(createItemTransaction);

  const commitTransaction = useCallback(() => {
    setErrorMessage(null);
    setSuccessMessage(null);

    create({
      eventId: Number(eventId),
      description,
      source:
        transactionSource.trim().length === 0
          ? undefined
          : transactionSource.trim(),
      transactions: itemsToCharacter
        .map(x => ({
          itemId: x.itemId,
          stack: x.stack,
          characterId: Number(characterId),
          registeredItemDescription:
            x.armorUpgradePoint || x.description
              ? compact([
                  x.armorUpgradePoint &&
                    x.armorUpgradePoint > 0 &&
                    `+${x.armorUpgradePoint * 5} Armor Points`,
                  x.description,
                ]).join(' - ')
              : undefined,
          expiresAt: x.expiresAt,
        }))
        .concat(
          selectedCraftingComponents
            .filter(x => x.stack !== 0)
            .map(x => ({
              itemId: x.itemId,
              stack: -x.stack,
              characterId: Number(characterId),
              registeredItemId: x.registeredItemId,
              registeredItemDescription: undefined,
              expiresAt: x.expiresAt,
            })),
        )
        .concat(
          craftingFinalProducts.map(x => ({
            itemId:
              (x.options?.length ?? 0) > 0
                ? Number(x.selectedOptionItemId)
                : x.itemId,
            stack: x.stack,
            characterId: Number(characterId),
            registeredItemDescription:
              x.armorUpgradePoint || x.description
                ? compact([
                    x.armorUpgradePoint &&
                      x.armorUpgradePoint > 0 &&
                      `+${x.armorUpgradePoint * 5} Armor Points`,
                    x.description,
                  ]).join(' - ')
                : undefined,
            expiresAt: x.expiresAt,
          })),
        )
        .concat(
          itemsToBranch.map(x => ({
            itemId: x.itemId,
            stack: -(x.stack ?? 1),
            characterId: Number(characterId),
            registeredItemId: x.registeredItemId,
            registeredItemDescription: undefined,
            expiresAt: x.expiresAt,
          })),
        ),
    }).then(res => {
      if (
        (res.data?.createItemTransaction?.itemTransactions?.length ?? 0) > 0
      ) {
        refetchInventories();
        resetItemsToCharacter();
        resetCrafting();
        resetItemsToBranch();
        setValue('description', '');
        setValue('transactionSource', '');
        setValue('attestCrafting', false);

        setSuccessMessage(
          `Transactions created: ${res.data?.createItemTransaction?.itemTransactions?.map(x => x.id).join(', ')}`,
        );
      }

      if (res.data?.createItemTransaction?.error) {
        setErrorMessage(res.data.createItemTransaction.error);
      }
    });
  }, [
    create,
    eventId,
    description,
    transactionSource,
    itemsToCharacter,
    selectedCraftingComponents,
    craftingFinalProducts,
    itemsToBranch,
    characterId,
    refetchInventories,
    resetItemsToCharacter,
    resetCrafting,
    resetItemsToBranch,
    setValue,
  ]);

  const numberOfTransactions =
    itemsToCharacter.length +
    selectedCraftingComponents.length +
    itemsToBranch.length;
  const canCommitTransaction =
    Number(characterId) > 0 &&
    numberOfTransactions > 0 &&
    craftingRequirementsSatisfied;

  useEffect(() => {
    if (canAssistLogistics) refetch();
  }, [ canAssistLogistics, refetch ]);

  if (!canAssistLogistics) return null;

  return (
    <div className="min-h-[100vh]">
      <div className="grid gap-2 place-items-center">
        <div className="w-64">
          <PlayerSearch
            withCharacters
            title="Start Here"
            placeholder="Search Players or Characters..."
            characterIcon={attendanceMarker}
            onSearchResultClick={({ characterId, playerId }) => {
              if (characterId && playerId) {
                navigate({
                  to: '.',
                  search: x => ({
                    ...x,
                    character_id: characterId,
                    player_id: playerId,
                  }),
                });
              } else {
                navigate({
                  to: '.',
                  search: x => ({
                    ...x,
                    character_id: undefined,
                    player_id: undefined,
                  }),
                });
              }
            }}
          />
        </div>
        <div className="flex justify-center w-full">
          <div className="max-w-[640px]">
            <Inventories
              user={user}
              character={character}
              inventories={inventories}
              itemsToBranch={itemsToBranch}
              maxH="max-h-[25vh]"
              onCollect={addItemToBranch}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full">
          <OneSidedTransaction
            direction="toCharacter"
            itemsToCharacter={itemsToCharacter}
            craftingFinalProducts={craftingFinalProducts}
            onAddItemToCharacter={addItemToCharacter}
            onSetItemCrafting={setBlueprintId}
            onUpdateCraftingOutput={updateCraftingOutput}
            onUpdateItemToCharacter={updateItemToCharacter}
            onRemoveItemToCharacter={removeItemToCharacter}
          />
          <OneSidedTransaction
            direction="toBranch"
            itemsToBranch={itemsToBranch}
            requiredCraftingComponents={craftingComponents}
            selectedCraftingComponents={selectedCraftingComponents}
            inventories={inventories}
            onUpdateSelectedCraftingComponent={updateSelectedCrafingComponent}
            onUpdateItemsToBranch={updateItemToBranch}
            onRemoveItemsToBranch={removeItemToBranch}
          />
        </div>
        <div className="w-full">
          <Textarea
            width="w-full"
            height="h-20"
            {...register('description')}
            placeholder="Add Notes (Optional)..."
          />
        </div>

        {errorMessage && (
          <div className="w-full">
            <ResponseBox type="error" withIcon={faExclamationTriangle}>
              <div className="whitespace-pre-line">{errorMessage}</div>
            </ResponseBox>
          </div>
        )}
        {successMessage && (
          <div className="w-full">
            <ResponseBox type="success" withIcon={faCheck}>
              {successMessage}
            </ResponseBox>
          </div>
        )}

        <FormProvider {...methods}>
          <Execution
            canCommitTransaction={canCommitTransaction}
            commitTransaction={commitTransaction}
            fetching={createResult.fetching}
          />
        </FormProvider>
      </div>
    </div>
  );
};

export default Logistics;
