import { gql } from 'urql';

export const getEvents = gql`
  query GetEvents(
    $from: String
    $upto: String
    $organizationIds: [Int!]
    $branchIds: [Int!]
  ) {
    events(
      from: $from
      upto: $upto
      organizationIds: $organizationIds
      branchIds: $branchIds
    ) {
      id
      name
      kind
      description
      startsAt
      endsAt
      registrationOpensAt
      kind
      isVirtual
      location {
        id
        name
        streetAddress
        city
        region
        zipCode
      }
      branch {
        id
        shorthand
        organization {
          id
          slug
        }
      }
      config {
        baseBuild
        maxExtraXp
        considersTravelersLocal
      }
    }
  }
`;

export const getEventInfo = gql`
  query GetEvent($eventId: Int!) {
    event(eventId: $eventId) {
      id
      name
      description
      kind
      startsAt
      endsAt
      registrationOpensAt
      kind
      isEventManager
      isEventAssistant
      isEventRegistrar
      nextEvent {
        id
      }
      prevEvent {
        id
      }
      config {
        baseBuild
        maxCurveXp
        extraXpCost
        maxExtraXp
        maxExtraXpLimit
        shiftUnit
        shiftMultiplier
        maxShift
        shiftKinds
        considersTravelersLocal
      }
      branch {
        id
        shorthand
        organization {
          id
          slug
        }
      }
      location {
        id
        name
        streetAddress
        city
        region
        zipCode
      }
      temporaryManagers {
        id
        user {
          id
          fullName
          userOrganization {
            id
            role
            assistantFlags
            branch {
              id
              shorthand
            }
          }
          ownedBranches {
            id
            shorthand
          }
        }
      }
    }
  }
`;

export const getEventTickets = gql`
  query GetEventTickets($eventId: Int!, $playerId: Int, $editMode: Boolean) {
    event(eventId: $eventId) {
      id
      tickets(playerId: $playerId, editMode: $editMode) {
        id
        cost
        label
        limit
        discountType
        passType
        restrictionType
        shiftResponsibilities
        fixedLimit
        fullyBooked
        eventAttendeesCount
      }
    }
  }
`;

export const getEventShifts = gql`
  query GetEventShifts($eventId: Int!) {
    event(eventId: $eventId) {
      id
      shiftKinds
      shifts(withFulfillments: true) {
        id
        label
        baseRatio
        limit
        kind
        identifier
        isEnabled
        autoExpand
        adjustableExpansionStrategy
        playersCount
        attendeeShifts {
          id
          status
          player {
            id
            fullName
            roleInOrganization
          }
        }
      }
    }
  }
`;

export const getEventAttendances = gql`
  query GetEventAttendances($eventId: Int!, $ticketId: Int) {
    event(eventId: $eventId) {
      id
      eventAttendees(ticketId: $ticketId) {
        id
        buildGrowth
        kind
        paid
        attending
        referralInfo
        ticketId
        ticket {
          id
          restrictionType
          discountType
          cost
        }
        user {
          id
          fullName
        }
        attendeeCharacters {
          id
          character {
            id
            name
            experimentalCharacter {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const getEventPrintouts = gql`
  query GetEventPrintouts(
    $eventId: Int!
    $paid: Boolean
    $printRequested: Boolean
    $homeGame: Boolean
    $ticketIds: [Int!]
  ) {
    event(eventId: $eventId) {
      id
      name
      startsAt
      endsAt
      branch {
        id
        shorthand
      }
      location {
        id
        name
      }
      printouts(
        paid: $paid
        printRequested: $printRequested
        homeGame: $homeGame
        ticketIds: $ticketIds
      ) {
        id
        user {
          id
          fullName
          membershipActiveDuringEvent(eventId: $eventId)
          totalCapsForEvent(eventId: $eventId)
          roleInOrganization
          userOrganization {
            id
            role
            branch {
              id
              shorthand
            }
          }
        }
        shifts {
          id
          kind
          label
        }
        ticket {
          id
          label
        }
        attendeeCharacters {
          id
          printRequestStatus
          character {
            id
            name
            body
            mind
            resolve
            infection
            currentLife
            lifeCapacity
            lifeGain
            lifeLoss
            latestLifecycleGainsThisYear
            buildUsed
            buildEarned
            faithId
            strainId
            lineageId
            variant
            playerNotes
            skills {
              id
              characterSkillId
              level
              positions
            }
            loreIds
            fractures {
              id
              name
              createdAt
              removedAt
            }
            characterFellowships {
              id
              rank
              removedAt
              fellowship {
                id
                name
              }
            }
            experimentalCharacter {
              id
              name
              body
              mind
              resolve
              infection
              currentLife
              lifeCapacity
              lifeGain
              lifeLoss
              latestLifecycleGainsThisYear
              buildUsed
              buildEarned
              faithId
              strainId
              lineageId
              variant
              playerNotes
              skills {
                id
                characterSkillId
                level
                positions
              }
              loreIds
              fractures {
                id
                name
                createdAt
                removedAt
              }
              characterFellowships {
                id
                rank
                removedAt
                fellowship {
                  id
                  name
                  kind
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getEventTransactions = gql`
  query GetEventTransactions($eventId: Int!) {
    event(eventId: $eventId) {
      id
      itemTransactions {
        id
        item {
          id
          kind
          name
        }
        status
        stack
        expiresAt
        registeredItem {
          id
          description
        }
        character {
          id
          name
          user {
            id
            fullName
          }
        }
        postie {
          id
          fullName
        }
        source
        description
        createdAt
        childTransactions {
          id
          item {
            id
            kind
            name
          }
          stack
          expiresAt
          source
          registeredItem {
            id
            description
          }
          character {
            id
            name
          }
        }
      }
    }
  }
`;
