import { Link, useMatch, useParams, useSearch } from '@tanstack/react-router';
import clsx from 'clsx';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import useEventAttendees from 'src/hooks/events/useEventAttendees';
import usePermission from 'src/hooks/permissions/usePermissions';

const CheckoutSidebar: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { character_id: characterId, player_id: playerId } = useSearch({
    strict: false,
  });
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const isEventCheckoutPath = useMatch({
    from: '/$organizationSlug/events/$eventId/checkout',
    shouldThrow: false,
  });

  const { attendees, refetch, stale } = useEventAttendees({});
  const { organizationSlug, eventId } = useParams({ strict: false });
  const { isPermitted: canListAttendees } = usePermission({
    action: 'manage_event',
    eventId: Number(eventId),
  });
  const isCharacterSelected = useCallback(
    (id: number) => id === Number(characterId),
    [ characterId ],
  );
  const isPlayerSelected = useCallback(
    (id: number) => id === Number(playerId),
    [ playerId ],
  );

  useEffect(() => {
    if (isEventCheckoutPath && canListAttendees) {
      refetch();
    }
  }, [ canListAttendees, isEventCheckoutPath, refetch ]);

  useEffect(() => {
    if (!stale && ref.current) {
      ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [ stale ]);

  if (!isEventCheckoutPath) return null;
  if (!canListAttendees) return null;
  if (attendees.length === 0) return null;

  return (
    <div>
      <div className="border-l-4 border-juno-gray-200 font-bold sticky top-0 z-10">
        <div ref={ref} className="white-box text-juno-gray-900 p-2">
          Event Checkout
        </div>
        <div className="p-2 w-full gray-box">
          <Input fullWidth placeholder="Search..." {...register('query')} />
        </div>
      </div>
      <div>
        {attendees
          .filter(x =>
            query.trim().length > 0
              ? String(x.user.id) === query.toLowerCase() ||
                x.user.fullName.toLowerCase().includes(query.toLowerCase()) ||
                x.attendeeCharacters.some(atc =>
                  atc.character.name
                    .toLowerCase()
                    .includes(query.toLowerCase()),
                )
              : true,
          )
          .sort((a, b) => a.user.id - b.user.id)
          .map(attendee => (
            <div
              key={attendee.id}
              className="grid border-transparent overflow-x-hidden"
            >
              <Link
                to="/$organizationSlug/events/$eventId/checkout"
                params={{
                  organizationSlug: String(organizationSlug),
                  eventId: String(eventId),
                }}
                search={{ player_id: attendee.user.id }}
                className={clsx(
                  'flex gap-2 py-1 border-l-4 transition-all duration-300',
                  isPlayerSelected(attendee.user.id) &&
                    characterId &&
                    'border-transparent',

                  isPlayerSelected(attendee.user.id) &&
                    !characterId &&
                    'border-juno-gray-50 midtone-box brightness-110 text-shadow',

                  !isPlayerSelected(attendee.user.id) &&
                    'border-transparent text-juno-gray-200 opacity-75',
                )}
              >
                <div className="text-right w-16">#{attendee.user.id}</div>
                <div className="whitespace-nowrap text-ellipsis overflow-x-hidden">
                  {attendee.user.fullName}
                </div>
              </Link>
              <div className="grid">
                {attendee.attendeeCharacters
                  .sort((a, b) =>
                    (
                      a.character.experimentalCharacter?.name ??
                      a.character.name
                    ).localeCompare(
                      b.character.experimentalCharacter?.name ??
                        b.character.name,
                    ),
                  )
                  .map(atc => (
                    <Link
                      key={atc.id}
                      to="/$organizationSlug/events/$eventId/checkout"
                      params={{
                        organizationSlug: String(organizationSlug),
                        eventId: String(eventId),
                      }}
                      search={{
                        player_id: attendee.user.id,
                        character_id:
                          atc.character.experimentalCharacter?.id ??
                          atc.character.id,
                      }}
                      className={clsx(
                        'flex py-1 gap-2 border-l-4 hover:text-shadow hover:text-juno-gray-50 hover:border-juno-gray-200 transition-all duration-300',
                        isCharacterSelected(
                          atc.character.experimentalCharacter?.id ??
                            atc.character.id,
                        )
                          ? 'border-juno-gray-50 text-juno-gray-50 midtone-box'
                          : 'border-transparent text-juno-gray-700',
                      )}
                    >
                      <div className="w-16" />
                      <div className="whitespace-nowrap text-ellipsis overflow-x-hidden">
                        {atc.character.experimentalCharacter?.name ??
                          atc.character.name}
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CheckoutSidebar;
