import { startCase } from 'lodash';
import { FC } from 'react';
import useEventShifts from 'src/hooks/events/useEventShifts';
import { sortFunctor } from 'src/utils/shiftTime';
import Title from 'src/components/0100_title';
import ShiftGroup from './ShiftGroup';

const PrintableShifts: FC = () => {
  const { shiftKinds, shifts } = useEventShifts();

  return (
    <div className="printout-container text-juno-gray-900 no-render w-full">
      {shiftKinds.map(kind => (
        <div key={kind}>
          <Title title={startCase(kind)} variant="heading" />
          <div className="grid gap-2">
            {shifts
              .filter(x => x.kind === kind)
              .sort((a, b) => sortFunctor(a, b, kind))
              .map(x => (
                <ShiftGroup key={x.id} shift={x} />
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PrintableShifts;
