import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLinkProps, useMatch, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useRootUser from 'src/hooks/players/useRootUser';

const BlueprintSidebar: FC = () => {
  const blueprintRoot = useMatch({
    from: '/$organizationSlug/blueprints/$',
    shouldThrow: false,
  });
  const {
    organizationSlug,
    _splat: itemId,
    eventId,
  } = useParams({ strict: false });
  const { rootUserId } = useRootUser();
  const linkToBlueprint = useLinkProps({
    to: '/$organizationSlug/blueprints/$',
    params: {
      organizationSlug: String(organizationSlug),
    },
  });

  if (rootUserId < 1) return null;

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faLayerGroup} className="pr-2 fa-fw" />
          Blueprints
        </div>
      }
      to={linkToBlueprint.href}
      isActive={!!blueprintRoot || (!eventId && !!itemId)}
    />
  );
};

export default BlueprintSidebar;
