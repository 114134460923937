import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

const CraftingBadge: FC = () => (
  <div className="flex gap-1 items-center purple-box px-2 rounded-xs">
    <FontAwesomeIcon icon={faScrewdriverWrench} className="fa-fw" />
    Craft
  </div>
);

export default CraftingBadge;
