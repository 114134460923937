import {
  createFileRoute,
  Outlet,
  useLocation,
  useParams,
} from '@tanstack/react-router';
import { FC } from 'react';
import Title from 'src/components/0100_title';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';

const Player: FC = () => {
  const { pathname } = useLocation();
  const { playerId } = useParams({ strict: false });
  const { data } = usePlayerWithCharactersInOrganization({
    playerId: Number(playerId) || 0,
  });
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });
  const isCharacter = pathname.includes('characters');

  if (playerId === 'new' && !canCreateNewPlayer) return null;

  return (
    <div>
      {!isCharacter && (
        <Title
          title={
            playerId === 'new'
              ? 'Create New Player'
              : data?.user?.fullName || ''
          }
        />
      )}
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute('/$organizationSlug/players/$playerId')({
  component: Player,
});
