import { createFileRoute } from '@tanstack/react-router';
import { FC } from 'react';
import useRootUser from 'src/hooks/players/useRootUser';
import GuestWelcome from 'src/pages/WelcomePage/GuestWelcome';
import UserWelcome from 'src/pages/WelcomePage/UserWelcome';

type TRootSearch = {
  redirect?: boolean;
  reset_password_token?: string;
  player_id?: number;
  character_id?: number;
  start?: string;
  end?: string;
  h?: string;
  is_paid?: boolean;
  is_participating?: boolean;
  is_local?: boolean;
  sides?: string;
  checkout_section?: string;
  blank_sheet?: boolean;
  print_requested?: boolean;
  print_paid?: boolean;
  print_local?: boolean;
  print_ticket_ids?: string;
  print?: boolean; // blueprint
  print_label?: boolean; // card label
  item_id?: number;
};

const Root: FC = () => {
  const { isValidated } = useRootUser();

  return isValidated ? <UserWelcome /> : <GuestWelcome />;
};

export const Route = createFileRoute('/')({
  component: Root,
  validateSearch: (search: Record<string, unknown>): TRootSearch => ({
    redirect: search.redirect === true ? true : undefined,
    reset_password_token: search.reset_password_token as string,
    player_id: search.player_id as number,
    character_id: search.character_id as number,
    start: search.start as string,
    end: search.end as string,
    h: search.h as string,
    is_paid: search.is_paid === true ? true : undefined,
    is_participating: search.is_participating === true ? true : undefined,
    is_local: search.is_local === true ? true : undefined,
    sides: search.sides as string,
    checkout_section: search.checkout_section as string,
    blank_sheet: search.blank_sheet === true ? true : undefined,
    print_requested: search.print_requested as boolean,
    print_paid: search.print_paid as boolean,
    print_local: search.print_local as boolean,
    print_ticket_ids: search.print_ticket_ids as string,
    print: search.print === true ? true : undefined,
    print_label: search.print_label === true ? true : undefined,
    item_id: search.item_id as number,
  }),
});
