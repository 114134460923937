import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import ResponseBox from 'src/components/0100_response_box';
import Textarea from 'src/components/0100_textarea';
import BaseSelect from 'src/components/0300_base_select';
import { createCharacterEventLifecycle } from 'src/graphql/mutations/characters.graphql';
import {
  ICreateCharacterEventLifecycleMutation,
  ICreateCharacterEventLifecycleMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { ILifecycleKindEnum } from 'src/graphql/types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import { useMutation } from 'urql';

interface IProps {
  playerId: number;
  characterId: number;
  eventId: number;
  canGain: boolean;
  canPurchase: boolean;
  canLose: boolean;
}

const NewLifecycle: FC<IProps> = ({
  characterId,
  eventId,
  canGain,
  canPurchase,
  canLose,
}) => {
  const { buttonState } = useButtonStates();
  const [ error, setError ] = useState<string | null>(null);
  const methods = useForm({
    defaultValues: {
      kind: null as ILifecycleKindEnum | null,
      description: '',
    },
  });
  const {
    register,
    reset,
    setFocus,
    watch,
    formState: { isValid },
  } = methods;
  const { kind, description } = watch();
  const [ createResult, create ] = useMutation<
    ICreateCharacterEventLifecycleMutation,
    ICreateCharacterEventLifecycleMutationVariables
  >(createCharacterEventLifecycle);

  const handleAdd = useCallback(() => {
    if (!kind) return;
    setError(null);
    create({
      characterId,
      eventId,
      kind,
      description,
    }).then(res => {
      if (res.data?.createEventLifecycle?.character) {
        reset({ kind: null, description: '' });
        setFocus('kind');
      }

      if (res.data?.createEventLifecycle?.error) {
        setError(res.data.createEventLifecycle.error);
      }
    });
  }, [ create, characterId, eventId, kind, description, reset, setFocus ]);

  return (
    <FormProvider {...methods}>
      <>
        <tr>
          <td className="py-1 pt-2 pr-2">
            <BaseSelect
              customSort
              width="w-32"
              defaultLabel="Select"
              selectedValue={kind}
              options={[
                {
                  label: 'Purchase',
                  value: ILifecycleKindEnum.Purchase,
                  isLocked: !canPurchase,
                },
                {
                  label: 'Gain',
                  value: ILifecycleKindEnum.Gain,
                  isLocked: !canGain,
                },
                {
                  label: 'Loss',
                  value: ILifecycleKindEnum.Loss,
                  isLocked: !canLose,
                },
              ]}
              {...register('kind', { required: true })}
            />
          </td>
          <td className="py-1 pt-2 pr-2">
            <Textarea
              height="h-16"
              width="w-full"
              placeholder="What happened to this Character?"
              type="text"
              {...register('description')}
            />
          </td>
          <td colSpan={3} className="py-1 pt-2 pr-2">
            <Button
              defaultLabel="Add Record"
              className="w-full"
              state={buttonState({
                isHighlight: true,
                isValid,
                isDirty: true,
                isFetching: createResult.fetching,
              })}
              stateLabel={{
                loading: 'Adding...',
              }}
              onClick={handleAdd}
            />
          </td>
        </tr>
        {error && (
          <tr>
            <td colSpan={5}>
              <ResponseBox type="error">{error}</ResponseBox>
            </td>
          </tr>
        )}
      </>
    </FormProvider>
  );
};

export default NewLifecycle;
