import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  isExpanded: boolean;
  classNames?: {
    parent?: {
      expanded?: string;
      collapsed?: string;
      steadyState?: string;
    };
    child?: string;
  };
}

const Collapsible: FC<PropsWithChildren<IProps>> = ({
  isExpanded,
  classNames,
  children,
}) => (
  <div
    className={clsx(
      'grid transition-all duration-300',
      classNames?.parent?.steadyState,
      isExpanded
        ? `grid-rows-[1fr] ${classNames?.parent?.expanded}`
        : `grid-rows-[0fr] ${classNames?.parent?.collapsed}`,
    )}
  >
    <div className={clsx('overflow-hidden grid', classNames?.child)}>
      {children}
    </div>
  </div>
);

export default Collapsible;
