import { useLinkProps, useLocation, useParams } from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import ResponsiveTabGroup from 'src/components/0300_responsive_tabs_group';
import useRootUser from 'src/hooks/players/useRootUser';

const ReportsHeader: FC = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { homeBranch } = useRootUser();
  const { pathname } = useLocation();

  const isReportSection = pathname.includes('reports');
  const reportSection = pathname.match(
    /(memberships|corrective_actions|caps|character_retirements)/,
  );
  const linkToMemberships = useLinkProps({
    to: '/$organizationSlug/reports/memberships',
    params: { organizationSlug: String(organizationSlug) },
  });
  const linkToCaps = useLinkProps({
    to: '/$organizationSlug/reports/caps/$branchId',
    params: {
      organizationSlug: String(organizationSlug),
      branchId: String(homeBranch?.id),
    },
  });
  const linkToCorrectiveActions = useLinkProps({
    to: '/$organizationSlug/reports/corrective_actions/$branchId',
    params: {
      organizationSlug: String(organizationSlug),
      branchId: String(homeBranch?.id),
    },
  });
  const linkToCharacterRetirements = useLinkProps({
    to: '/$organizationSlug/reports/character_retirements',
    params: { organizationSlug: String(organizationSlug) },
  });

  if (!organizationSlug || !isReportSection) return null;

  return (
    <ResponsiveTabGroup
      label={startCase((reportSection && reportSection[1]) ?? 'memberships')}
    >
      <Tab
        label="Memberships"
        to={linkToMemberships.href}
        highlightMode="responsive"
        isActive={
          !reportSection ||
          (reportSection && reportSection[1]) === 'memberships'
        }
      />
      <Tab
        label="CAPS"
        to={linkToCaps.href}
        highlightMode="responsive"
        isActive={reportSection ? reportSection[1] === 'caps' : false}
      />
      <Tab
        label="Corrective Actions"
        to={linkToCorrectiveActions.href}
        highlightMode="responsive"
        isActive={
          reportSection ? reportSection[1] === 'corrective_actions' : false
        }
      />
      <Tab
        label="Character Retirements"
        to={linkToCharacterRetirements.href}
        highlightMode="responsive"
        isActive={
          reportSection ? reportSection[1] === 'character_retirements' : false
        }
      />
    </ResponsiveTabGroup>
  );
};

export default ReportsHeader;
