import { Link, useLinkProps, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import Tooltip from 'src/components/0100_tooltip';

interface IProps {
  id: number;
  className?: string;
  content: ReactNode;
  position: string;
}

const Dot: FC<IProps> = ({ id, className, content, position }) => {
  const { organizationSlug } = useParams({ strict: false });
  const linkToEvent = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: String(id),
    },
  });

  return (
    <Link
      key={id}
      to={linkToEvent.href}
      className={clsx(
        'absolute w-2 h-2 top-[11px] z-10 rounded-full group cursor-pointer',
        className,
      )}
      style={{ left: `${position}` }}
    >
      <Tooltip>{content}</Tooltip>
    </Link>
  );
};

export default Dot;
