import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Basics from 'src/pages/Organization/Event/Basics';
import Checkin from 'src/pages/Organization/Event/Checkin';

const Component: FC = () => {
  const { eventId } = useParams({ strict: false });

  return eventId === 'new' ? <Basics /> : <Checkin />;
};

export const Route = createFileRoute('/$organizationSlug/events/$eventId/*')({
  component: Component,
});
