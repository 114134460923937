import { FC } from 'react';
import { zip } from 'lodash';
import clsx from 'clsx';

interface IProps {
  className?: string;
  nodeClassName?: string;
  segment?: number;
  fulfilled?: number;
}

const DiscreteBar: FC<IProps> = ({
  className,
  nodeClassName,
  segment = 1,
  fulfilled = 0,
}) => (
  <div className={className}>
    {zip(
      Array.from({ length: segment }, (_, i) => i),
      Array.from({ length: fulfilled }, (_, i) => i),
    ).map(x => {
      const [ base, active ] = x;
      return (
        <div
          key={base}
          className={clsx(
            'h-0.5 rounded-xs',
            'col-span-1',
            active !== undefined ? nodeClassName : 'midtone-box',
          )}
        />
      );
    })}
  </div>
);

export default DiscreteBar;
