import { createFileRoute, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingXpAudit from 'src/components/0500_xp_audits/dystopia_rising';
import useCharacterXp from 'src/hooks/characters/useCharacterXp';

const XpAudit: FC = () => {
  const { playerId, characterId } = useParams({ strict: false });
  const characterXp = useCharacterXp({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  return <DystopiaRisingXpAudit {...characterXp} />;
};

export const Route = createFileRoute(
  '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit',
)({
  component: XpAudit,
});
