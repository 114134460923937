import { FC, useState } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import { useParams } from '@tanstack/react-router';
import ByTicket from './ByTicket';

const Tickets: FC = () => {
  const { eventId } = useParams({ strict: false });
  const { isPermitted: canAccessTicketingPage } = usePermission({
    action: 'checkin_character',
    eventId: Number(eventId),
  });
  const [ displayMode, setDisplayMode ] = useState('byTicket');

  if (!canAccessTicketingPage) return null;

  return <ByTicket />;
};

export default Tickets;
