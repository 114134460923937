import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLinkProps, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import Textarea from 'src/components/0100_textarea';
import { updateCharacterEventLifecycle } from 'src/graphql/mutations/characters.graphql';
import {
  IUpdateCharacterEventLifecycleMutation,
  IUpdateCharacterEventLifecycleMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { IEvent, ILifecycle } from 'src/graphql/types';
import { useMutation } from 'urql';

type TLifecycle = Pick<
  ILifecycle,
  'id' | 'kind' | 'description' | 'createdAt' | 'deletedAt'
> & {
  event: Pick<IEvent, 'id' | 'name'>;
};
interface IProps {
  lifecycle: TLifecycle;
  canEdit?: boolean;
  playerId?: number;
  characterId?: number;
}

const Lifecycle: FC<IProps> = ({
  lifecycle,
  canEdit,
  playerId,
  characterId,
}) => {
  const { organizationSlug } = useParams({ strict: false });
  const [ error, setError ] = useState<string | null>(null);
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState(false);
  const methods = useForm({
    defaultValues: {
      description: '',
    },
  });
  const {
    reset,
    register,
    watch,
    formState: { isDirty },
  } = methods;
  const { description } = watch();

  const [ updateResult, update ] = useMutation<
    IUpdateCharacterEventLifecycleMutation,
    IUpdateCharacterEventLifecycleMutationVariables
  >(updateCharacterEventLifecycle);

  const handleResponse = useCallback((errorMessage?: string | null) => {
    if (errorMessage) {
      setError(errorMessage);
    } else {
      setIsUpdateSuccessful(true);
      setTimeout(() => setIsUpdateSuccessful(false), 3000);
    }
  }, []);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;
    update({
      lifecycleId: lifecycle.id,
      description,
    }).then(res => handleResponse(res.data?.updateEventLifecycle?.error));
  }, [ description, handleResponse, isDirty, lifecycle.id, update ]);

  const linkToEventCheckout = useLinkProps({
    to: '/$organizationSlug/events/$eventId/checkout',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: String(lifecycle.event.id),
    },
    search: {
      player_id: String(playerId),
      character_id: String(characterId),
    },
  });

  const linkToEventCheckin = useLinkProps({
    to: '/$organizationSlug/events/$eventId/*',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: String(lifecycle.event.id),
    },
  });

  useEffect(() => {
    reset({ description: lifecycle.description ?? '' });
  }, [ lifecycle, reset ]);

  return (
    <FormProvider {...methods}>
      <tr
        className={clsx(
          'transition-all ease-in-out',
          isUpdateSuccessful ? 'bg-lime-600/75' : 'bg-transparent',
          lifecycle.deletedAt && 'opacity-50',
        )}
      >
        <td
          className={clsx('py-1 pr-2', lifecycle.deletedAt && 'line-through')}
        >
          {startCase(lifecycle.kind)}
        </td>
        <td className="py-1 pr-2">
          <Textarea
            disabled={!canEdit}
            height="h-16"
            width="w-full"
            {...register('description')}
            onBlur={handleUpdate}
          />
        </td>
        <td
          className={clsx('py-1 pr-2', lifecycle.deletedAt && 'line-through')}
        >
          <Link
            to={canEdit ? linkToEventCheckout.href : linkToEventCheckin.href}
            className="underline"
          >
            {lifecycle.event.name}
          </Link>
          {!lifecycle.deletedAt && (
            <div className="opacity-75">
              {format(lifecycle.createdAt, 'yyyy MMM dd')}
            </div>
          )}
        </td>
        <td className="py-1 text-right" colSpan={canEdit ? 1 : 2}>
          {updateResult.fetching ? (
            <div>
              <Loading size="small" />
            </div>
          ) : (
            <>
              {canEdit && (
                <FontAwesomeIcon
                  className="cursor-pointer"
                  icon={lifecycle.deletedAt ? faUndo : faTrash}
                  onClick={() => {
                    if (updateResult.fetching) return;

                    setError(null);
                    update({
                      lifecycleId: lifecycle.id,
                      isDeleted: !lifecycle.deletedAt,
                    }).then(res =>
                      handleResponse(res.data?.updateEventLifecycle?.error),
                    );
                  }}
                />
              )}{' '}
            </>
          )}
        </td>
      </tr>
      {error && (
        <tr>
          <td colSpan={5}>
            <ResponseBox type="error">{error}</ResponseBox>
          </td>
        </tr>
      )}
    </FormProvider>
  );
};

export default Lifecycle;
