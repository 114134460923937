import { useQuery } from 'urql';
import {
  IGetBranchInvoiceQuery,
  IGetBranchInvoiceQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import { getBranchInvoice } from 'src/graphql/queries/branches.graphql';

const useBranchInvoice = ({ branchId }: { branchId: number }) => {
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetBranchInvoiceQuery,
    IGetBranchInvoiceQueryVariables
  >({
    query: getBranchInvoice,
    variables: { branchId },
    requestPolicy: 'cache-and-network',
  });

  return {
    error,
    fetching,
    stale,
    refetch,
    data,
    invoice: data?.branch?.invoice,
  };
};

export default useBranchInvoice;
