import { useQuery } from 'urql';
import {
  IGetEventAttendancesQuery,
  IGetEventAttendancesQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { getEventAttendances } from 'src/graphql/queries/events.graphql';
import { useParams } from '@tanstack/react-router';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useEventAttendees = ({ ticketId }: { ticketId?: number }) => {
  const { eventId } = useParams({ strict: false });

  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetEventAttendancesQuery,
    IGetEventAttendancesQueryVariables
  >({
    query: getEventAttendances,
    variables: { eventId: Number(eventId), ticketId },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useRootUserRevalidation({ refetch });

  return {
    error,
    fetching,
    stale,
    refetch,
    attendees: data?.event?.eventAttendees || [],
  };
};

export default useEventAttendees;
