import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

interface IProps {
  isExpanded: boolean;
  isTransparent?: boolean;
  className?: string;
  zIndex?: number;
}

const DropdownPanel: FC<PropsWithChildren<IProps>> = ({
  isExpanded,
  isTransparent,
  className = '',
  zIndex = 10,
  children,
}) => (
  <div className="relative">
    <div className="absolute w-full" style={{ zIndex }}>
      <div
        className={clsx(
          'overflow-x-hidden rounded-xs transition-all duration-300 ease-in-out',
          !isTransparent && 'gray-box',
          isExpanded
            ? 'max-h-[50vh] overflow-y-auto'
            : 'max-h-0 overflow-y-hidden',
          className,
        )}
      >
        {children}
      </div>
    </div>
  </div>
);

export default DropdownPanel;
