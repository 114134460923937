import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { FC, useCallback, useEffect } from 'react';
import useEventTransactions from 'src/hooks/events/useEventTransactions';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import {
  IUpdateItemTransactionMutation,
  IUpdateItemTransactionMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { updateeItemTransaction } from 'src/graphql/mutations/characters.graphql';
import Textarea from 'src/components/0100_textarea';
import { IItemTransactionStatusEnum } from 'src/graphql/types';
import clsx from 'clsx';
import Entry from './Entry';

type TTransactionPair = ReturnType<
  typeof useEventTransactions
>['transactions'][0];
interface IProps extends TTransactionPair {}

const Record: FC<IProps> = parent => {
  const {
    id,
    status,
    source,
    character,
    description: upstreamDescription,
    postie,
    expiresAt,
    stack,
    item,
    createdAt,
    registeredItem,
    childTransactions,
  } = parent;
  const { watch, register, reset } = useForm({
    defaultValues: { description: '' },
  });
  const { description } = watch();
  const [ updateResult, update ] = useMutation<
    IUpdateItemTransactionMutation,
    IUpdateItemTransactionMutationVariables
  >(updateeItemTransaction);

  const handleUpdateDescription = useCallback(() => {
    update({ itemTransactionId: id, description });
  }, [ update, id, description ]);

  const handleUpdateStatus = useCallback(() => {
    update({
      itemTransactionId: id,
      status:
        status === IItemTransactionStatusEnum.Invalidated
          ? IItemTransactionStatusEnum.Legit
          : IItemTransactionStatusEnum.Invalidated,
    });
  }, [ update, status, id ]);

  useEffect(() => {
    reset({ description: upstreamDescription ?? '' });
  }, [ reset, upstreamDescription ]);

  return (
    <div key={id}>
      <div className="grid grid-cols-12 gap-2 border-b border-juno-gray-700 text-juno-gray-200 mb-1 items-center">
        <div className="col-span-3">
          {format(parseISO(createdAt), 'yyyy MMM dd - HH:mm')}
        </div>
        <div className="col-span-3 overflow-hidden whitespace-nowrap">
          #{character.user.id} {character.user.fullName}
        </div>
        <div className="col-span-3 overflow-hidden whitespace-nowrap">
          {source ? (
            <div className="text-shadow">Mod: {source}</div>
          ) : (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faScrewdriverWrench} />
              Attested Crafting
            </div>
          )}
        </div>
        <div className="col-span-3 overflow-hidden whitespace-nowrap">
          #{postie.id} {postie.fullName}
        </div>
      </div>
      <div
        className={clsx(
          'grid gap-1',
          status === IItemTransactionStatusEnum.Invalidated &&
            'opacity-50 line-through',
        )}
      >
        <Entry
          key={id}
          transactionId={id}
          stack={stack}
          itemName={item.name}
          itemKind={item.kind}
          characterName={character.name}
          personalization={registeredItem?.description}
          expiresAt={expiresAt}
        />
        {childTransactions.map(child => (
          <Entry
            key={child.id}
            transactionId={child.id}
            stack={child.stack}
            itemName={child.item.name}
            itemKind={child.item.kind}
            characterName={character.name}
            personalization={child.registeredItem?.description}
            expiresAt={child.expiresAt}
          />
        ))}
      </div>
      <div className="py-2 grid grid-cols-12 gap-2">
        <div className="col-span-9">
          <Textarea
            width="w-full"
            height="h-20"
            placeholder="Add Notes (Optional)..."
            {...register('description', { onBlur: handleUpdateDescription })}
          />
        </div>
        <div className="col-span-3">
          <button
            type="button"
            className="underline"
            onClick={handleUpdateStatus}
          >
            {status === IItemTransactionStatusEnum.Legit
              ? 'Invalidate'
              : 'Legitimize'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Record;
