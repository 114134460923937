import { useParams } from '@tanstack/react-router';
import { useVirtualizer } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { format, isBefore, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { FC, useRef } from 'react';
import Loading from 'src/components/0100_loading';
import { ICapStateEnum } from 'src/graphql/types';
import useBranchCaps from 'src/hooks/organizations/branches/useBranchCaps';
import Player from './Player';

import 'src/basic-table.css';

const Caps: FC = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { branchId } = useParams({ strict: false });

  const { caps, fetching } = useBranchCaps({ branchId: Number(branchId) });
  const sortedCaps = caps.sort((a, b) =>
    isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
  );
  const virtualizer = useVirtualizer({
    count: sortedCaps.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 32,
    overscan: 20,
  });

  if (fetching) return <Loading />;
  return (
    <div
      ref={parentRef}
      className="p-2"
      style={{ height: `${virtualizer.getTotalSize()}px` }}
    >
      <table className="basic-table table-fixed">
        <thead>
          <tr className="sticky top-0 midtone-box z-10">
            <th>ID</th>
            <th className="min-w-[128px]">Created At</th>
            <th className="min-w-[64px]">Recipient</th>
            <th className="min-w-[128px]">Recipient Name</th>
            <th className="min-w-[64px]">Grantor</th>
            <th className="min-w-[128px]">Grantor Name</th>
            <th className="min-w-[64px]">State</th>
            <th className="min-w-[64px]">Amount</th>
            <th className="min-w-[128px]">Reason</th>
          </tr>
        </thead>
        <tbody>
          {virtualizer.getVirtualItems().map((virtualRow, index) => {
            const x = sortedCaps[virtualRow.index];

            return (
              <tr
                key={x.id}
                className={clsx(
                  x.state === ICapStateEnum.Removed && 'opacity-50',
                  x.state === ICapStateEnum.Active &&
                    x.amount < 0 &&
                    'text-juno-orange-400',
                )}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                }}
              >
                <td className="text-right">
                  <Player id={x.id} section="perks" />
                </td>
                <td>{format(parseISO(x.createdAt), 'yyyy-MM-dd')}</td>
                <td className="text-right">
                  <Player id={x.user.id} />
                </td>
                <td>{x.user.fullName}</td>
                <td className="text-right">
                  <Player id={x.grantor.id} />
                </td>
                <td>{x.grantor.fullName}</td>
                <td>{startCase(x.state)}</td>
                <td className="text-right">{x.amount}</td>
                <td>{x.reason}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Caps;
