import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useCharacter from 'src/hooks/characters/useCharacter';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebouncedValue, useInViewRef } from 'rooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useMutation } from 'urql';
import {
  IUpdateCharacterMutation,
  IUpdateCharacterMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import { updateCharacter } from 'src/graphql/mutations/characters.graphql';
import { omit } from 'lodash';
import useCharacterBuilderStore from 'src/stores/characterBuilder';
import ResponseBox from 'src/components/0100_response_box';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import LineageFaith from './LineageFaith';
import SkillBuilder from './SkillBuilder';
import { TBuildTemplate, TLore, TSkill } from './types';
import ResolveInfection from './ResolveInfection';
import XpSunBurst from './XpSunBurst';
import XpBar from './XpBar';
import Shareable from './Shareable';
import loreData from './SkillBuilder/LoreGroup/data';
import { skillNames } from './SkillBuilder/data';
import LifecycleEditor from './ResolveInfection/LifecycleEditor';
import Copyable from './Copyable';

const buildTemplate: TBuildTemplate = {
  lineageId: 0,
  strainId: 0,
  faithId: 0,
  lores: [] as TLore[],
  stats: {
    body: 0,
    mind: 0,
    resolve: 0,
    infection: 0,
  },
  skills: [] as TSkill[],
  variant: 'standard',
};

const defaultValues = {
  ...buildTemplate,
  lifeGain: 0,
  lifeLoss: 0,
  lifeCapacity: 0,
  currentLife: 0,
  xpEarned: 0,
  variant: 'standard',
  showAcquiredOnly: false,
};

interface IProps extends ReturnType<typeof useCharacter> {
  showLifecycle?: boolean;
  eventId?: number;
  playerId?: number;
}

const DystopiaRisingBuild: FC<IProps> = ({
  character,
  canEdit: canEditCharacter,
  canEditSensitiveData,
  showLifecycle,
  playerId,
  eventId,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { actions } = useCharacterBuilderStore();
  const [ shouldDisplaySunburst, setShouldDisplaySunburst ] = useState(false);
  const [ isDomLoaded, setIsDomLoaded ] = useState(false);
  const [ isClientPriority, setIsClientPriority ] = useState(false);
  const [ inViewRef, isInView ] = useInViewRef();
  const canEdit = !character || canEditCharacter;
  const methods = useForm({
    defaultValues,
  });
  const {
    getValues,
    reset,
    setValue,
    formState: { isDirty },
  } = methods;

  const [ , update ] = useMutation<
    IUpdateCharacterMutation,
    IUpdateCharacterMutationVariables
  >(updateCharacter);

  const calculateSunburst = useCallback(
    ({ height, width }: { height: number; width: number }) => {
      setShouldDisplaySunburst(height > 800 && width > 640);
    },
    [],
  );

  const { lineageId, strainId, faithId, lores, stats, skills, variant } =
    getValues();
  const payload = useMemo(
    () => ({
      characterId: character?.id,
      isDirty,
      lineageId,
      strainId,
      faithId,
      lores,
      stats,
      skills,
      variant,
    }),
    [
      character?.id,
      faithId,
      isDirty,
      lineageId,
      lores,
      skills,
      stats,
      strainId,
      variant,
    ],
  );

  const [ debounced ] = useDebouncedValue(payload, 250);
  const maxPositionable = useMemo(
    () => skills.find(x => x.id === 240)?.level ?? 0,
    [ skills ],
  );

  useEffect(() => {
    if (!isClientPriority && character?.id && debounced.isDirty) {
      setIsClientPriority(true);
    }
  }, [ character?.id, debounced.isDirty, isClientPriority ]);

  useEffect(() => {
    if (
      character?.id &&
      isClientPriority &&
      debounced.characterId === character.id
    ) {
      actions.isSaving();
      update({
        characterId: Number(debounced.characterId),
        strainId: debounced.strainId,
        faithId: debounced.faithId,
        loreIds: debounced.lores.filter(x => x.id >= 201).map(x => x.id),
        skills: debounced.skills
          .filter(x => x.level > 0)
          .filter(x => x.id >= 200)
          .filter(x => (maxPositionable === 0 ? !x.positions : x))
          .map(x => {
            if (x.positions) {
              return {
                ...omit(x, 'name', 'characterSkillId'),
                positions: x.positions.slice(0, maxPositionable),
              };
            }
            return omit(x, 'name', 'characterSkillId');
          }),
        body: debounced.stats.body,
        mind: debounced.stats.mind,
        resolve: debounced.stats.resolve,
        infection: debounced.stats.infection,
        variant: debounced.variant,
      }).then(res => {
        if (res.data?.updateCharacterData?.character) {
          actions.isSaved();
        } else {
          actions.isErrored(
            res.data?.updateCharacterData?.error ?? 'Unknown Error',
          );
        }
      });
    }
  }, [
    actions,
    character?.id,
    debounced,
    isClientPriority,
    maxPositionable,
    update,
  ]);

  useEffect(() => {
    if (character && !isClientPriority) {
      reset({
        lineageId: Number(character.lineageId || 0),
        strainId: Number(character.strainId || 0),
        faithId: Number(character.faithId || 0),
        lores: character.loreIds
          .filter(x => x >= 201)
          .map(x => ({
            id: x,
            name: loreData.find(y => y.id === x)?.name,
          })),
        stats: {
          body: character.body,
          mind: character.mind,
          resolve: character.resolve,
          infection: character.infection,
        },
        skills: character.skills.map(x => ({
          ...x,
          name: skillNames[x.id] ?? 'Unknown Skill',
          positions: x.positions ?? undefined,
        })),
        xpEarned: character.buildEarned,
        showAcquiredOnly: false,
        currentLife: character.currentLife,
        lifeGain: character.lifeGain,
        lifeLoss: character.lifeLoss,
        lifeCapacity: character.lifeCapacity,
        variant: character.variant,
      });
    }
  }, [ character, isClientPriority, reset ]);

  useEffect(() => {
    if (character) {
      setValue('currentLife', character.currentLife);
      setValue('lifeGain', character.lifeGain);
      setValue('lifeCapacity', character.lifeCapacity);
      setValue('lifeLoss', character.lifeLoss);
    }
  }, [ character, setValue ]);

  useEffect(() => {
    setTimeout(() => setIsDomLoaded(true), 250);
  }, []);

  useEffect(() => {
    calculateSunburst({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', () =>
      calculateSunburst({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    );

    return () =>
      window.removeEventListener('resize', () =>
        calculateSunburst({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      );
  }, [ calculateSunburst ]);

  useEffect(() => {
    setIsClientPriority(false);
    actions.reset();
  }, [ character?.id, actions ]);

  if (character?.version && character.version !== 4) {
    return (
      <ResponseBox type="error" withIcon={faTriangleExclamation}>
        This Character has not been migrated and is incompatible with DR:Live
        ruleset.
      </ResponseBox>
    );
  }

  return (
    <FormProvider {...methods}>
      <div className="min-h-[calc(max(50vh,420px))] flex flex-wrap justify-center gap-4">
        <div className="grid grid-cols-1 gap-4 w-full max-w-[640px]">
          <Shareable />
          <Copyable />
          <div
            ref={inViewRef}
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 relative z-20"
          >
            <div className="grid grid-cols-1 gap-4 z-10 place-self-start w-full">
              <div className="relative z-10">
                <LineageFaith canEdit={canEdit} />
              </div>
              <div className="relative z-0">
                <ResolveInfection
                  canEdit={canEdit}
                  canEditStartingInfection={
                    !character ||
                    [ 'staged', 'experimental' ].includes(character?.status ?? '')
                  }
                  isFreeform={!character}
                  showLifecycle={showLifecycle}
                />
              </div>
            </div>
            <div ref={ref}>
              <XpSunBurst displayOverage={!!character?.id} />
            </div>
          </div>

          {(showLifecycle ||
            (character?.lifeGain ?? 0) > 0 ||
            (character?.lifeLoss ?? 0) > 0) && (
            <LifecycleEditor
              canEdit={canEditSensitiveData}
              characterId={character?.id}
              playerId={playerId}
              eventId={eventId}
              currentLife={character?.currentLife}
              lifeCapacity={character?.lifeCapacity}
            />
          )}

          <div className="relative z-10">
            <SkillBuilder canEdit={canEdit} />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isDomLoaded && !isInView && (
          <div>
            {shouldDisplaySunburst ? (
              <motion.div
                className="absolute z-50 top-12 right-0"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                style={{
                  width: ref.current?.getBoundingClientRect().width,
                  height: ref.current?.getBoundingClientRect().height,
                }}
              >
                <div className="relative w-full h-full">
                  <XpSunBurst
                    responsivelyHidePie
                    displayOverage={!!character?.id}
                  />
                </div>
              </motion.div>
            ) : (
              <motion.div
                className="fixed z-50 top-[50px] right-1 text-juno-gray-50"
                initial={{ marginRight: '-100vw' }}
                animate={{ marginRight: 0 }}
                exit={{ marginRight: '-100vw' }}
              >
                <XpBar displayOverage={!!character?.id} />
              </motion.div>
            )}
          </div>
        )}
      </AnimatePresence>
    </FormProvider>
  );
};

export default DystopiaRisingBuild;
