import { faChevronUp, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC, forwardRef, useEffect, useState } from 'react';
import { ChangeHandler, useFormContext } from 'react-hook-form';
import Toggle from 'react-toggle';

export interface IToggleGroupProps {
  name: string;
  className?: string;
  activeFlags: (string | number)[];
  canToggle?: boolean;
  alwaysExpanded?: boolean;

  onBlur?: ChangeHandler;
  onChange?: ChangeHandler;
  onToggle?: ({
    value,
    state,
  }: {
    value: string | number;
    state: boolean;
  }) => void;
  onUpdate?: () => void;
}

interface IProps extends IToggleGroupProps {
  flags: {
    label?: string;
    value: number | string;
  }[];
}

const ToggleGroup: FC<IProps> = forwardRef(
  (
    {
      name,
      activeFlags,
      flags,
      alwaysExpanded,
      className,
      canToggle,
      onToggle,
      onUpdate,
    },
    ref,
  ) => {
    const { setValue, getValues } = useFormContext();
    const [ isExpanded, setIsExpanded ] = useState(false);

    useEffect(() => {
      if (alwaysExpanded) {
        setIsExpanded(true);
      }
    }, [ alwaysExpanded ]);

    return (
      <div className="flex gap-2 relative glass-pane">
        <div
          className={clsx(
            className,
            'transition-all duration-300 overflow-auto',
          )}
          style={{
            height: `min(${
              32 * (isExpanded ? flags : activeFlags).length
            }px, 50vh)`,
          }}
        >
          {flags
            .filter(x =>
              canToggle && isExpanded ? x : activeFlags.includes(x.value),
            )
            .map(flag => (
              <div key={flag.value} className="flex items-center py-1">
                {canToggle && isExpanded && (
                  <Toggle
                    className="mr-2"
                    checked={activeFlags.includes(flag.value)}
                    onChange={x => {
                      if (x.currentTarget.checked) {
                        setValue(name, getValues(name).concat(flag.value), {
                          shouldDirty: true,
                        });
                      } else {
                        setValue(
                          name,
                          getValues(name).filter(
                            (x: string) => x !== flag.value,
                          ),
                          { shouldDirty: true },
                        );
                      }
                      onToggle?.({
                        value: flag.value,
                        state: x.currentTarget.checked,
                      });
                      onUpdate?.();
                    }}
                  />
                )}
                <div className="h-6">
                  {flag.label || startCase(String(flag.value))}
                </div>
              </div>
            ))}
        </div>
        {!alwaysExpanded && canToggle && (
          <div className="mt-0.5">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={isExpanded ? faChevronUp : faPencil}
              onClick={() => setIsExpanded(x => !x)}
            />
          </div>
        )}
      </div>
    );
  },
);

export default ToggleGroup;
