import { faBan, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Dictionary } from 'lodash';
import { FC } from 'react';
import Toggle from 'react-toggle';
import { IAttendeeCharacterPrintRequestStatusEnum } from 'src/graphql/types';
import useEventPrintouts from 'src/hooks/events/useEventPrintouts';

interface IProps {
  filteredCharacterIds: number[];
  playerGroup: Dictionary<ReturnType<typeof useEventPrintouts>['printouts']>;
  onTicketFilter: ({ ticketId }: { ticketId: number }) => void;
  onCharacterFilter: ({
    characterId,
    value,
  }: {
    characterId: number;
    value: boolean;
  }) => void;
}

const List: FC<IProps> = ({
  filteredCharacterIds,
  playerGroup,
  onTicketFilter,
  onCharacterFilter,
}) => (
  <>
    {Object.entries(playerGroup).map(([ playerId, attendee ]) => (
      <div key={playerId} className="w-full">
        <div className="flex items-center gap-2 border-b border-juno-gray-700">
          <div className="w-20 text-right">#{playerId}</div>
          <div className="w-full flex justify-between items-center">
            <div className="w-full whitespace-nowrap overflow-hidden">
              {attendee[0].user.fullName}
            </div>
            <button
              type="button"
              className="max-w-[50vw] sm:max-w-none overflow-hidden sm:overflow-visible opacity-75 text-sm py-0.5 px-2 rounded-xs bg-juno-gray-700 whitespace-nowrap mb-1 hover:brightness-150 transition-all duration-300"
              onClick={() =>
                onTicketFilter({ ticketId: attendee[0].ticket.id })
              }
            >
              {attendee[0].ticket.label}
            </button>
          </div>
        </div>
        <div className="grid gap-1 py-2">
          {attendee[0].attendeeCharacters
            .sort((a, b) => a.character.name.localeCompare(b.character.name))
            .map(atc => (
              <div key={atc.id} className="flex gap-2">
                <div className="w-20 text-right">
                  <Toggle
                    checked={!filteredCharacterIds.includes(atc.character.id)}
                    onChange={x =>
                      onCharacterFilter({
                        characterId: atc.character.id,
                        value: x.target.checked,
                      })
                    }
                  />
                </div>
                <div
                  className={clsx(
                    'flex justify-between w-full',
                    filteredCharacterIds.includes(atc.character.id) &&
                      'opacity-50',
                  )}
                >
                  <div>{atc.character.name}</div>
                  <FontAwesomeIcon
                    icon={
                      atc.printRequestStatus ===
                      IAttendeeCharacterPrintRequestStatusEnum.PrintRequested
                        ? faPrint
                        : faBan
                    }
                    className="opacity-50"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    ))}
  </>
);

export default List;
