import { format, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { FC } from 'react';
import { IItemKindEnum } from 'src/graphql/types';

interface IProps {
  itemName: string;
  itemKind: IItemKindEnum;
  expiresAt?: string;
  registeredItemDescription?: string | null;
}

const InventoryItem: FC<IProps> = ({
  itemName,
  itemKind,
  expiresAt,
  registeredItemDescription,
}) => (
  <div>
    <div>{itemName}</div>
    <div className="text-xs text-juno-gray-200 uppercase">
      {startCase(itemKind)}
    </div>
    {expiresAt && (
      <div className="text-sm">
        Expires: {format(parseISO(expiresAt), 'yyyy MMM dd')}
      </div>
    )}
    {registeredItemDescription && (
      <div className="text-juno-gray-200">{registeredItemDescription}</div>
    )}
  </div>
);

export default InventoryItem;
