import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC } from 'react';
import { IFellowship, IFellowshipKindEnum } from 'src/graphql/types';
import Collapsible from 'src/components/0100_collapsible';
import Details from '../Details';

interface IProps {
  fellowship: Pick<
    IFellowship,
    'id' | 'name' | 'charactersCount' | 'removedAt'
  >;
  type: IFellowshipKindEnum;
  isExpanded: boolean;
}
const Fellowship: FC<IProps> = ({ fellowship, isExpanded, type }) => (
  <div>
    <button
      type="button"
      className={clsx(
        'w-full flex gap-2 items-center border-l-4 px-2 py-2 hover:border-juno-gray-200 transition-all duration-300',
        isExpanded
          ? 'border-juno-gray-50 glass-pane text-juno-gray-50'
          : 'border-transparent text-juno-gray-200',
      )}
    >
      <div className="w-full text-left">{fellowship.name}</div>
      <div className="flex gap-2 items-center">
        <div className="text-right">{fellowship.charactersCount}</div>
        <div className="hidden lg:block">
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(
              'transition-all duration-300',
              isExpanded && '-rotate-90',
            )}
          />
        </div>
      </div>
    </button>
    <Collapsible
      isExpanded={isExpanded}
      classNames={{ child: 'w-full block lg:hidden' }}
    >
      <Details isEmbedded type={type} />
    </Collapsible>
  </div>
);

export default Fellowship;
