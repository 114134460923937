import { FC } from 'react';

interface IProps {
  text?: string | null;
}

const PlayerNotes: FC<IProps> = ({ text }) => {
  if (!text) return null;

  return (
    <div>
      <div className="font-bold border-b border-juno-gray-200">Notes</div>
      <div className="whitespace-pre text-sm">{text}</div>
    </div>
  );
};

export default PlayerNotes;
