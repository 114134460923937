import { FC, useMemo, useState } from 'react';
import { useOutsideClickRef } from 'rooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import useCharacterBuilder from '../../../hooks/useCharacterBuilder';

import '../index.css';

interface IProps {
  className: string;
  canEdit: boolean;
  name: string;
  level?: number;
  underlayLevel?: number;
  maxLevel?: number;
  alwaysDisplayControl?: boolean;
  isProfession?: boolean;
  isProfessionSkill?: boolean;
  isHovered?: boolean;
  onChange: (x: number) => void;
}

const BasicNode: FC<IProps> = ({
  name,
  className,
  canEdit,
  level = 0,
  underlayLevel,
  maxLevel = 1,
  alwaysDisplayControl = false,
  isProfession = false,
  isProfessionSkill = false,
  isHovered = false,
  onChange,
}) => {
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ ref ] = useOutsideClickRef(() => setIsExpanded(false));
  const {
    professionDependentLevelsRequirement: locks,
    canIncrementSkillLevel,
    canIncrementProfessionSkillLevel,
    canIncrementStat,
    canUnlockProfession,
    canDecrementSkillOrStat,
    getSkillXp,
    hasProfession,
    professionUnlockProgress,
  } = useCharacterBuilder({
    canEdit,
    levelCap: maxLevel,
  });

  const segment = useMemo(() => locks(name), [ locks, name ]);
  const nodeXp = useMemo(
    () => getSkillXp(name, level),
    [ getSkillXp, name, level ],
  );
  const fulfilled = useMemo(
    () => professionUnlockProgress(name),
    [ name, professionUnlockProgress ],
  );
  const canIncrement =
    canIncrementSkillLevel(name) ||
    canUnlockProfession(name) ||
    canIncrementProfessionSkillLevel(name) ||
    canIncrementStat(name);

  return (
    <div ref={ref} className="relative">
      <div className={clsx('flex justify-between items-center rounded-xs')}>
        <button
          type="button"
          disabled={!canDecrementSkillOrStat(name)}
          className={clsx(
            'text-2xl rounded-l z-20 disabled:opacity-25 node-remove',
            alwaysDisplayControl ? 'block' : 'hidden sm:block',
            canDecrementSkillOrStat(name) && className,
          )}
          onClick={() => onChange(-1)}
        >
          -
        </button>
        <button
          type="button"
          className={clsx(
            'hidden sm:block whitespace-nowrap overflow-x-hidden w-full rounded-l text-left z-10 node-basic',
            level === 0 && 'opacity-50',
          )}
          onClick={() => setIsExpanded(x => !x)}
        >
          <div className="flex justify-between items-center relative">
            <div
              className={clsx(
                'trasition-all duration-300',
                isHovered && nodeXp > 0 && 'opacity-25',
                nodeXp > 0 && 'text-shadow',
              )}
            >
              {name}
              {maxLevel > 3 && level > 1 && (
                <sup className="pl-0.5">{level}</sup>
              )}
            </div>
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  className="absolute right-0 text-shadow"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {nodeXp > 0 && <div>{`${nodeXp}XP`}</div>}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </button>
        <button
          type="button"
          className={clsx(
            'block sm:hidden whitespace-nowrap overflow-x-hidden w-full rounded-l text-left z-10 node-basic',
            level === 0 && 'opacity-50',
          )}
          onClick={() => setIsExpanded(x => !x)}
        >
          {name}
          {maxLevel > 3 && level > 1 && <sup className="pl-0.5">{level}</sup>}
        </button>
        <button
          type="button"
          disabled={!canIncrement}
          className={clsx(
            'text-2xl z-10 node-add',
            alwaysDisplayControl ? 'block' : 'hidden sm:block',
            level === maxLevel && 'invisible',
            canIncrement ? className : '-mt-1 disabled:opacity-25',
          )}
          onClick={() => onChange(1)}
        >
          {canIncrement ? (
            '+'
          ) : (
            <FontAwesomeIcon icon={faLock} className="text-base" />
          )}
        </button>
      </div>
      <div className="absolute w-full -mt-[32px] progress-skill">
        <div className="h-[32px] py-1 rounded-l progress-skill midtone-box">
          &nbsp;
        </div>

        <div
          className="h-[33px] -mt-[33px] py-1 rounded-l transition-all duration-300 progress-skill gray-cyan-box"
          style={{
            width: `${((underlayLevel ?? 0) * 100) / maxLevel}%`,
          }}
        >
          &nbsp;
        </div>

        <div
          className={clsx(
            'h-[33px] -mt-[33px] py-1 rounded-l transition-all duration-300 progress-skill',
            isProfession && !hasProfession(name) && 'saturate-50 brightness-50',
            className,
          )}
          style={{
            width: isProfession
              ? `${Math.min(100, (fulfilled * 100) / segment)}%`
              : `${(level * 100) / maxLevel}%`,
          }}
        >
          &nbsp;
        </div>
      </div>
      {!alwaysDisplayControl && (
        <div className="relative h-0 z-30 block sm:hidden">
          <div
            className={clsx(
              'absolute -top-2 w-full transition-all duration-1000 overflow-auto grid grid-cols-1 gap-1',
              isExpanded ? 'max-h-128px' : 'max-h-0',
              isProfession || isProfessionSkill ? '-left-3' : 'left-3',
            )}
          >
            <div className="flex justify-center items-center">
              <button
                type="button"
                disabled={!canDecrementSkillOrStat(name)}
                className="rounded-l white-box text-juno-gray-900 text-2xl disabled:brightness-50 node-remove"
                onClick={e => {
                  e.stopPropagation();
                  onChange(-1);
                }}
              >
                -
              </button>
              <button
                type="button"
                className="white-box w-full text-center text-juno-gray-900 py-1"
                onClick={e => e.stopPropagation()}
              >
                {canIncrement || level > 0 ? (
                  `${level} / ${maxLevel}`
                ) : (
                  <FontAwesomeIcon icon={faLock} />
                )}
              </button>
              <button
                type="button"
                disabled={!canIncrement}
                className="rounded-r white-box text-juno-gray-900 text-2xl disabled:brightness-50 node-add mobile-dropdown"
                onClick={e => {
                  e.stopPropagation();
                  onChange(1);
                }}
              >
                +
              </button>
            </div>
            {nodeXp > 0 && (
              <div className="rounded-xs white-box text-juno-gray-900 text-center">
                {`${nodeXp} XP`}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicNode;
