import { FC, useEffect, useState } from 'react';
import Title from 'src/components/0100_title';
import useEventAttendee from 'src/hooks/events/useEventAttendee';
import { IShiftKindEnum } from 'src/graphql/types';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import Loading from 'src/components/0100_loading';
import usePermission from 'src/hooks/permissions/usePermissions';
import ShiftList from './ShiftList';
import useTicketShiftRequirement from '../hooks/useTicketShiftRequirement';

const ShiftSelector: FC = () => {
  const { event, eventAttendee } = useEventAttendee();
  const [ showAllShifts, setShowAllShifts ] = useState(false);
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number(event?.id),
  });
  const canEditShift = canAssistCheckin || !eventAttendee;
  const { setValue } = useFormContext();
  const {
    castingShiftResponsibilities,
    requiresCleanupShift,
    selectedCastingShifts,
    selectedCleanupShifts,
    selectedShifts,
    selectedSpecialShifts,
    ticketId,
  } = useTicketShiftRequirement();

  useEffect(() => {
    if (!requiresCleanupShift) {
      setValue('shiftIds', []);
    }
  }, [ requiresCleanupShift, setValue ]);

  useEffect(() => {
    setShowAllShifts(!eventAttendee);
  }, [ eventAttendee ]);

  if (!event?.shifts)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <>
      {eventAttendee && (
        <button
          type="button"
          className="col-span-full flex justify-end -mb-8 underline"
          onClick={() => setShowAllShifts(x => !x)}
        >
          {showAllShifts ? 'Show Only Selected Shifts' : 'Show All Shifts'}
        </button>
      )}
      <div>
        <div
          className={clsx(
            (!ticketId || !requiresCleanupShift) &&
              'opacity-50 pointer-events-none',
          )}
        >
          <Title variant="heading">
            <div className="flex justify-between items-end">
              <div className="text-2xl">NPC</div>
              <div
                className={clsx(
                  selectedCastingShifts.length > castingShiftResponsibilities &&
                    'text-juno-orange-200',
                )}
              >
                {castingShiftResponsibilities === 0
                  ? ticketId
                    ? selectedSpecialShifts.length > 0
                      ? 'Special Shift Selected'
                      : 'Not Required'
                    : 'Select Ticket First'
                  : `${selectedCastingShifts.length}/${castingShiftResponsibilities} Required`}
              </div>
            </div>
          </Title>
          <ShiftList
            canEditShift={canEditShift}
            showAllShifts={showAllShifts}
            shifts={event?.shifts.filter(x => x.kind === 'casting') || []}
            kind={IShiftKindEnum.Casting}
            onActivate={x =>
              setValue(
                'shiftIds',
                [
                  ...selectedShifts
                    .filter(y => y.kind !== 'special')
                    .map(y => y.id),
                  x,
                ],
                { shouldDirty: true },
              )
            }
          />
        </div>
        <div
          className={clsx(
            (!ticketId || !requiresCleanupShift) &&
              'opacity-50 pointer-events-none',
          )}
        >
          <Title variant="heading">
            <div className="flex justify-between items-end">
              <div className="text-2xl">Special</div>

              {ticketId ? (
                requiresCleanupShift ? (
                  'Not Required'
                ) : (
                  ''
                )
              ) : (
                <div>Select Ticket First</div>
              )}
            </div>
          </Title>
          <ShiftList
            canEditShift={canEditShift}
            showAllShifts={showAllShifts}
            shifts={event?.shifts.filter(x => x.kind === 'special') || []}
            kind={IShiftKindEnum.Special}
            onActivate={x =>
              setValue(
                'shiftIds',
                [
                  ...selectedShifts
                    .filter(y => y.kind !== 'casting')
                    .map(y => y.id),
                  x,
                ],
                { shouldDirty: true },
              )
            }
          />
        </div>
      </div>
      <div>
        <div
          className={clsx(
            (!ticketId || !requiresCleanupShift) &&
              'opacity-50 pointer-events-none',
          )}
        >
          <Title variant="heading">
            <div className="flex justify-between items-end">
              <div className="text-2xl">Cleanup</div>
              <div
                className={clsx(
                  selectedCleanupShifts.length > 1 && 'text-juno-orange-200',
                )}
              >
                {ticketId
                  ? !requiresCleanupShift
                    ? 'Not Required'
                    : `${selectedCleanupShifts.length}/1 Required`
                  : 'Select Ticket First'}
              </div>
            </div>
          </Title>
          <ShiftList
            canEditShift={canEditShift}
            showAllShifts={showAllShifts}
            shifts={event?.shifts.filter(x => x.kind === 'cleanup') || []}
            kind={IShiftKindEnum.Cleanup}
            onActivate={x =>
              setValue(
                'shiftIds',
                [
                  ...selectedShifts
                    .filter(y => y.kind !== 'cleanup')
                    .map(y => y.id),
                  x,
                ],
                { shouldDirty: true },
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default ShiftSelector;
