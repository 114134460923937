import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from '@tanstack/react-router';
import LinkOrButton from '../0100_link_or_button';

export interface IProps extends ComponentPropsWithoutRef<'button'> {
  label: string | ReactNode;
  to?: string;
  className?: string;
  highlightMode?: 'responsive' | 'horizontal' | 'vertical';
  isActive?: boolean;
  isCompact?: boolean;
  isLocked?: boolean;
  onClick?: () => void;
}

const Tab: FC<IProps> = ({
  label,
  to,
  className,
  highlightMode = 'horizontal',
  isActive: overrideIsActive,
  isCompact = false,
  isLocked = false,
  onClick,
  ...props
}) => {
  const { pathname } = useLocation();
  const defaultIsActive = pathname === to;
  const isActive =
    overrideIsActive !== undefined ? overrideIsActive : defaultIsActive;

  return (
    <LinkOrButton
      to={to}
      className={clsx(
        'px-4 flex items-center justify-between w-full whitespace-nowrap transition-all duration-300',
        isActive ? 'border-juno-gray-200' : 'border-transparent',
        isLocked
          ? 'text-juno-gray-700 cursor-not-allowed pointer-events-none'
          : 'cursor-pointer hover:text-shadow hover:border-juno-gray-200',
        isCompact ? 'py-1' : 'py-2',
        highlightMode === 'horizontal' && 'border-b-4',
        highlightMode === 'vertical' && 'border-l-4 w-full',
        highlightMode === 'responsive' &&
          'border-l-4 lg:border-b-4 lg:border-l-0 lg:mt-0.5',
        className,
      )}
      onClick={onClick}
      {...props}
    >
      {label}
      {isLocked && <FontAwesomeIcon icon={faLock} className="pl-2" />}
    </LinkOrButton>
  );
};

export default Tab;
