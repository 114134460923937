import { faDollarSign, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'src/components/0100_button';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';

const ShowRevenue: FC = () => {
  const { setValue, watch } = useFormContext();
  const { showRevenue } = watch();
  const { buttonState } = useButtonStates();

  // return <button>{showRevenue ? 'Hide Revenue' : 'Show Revenue'}</button>;
  return (
    <>
      <Button
        className="block sm:hidden"
        defaultLabel={
          showRevenue ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
            <FontAwesomeIcon icon={faDollarSign} />
          )
        }
        state={buttonState({
          isValid: true,
          isDirty: true,
          isHighlight: true,
        })}
        onClick={() => setValue('showRevenue', !showRevenue)}
      />
      <Button
        className="hidden sm:block w-36"
        defaultLabel={showRevenue ? 'Hide Revenue' : 'Show Revenue'}
        state={buttonState({
          isValid: true,
          isDirty: true,
          isHighlight: true,
        })}
        onClick={() => setValue('showRevenue', !showRevenue)}
      />
    </>
  );
};

export default ShowRevenue;
