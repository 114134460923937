import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface IProps {
  stack?: number;
  limit: number;
  onAdjustSelection?: (stack: number) => void;
}

const Selection: FC<IProps> = ({ stack, limit, onAdjustSelection }) => (
  <div className="flex items-center gap-1">
    <button
      type="button"
      className="disabled:opacity-10"
      disabled={(stack ?? 0) === 0}
      onClick={() => onAdjustSelection?.((stack ?? 0) - 1)}
    >
      <FontAwesomeIcon icon={faCaretLeft} />
    </button>
    {stack ?? 0}
    <button
      type="button"
      className="disabled:opacity-10"
      disabled={(stack ?? 0) === limit}
      onClick={() => onAdjustSelection?.((stack ?? 0) + 1)}
    >
      <FontAwesomeIcon icon={faCaretRight} />
    </button>
  </div>
);

export default Selection;
