import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLinkProps, useParams } from '@tanstack/react-router';
import Tab from 'src/components/0200_tab';
import usePermission from 'src/hooks/permissions/usePermissions';

const CreateNewEventSidebar = () => {
  const { organizationSlug } = useParams({ strict: false });
  const linkToNewEvent = useLinkProps({
    to: '/$organizationSlug/events/$eventId',
    params: {
      organizationSlug: String(organizationSlug),
      eventId: 'new',
    },
  });
  const { isPermitted: canCreateNewEvent } = usePermission({
    action: 'create_event_ui',
  });

  if (!canCreateNewEvent) return null;

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faPlus} className="pr-2 fa-fw" />
          Create New Event
        </div>
      }
      to={linkToNewEvent.href}
    />
  );
};

export default CreateNewEventSidebar;
