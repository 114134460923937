import useEventAttendee from 'src/hooks/events/useEventAttendee';
import ResponseBox from 'src/components/0100_response_box';
import usePermission from 'src/hooks/permissions/usePermissions';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import useCharacterChangeDetection from '../../hooks/useCharacterChangeDetection';

const CharacterChangeDetection = () => {
  const { event } = useEventAttendee();
  const { isPermitted: canAssistCheckin } = usePermission({
    action: 'checkin_character',
    eventId: Number(event?.id),
  });
  const { hasCharactersChanged } = useCharacterChangeDetection();

  if (!hasCharactersChanged) return null;

  return (
    <ResponseBox type="error" withIcon={faTriangleExclamation}>
      Membership change has affected Checked-in Characters.
      {canAssistCheckin
        ? ' Please Update this Check-in.'
        : ' Please contact your Game Runner.'}
    </ResponseBox>
  );
};

export default CharacterChangeDetection;
