import { ResponsiveBar } from '@nivo/bar';
import { fromPairs, groupBy, keyBy } from 'lodash';
import { FC, useMemo } from 'react';
// import Collapsible from 'src/components/0100_collapsible';
import useOrganizationTimelines from 'src/hooks/organizations/useOrganizationTimelines';
import { format, parseISO } from 'date-fns';
import { useParams } from '@tanstack/react-router';
import theme from '../theme';

const Timelines: FC = () => {
  const { branchId } = useParams({ strict: false });
  const { timelines } = useOrganizationTimelines();
  const scopedBranchTimelines = useMemo(
    () => timelines.filter(x => (branchId ? x.id === Number(branchId) : x)),
    [ branchId, timelines ],
  );
  const shorthands = useMemo(
    () =>
      Object.keys(keyBy(scopedBranchTimelines, 'shorthand')).sort((a, b) =>
        a.localeCompare(b),
      ),
    [ scopedBranchTimelines ],
  );

  const timecodes = useMemo(() => groupBy(timelines, 'timecode'), [ timelines ]);
  const nivoData = useMemo(
    () =>
      Object.keys(timecodes)
        .slice(-12)
        .sort((a, b) => a.localeCompare(b))
        .map(timecode => ({
          timecode: format(parseISO(timecode), 'yyyy MMM'),
          ...(branchId
            ? {
                local: timecodes[timecode]
                  .filter(x => x.local && x.id === Number(branchId))
                  .map(x => x.attendance)
                  .reduce((a, b) => a + b, 0),
                traveler: timecodes[timecode]
                  .filter(x => !x.local && x.id === Number(branchId))
                  .map(x => x.attendance)
                  .reduce((a, b) => a + b, 0),
              }
            : fromPairs(
                shorthands.map(shorthand => [
                  shorthand,
                  timecodes[timecode]
                    .filter(x => x.shorthand === shorthand)
                    .map(x => x.attendance)
                    .reduce((a, b) => a + b, 0),
                ]),
              )),
        })),
    [ branchId, shorthands, timecodes ],
  );

  return (
    <div>
      <div className="h-[320px] w-full">
        <ResponsiveBar
          enableTotals
          data={nivoData}
          keys={branchId ? [ 'local', 'traveler' ] : shorthands}
          indexBy="timecode"
          margin={{ top: 32, right: 32, bottom: 64, left: 64 }}
          theme={theme}
          colors={{ scheme: 'tableau10' }}
          totalsOffset={8}
          labelSkipHeight={16}
          axisLeft={{
            legend: 'Attendance',
            legendPosition: 'middle',
            legendOffset: -48,
          }}
        />
      </div>
      {/* <div className="grid xl:grid-cols-3 gap-2 place-items-start">
        <Collapsible isExpanded>
          {shorthands.map(shorthand => (
            <div key={shorthand}>{shorthand}</div>
          ))}
        </Collapsible>
        <Collapsible isExpanded>
          {Object.keys(timecodes)
            .sort((a, b) => a.localeCompare(b))
            .map(timecode => (
              <div key={timecode}>{timecode}</div>
            ))}
        </Collapsible>
        <Collapsible isExpanded>
          <div>Virtual</div>
          <div>Locality</div>
        </Collapsible>
      </div> */}
    </div>
  );
};

export default Timelines;
