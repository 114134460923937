import { FC } from 'react';
import { TAttendanceInvoice } from '../Formatted/AttendanceInvoice';

interface IProps {
  attendances: TAttendanceInvoice[];
}

const Raw: FC<IProps> = ({ attendances }) => (
  <div className="overflow-auto text-juno-gray-200 text-xs select-all p-1">
    <pre>
      {[
        'ID',
        'Chg',
        'Res',
        'Ticket',
        'Player ID',
        'Player Name',
        '+XP',
        'Paid',
        'Attending',
        'Base',
        '+XP',
        'Sub',
      ].join(',')}
      {'\n'}
      {attendances
        .map(x =>
          [
            x.id,
            x.change?.kind,
            x.kind,
            x.ticket.label,
            x.user.id,
            x.user.name,
            x.buildGrowth,
            x.paid,
            x.attending,
            x.baseCost,
            x.extraXpCost,
            x.totalCost,
          ]
            .map(y => `"${y}"`)
            .join(','),
        )
        .join('\n')}
    </pre>
  </div>
);

export default Raw;
