import { useParams } from '@tanstack/react-router';
import { getBranch } from 'src/graphql/queries/branches.graphql';
import {
  IGetBranchQuery,
  IGetBranchQueryVariables,
} from 'src/graphql/queries/branches.graphql.types';
import useRootUserRevalidation from 'src/hooks/useRootUserRevalidation';
import { useQuery } from 'urql';

const useBranch = ({ overrideBranchId }: { overrideBranchId?: number }) => {
  const { branchId } = useParams({ strict: false });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetBranchQuery,
    IGetBranchQueryVariables
  >({
    query: getBranch,
    variables: { branchId: Number(overrideBranchId ?? branchId) },
    requestPolicy: 'cache-and-network',
    pause: Number(branchId) <= 0,
  });

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    fetching,
    stale,
    locations: data?.branch?.locations ?? [],
  };
};

export default useBranch;
