import { Link, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import useItemEditPermission from 'src/components/0500_blueprints/dystopia_rising/hooks/useItemEditPermission';
import {
  destroyItemReproductionComponent,
  updateItemReproductionComponent,
} from 'src/graphql/mutations/items.graphql';
import {
  IDestroyItemReproductionComponentMutation,
  IDestroyItemReproductionComponentMutationVariables,
  IUpdateItemReproductionComponentMutation,
  IUpdateItemReproductionComponentMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemKindEnum } from 'src/graphql/types';
import { useMutation } from 'urql';

export interface IReproductionComponent {
  id: number;
  amount: number;
  item: {
    id: number;
    name: string;
    kind: IItemKindEnum;
  };
}

const ReproductionComponent: FC<IReproductionComponent> = ({ ...props }) => {
  const { organizationSlug } = useParams({ strict: false });
  const { canEdit } = useItemEditPermission();
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      amount: 1,
    },
  });
  const { amount } = watch();

  const [ , update ] = useMutation<
    IUpdateItemReproductionComponentMutation,
    IUpdateItemReproductionComponentMutationVariables
  >(updateItemReproductionComponent);
  const [ , destroy ] = useMutation<
    IDestroyItemReproductionComponentMutation,
    IDestroyItemReproductionComponentMutationVariables
  >(destroyItemReproductionComponent);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;

    setIsSuccessful(false);
    setError(null);
    update({
      itemReproductionComponentId: props.id,
      amount: Number(amount),
    }).then(res => {
      if (
        res.data?.updateItemReproductionComponent?.itemReproductionComponent
      ) {
        setIsSuccessful(true);
        setTimeout(() => setIsSuccessful(false), 3000);
      }

      if (res.data?.updateItemReproductionComponent?.error) {
        setError(res.data.updateItemReproductionComponent.error);
      }
    });
  }, [ amount, isDirty, props.id, update ]);

  const handleDestroy = useCallback(() => {
    destroy({ itemReproductionComponentId: props.id });
    setIsDeleting(true);
  }, [ destroy, props.id ]);

  useEffect(() => {
    reset({
      amount: props.amount,
    });
  }, [ props.id, props.amount, reset ]);

  if (isDeleting) return null;

  return (
    <div
      className={clsx(
        'flex justify-between items-center gap-4 w-full p-2 border-l-4 border-b border-juno-gray-700 transition-all duration-300 hover:border-l-juno-gray-200 hover:text-shadow',
        isSuccessful && 'border-l-juno-cyan-200 bg-juno-cyan-200',
      )}
    >
      <div className="flex items-center gap-4">
        <div className="w-20">
          <Input
            fullWidth
            disabled={!canEdit}
            type="number"
            className="text-right"
            {...register('amount')}
            onBlur={handleUpdate}
          />
        </div>
        <div>
          <Link
            className="underline"
            to="/$organizationSlug/blueprints/$"
            params={{
              organizationSlug: String(organizationSlug),
              _splat: String(props.item.id),
            }}
          >
            {props.item.name}
          </Link>
          <div className="text-sm opacity-75">{startCase(props.item.kind)}</div>
          {error && <div className="text-juno-orange-400">{error}</div>}
        </div>
      </div>
      {canEdit && (
        <div>
          <ConfirmDropdown onConfirm={handleDestroy} />
        </div>
      )}
    </div>
  );
};

export default ReproductionComponent;
