import { getCharacter } from 'src/graphql/queries/characters.graphql';
import { useQuery } from 'urql';
import {
  IGetCharacterQuery,
  IGetCharacterQueryVariables,
} from 'src/graphql/queries/characters.graphql.types';
import useCharacterBuilderStore from 'src/stores/characterBuilder';
import usePermission from '../permissions/usePermissions';
import useRootUserRevalidation from '../useRootUserRevalidation';

const useCharacter = ({
  playerId,
  characterId,
}: {
  playerId: number;
  characterId: number;
}) => {
  const { isSafetyLockActive } = useCharacterBuilderStore();
  const { isPermitted: canOverride } = usePermission({
    action: 'update_character_data_sensitive',
    characterId: Number(characterId),
  });
  const [{ data, error, fetching, stale }, refetch ] = useQuery<
    IGetCharacterQuery,
    IGetCharacterQueryVariables
  >({
    query: getCharacter,
    variables: { playerId, characterId },
    requestPolicy: 'cache-and-network',
    pause: true,
  });
  const stagedOrExperimental = [ 'staged', 'experimental' ].includes(
    data?.user?.character?.status ?? '',
  );

  useRootUserRevalidation({ refetch });

  return {
    data,
    error,
    player: data?.user,
    character: data?.user?.character,
    canEditSensitiveData: canOverride,
    canDisableSafetyLock: canOverride || stagedOrExperimental,
    canEdit: (canOverride || stagedOrExperimental) && !isSafetyLockActive,
    fetching,
    stale,
    refetch,
  };
};

export default useCharacter;
