import {
  addMonths,
  differenceInCalendarDays,
  eachMonthOfInterval,
  isAfter,
} from 'date-fns';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import Dot from './Dot';

type TDot = {
  id: number;
  date: Date;
  content: ReactNode;
  className?: string;
};

interface IProps {
  dots: TDot[];
  startDate?: Date;
  endDate?: Date;
}

const PunchTape: FC<IProps> = ({
  dots,
  startDate = addMonths(new Date(), -6),
  endDate = addMonths(new Date(), 1),
}) => {
  const daysInRange = differenceInCalendarDays(startDate, endDate);
  const months = useMemo(
    () =>
      eachMonthOfInterval({ start: startDate, end: endDate }).filter(
        x => isAfter(x, startDate) && isAfter(endDate, x),
      ),
    [ endDate, startDate ],
  );
  const position = useCallback(
    ({ point }: { point: Date }) => {
      const daysFromOrigin = differenceInCalendarDays(startDate, point);
      return daysFromOrigin / daysInRange;
    },
    [ daysInRange, startDate ],
  );

  return (
    <div className="w-full gray-box h-8 relative">
      {dots.map(dot => (
        <Dot
          key={dot.id}
          id={dot.id}
          content={dot.content}
          position={`${position({ point: dot.date }) * 100}%`}
          className={dot.className}
        />
      ))}
      {months.map(month => (
        <div
          key={month.toUTCString()}
          className="absolute h-8 w-[1px] z-0 border-l border-slate-700/90"
          style={{ left: `${position({ point: month }) * 100}%` }}
        />
      ))}
      <div
        className="absolute h-8 w-[1px] border border-juno-cyan-200"
        style={{ left: `${position({ point: new Date() }) * 100}%` }}
      />
    </div>
  );
};

export default PunchTape;
