import { useMemo } from 'react';
import useXp from '../../hooks/useXp';

const useXpData = () => {
  const costs = useXp();
  const donutData = useMemo(
    () => ({
      id: 'root',
      name: 'root',
      children: [
        {
          id: 'Stats',
          name: 'Stats',
          color: '#629134',
          value: costs.statsCost,
        },
        {
          id: 'Professions',
          name: 'Professions',
          color: '#ca8a04',
          value: costs.professionsCost,
        },
        {
          id: 'Skills',
          name: 'Skills',
          color: '#be185d',
          children: [
            {
              id: 'Default',
              name: 'Default',
              value: costs.weaponProfessionSkillsCost,
              color: '#65a30d',
            },

            {
              id: 'Impact',
              name: 'Impact',
              color: '#c66d3a',
              value: costs.impactsCost,
            },
            {
              id: 'Development',
              name: 'Development',
              color: '#7e22ce',
              value: costs.developmentsCost,
            },
            {
              id: 'Aberrants',
              name: 'Aberrants',
              color: '#06b6d4',
              value: costs.aberrantsCost,
            },
          ],
        },
        {
          id: 'Unallocated',
          name: 'Unallocated',
          color: '#555',
          value: Math.max(0, costs.unspentXp),
        },
      ],
    }),
    [ costs ],
  );

  return { costs, donutData };
};

export default useXpData;
