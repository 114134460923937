import { FC, useCallback, useEffect, useState } from 'react';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Badge from 'src/components/0100_badge';
import { fulfillShift } from 'src/graphql/mutations/events.graphql';
import {
  IFulfillShiftMutation,
  IFulfillShiftMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { IAttendeeShift, IUser } from 'src/graphql/types';
import { useMutation } from 'urql';
import clsx from 'clsx';

export type TAttendeeShift = Pick<IAttendeeShift, 'id' | 'status'> & {
  player: Pick<IUser, 'id' | 'fullName' | 'roleInOrganization'>;
};

interface IProps {
  attendeeShift: TAttendeeShift;
}

const AttendeeShift: FC<IProps> = ({ attendeeShift }) => {
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState(false);
  const { reset, setValue, watch } = useForm({
    defaultValues: { isFulfilled: false },
  });
  const { isFulfilled } = watch();
  const [ , update ] = useMutation<
    IFulfillShiftMutation,
    IFulfillShiftMutationVariables
  >(fulfillShift);

  const handleChange = useCallback(() => {
    const nextValue = !isFulfilled;
    setValue('isFulfilled', nextValue);
    update({
      attendeeShiftId: attendeeShift.id,
      isFulfilled: nextValue,
    }).then(res => {
      if (res.data?.fulfillShift?.attendeeShift) {
        setIsUpdateSuccessful(true);
        setTimeout(() => setIsUpdateSuccessful(false), 1000);
      }
    });
  }, [ attendeeShift.id, isFulfilled, setValue, update ]);

  useEffect(() => {
    if (attendeeShift) {
      reset({
        isFulfilled: attendeeShift.status === 'fulfilled',
      });
    }
  }, [ attendeeShift, reset ]);

  return (
    <button
      type="button"
      className={clsx(
        'flex items-center w-full transition-all ease-in-out pb-2',
        isUpdateSuccessful ? 'bg-lime-600/75' : 'bg-transparent',
      )}
      onClick={handleChange}
    >
      <div className="flex qjustify-around w-full">
        <div className="flex justify-start w-full gap-2">
          <div className="w-16 text-right">{attendeeShift.player.id}</div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={isFulfilled ? faCheckSquare : faSquare} />
            <div>{attendeeShift.player.fullName}</div>
            <Badge type={attendeeShift.player.roleInOrganization} />
          </div>
        </div>
      </div>
    </button>
  );
};

export default AttendeeShift;
