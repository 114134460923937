import { FC, useCallback, useEffect, useState } from 'react';
import Loading from 'src/components/0100_loading';
import ResponseBox from 'src/components/0100_response_box';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import useEventPrintouts from 'src/hooks/events/useEventPrintouts';
import { groupBy, without } from 'lodash';
import Toggle from 'react-toggle';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import { useSearch } from '@tanstack/react-router';
import useTicketFilter from '../hooks/useTicketFilter';
import Summary from './Summary';
import List from './List';

interface IProps {
  event: ReturnType<typeof useEventPrintouts>['event'];
  printouts: ReturnType<typeof useEventPrintouts>['printouts'];
  fetching: boolean;
}

const Results: FC<IProps> = ({ event, printouts, fetching }) => {
  const {
    print_requested: printRequested,
    print_paid: paid,
    print_local: homeGame,
    print_ticket_ids: ticketIds,
  } = useSearch({ strict: false });
  const hasSearchParams = [ printRequested, paid, homeGame, ticketIds ].some(
    x => x !== undefined,
  );
  const { handleChange } = useTicketFilter();
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();
  const hasSearchQuery = query.trim().length > 0;
  const [ filteredCharacterIds, setFilteredCharacterIds ] = useState<number[]>(
    [],
  );
  const queryFilter = useCallback(
    (x: (typeof printouts)[0]) =>
      hasSearchQuery
        ? String(x.user.id) === query.toLowerCase() ||
          x.user.fullName.toLowerCase().includes(query.toLowerCase()) ||
          x.attendeeCharacters.some(atc =>
            atc.character.name.toLowerCase().includes(query.toLowerCase()),
          )
        : true,
    [ hasSearchQuery, query ],
  );
  const sheetsToPrint = printouts
    .filter(queryFilter)
    .map(
      x =>
        x.attendeeCharacters.filter(
          atc => !filteredCharacterIds.includes(atc.character.id),
        ).length,
    )
    .reduce((a, b) => a + b, 0);

  const printablePrintouts = printouts
    .filter(queryFilter)
    .filter(
      x =>
        x.attendeeCharacters.filter(
          atc => !filteredCharacterIds.includes(atc.character.id),
        ).length > 0,
    );

  const playerGroup = groupBy(printouts.filter(queryFilter), x => x.user.id);

  const noPrintableSheets =
    !fetching &&
    !hasSearchParams &&
    !hasSearchQuery &&
    filteredCharacterIds.length === 0 &&
    sheetsToPrint === 0;

  useEffect(() => {
    setFilteredCharacterIds([]);
  }, [ query ]);

  if (!event) return null;
  if (fetching)
    return (
      <div className="flex items-center gap-4">
        <div>
          <Loading />
        </div>
        <div>Rendering Printouts. This may take a while...</div>
      </div>
    );

  return (
    <div className="grid gap-4">
      <div className="sticky top-0 z-10 gray-box pb-1 border-b border-juno-cyan-200">
        <ResponseBox type={sheetsToPrint > 0 ? 'success' : 'neutral'}>
          <Summary
            sheetsToPrint={sheetsToPrint}
            attendeesToPrint={printablePrintouts.length}
            hasAttendees={!noPrintableSheets}
          />
        </ResponseBox>
        <div className="flex items-center gap-2">
          <div className="w-20 text-right">
            <Toggle
              checked={filteredCharacterIds.length === 0}
              onChange={() => {
                if (filteredCharacterIds.length === 0) {
                  setFilteredCharacterIds(
                    printouts
                      .map(x =>
                        x.attendeeCharacters.map(atc => atc.character.id),
                      )
                      .flat(),
                  );
                } else {
                  setFilteredCharacterIds([]);
                }
              }}
            />
          </div>
          <div className="w-full pb-1">
            <Input placeholder="Search..." {...register('query')} />
          </div>
        </div>
      </div>
      <div className="grid gap-2">
        <List
          filteredCharacterIds={filteredCharacterIds}
          playerGroup={playerGroup}
          onTicketFilter={({ ticketId }) => handleChange(ticketId)}
          onCharacterFilter={({ characterId, value }) => {
            if (value) {
              setFilteredCharacterIds(
                filteredCharacterIds.filter(x => x !== characterId),
              );
            } else {
              setFilteredCharacterIds([ ...filteredCharacterIds, characterId ]);
            }
          }}
        />
      </div>
      <div className="printout-container no-render">
        {printouts
          .filter(queryFilter)
          .filter(
            x =>
              without(
                x.attendeeCharacters.map(atc => atc.character.id),
                ...filteredCharacterIds,
              ).length > 0,
          )
          .sort((a, b) => a.user.id - b.user.id)
          .map(printout => (
            <div key={printout.id}>
              {printout.attendeeCharacters
                .filter(x => !filteredCharacterIds.includes(x.character.id))
                .map(atc => (
                  <DystopiaRisingPrintout
                    key={atc.id}
                    character={atc.character.experimentalCharacter}
                    event={event}
                    player={printout.user}
                    shifts={printout.shifts}
                    ticket={printout.ticket}
                  />
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Results;
