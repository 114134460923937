import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useState } from 'react';
import Table from 'src/components/0100_table';
import usePermission from 'src/hooks/permissions/usePermissions';
import usePlayerPerks from 'src/hooks/players/usePlayerPerks';
import { isBefore, parseISO } from 'date-fns';
import { groupBy } from 'lodash';
import Button from 'src/components/0100_button';
import clsx from 'clsx';
import { ICapStateEnum } from 'src/graphql/types';
import Loading from 'src/components/0100_loading';
import usePlayerIdFromUrl from 'src/hooks/players/usePlayerIdFromUrl';
import Perk from './Perk';

const Perks: FC = () => {
  const { playerId } = usePlayerIdFromUrl();
  const [ filters, setFilters ] = useState<string[]>([]);
  const { isPermitted: canUpdatePerks } = usePermission({
    action: 'update_player_data_sensitive',
    playerId: Number(playerId),
  });
  const { perks, fetching } = usePlayerPerks({ playerId: Number(playerId) });
  const perksByBranch = groupBy(perks, 'branch.shorthand');
  const perksBranchSum = Object.keys(perksByBranch).map(branch => ({
    branch,
    amount: perksByBranch[branch]
      .filter(x => x.state === ICapStateEnum.Active)
      .map(x => x.amount)
      .reduce((a, b) => a + b, 0),
  }));

  const isFilterActive = filters.length > 0;
  const handleUpdateFilter = useCallback(
    (branch: string) => {
      if (filters.includes(branch)) {
        setFilters(x => x.filter(y => y !== branch));
      } else {
        setFilters(x => [ ...x, branch ]);
      }
    },
    [ filters ],
  );

  return (
    <div>
      <div
        className={clsx(
          'flex flex-wrap sticky top-0 glass-pane z-10',
          perks.length > 0 && 'py-2',
        )}
      >
        {fetching ? (
          <Loading />
        ) : (
          perksBranchSum
            .sort((a, b) => b.amount - a.amount)
            .map(perkBranch => (
              <Button
                key={perkBranch.branch}
                defaultLabel=""
                state={
                  isFilterActive
                    ? filters.includes(perkBranch.branch)
                      ? 'enabled-highlight'
                      : 'enabled'
                    : 'enabled'
                }
                className="mr-2 mb-2 flex items-center"
                onClick={() => handleUpdateFilter(perkBranch.branch)}
              >
                <div
                  className={clsx(
                    'border-r pr-2',
                    !isFilterActive ||
                      (isFilterActive && filters.includes('branch'))
                      ? 'border-juno-gray-700'
                      : 'border-juno-gray-900',
                  )}
                >
                  {perkBranch.branch}
                </div>
                <div className="pl-2">{perkBranch.amount}</div>
              </Button>
            ))
        )}
        {isFilterActive && (
          <Button
            key="clear-all"
            state="enabled-highlight"
            defaultLabel=""
            className="mr-2 mb-2"
            onClick={() => setFilters([])}
          >
            Clear Filters <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </div>

      <Table
        headers={[
          { content: 'Branch', className: 'min-w-[64px]' },
          { content: 'Grantor', className: 'min-w-[192px]' },
          { content: 'Reason', className: 'min-w-[256px]' },
          { content: 'Amount', className: 'min-w-[64px]' },
          { content: 'Granted At', className: 'min-w-[128px]' },
          canUpdatePerks
            ? {
                content: <FontAwesomeIcon icon={faTrash} />,
                className: 'text-center max-w-[16px]',
              }
            : undefined,
        ]}
      >
        {canUpdatePerks && <Perk key="new-perk" canUpdatePerks />}
        {perks.length > 0 ? (
          perks
            .filter(x =>
              isFilterActive ? filters.includes(x.branch.shorthand) : x,
            )
            .sort((a, b) =>
              isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
            )
            .map(perk => (
              <Perk key={perk.id} canUpdatePerks={canUpdatePerks} perk={perk} />
            ))
        ) : (
          <tr>
            <td colSpan={5} className="p-2">
              No Perks Found
            </td>
          </tr>
        )}
      </Table>
    </div>
  );
};

export default Perks;
