import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@tanstack/react-router';
import { FC } from 'react';

import Tab from 'src/components/0200_tab';
import usePermission from 'src/hooks/permissions/usePermissions';

const CreateNewPlayerSidebar: FC = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { isPermitted: canCreateNewPlayer } = usePermission({
    action: 'create_new_player_ui',
  });

  if (!canCreateNewPlayer) return null;

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faUserPlus} className="pr-2 fa-fw" />
          Create New Player
        </div>
      }
      to={`/${organizationSlug}/players/new`}
    />
  );
};

export default CreateNewPlayerSidebar;
