import { useQuery } from 'urql';
import { getEventInfo } from 'src/graphql/queries/events.graphql';
import {
  IGetEventQuery,
  IGetEventQueryVariables,
} from 'src/graphql/queries/events.graphql.types';
import { useParams } from '@tanstack/react-router';
import useRootUserRevalidation from '../useRootUserRevalidation';

type TUseEvent = { id: number | null };

const useEvent = (prop?: TUseEvent) => {
  const { eventId: eventIdFromParams } = useParams({ strict: false });

  const eventId = prop?.id ?? eventIdFromParams;

  const [{ data, error, fetching }, refetch ] = useQuery<
    IGetEventQuery,
    IGetEventQueryVariables
  >({
    query: getEventInfo,
    variables: { eventId: Number(eventId) },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useRootUserRevalidation({ refetch });

  return { event: data?.event, error, fetching };
};

export default useEvent;
