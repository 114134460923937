import { Link, useParams } from '@tanstack/react-router';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import useItemEditPermission from 'src/components/0500_blueprints/dystopia_rising/hooks/useItemEditPermission';
import {
  destroyItemCraftingFinalProduct,
  updateItemCraftingFinalProduct,
} from 'src/graphql/mutations/items.graphql';
import {
  IDestroyItemCraftingFinalProductMutation,
  IDestroyItemCraftingFinalProductMutationVariables,
  IUpdateItemCraftingFinalProductMutation,
  IUpdateItemCraftingFinalProductMutationVariables,
} from 'src/graphql/mutations/items.graphql.types';
import { IItemGradeEnum, IItemKindEnum } from 'src/graphql/types';
import { useMutation } from 'urql';

export interface IFinalProduct {
  id: number;
  stack: number;
  finalProduct: {
    id: number;
    name: string;
    grade: IItemGradeEnum;
    kind: IItemKindEnum;
  };
}

const ItemFinalProduct: FC<IFinalProduct> = ({ ...props }) => {
  const { canEdit } = useItemEditPermission();
  const { organizationSlug } = useParams({ strict: false });
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);
  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      stack: 1,
    },
  });
  const { stack } = watch();

  const [ , update ] = useMutation<
    IUpdateItemCraftingFinalProductMutation,
    IUpdateItemCraftingFinalProductMutationVariables
  >(updateItemCraftingFinalProduct);
  const [ , destroy ] = useMutation<
    IDestroyItemCraftingFinalProductMutation,
    IDestroyItemCraftingFinalProductMutationVariables
  >(destroyItemCraftingFinalProduct);

  const handleUpdate = useCallback(() => {
    if (!isDirty) return;

    setIsSuccessful(false);
    setError(null);
    update({ itemCraftingFinalProductId: props.id, stack: Number(stack) }).then(
      res => {
        if (res.data?.updateItemCraftingFinalProduct?.craftingFinalProduct) {
          setIsSuccessful(true);
        } else if (res.error) {
          setError(res.error.message);
        }
      },
    );
  }, [ isDirty, props.id, stack, update ]);

  const handleDestroy = useCallback(() => {
    destroy({ itemCraftingFinalProductId: props.id });
    setIsDeleting(true);
  }, [ destroy, props.id ]);

  useEffect(() => {
    reset({
      stack: props.stack,
    });
  }, [ props.stack, props.id, reset ]);

  if (isDeleting) return null;

  return (
    <div
      className={clsx(
        'flex justify-between items-center gap-4 w-full p-2 border-l-4 border-b border-juno-gray-700 transition-all duration-300 hover:border-l-juno-gray-200 hover:text-shadow',
        isSuccessful && 'border-l-juno-purple-200 bg-juno-purple-200',
      )}
    >
      <div className="flex items-center gap-4">
        <div className="w-20">
          <Input
            fullWidth
            disabled={!canEdit}
            type="number"
            className="text-right"
            {...register('stack')}
            onBlur={handleUpdate}
          />
        </div>
        <div>
          <Link
            className="underline"
            to="/$organizationSlug/blueprints/$"
            params={{
              organizationSlug: String(organizationSlug),
              _splat: String(props.finalProduct.id),
            }}
          >
            {props.finalProduct.name}
          </Link>
          <div className="text-sm opacity-75">
            {startCase(props.finalProduct.kind)}
          </div>
          {error && <div className="text-juno-orange-400">{error}</div>}
        </div>
      </div>
      {canEdit && (
        <div>
          <ConfirmDropdown onConfirm={handleDestroy} />
        </div>
      )}
    </div>
  );
};

export default ItemFinalProduct;
