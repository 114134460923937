import { faFont } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/0100_input';
import Textarea from 'src/components/0100_textarea';
import { IItemKindEnum } from 'src/graphql/types';

interface IProps {
  id: number;
  itemCrafting?: {
    id: number;
    craftingFinalProducts: {
      id: number;
      finalProduct?: {
        id: number;
        metadata: {
          mechanics?: string | null;
        };
        childItemClassifications: {
          childItem: {
            id: number;
            kind: IItemKindEnum;
            metadata: {
              mechanics?: string | null;
            };
          };
        }[];
      };
    }[];
  };
}

const fontSizeMap = {
  small: 12,
  large: 15,
};

const Label: FC<IProps> = ({ itemCrafting }) => {
  const [ selectedChildItemId, setSelectedChildItemId ] = useState<
    number | undefined
  >(undefined);
  const { watch, register, setValue } = useForm({
    defaultValues: {
      width: localStorage.getItem('label_width') ?? '60',
      height: localStorage.getItem('label_height') ?? '40',
      fontSize: (localStorage.getItem('label_font_size') ?? 'small') as
        | 'small'
        | 'large',
      content: '',
    },
  });
  const { width, height, fontSize, content } = watch();
  const originalContent = useMemo(
    () =>
      itemCrafting?.craftingFinalProducts
        .map(x => x.finalProduct?.metadata.mechanics)
        .join('\n')
        .split(/At armor creation/)[0]
        .split('!--')
        .filter((_, i) => i % 2 === 0)
        .map(x => x.trim())
        .join(' '),
    [ itemCrafting ],
  );
  const hasChildItems =
    Number(
      itemCrafting?.craftingFinalProducts[0].finalProduct
        ?.childItemClassifications.length,
    ) > 1;

  useEffect(() => {
    if (hasChildItems) {
      setValue(
        'content',
        itemCrafting?.craftingFinalProducts[0].finalProduct?.childItemClassifications.find(
          x => x.childItem.id === selectedChildItemId,
        )?.childItem.metadata.mechanics ?? '',
      );
    } else {
      setValue('content', originalContent ?? '');
    }
  }, [
    hasChildItems,
    itemCrafting?.craftingFinalProducts,
    originalContent,
    selectedChildItemId,
    setValue,
  ]);

  useEffect(() => {
    if (hasChildItems) {
      setSelectedChildItemId(
        itemCrafting?.craftingFinalProducts[0].finalProduct
          ?.childItemClassifications[0].childItem.id,
      );
    }
  }, [ hasChildItems, itemCrafting?.craftingFinalProducts ]);

  useEffect(() => localStorage.setItem('label_width', width), [ width ]);
  useEffect(() => localStorage.setItem('label_height', height), [ height ]);
  useEffect(
    () => localStorage.setItem('label_font_size', fontSize),
    [ fontSize ],
  );

  useEffect(() => {
    document
      .querySelectorAll<HTMLElement>('[data-id="printable-content"]')
      .forEach(t => {
        const target = t;
        target.innerHTML = content;
        target.style.width = '100vw';
        target.style.height = '100vh';
        target.style.fontSize = `${fontSizeMap[fontSize]}pt`;
        target.style.lineHeight = '1.15';
      });
  }, [ width, height, fontSize, content ]);

  return (
    <div className="max-w-[480px] grid gap-2 break-after-page">
      {hasChildItems && (
        <div className="flex justify-center no-print">
          {itemCrafting?.craftingFinalProducts[0].finalProduct?.childItemClassifications.map(
            x => (
              <button
                type="button"
                key={x.childItem.id}
                className={clsx(
                  'text-center border border-juno-gray-700 p-1 px-2 ml-[-1px]',
                  'first-of-type:rounded-l first-of-type:ml-0 last-of-type:rounded-r',
                  selectedChildItemId === x.childItem.id
                    ? 'opacity-100 text-shadow'
                    : 'opacity-50',
                )}
                onClick={() => setSelectedChildItemId(x.childItem.id)}
              >
                {startCase(x.childItem.kind)}
              </button>
            ),
          )}
        </div>
      )}
      <div className="grid grid-cols-12 gap-2 no-print">
        <div className="col-span-2" />

        <div className="col-span-8 flex gap-1 justify-center items-center border-b border-juno-gray-700 pb-2 -mb-2">
          <div className="flex items-center gap-2">
            <div className="w-12">
              <Input fullWidth className="text-right" {...register('width')} />
            </div>
            mm
          </div>
        </div>

        <div className="col-span-3" />
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-2 no-print flex justify-end items-center h-full border-r border-juno-gray-700 pr-2">
          <div className="w-12">
            <Input fullWidth className="text-right" {...register('height')} />
          </div>
        </div>
        <div className="col-span-8">
          <div className="no-print h-[40mm]">
            <Textarea
              style={{
                width: `${width}mm`,
                height: `${height}mm`,
                fontSize: fontSizeMap[fontSize],
                lineHeight: 1.15,
              }}
              resizable={false}
              {...register('content')}
              onBlur={x => setValue('content', x.target.value)}
            />
          </div>
          <div
            data-id="printable-content"
            className="p-1 px-2 no-render text-black flex items-center"
          />
        </div>
        <div className="col-span-2 no-print border-l border-juno-gray-700 pl-2 h-full flex items-center">
          <div>
            <button
              type="button"
              className={clsx(
                'border rounded-t border-juno-gray-700 text-center py-1 w-full transition-all duration-300',
                width === '60' && height === '40'
                  ? 'opacity-100'
                  : 'opacity-50',
              )}
              onClick={() => {
                setValue('width', '60');
                setValue('height', '40');
              }}
            >
              40x60
            </button>
            <button
              type="button"
              className={clsx(
                'border border-juno-gray-700 text-center py-1 mt-[-1px] w-full transition-all duration-300',
                width === '76' && height === '38'
                  ? 'opacity-100'
                  : 'opacity-50',
              )}
              onClick={() => {
                setValue('width', '76');
                setValue('height', '38');
              }}
            >
              38x76
            </button>
            <button
              type="button"
              className={clsx(
                'border rounded-b border-juno-gray-700 text-center py-1 mt-[-1px] w-full transition-all duration-300',
                width === '62' && height === '29'
                  ? 'opacity-100'
                  : 'opacity-50',
              )}
              onClick={() => {
                setValue('width', '62');
                setValue('height', '29');
              }}
            >
              29x62
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 no-print">
        <div className="col-span-2" />
        <div className="col-span-8 flex items-center justify-center">
          <button
            type="button"
            className={clsx(
              'border rounded-l border-juno-gray-700 px-2 py-1 transition-all duration-300',
              fontSize !== 'small' && 'opacity-50',
            )}
            onClick={() => setValue('fontSize', 'small')}
          >
            <FontAwesomeIcon icon={faFont} className="text-xs" />
          </button>
          <button
            type="button"
            className={clsx(
              'border rounded-r border-juno-gray-700 px-2 py-1 transition-all duration-300 -ml-[1px]',
              fontSize !== 'large' && 'opacity-50',
            )}
            onClick={() => setValue('fontSize', 'large')}
          >
            <FontAwesomeIcon icon={faFont} className="text-xl" />
          </button>
        </div>
        <div className="col-span-2" />
      </div>
      <div className="text-right text-sm text-juno-gray-200 no-print">
        Note: Previewer size is estimate. Actual printed size may vary.
      </div>
    </div>
  );
};

export default Label;
