import clsx from 'clsx';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { FC } from 'react';
import useEvent from 'src/hooks/events/useEvent';

const CurrentEventSidebar: FC = () => {
  const { event } = useEvent();

  if (!event) return null;

  return (
    <div className={clsx('border-l-4 border-juno-gray-200 p-2')}>
      <div className="flex justify-between whitespace-nowrap overflow-x-hidden">
        <div>{event.name}</div>
        <div className="text-juno-gray-200 pl-2">{`#${event.id}`}</div>
      </div>
      <div>{event.location.name}</div>
      {event.registrationOpensAt ? (
        <div>
          <div>{`Reg. open ${format(
            parseISO(event.registrationOpensAt),
            'MMM d',
          )}`}</div>
          <div className="text-juno-gray-200 pl-2">
            {formatDistanceToNow(parseISO(event.registrationOpensAt), {
              addSuffix: true,
            })}
          </div>
        </div>
      ) : (
        <div className="text-juno-orange-200">Registration Disabled</div>
      )}
      <div>{`Starts ${format(parseISO(event.startsAt), 'MMM d')}`}</div>
      <div className="text-juno-gray-200 pl-2">
        {formatDistanceToNow(parseISO(event.startsAt), {
          addSuffix: true,
        })}
      </div>
    </div>
  );
};

export default CurrentEventSidebar;
