import { isBefore, parseISO } from 'date-fns';
import { FC } from 'react';
import Loading from 'src/components/0100_loading';
import useEventTransactions from 'src/hooks/events/useEventTransactions';
import { Link, useParams } from '@tanstack/react-router';
import Record from './Record';

const Audits: FC = () => {
  const { organizationSlug, eventId } = useParams({ strict: false });
  const { transactions, fetching } = useEventTransactions();
  if (fetching) return <Loading />;
  if (!fetching && transactions.length === 0)
    return (
      <div>
        No transactions found. Record transactions using{' '}
        <Link
          to="/$organizationSlug/events/$eventId/logistics"
          params={{
            organizationSlug: String(organizationSlug),
            eventId: String(eventId),
          }}
          className="underline"
        >
          LIT
        </Link>
        .
      </div>
    );

  return (
    <div className="overflow-auto p-2 lg:p-0">
      <div className="grid gap-6 min-w-[640px]">
        {transactions
          .sort((a, b) =>
            isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
          )
          .map(parent => (
            <Record key={parent.id} {...parent} />
          ))}
      </div>
    </div>
  );
};

export default Audits;
