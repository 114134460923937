import { FC, forwardRef } from 'react';
import useOrganizationRoles from 'src/hooks/organizations/useOrganizationRoles';
import { startCase } from 'lodash';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {}

const RoleSelect: FC<IProps> = forwardRef(({ ...props }, ref) => {
  const { roles } = useOrganizationRoles();

  return (
    <BaseSelect
      options={roles?.map(x => ({
        value: x,
        label: x === 'assistant' ? 'Guide' : startCase(x),
        isLocked: x === 'admin',
      }))}
      {...props}
    />
  );
});

export default RoleSelect;
