import { FC, useEffect } from 'react';
import Currency from 'src/components/0100_currency';
import Loading from 'src/components/0100_loading';
import useBranchInvoice from 'src/hooks/organizations/branches/useBranchInvoice';
import { isBefore, parseISO } from 'date-fns';
import { Link, useParams } from '@tanstack/react-router';
import EventInvoice from './EventInvoice';

interface IProps {
  id: number;
  region: string;
  name: string;
  shorthand: string;
  district?: string | null;
  expansion: 'collapseAll' | 'expandAll' | null;
  showAllBalance: boolean;
  onLoad: ({
    branchId,
    balance,
  }: {
    branchId: number;
    balance: number;
  }) => void;
}

const BranchInvoice: FC<IProps> = ({
  id,
  region,
  name,
  district,
  expansion,
  showAllBalance,
  onLoad,
}) => {
  const { organizationSlug } = useParams({ strict: false });
  const { invoice, stale } = useBranchInvoice({ branchId: id });

  useEffect(() => {
    if (invoice?.billableAmount) {
      onLoad({ branchId: id, balance: invoice.billableAmount });
    }
  }, [ id, invoice?.billableAmount, onLoad ]);

  if (!showAllBalance && (invoice?.billableAmount ?? 0) === 0) return null;

  return (
    <div className="grid gap-2">
      <div className="border-t border-juno-gray-700 green-box-angled p-2">
        <div className="flex items-start justify-between ">
          <div>
            <Link
              to="/$organizationSlug/branches/$branchId"
              params={{
                organizationSlug: String(organizationSlug),
                branchId: String(id),
              }}
              className="text-xl underline"
              target={`_branch-${id}`}
            >
              {name}
            </Link>
            <div className="text-juno-gray-200">{`${[ region, district ?? '?' ].join(' - ')}`}</div>
          </div>

          {stale ? (
            <div className="py-1">
              <Loading size="small" />
            </div>
          ) : (
            <div className="text-right">
              <div className="text-xs uppercase text-juno-gray-200 leading-3">
                Balance
              </div>
              <div className="text-right text-xl font-bold">
                <Currency value={invoice?.billableAmount ?? 0} />
              </div>
            </div>
          )}
        </div>
        {(invoice?.billableAmount ?? 0) > 0 && (
          <div>
            <table className="border border-juno-gray-700 border-collapse">
              <thead>
                <tr>
                  <th className="text-center p-1 px-4 border border-juno-gray-700 min-w-[240px]">
                    Category
                  </th>
                  <th className="text-center p-1 px-4 border border-juno-gray-700">
                    Count
                  </th>
                  <th className="text-center p-1 px-4 border border-juno-gray-700">
                    Each
                  </th>
                  <th className="text-center p-1 px-4 border border-juno-gray-700">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Live Ticket Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.liveEventTicketCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={5} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={(invoice?.liveEventTicketCount ?? 0) * 5}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Virtual Ticket Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.virtualEventTicketCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={8} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={(invoice?.virtualEventTicketCount ?? 0) * 8}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Additional XP Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.additionalXpCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={1.5} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={(invoice?.additionalXpCount ?? 0) * 1.5} />
                  </td>
                </tr>
                <tr className="purple-box">
                  <td className="p-1 border border-juno-gray-700 font-bold">
                    Subtotal
                  </td>
                  <td colSpan={2} className="border border-juno-gray-700" />
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={
                        (invoice?.additionalXpCount ?? 0) * 1.5 +
                        (invoice?.liveEventTicketCount ?? 0) * 5 +
                        (invoice?.virtualEventTicketCount ?? 0) * 8
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Live Ticket Buyback Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.liveEventTicketBuybackCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={5} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={(invoice?.liveEventTicketBuybackCount ?? 0) * 5}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Virtual Ticket Buyback Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.virtualEventTicketBuybackCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={8} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={(invoice?.virtualEventTicketBuybackCount ?? 0) * 8}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-1 border border-juno-gray-700">
                    Additional XP Buyback Sales
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    {invoice?.additionalXpBuybackCount ?? 0}
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency value={1.5} />
                  </td>
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={(invoice?.additionalXpBuybackCount ?? 0) * 1.5}
                    />
                  </td>
                </tr>
                <tr className="cyan-box">
                  <td className="p-1 border border-juno-gray-700 font-bold">
                    Buyback Subtotal
                  </td>
                  <td colSpan={2} className="border border-juno-gray-700" />
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={
                        (invoice?.additionalXpBuybackCount ?? 0) * 1.5 +
                        (invoice?.liveEventTicketBuybackCount ?? 0) * 5 +
                        (invoice?.virtualEventTicketBuybackCount ?? 0) * 8
                      }
                    />
                  </td>
                </tr>
                <tr className="pink-box">
                  <td className="p-1 border border-juno-gray-700 font-bold">
                    Invoice Total
                  </td>
                  <td colSpan={2} className="border border-juno-gray-700" />
                  <td className="p-1 border border-juno-gray-700 text-right">
                    <Currency
                      value={
                        (invoice?.additionalXpBuybackCount ?? 0) * 1.5 +
                        (invoice?.liveEventTicketBuybackCount ?? 0) * 5 +
                        (invoice?.virtualEventTicketBuybackCount ?? 0) * 8 +
                        (invoice?.additionalXpCount ?? 0) * 1.5 +
                        (invoice?.liveEventTicketCount ?? 0) * 5 +
                        (invoice?.virtualEventTicketCount ?? 0) * 8
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="grid gap-2">
        {invoice?.events
          .sort((a, b) =>
            isBefore(parseISO(a.startsAt), parseISO(b.startsAt)) ? 1 : -1,
          )
          .map(x => <EventInvoice key={x.id} {...x} expansion={expansion} />)}
      </div>
    </div>
  );
};

export default BranchInvoice;
