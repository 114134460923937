import { chunk, range } from 'lodash';
import {
  compressionMapping,
  deCompressionMapping,
} from 'src/components/0500_character_builders/dystopia_rising/SkillBuilder/data';
import { TSkill } from 'src/components/0500_character_builders/dystopia_rising/types';

type CharacterBuild = {
  lineageId: number;
  strainId: number;
  faithId: number;
  loreIds: number[];
  skills: Pick<TSkill, 'id' | 'level' | 'positions'>[];
  body: number;
  mind: number;
  resolve: number;
  infection: number;
  variant?: 'standard' | 'civic' | 'wasteland';
};

const usableAsciiCode = range(48, 58)
  .concat(range(65, 91))
  .concat(range(97, 121));
const baseNumber = usableAsciiCode.length;

export const numberToAscii = (num: number): string => {
  const tens = Math.floor(num / baseNumber);
  const ones = num % baseNumber;

  switch (tens) {
    case 2:
      return `z${String.fromCharCode(usableAsciiCode[ones])}`;
    case 1:
      return `y${String.fromCharCode(usableAsciiCode[ones])}`;
    case 0:
      return String.fromCharCode(usableAsciiCode[ones]);
    default:
      throw new Error('Number out of range');
  }
};

export const asciiToNumber = (ascii: string): number => {
  switch (ascii[0]) {
    case 'z':
      return baseNumber * 2 + usableAsciiCode.indexOf(ascii.charCodeAt(1));
    case 'y':
      return baseNumber + usableAsciiCode.indexOf(ascii.charCodeAt(1));
    default:
      return usableAsciiCode.indexOf(ascii.charCodeAt(0));
  }
};

export const packScalars = (build: Omit<CharacterBuild, 'skills'>): string =>
  [
    numberToAscii(build.lineageId),
    numberToAscii(build.strainId),
    numberToAscii(build.faithId),
    numberToAscii(build.resolve),
    numberToAscii(build.infection),
    numberToAscii(build.body),
    numberToAscii(build.mind),
  ]
    .concat(build.loreIds.map(loreId => numberToAscii(loreId - 200)).join(''))
    .join('');

export const unpackScalars = (compressed: string): CharacterBuild => {
  const isDoubleBody = [ 'y', 'z' ].includes(compressed[5]);
  const body = isDoubleBody
    ? asciiToNumber(compressed[5] + compressed[6])
    : asciiToNumber(compressed[5]);
  const bodyOffset = isDoubleBody ? 1 : 0;

  const isDoubleMind = [ 'y', 'z' ].includes(compressed[6 + bodyOffset]);
  const mind = isDoubleMind
    ? asciiToNumber(compressed[6 + bodyOffset] + compressed[7 + bodyOffset])
    : asciiToNumber(compressed[6 + bodyOffset]);
  const mindOffset = isDoubleMind ? 1 : 0;

  return {
    lineageId: asciiToNumber(compressed[0]),
    strainId: asciiToNumber(compressed[1]),
    faithId: asciiToNumber(compressed[2]),
    resolve: asciiToNumber(compressed[3]),
    infection: asciiToNumber(compressed[4]),
    body,
    mind,
    loreIds: compressed
      .slice(7 + bodyOffset + mindOffset)
      .split('')
      .map(lore => asciiToNumber(lore) + 200),
    skills: [],
    variant: 'standard', // hardcoded
  };
};

export const packSkills = (build: Pick<CharacterBuild, 'skills'>): string => {
  const { skills } = build;

  const unpositionedSkills = skills
    .filter(x => x.id >= 200 && ![ 298, 299 ].includes(x.id) && x.level >= 1)
    .map(skill => {
      const compressBase = compressionMapping[String(skill.id)];

      if (!compressBase) {
        throw new Error(`Invalid skill ID ${skill.id}`);
      }
      const asciiBase = compressBase.charCodeAt(1) - 1;
      const asciiLevel = String.fromCharCode(asciiBase + skill.level);

      return `${compressBase[0]}${asciiLevel}`;
    })
    .join('');

  const positionedSkills = skills
    .filter(x => [ 298, 299 ].includes(x.id))
    .map(skill => {
      const sortedPositions = skill.positions?.sort((a, b) => a - b);
      const sum = (sortedPositions ?? [])
        .map(x => 2 ** x)
        .reduce((a, b) => a + b, 0);
      const compressBase = compressionMapping[String(skill.id)];
      const asciiBase = compressBase.charCodeAt(1) - 1;

      if (sum === 0) return '';
      return `${compressBase[0]}${String.fromCharCode(asciiBase + (sum ?? 0))}`;
    })
    .join('');

  return [ unpositionedSkills, positionedSkills ].join('');
};

export const unpackSkills = (
  compressed: string,
): Pick<CharacterBuild, 'skills'> => {
  const chunked = chunk(compressed, 2);
  const getClosestNeighborAsciiIndex = (x: string) => {
    switch (x) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        return 48;
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
      case 'F':
      case 'G':
      case 'H':
      case 'I':
      case 'J':
        return 65;
      case 'K':
      case 'L':
      case 'M':
      case 'N':
      case 'O':
      case 'P':
      case 'Q':
      case 'R':
      case 'S':
      case 'T':
        return 75;
      case 'a':
      case 'b':
      case 'c':
      case 'd':
      case 'e':
      case 'f':
      case 'g':
      case 'h':
      case 'i':
      case 'j':
        return 97;
      case 'k':
      case 'l':
      case 'm':
      case 'n':
      case 'o':
      case 'p':
      case 'q':
      case 'r':
      case 's':
      case 't':
        return 107;
      default:
        return null;
    }
  };
  const oneHotDecode = (x: number) => {
    switch (x) {
      case 1:
        return [ 0 ];
      case 2:
        return [ 1 ];
      case 3:
        return [ 0, 1 ];
      case 4:
        return [ 2 ];
      case 5:
        return [ 0, 2 ];
      case 6:
        return [ 1, 2 ];
      case 7:
        return [ 0, 1, 2 ];
      default:
        return null;
    }
  };

  const skills = chunked.map(chunk => {
    const firstChar = chunk[0].charCodeAt(0);
    const secondChar = chunk[1].charCodeAt(0);

    if (firstChar < 65 || firstChar > 122) {
      throw new Error(`Skill ID out of range: #${chunk.join('')}`);
    }

    if (firstChar === 122) {
      // profession: first character is z
      return {
        id: deCompressionMapping[chunk.join('')],
        level: 1,
      };
    }

    if (firstChar > 107) {
      // profession skills: first character starts from y back down to l
      const closestNeighborAsciiIndex = getClosestNeighborAsciiIndex(chunk[1]);

      if (!closestNeighborAsciiIndex) {
        throw new Error(`Invalid skill ID ${chunk}`);
      }

      const level = secondChar - closestNeighborAsciiIndex + 1;

      return {
        id: deCompressionMapping[
          `${chunk[0]}${String.fromCharCode(closestNeighborAsciiIndex)}`
        ],
        level,
      };
    }

    if (firstChar === 107) {
      // positionable skills, first character is k

      const closestNeighborAsciiIndex = secondChar >= 96 ? 97 : 65;
      const diff = oneHotDecode(secondChar - closestNeighborAsciiIndex + 1);
      if (diff === null) {
        throw new Error(`Invalid positionable skill ID ${chunk.join('')}`);
      }

      return {
        id: deCompressionMapping[
          `${chunk[0]}${String.fromCharCode(closestNeighborAsciiIndex)}`
        ],
        level: 1,
        positions: diff,
      };
    }

    // basic skills: first character between A to Z
    const closestNeighborAsciiIndex =
      secondChar >= 117
        ? 117
        : secondChar >= 97
          ? 97
          : secondChar >= 85
            ? 85
            : 65;

    const level = secondChar - closestNeighborAsciiIndex + 1;

    return {
      id: deCompressionMapping[
        `${chunk[0]}${String.fromCharCode(closestNeighborAsciiIndex)}`
      ],
      level,
    };
  });

  return { skills };
};

export const pack = (build: CharacterBuild): string =>
  [ packScalars(build), packSkills(build) ].filter(x => x.length > 0).join('-');

export const unpack = (compressed: string): CharacterBuild => {
  const split = compressed.split('-');
  return {
    ...unpackScalars(split[0]),
    ...unpackSkills(split[1]),
  };
};
