import { FC, forwardRef } from 'react';
import useLocations from 'src/hooks/organizations/branches/useLocations';
import BaseSelect, { IBaseSelectProps } from '../0300_base_select';

interface IProps extends IBaseSelectProps {
  existingIds?: number[];
}

const AllLocationsSelect: FC<IProps> = forwardRef(
  ({ existingIds = [], ...props }, ref) => {
    const { locations } = useLocations();

    return (
      <BaseSelect
        withSearch
        options={locations
          .filter(x => !existingIds.includes(x.id))
          .map(x => ({
            value: x.id,
            label: x.name,
            customRender: (
              <div className="w-full text-left">
                <div>{x.name}</div>
                <div className="text-sm">
                  {[ x.streetAddress, x.city, x.region, x.zipCode ].join(', ')}
                </div>
              </div>
            ),
          }))}
        {...props}
      />
    );
  },
);

export default AllLocationsSelect;
