import { FC } from 'react';
import { format, isBefore, parseISO } from 'date-fns';
import ResponseBox from 'src/components/0100_response_box';
import clsx from 'clsx';
import { TInventory } from '../../../Inventories';
import { TTransactableItem } from '../../../LineItem';
import Selection from '../../../Selection';

export type TSelectedInventory = {
  itemId: number;
  registeredItemId?: number | null;
  expiresAt?: string;
  stack: number;
  limit?: number;
};

interface IProps {
  requirement: TTransactableItem;
  inventories: TInventory[];
  selectedCraftingComponents: TSelectedInventory[];
  onUpdateSelectedCraftingComponent?: ({
    itemId,
    registeredItemId,
    expiresAt,
    stack,
  }: TSelectedInventory) => void;
}

const Boolean: FC<IProps> = ({
  requirement,
  inventories,
  selectedCraftingComponents,
  onUpdateSelectedCraftingComponent,
}) => {
  const selectedCount = selectedCraftingComponents
    .map(x => x.stack)
    .reduce((a, b) => a + b, 0);

  return (
    <div>
      <div className="border-y border-juno-gray-700">
        <div
          className={clsx(
            'flex items-center justify-between p-2',
            selectedCount === requirement.stack ? 'midtone-box' : 'orange-box',
          )}
        >
          <div>{requirement.itemName}</div>
          <div className="text-right">
            {selectedCount}/{requirement.stack}
          </div>
        </div>
      </div>
      {inventories.length > 0 ? (
        <div>
          <div className="flex justify-between border-b border-juno-gray-700 pt-2 pl-2 text-xs uppercase text-juno-gray-200">
            <div>Expiration</div>
            <div className="flex justify-end">
              <div className="w-8">Use</div>
              <div className="w-8">Cap</div>
            </div>
          </div>

          {inventories
            .sort((a, b) => {
              if (!a.expiresAt) return 1;
              if (!b.expiresAt) return -1;

              return isBefore(parseISO(a.expiresAt), parseISO(b.expiresAt))
                ? -1
                : 1;
            })
            .map(x => (
              <div
                key={`${[ x.itemId, x.registeredItemId, x.expiresAt ].join('-')}`}
                className="flex justify-between p-1 px-2 border-b border-juno-gray-700"
              >
                <div>
                  {x.expiresAt
                    ? format(parseISO(x.expiresAt), 'yyyy MMM dd')
                    : 'Does not expire'}
                </div>
                <div className="flex justify-end">
                  <Selection
                    {...selectedCraftingComponents.find(
                      y =>
                        y.itemId === x.itemId &&
                        y.registeredItemId === x.registeredItemId &&
                        y.expiresAt === x.expiresAt,
                    )}
                    limit={x.stack}
                    onAdjustSelection={stack =>
                      onUpdateSelectedCraftingComponent?.({
                        itemId: x.itemId,
                        registeredItemId: x.registeredItemId,
                        expiresAt: x.expiresAt,
                        stack,
                      })
                    }
                  />
                  <div className="w-8 text-right">{x.stack}</div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <ResponseBox type="error">
          No matching Item found in Inventory
        </ResponseBox>
      )}
    </div>
  );
};

export default Boolean;
