import { FC, useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import Currency from 'src/components/0100_currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faFileCsv,
  faLaptop,
  faRotateRight,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import Collapsible from 'src/components/0100_collapsible';
import clsx from 'clsx';
import { Link, useParams } from '@tanstack/react-router';
import { TAttendanceInvoice } from './Formatted/AttendanceInvoice';
import Formatted from './Formatted';
import Raw from './Raw';

interface IProps {
  id: number;
  name: string;
  startsAt: string;
  endsAt: string;
  isLegacy: boolean;
  isVirtual: boolean;
  isCurrentCycle: boolean;
  billableAmount: number;
  baseCompensationTicketCount: number;
  fullCompensationTicketCount: number;
  attendances: TAttendanceInvoice[];
  expansion: 'collapseAll' | 'expandAll' | null;
}

const EventInvoice: FC<IProps> = ({
  id,
  name,
  startsAt,
  endsAt,
  isLegacy,
  isVirtual,
  isCurrentCycle,
  billableAmount,
  baseCompensationTicketCount,
  fullCompensationTicketCount,
  attendances,
  expansion,
}) => {
  const { organizationSlug } = useParams({ strict: false });
  const startDate = format(parseISO(startsAt), 'MMM d');
  const endDate = format(parseISO(endsAt), 'MMM d yyyy');
  const [ showFormatted, setShowFormatted ] = useState(true);
  const [ isExpanded, setIsExpanded ] = useState(false);

  useEffect(() => {
    if (expansion === 'collapseAll') setIsExpanded(false);
    if (expansion === 'expandAll') setIsExpanded(true);
  }, [ expansion ]);

  return (
    <div className="border border-juno-gray-700 glass-pane">
      <div className="flex items-start justify-between p-2">
        <div>
          <Link
            to="/$organizationSlug/events/$eventId/tickets"
            params={{
              organizationSlug: String(organizationSlug),
              eventId: String(id),
            }}
            target={`_event-${id}`}
          >
            <span className="underline">{name}</span>
            <span className="text-juno-gray-200 text-sm pl-2">#{id}</span>
          </Link>
          <div>{`${startDate} - ${endDate}`}</div>
          <div className="flex items-center gap-2">
            {isCurrentCycle ? (
              <div className="px-4 py-0.5 text-sm purple-box rounded-full text-juno-gray-50 brightness-110">
                Current
              </div>
            ) : (
              <div className="px-4 py-0.5 text-sm cyan-box rounded-full text-juno-gray-50 brightness-110">
                <FontAwesomeIcon
                  icon={faRotateRight}
                  className="fa-fw -scale-x-100 mr-1"
                />
                Buyback
              </div>
            )}
            {isVirtual && <FontAwesomeIcon icon={faLaptop} />}
            {isLegacy && <div className="text-juno-orange-200">Legacy</div>}
          </div>
        </div>

        <div className="flex items-start justify-end gap-2">
          <div className="text-right">
            <Currency value={billableAmount} />
            <div className="grid grid-cols-1 sm:grid-cols-2 leading-5 sm:leading-6">
              <div className="text-juno-orange-400 brightness-125">
                {baseCompensationTicketCount} Base
              </div>

              <div className="text-juno-purple-400 brightness-150">
                {fullCompensationTicketCount} Full
              </div>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(
              'fa-fw cursor-pointer transition-all duration-300 py-1 origin-center',
              isExpanded && '-scale-y-100',
            )}
            onClick={() => setIsExpanded(x => !x)}
          />
        </div>
      </div>

      <Collapsible isExpanded={isExpanded}>
        <div className="flex items-center justify-end gap-2 w-full p-2">
          <button type="button" onClick={() => setShowFormatted(false)}>
            <FontAwesomeIcon
              icon={faFileCsv}
              className={clsx(
                'fa-fw transition-all duration-300',
                showFormatted && 'opacity-25',
              )}
            />
          </button>
          <button type="button" onClick={() => setShowFormatted(true)}>
            <FontAwesomeIcon
              icon={faTable}
              className={clsx(
                'fa-fw transition-all duration-300',
                !showFormatted && 'opacity-25',
              )}
              onClick={() => setShowFormatted(true)}
            />
          </button>
        </div>
        <div className="-mx-[1px] -mb-[1px] max-w-[calc(100vw-16px)] overflow-auto">
          {showFormatted ? (
            <Formatted attendances={attendances} />
          ) : (
            <Raw attendances={attendances} />
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default EventInvoice;
