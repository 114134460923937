import { FC } from 'react';
import clsx from 'clsx';
import { useNavigate } from '@tanstack/react-router';
import useItemUpdate from '../../hooks/useItemUpdate';
import { IPropsWithOnUpdate } from '../../types';

interface IProps extends IPropsWithOnUpdate {
  itemId: number;
  field: string;
  initialValue?: unknown;
  isNumeric?: boolean;
  isEditable?: boolean;
}

const SingleItemEditor: FC<IProps> = ({
  itemId,
  field,
  initialValue,
  isNumeric = false,
  isEditable = true,
  onUpdate,
}) => {
  const navigate = useNavigate();
  const { handleUpdate, register } = useItemUpdate({
    itemId,
    isNumeric,
    field,
    initialValue,
    onUpdate,
  });

  if (isEditable) {
    return (
      <input
        className={clsx(
          'border-0 bg-transparent border-b last:border-b-0 border-b-juno-gray-700 w-full p-1',
          isNumeric && 'text-right',
          !isEditable && 'cursor-pointer',
        )}
        type="text"
        {...register(field, { onBlur: handleUpdate })}
      />
    );
  }

  return (
    <button
      type="button"
      className="p-1 text-left w-full"
      onClick={() => {
        if (isEditable) return;

        navigate({ to: '.', search: x => ({ ...x, item_id: itemId }) });
      }}
    >
      {String(initialValue)}
    </button>
  );
};

export default SingleItemEditor;
