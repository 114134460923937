import { format, isBefore, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import { FC } from 'react';
import useBranchCorrectiveActions from 'src/hooks/organizations/branches/useBranchCorrectiveActions';

import Loading from 'src/components/0100_loading';
import { useParams } from '@tanstack/react-router';
import Player from './Player';

import 'src/basic-table.css';

const CorrectiveActions: FC = () => {
  const { branchId } = useParams({ strict: false });
  const { correctiveActions, fetching } = useBranchCorrectiveActions({
    branchId: Number(branchId),
  });

  if (fetching) return <Loading />;
  return (
    <div className="p-2">
      <table className="basic-table table-fixed">
        <thead>
          <tr className="sticky top-0 midtone-box z-10">
            <th>ID</th>
            <th className="min-w-[128px]">Issued At</th>
            <th className="min-w-[64px]">Player</th>
            <th className="min-w-[128px]">Player Name</th>
            <th className="min-w-[64px]">Issuer</th>
            <th className="min-w-[128px]">Issuer Name</th>
            <th className="min-w-[128px]">Type</th>
            <th className="min-w-[128px]">Starts At</th>
            <th className="min-w-[128px]">Ends At</th>
            <th className="min-w-[320px]">Reason</th>
          </tr>
        </thead>
        <tbody>
          {correctiveActions
            .sort((a, b) =>
              isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
            )
            .map(x => (
              <tr key={x.id}>
                <td className="text-right">
                  <Player id={x.id} section="corrective_actions" />
                </td>
                <td>{format(parseISO(x.createdAt), 'yyyy-MM-dd')}</td>
                <td className="text-right">
                  <Player id={x.user.id} />
                </td>
                <td>{x.user.fullName}</td>
                <td className="text-right">
                  <Player id={x.issuer.id} />
                </td>
                <td>{x.issuer.fullName}</td>
                <td>{startCase(x.kind)}</td>
                <td>
                  {x.startsAt
                    ? format(parseISO(x.startsAt), 'yyyy-MM-dd')
                    : 'Indefinite'}
                </td>
                <td>
                  {x.endsAt
                    ? format(parseISO(x.endsAt), 'yyyy-MM-dd')
                    : 'Indefinite'}
                </td>
                <td>{x.reason}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CorrectiveActions;
