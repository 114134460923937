import {
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faPlus,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createFileRoute,
  Link,
  LinkComponentProps,
  Outlet,
  useLocation,
  useParams,
  useSearch,
} from '@tanstack/react-router';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import { IEventKindEnum, IRegionEnum } from 'src/graphql/types';
import useEvent from 'src/hooks/events/useEvent';
import usePermission from 'src/hooks/permissions/usePermissions';

const Event: FC = () => {
  const { pathname } = useLocation();
  const { organizationSlug, eventId } = useParams({ strict: false });
  const { event, fetching } = useEvent();
  const { isPermitted: canManageBranch } = usePermission({
    action: 'manage_branch',
    branchId: event?.branch.id,
  });
  const { player_id: playerIdInURL } = useSearch({ strict: false });
  const { isPermitted: canCreateEvent } = usePermission({
    action: 'create_event_ui',
  });
  const address =
    event &&
    [
      event.location.streetAddress,
      event.location.city,
      event.location.region,
      event.location.zipCode,
    ].join(', ');
  const section =
    pathname.match(
      /(basics|blueprints|checkout|logistics|printouts|shifts|tickets|transactions_audit|war_room)/,
    )?.[1] ?? '*';
  const targetEvent: LinkComponentProps<'a'>['to'] = useMemo(() => {
    switch (section) {
      case 'basics':
        return '/$organizationSlug/events/$eventId/basics';
      case 'blueprints':
        return '/$organizationSlug/events/$eventId/blueprints/$';
      case 'checkout':
        return '/$organizationSlug/events/$eventId/checkout';
      case 'logistics':
        return '/$organizationSlug/events/$eventId/logistics';
      case 'printouts':
        return '/$organizationSlug/events/$eventId/printouts';
      case 'shifts':
        return '/$organizationSlug/events/$eventId/shifts';
      case 'tickets':
        return '/$organizationSlug/events/$eventId/tickets';
      case 'transactions_audit':
        return '/$organizationSlug/events/$eventId/transactions_audit';
      case 'war_room':
        return '/$organizationSlug/events/$eventId/war_room';
      default:
        return '/$organizationSlug/events/$eventId/*';
    }
  }, [ section ]);

  return (
    <div>
      <Title>
        <div className="flex justify-between items-center px-4">
          <Link
            className={clsx(
              'hover:scale-125 transition-transform text-2xl',
              !event?.prevEvent && 'invisible pointer-events-none',
            )}
            to={targetEvent}
            params={{
              organizationSlug: String(organizationSlug),
              eventId: String(event?.prevEvent?.id),
            }}
            search={{
              player_id: playerIdInURL ? Number(playerIdInURL) : undefined,
            }}
          >
            <FontAwesomeIcon className="fa-fw" icon={faChevronLeft} />
          </Link>
          {fetching ? (
            <Loading />
          ) : (
            <div>
              <div className="text-2xl flex items-center justify-center gap-2 w-full">
                {eventId === 'new'
                  ? 'Create New Event'
                  : (event?.name ?? '...')}
                {canManageBranch && (
                  <Link
                    className="hover:scale-125 transition-all text-lg"
                    to="/$organizationSlug/branches/$branchId/*"
                    params={{
                      organizationSlug: String(organizationSlug),
                      branchId: String(event?.branch.id),
                    }}
                  >
                    <FontAwesomeIcon
                      className="fa-fw"
                      icon={faUpRightFromSquare}
                    />
                  </Link>
                )}
              </div>
              {event?.location && (
                <div>
                  <div>{event.location.name}</div>
                  {event.location.region !== IRegionEnum.Virtual && (
                    <a
                      href={`https://google.com/maps/search/${address}`}
                      className="underline"
                      target="_google-maps"
                    >
                      {address}
                      <FontAwesomeIcon
                        icon={faUpRightFromSquare}
                        className="fa-fw pl-2"
                      />
                    </a>
                  )}
                </div>
              )}
              {event?.startsAt && event.endsAt && (
                <div>
                  {`${format(parseISO(event?.startsAt), 'MMM d, eee hh:mm a')} - ${format(parseISO(event?.endsAt), 'eee hh:mm a')}`}
                </div>
              )}
              <div>
                {event && event.kind !== IEventKindEnum.Live && (
                  <div className="flex gap-2 items-center justify-center text-juno-orange-200">
                    <FontAwesomeIcon
                      className="fa-fw"
                      icon={faExclamationTriangle}
                    />
                    Legacy DR 3.0 Game
                  </div>
                )}
              </div>
            </div>
          )}
          {!event?.nextEvent && canCreateEvent && eventId !== 'new' ? (
            <Link
              className={clsx('hover:scale-125 transition-transform text-2xl')}
              to="/$organizationSlug/events/$eventId"
              params={{
                organizationSlug: String(organizationSlug),
                eventId: 'new',
              }}
            >
              <FontAwesomeIcon className="fa-fw" icon={faPlus} />
            </Link>
          ) : (
            <Link
              className={clsx(
                'hover:scale-125 transition-transform text-2xl',
                !event?.nextEvent && 'invisible pointer-events-none',
              )}
              to={targetEvent}
              params={{
                organizationSlug: String(organizationSlug),
                eventId: String(event?.nextEvent?.id),
              }}
              search={{
                player_id: playerIdInURL ? Number(playerIdInURL) : undefined,
              }}
            >
              <FontAwesomeIcon className="fa-fw" icon={faChevronRight} />
            </Link>
          )}
        </div>
      </Title>
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute('/$organizationSlug/events/$eventId')({
  component: Event,
});
