import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useCharacter from 'src/hooks/characters/useCharacter';
import useOrganizationFellowships from 'src/hooks/events/useOrganizationFellowships';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheck,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useMutation } from 'urql';
import {
  ICreateCharacterFellowshipMutation,
  ICreateCharacterFellowshipMutationVariables,
} from 'src/graphql/mutations/fellowships.graphql.types';
import { createCharacterFellowship } from 'src/graphql/mutations/fellowships.graphql';
import Loading from 'src/components/0100_loading';
import Title from 'src/components/0100_title';
import Input from 'src/components/0100_input';
import { IFellowshipKindEnum } from 'src/graphql/types';
import Collapsible from 'src/components/0100_collapsible';
import ResponseBox from 'src/components/0100_response_box';
import CharacterFellowship from './CharacterFellowship';

interface IProps extends ReturnType<typeof useCharacter> {
  type: IFellowshipKindEnum;
  onFellowshipAssignment: () => void;
}

const title = {
  [IFellowshipKindEnum.Disease]: 'Diseases',
  [IFellowshipKindEnum.Society]: 'Society Memberships',
};

const DystopiaRisingFellowships: FC<IProps> = ({
  character,
  canEditSensitiveData,
  type,
  onFellowshipAssignment,
}) => {
  const { fellowships } = useOrganizationFellowships({ type });
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const { register, watch } = useForm({
    defaultValues: { fellowshipQuery: '' },
  });
  const { fellowshipQuery } = watch();
  const filteredFellowshipIds = useMemo(() => {
    const exlucdedFellowships = fellowships.filter(
      x =>
        !character?.characterFellowships
          .map(y => y.fellowship.id)
          .includes(x.id),
    );
    if (fellowshipQuery.trim().length === 0)
      return exlucdedFellowships.map(x => x.id);

    return exlucdedFellowships
      .filter(x =>
        x.name.toLowerCase().includes(fellowshipQuery.trim().toLowerCase()),
      )
      .map(x => x.id);
  }, [ character?.characterFellowships, fellowshipQuery, fellowships ]);
  const [ assignmentResult, assign ] = useMutation<
    ICreateCharacterFellowshipMutation,
    ICreateCharacterFellowshipMutationVariables
  >(createCharacterFellowship);

  const handleAssignment = useCallback(
    ({ fellowshipId }: { fellowshipId: number }) => {
      setIsSuccessful(false);
      setError(null);
      assign({ characterId: Number(character?.id), fellowshipId }).then(res => {
        if (res.data?.createCharacterFellowship?.fellowship?.id) {
          onFellowshipAssignment();
          setIsSuccessful(true);
        }

        if (res.data?.createCharacterFellowship?.error) {
          setError(res.data.createCharacterFellowship.error);
        }
      });
    },
    [ assign, character?.id, onFellowshipAssignment ],
  );

  useEffect(() => {
    setError(null);
  }, [ character?.id ]);

  if (!character) return null;

  return (
    <div>
      <Title variant="heading">
        <div className="flex justify-between items-center">
          <div className="text-2xl">{title[type]}</div>
          <div>
            {assignmentResult.fetching && <Loading size="small" />}
            {isSuccessful && <FontAwesomeIcon icon={faCheck} />}
          </div>
        </div>
      </Title>
      <div className="grid gap-2">
        {character.characterFellowships.length === 0 && (
          <div className="opacity-50">No {title[type]} found</div>
        )}
        {character.characterFellowships
          .filter(x => x.fellowship.kind === type)
          .sort((a, b) => a.fellowship.name.localeCompare(b.fellowship.name))
          .map(x => (
            <CharacterFellowship
              key={x.id}
              {...x}
              type={type}
              canEditSensitiveData={canEditSensitiveData}
            />
          ))}
      </div>
      {canEditSensitiveData && (
        <div className="grid gap-2 pt-4">
          <button
            type="button"
            className="w-full flex justify-between items-center border-b border-juno-gray-200"
            onClick={() => setIsExpanded(x => !x)}
          >
            <div className="text-lg">Assign {title[type]}</div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={clsx(
                'transition-all duration-300',
                isExpanded && '-scale-y-100',
              )}
            />
          </button>
          {error && <ResponseBox type="error">{error}</ResponseBox>}
          <Collapsible
            isExpanded={isExpanded}
            classNames={{ parent: { steadyState: 'gap-2' }}}
          >
            <div className="pl-2 py-1 pr-1">
              <Input
                fullWidth
                placeholder={`Search ${title[type]}...`}
                {...register('fellowshipQuery')}
              />
            </div>
            <Collapsible isExpanded={filteredFellowshipIds.length === 0}>
              <div className="p-1 pl-3 opacity-50">No results</div>
            </Collapsible>
            {fellowships
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(x => (
                <Collapsible
                  isExpanded={filteredFellowshipIds.includes(x.id)}
                  key={x.id}
                >
                  <button
                    type="button"
                    disabled={x.removedAt !== null}
                    className={clsx(
                      'w-full text-left border-l-4 border-transparent pl-2 py-1 hover:border-juno-gray-200 transition-all duration-300 flex items-center gap-2',
                      'disabled:opacity-50 disabled:hover:border-transparent disabled:hover:bg-transparent',
                      !x.removedAt && 'hover:dark-box',
                    )}
                    onClick={() => handleAssignment({ fellowshipId: x.id })}
                  >
                    {x.removedAt && <FontAwesomeIcon icon={faBan} />}
                    {x.name}
                  </button>
                </Collapsible>
              ))}
          </Collapsible>
        </div>
      )}
    </div>
  );
};

export default DystopiaRisingFellowships;
