import { FC, ReactNode } from 'react';

import clsx from 'clsx';
import { useLinkProps, useParams } from '@tanstack/react-router';
import useTargetCharacterSection from 'src/hooks/characters/useTargetCharacterSection';
import useTargetPlayerSection from 'src/hooks/players/useTargetPlayerSection';
import Tab from '../0200_tab';
import PlayerBadges, { TUserOrganization } from '../0200_player_badges';

interface IProps {
  characterOnly?: boolean;
  characterIcon?: ({ characterId }: { characterId?: number }) => ReactNode;
  playerId?: number;
  playerName?: string;
  playerFirstName?: string | null;
  playerLastName?: string;
  playerHasPreferredName?: boolean;
  characterId?: number;
  characterName?: string;
  userOrganization?: TUserOrganization;
  onClick?: ({
    playerId,
    characterId,
  }: {
    playerId?: number;
    characterId?: number;
  }) => void;
}

const SearchResult: FC<IProps> = ({
  characterOnly,
  characterIcon,
  playerId,
  playerName,
  playerFirstName,
  playerLastName,
  playerHasPreferredName,
  characterId,
  characterName,
  userOrganization,
  onClick,
}) => {
  const { organizationSlug } = useParams({ strict: false });
  const { targetCharacterSection } = useTargetCharacterSection();
  const { targetPlayerSection } = useTargetPlayerSection();
  const linkToPlayer = useLinkProps({
    to: targetPlayerSection,
    params: {
      organizationSlug: String(organizationSlug),
      playerId: String(playerId),
    },
  });
  const linkToCharacter = useLinkProps({
    to: targetCharacterSection,
    params: {
      organizationSlug: String(organizationSlug),
      playerId: String(playerId),
      characterId: String(characterId),
    },
  });

  return (
    <Tab
      isCompact
      label={
        <div className="flex">
          <div className="w-12 pr-2 text-right">
            {!characterOnly && <div>{playerId}</div>}
            {!characterOnly &&
              characterName &&
              (characterIcon ? (
                characterIcon({ characterId })
              ) : (
                <div>&raquo;</div>
              ))}
            {characterOnly &&
              characterName &&
              characterIcon &&
              characterIcon({ characterId })}
          </div>
          <div className="w-48 text-left">
            {!characterOnly && (
              <div className={clsx(characterName && 'text-juno-gray-700')}>
                <div>
                  {playerName}
                  <PlayerBadges userOrganization={userOrganization} />
                </div>
                {playerHasPreferredName && (
                  <div className="opacity-50">
                    a.k.a. {[ playerFirstName, playerLastName ].join(' ')}
                  </div>
                )}
              </div>
            )}
            {characterName && <div>{characterName}</div>}
          </div>
        </div>
      }
      className="py-1"
      highlightMode="vertical"
      to={
        onClick
          ? undefined
          : characterId
            ? linkToCharacter.href
            : linkToPlayer.href
      }
      onClick={() => onClick?.({ playerId, characterId })}
    />
  );
};

export default SearchResult;
