import { FC } from 'react';

interface IProps extends Intl.NumberFormatOptions {
  value: number;
}

const Currency: FC<IProps> = ({ value, ...props }) => {
  const [ dollars, cents ] = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    ...props,
  })
    .format(value)
    .split('.');

  if (value === 0) return <div className="opacity-50">$0</div>;

  return (
    <div>
      {dollars}
      <span className="text-sm">.{cents}</span>
    </div>
  );
};

export default Currency;
