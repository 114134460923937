import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { FC, useCallback } from 'react';
import Button from 'src/components/0100_button';
import Modal from 'src/components/0300_modal';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';

const RedirectLayer: FC = () => {
  const { buttonState } = useButtonStates();
  const { redirect: isRedirected } = useSearch({ strict: false });
  const navigate = useNavigate({ from: '/' });
  const removeRedirect = useCallback(() => {
    navigate({ to: '.', search: () => ({ redirect: undefined }) });
  }, [ navigate ]);

  if (!isRedirected) return null;

  return (
    <Modal isOpen title="We Have A New Home!" zIndex={60}>
      <div>
        <div className="p-4 flex items-start gap-3">
          <FontAwesomeIcon icon={faEarthAmericas} className="text-3xl" />
          <div>
            Dystopia Rising Database has a new address.
            <br />
            <br />
            If you arrive here from{' '}
            <code className="text-sm brightness-75 p-1">
              juno.dystopiarisingnetwork.com
            </code>
            , please update your bookmark to{' '}
            <button
              type="button"
              onClick={() => navigator.clipboard.writeText('db.larp.network')}
            >
              <code className="text-sm text-juno-cyan-200 brightness-150 px-1 underline underline-offset-4">
                db.larp.network
              </code>
            </button>
            .<br />
            <br />
            You will need to re-authenticate.
          </div>
        </div>
        <div className="grid place-items-end border-t border-juno-cyan-200 p-4">
          <Button
            defaultLabel="Got it!"
            state={buttonState({
              isHighlight: true,
              isValid: true,
              isDirty: true,
            })}
            onClick={removeRedirect}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RedirectLayer;
