import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import {
  isPast,
  parseISO,
  isWithinInterval,
  format,
  formatDistanceToNow,
} from 'date-fns';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePicker from 'src/components/0200_datepicker';
import {
  destroyPlayerMembership,
  updatePlayerMembership,
} from 'src/graphql/mutations/players.graphql';
import {
  IUpdatePlayerMembershipMutationVariables,
  IUpdatePlayerMembershipMutation,
  IDestroyPlayerMembershipMutation,
  IDestroyPlayerMembershipMutationVariables,
} from 'src/graphql/mutations/players.graphql.types';
import { IBranch, IMembership, IUser } from 'src/graphql/types';
import { isExpiring } from 'src/utils/date';
import { useMutation } from 'urql';
import ConfirmDropdown from 'src/components/0200_confirm_dropdown';
import { AnimatePresence, motion } from 'framer-motion';
import { useParams } from '@tanstack/react-router';

interface IProps {
  canUpdateMembership?: boolean;
  membership: Pick<IMembership, 'id' | 'startsAt' | 'endsAt'> & {
    grantor?: Pick<IUser, 'id' | 'fullName'> | null;
    grantingBranch?: Pick<IBranch, 'id' | 'shorthand'> | null;
  };
  index: number;
  onDelete: () => void;
}

const Membership: FC<IProps> = ({
  canUpdateMembership,
  membership,
  index,
  onDelete,
}) => {
  const { playerId } = useParams({ strict: false });
  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ , destroyMembership ] = useMutation<
    IDestroyPlayerMembershipMutation,
    IDestroyPlayerMembershipMutationVariables
  >(destroyPlayerMembership);
  const isSelfSignoff = useCallback(
    (staffId: number | undefined) => staffId === Number(playerId),
    [ playerId ],
  );
  const { register, setValue, watch } = useForm({
    defaultValues: { startsAt: null },
    values: { startsAt: parseISO(membership.startsAt) },
  });
  const [ updateMembershipResult, updateMembership ] = useMutation<
    IUpdatePlayerMembershipMutation,
    IUpdatePlayerMembershipMutationVariables
  >(updatePlayerMembership);

  const handleUpdate = useCallback(
    (date: Date | null) => {
      if (date) {
        setValue('startsAt', date);
        updateMembership({
          membershipId: membership.id,
          startsAt: date?.toISOString(),
        });
      }
    },
    [ membership.id, setValue, updateMembership ],
  );

  return (
    <AnimatePresence>
      {!isDeleted && (
        <motion.tr
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <td className="p-1 pb-0 text-center">
            {isWithinInterval(new Date(), {
              start: parseISO(membership.startsAt),
              end: parseISO(membership.endsAt),
            }) && <FontAwesomeIcon icon={faChevronRight} />}
          </td>
          <td className="p-1 pb-0">{membership.grantingBranch?.shorthand}</td>
          <td
            className={clsx('p-1 pb-0', {
              'text-juno-gray-700': isPast(parseISO(membership.endsAt)),
            })}
          >
            <div className="flex justify-start">
              {canUpdateMembership ? (
                <DatePicker
                  date={watch('startsAt')}
                  isUpdating={updateMembershipResult.fetching}
                  isUpdated={
                    updateMembershipResult.data?.updateMembership?.error ===
                    null
                  }
                  onUpdate={handleUpdate}
                  {...register('startsAt')}
                />
              ) : (
                format(parseISO(membership.startsAt), 'yyyy-MM-dd')
              )}
            </div>
          </td>
          <td
            className={clsx('p-1 pb-0', {
              'text-juno-gray-700': isPast(parseISO(membership.endsAt)),
            })}
          >
            <div>{format(parseISO(membership.endsAt), 'yyyy-MM-dd')}</div>
            {index === 0 && isExpiring(parseISO(membership.endsAt)) && (
              <div className="text-juno-orange-200">
                Expires{' '}
                {formatDistanceToNow(parseISO(membership.endsAt), {
                  addSuffix: true,
                })}
              </div>
            )}
          </td>
          <td className="p-1 pb-0">
            {membership.grantor?.id
              ? isSelfSignoff(membership.grantor?.id)
                ? 'Self'
                : membership.grantor?.fullName
              : 'System'}
          </td>
          {canUpdateMembership && (
            <td className="p-1 pb-0 text-center">
              <ConfirmDropdown
                onConfirm={() => {
                  setIsDeleted(true);
                  destroyMembership({ membershipId: membership.id }).then(
                    onDelete,
                  );
                }}
              />
            </td>
          )}
        </motion.tr>
      )}
    </AnimatePresence>
  );
};

export default Membership;
