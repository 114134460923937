import { FC } from 'react';
import clsx from 'clsx';
import useCharacterBuilder from '../../hooks/useCharacterBuilder';
import loreData from './data';
import { TLore } from '../../types';

interface IProps {
  canEdit: boolean;
}

const LoreGroup: FC<IProps> = ({ canEdit }) => {
  const { maxLores, toggleLore, loreCount, isLoreOverLimit, watch } =
    useCharacterBuilder({ canEdit });
  const { lores } = watch();
  const sortedLores = (lores as TLore[])
    .filter(x => x.id >= 201)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(x => x.name);

  return (
    <div className="grid grid-cols-1 gap-4">
      <div className="flex justify-end">
        <div
          id="loreCount"
          className={clsx(
            'relative w-[40%] text-center rounded-xs',
            isLoreOverLimit ? 'orange-box' : 'midtone-box',
          )}
        >
          <div className="p-2 relative z-10">
            {isLoreOverLimit && `${loreCount - maxLores} Lores Over`}
            {!isLoreOverLimit && maxLores === 0 && 'Requires Education'}
            {maxLores > 0 &&
              loreCount <= maxLores &&
              `${loreCount}/${maxLores} Lores Learned`}
          </div>
          <div
            className="purple-box absolute -mt-10 py-2 rounded-xs z-0 transition-all duration-300"
            style={{
              width: ` ${
                isLoreOverLimit || maxLores === 0
                  ? 0
                  : (loreCount * 100) / maxLores
              }%`,
            }}
          >
            &nbsp;
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {loreData.map(lore => (
          <button
            type="button"
            key={lore.name}
            className="midtone-box relative"
            onClick={() => toggleLore({ id: lore.id, name: lore.name })}
          >
            <div className="flex items-center p-2 gap-2 relative z-10">
              <div className="h-8 w-8 flex shrink-0 items-center justify-center bg-juno-gray-900 rounded-full">
                {sortedLores.indexOf(lore.name) < 0
                  ? ''
                  : sortedLores.indexOf(lore.name) + 1}
              </div>
              <div
                className={clsx(
                  'text-left whitespace-nowrap overflow-hidden',
                  sortedLores.indexOf(lore.name) < 0 && 'opacity-50',
                )}
              >
                {lore.name}
              </div>
            </div>
            <div
              className="purple-box absolute -mt-[48px] py-3 rounded-xs transition-all duration-300 z-0"
              style={{ width: sortedLores.indexOf(lore.name) < 0 ? 0 : '100%' }}
            >
              &nbsp;
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default LoreGroup;
