import { FC, useCallback, useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import {
  faArrowUpRightFromSquare,
  faDollarSign,
  faHiking,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from 'react-toggle';
import {
  IEventAttendee,
  ITicketDiscountEnum,
  ITicketRestrictionEnum,
  IUser,
} from 'src/graphql/types';
import { useMutation } from 'urql';
import {
  IUpdateEventAttendeeMutation,
  IUpdateEventAttendeeMutationVariables,
} from 'src/graphql/mutations/events.graphql.types';
import { updateEventAttendee } from 'src/graphql/mutations/events.graphql';
import clsx from 'clsx';
import Currency from 'src/components/0100_currency';
import Collapsible from 'src/components/0100_collapsible';
import { Link, useNavigate, useParams } from '@tanstack/react-router';
import ticketRevenue from '../../../../../utils/ticketRevenue';

export type TAttendee = Pick<
  IEventAttendee,
  'id' | 'kind' | 'paid' | 'attending' | 'buildGrowth'
> & {
  user: Pick<IUser, 'id' | 'fullName'>;
  attendeeCharacters: {
    id: number;
    character: {
      id: number;
      name: string;
    };
  }[];
  ticket: {
    id: number;
    restrictionType: ITicketRestrictionEnum;
    discountType: ITicketDiscountEnum;
    cost: number;
  };
};

interface IProps {
  attendee: TAttendee;
  isExpanded: boolean;
  extraXpCost: number;
}

const Attendee: FC<IProps> = ({ attendee, isExpanded, extraXpCost }) => {
  const navigate = useNavigate();
  const { organizationSlug } = useParams({ strict: false });
  const { watch: topWatch } = useFormContext();
  const { showRevenue } = topWatch();
  const [ isUpdateSuccessful, setIsUpdateSuccessful ] = useState(false);
  const [ , update ] = useMutation<
    IUpdateEventAttendeeMutation,
    IUpdateEventAttendeeMutationVariables
  >(updateEventAttendee);
  const { reset, setValue, watch } = useForm({
    defaultValues: {
      isPaid: false,
      isAttending: false,
    },
  });

  const { isPaid, isAttending } = watch();
  const { baseRevenue, extraXpRevenue, totalRevenue } = ticketRevenue({
    attendees: [ attendee ],
    extraXpCost,
  });

  const handleUpdate = useCallback(
    ({ field, value }: { field: 'isPaid' | 'isAttending'; value: boolean }) => {
      update({
        eventAttendeeId: attendee.id,
        [field]: value,
      }).then(res => {
        if (res.data?.updateEventAttendee?.eventAttendee) {
          setIsUpdateSuccessful(true);
          setTimeout(() => setIsUpdateSuccessful(false), 1000);
        }
      });

      setValue(field, value);
      if (attendee.kind === 'home_game') {
        if (field === 'isPaid') {
          setValue('isAttending', value);
        }
      } else if (field === 'isPaid' && value === false) {
        setValue('isAttending', value);
      } else if (field === 'isAttending' && value === true) {
        setValue('isPaid', value);
      }
    },
    [ update, setValue, attendee ],
  );

  useEffect(() => {
    reset({
      isPaid: attendee.paid,
      isAttending: attendee.attending,
    });
  }, [ attendee, reset ]);

  return (
    <tr>
      <td
        colSpan={4}
        className={clsx(
          'p-0',
          showRevenue && 'border-r',
          isExpanded && 'border-b border-juno-gray-700',
        )}
      >
        <Collapsible
          isExpanded={isExpanded}
          classNames={{
            parent: {
              expanded: 'pt-2',
              steadyState: isUpdateSuccessful
                ? 'bg-lime-600/75'
                : 'bg-transparent',
            },
          }}
        >
          <div className="flex items-start justify-between gap-4 ">
            <div>
              <button
                type="button"
                className="w-16 shrink-0 text-right underline"
                onClick={() => {
                  navigate({
                    to: '.',
                    search: x => ({
                      ...x,
                      player_id: Number(attendee.user.id),
                    }),
                  });
                }}
              >
                {attendee.user.id}
              </button>
              {attendee.buildGrowth > 0 && (
                <div className="text-right">{`+${attendee.buildGrowth}XP`}</div>
              )}
            </div>
            <div className="w-full">
              <Link
                to="/$organizationSlug/players/$playerId"
                params={{
                  organizationSlug: String(organizationSlug),
                  playerId: String(attendee.user.id),
                }}
                className="flex justify-between items-center group"
                target={`_player-${attendee.user.id}`}
              >
                <div>{attendee.user.fullName}</div>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="fa-fw opacity-25 group-hover:opacity-100 transition-all"
                />
              </Link>
              <div className="border-l-4 pl-2 mt-1 mb-2 border-juno-gray-700 grid">
                {attendee.attendeeCharacters.map(ac => (
                  <div key={ac.id} className="py-0.5 opacity-75">
                    {ac.character.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-16 shrink-0 self-center text-center">
              <div>
                <Toggle
                  checked={isPaid}
                  icons={{
                    checked: (
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="text-sm -mt-0.5 "
                      />
                    ),
                    unchecked: (
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="text-sm -mt-0.5 opacity-50"
                      />
                    ),
                  }}
                  onChange={x =>
                    handleUpdate({ field: 'isPaid', value: x.target.checked })
                  }
                />
              </div>
              <div>
                {attendee.kind === 'home_game' ? (
                  <FontAwesomeIcon icon={faHome} className="pb-2" />
                ) : (
                  <Toggle
                    checked={isAttending}
                    icons={{
                      checked: (
                        <FontAwesomeIcon
                          icon={faHiking}
                          className="text-sm -mt-0.5 "
                        />
                      ),
                      unchecked: (
                        <FontAwesomeIcon
                          icon={faHiking}
                          className="text-sm -mt-0.5 opacity-50"
                        />
                      ),
                    }}
                    onChange={x =>
                      handleUpdate({
                        field: 'isAttending',
                        value: x.target.checked,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </Collapsible>
      </td>
      {showRevenue && (
        <td
          className={clsx(
            'text-right p-0',
            showRevenue && 'pr-2 border-r',
            isExpanded && 'border-b border-juno-gray-700',
          )}
        >
          <Collapsible
            isExpanded={isExpanded}
            classNames={{ parent: { expanded: 'pt-1.5' }}}
          >
            <Currency value={baseRevenue} />
          </Collapsible>
        </td>
      )}
      {showRevenue && (
        <td
          className={clsx(
            'text-right p-0',
            showRevenue && 'pr-2 border-r',
            isExpanded && 'border-b border-juno-gray-700',
          )}
        >
          <Collapsible
            isExpanded={isExpanded}
            classNames={{ parent: { expanded: 'pt-1.5' }}}
          >
            <Currency value={extraXpRevenue} />
          </Collapsible>
        </td>
      )}
      {showRevenue && (
        <td
          className={clsx(
            'text-right p-0',
            showRevenue && 'pr-2 border-r',
            isExpanded && 'border-b border-juno-gray-700',
          )}
        >
          <Collapsible
            isExpanded={isExpanded}
            classNames={{ parent: { expanded: 'pt-1.5' }}}
          >
            <Currency value={totalRevenue} />
          </Collapsible>
        </td>
      )}
      {showRevenue && (
        <td
          className={clsx('p-0', isExpanded && 'border-b border-juno-gray-700')}
        />
      )}
    </tr>
  );
};

export default Attendee;
