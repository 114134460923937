import { FC, ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { useOutsideClickRef } from 'rooks';
import { startCase } from 'lodash';
import { IItemKindEnum, IItemLifetimeUnitEnum } from 'src/graphql/types';
import useItems from 'src/hooks/items/useItems';
import Input from '../0100_input';
import DropdownPanel from '../0100_dropdown_panel';
import Loading from '../0100_loading';

interface IProps {
  kind?: IItemKindEnum;
  placeholder?: string;
  onSearchResultClick: ({
    itemId,
  }: {
    itemId: number;
    itemName: string;
    itemKind: IItemKindEnum;
    itemLifetimeAmount?: number | null;
    itemLifetimeUnit: IItemLifetimeUnitEnum;
  }) => void;
  onRenderBadge?: ({ kind }: { kind: IItemKindEnum }) => ReactNode | undefined;
}

const ItemSearch: FC<IProps> = ({
  kind,
  placeholder,
  onSearchResultClick,
  onRenderBadge,
}) => {
  const [ isFocused, setIsFocused ] = useState(false);
  const { register, watch } = useForm({ defaultValues: { query: '' }});
  const { query } = watch();

  const { items, fetching } = useItems({ keyword: query, kind });
  const [ ref ] = useOutsideClickRef(() => setIsFocused(false));

  const canExecuteSearch = query.trim().length >= 2;
  const hasResults = items.length > 0;

  return (
    <div ref={ref}>
      <div className="flex justify-between items-center">
        <Input
          fullWidth
          placeholder={
            placeholder ?? `Search ${kind ? 'Blueprints' : 'Items'}...`
          }
          onFocus={() => setIsFocused(true)}
          {...register('query')}
        />
      </div>
      <DropdownPanel
        isExpanded={isFocused}
        zIndex={10}
        className={clsx(
          'mt-2 border-juno-gray-700',
          isFocused && fetching && 'border',
        )}
      >
        {isFocused && fetching && (
          <div className="p-2">
            <Loading size="small" />
          </div>
        )}

        {isFocused && !fetching && canExecuteSearch && hasResults && (
          <div className="border border-juno-gray-700 rounded-xs">
            {items.map(x => (
              <div
                key={x.id}
                className="w-full px-2 py-1 border-l-4 border-transparent hover:border-juno-gray-200 transition-all duration-300"
              >
                <div className="flex justify-between items-center">
                  <button
                    type="button"
                    className="text-left w-full"
                    onClick={() =>
                      onSearchResultClick({
                        itemId: x.id,
                        itemName: x.name,
                        itemKind: x.kind,
                        itemLifetimeAmount: x.lifetimeAmount,
                        itemLifetimeUnit: x.lifetimeUnit,
                      })
                    }
                  >
                    <div>{x.name}</div>
                    <div className="text-sm opacity-75">
                      {startCase(x.kind)}
                    </div>
                  </button>
                  {onRenderBadge?.({ kind: x.kind })}
                </div>
              </div>
            ))}
          </div>
        )}

        {isFocused && !fetching && !hasResults && (
          <div className="p-2 border border-juno-gray-700 rounded-xs">
            {canExecuteSearch ? 'No Results' : 'Start Typing...'}
          </div>
        )}
      </DropdownPanel>
    </div>
  );
};

export default ItemSearch;
