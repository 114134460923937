import { FC, ReactNode, useCallback, useState } from 'react';
import Table from 'src/components/0100_table';
import useEventShifts from 'src/hooks/events/useEventShifts';
import Loading from 'src/components/0100_loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faEye,
  faEyeSlash,
  faLightbulb,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { IShiftKindEnum } from 'src/graphql/types';
import ResponseBox from 'src/components/0100_response_box';
import ShiftKind from './ShiftKind';
import PrintableShifts from './PrintableShifts';

const shiftTip: Record<IShiftKindEnum, ReactNode | null> = {
  [IShiftKindEnum.Casting]: (
    <div>
      NPC Shifts sort automatically by their start day and time if you follow
      the following case-
      <u>insensitive</u> pattern:
      <div>
        <code className="text-sm bg-juno-gray-200 text-juno-gray-700 p-1 rounded-xs">
          [3-letters-day] [start] - [end] [description?]
        </code>
      </div>
      Where:
      <ul className="list-disc ml-8 leading-7">
        <li>
          <code className="text-sm bg-juno-gray-200 text-juno-gray-700 p-1 rounded-xs">
            [3-letters-day]
          </code>{' '}
          is any of Mon, Tue, Wed, Thu, Fri, Sat, or Sun.
        </li>
        <li>
          <code className="text-sm bg-juno-gray-200 text-juno-gray-700 p-1 rounded-xs">
            [start]
          </code>{' '}
          and{' '}
          <code className="text-sm bg-juno-gray-200 text-juno-gray-700 p-1 rounded-xs">
            [end]
          </code>{' '}
          are in 12-hour format or &quot;Noon&quot; or &quot;Midnight&quot;.
          Military time is currently not supported.
        </li>
        <li>
          <code className="text-sm bg-juno-gray-200 text-juno-gray-700 p-1 rounded-xs">
            [description]
          </code>{' '}
          is optional.
        </li>
      </ul>
      <hr className="border-juno-gray-200 my-2" />
      Example:
      <ul className="list-disc ml-8">
        <li>Fri 10PM - Midnight</li>
        <li>Sat Midnight - 2AM: Murder Hobo</li>
      </ul>
      <hr className="border-juno-gray-200 my-2" />
      Legacy Shifts are marked as such and will not follow this sorting rule.
    </div>
  ),
  [IShiftKindEnum.Cleanup]: null,
  [IShiftKindEnum.Special]: null,
};
const ByAssignment: FC = () => {
  const { shiftKinds, shifts, fetching } = useEventShifts();
  const [ expandedShiftIds, setExpandedShiftIds ] = useState<number[]>([]);
  const [ showInvisible, setShowInvisible ] = useState(true);
  const handleExpand = useCallback((id: number) => {
    setExpandedShiftIds(x =>
      x.includes(id) ? x.filter(y => y !== id) : [ ...x, id ],
    );
  }, []);
  const handleExpandAll = useCallback(() => {
    if (expandedShiftIds.length === 0) {
      setExpandedShiftIds(shifts.map(x => x.id));
    } else {
      setExpandedShiftIds([]);
    }
  }, [ expandedShiftIds.length, shifts ]);
  const partialExpanded = expandedShiftIds.length > 0;

  if (fetching) return <Loading />;

  return (
    <div className="grid justify-center w-full">
      <ResponseBox type="success" withIcon={faLightbulb}>
        You can{' '}
        <button
          type="button"
          className="underline"
          onClick={() => window.print()}
        >
          print this page.
        </button>
      </ResponseBox>
      <Table
        className="table-fixed w-[calc(100vw-8px)] sm:w-[480px] lg:w-[640px]"
        headers={[
          {
            content: (
              <FontAwesomeIcon icon={showInvisible ? faEye : faEyeSlash} />
            ),
            className: 'w-[58px] text-center cursor-pointer',
            key: 'visibility',
            onClick: () => setShowInvisible(x => !x),
          },
          { content: 'Name' },
          { content: 'Cap', className: 'w-[80px] md:w-[96px] text-right' },
          {
            content: 'Expand',
            className: 'w-[64px] md:w-[96px] text-left',
          },
          {
            content: (
              <FontAwesomeIcon
                icon={faChevronDown}
                className={clsx(
                  'cursor-pointer transition-all duration-300',
                  partialExpanded && '-scale-y-100',
                )}
              />
            ),
            className: 'w-[24px]',
            onClick: handleExpandAll,
          },
        ]}
      >
        {shiftKinds.map(kind => (
          <ShiftKind
            key={kind}
            kind={kind}
            expandedShiftIds={expandedShiftIds}
            showInvisible={showInvisible}
            shifts={shifts.filter(x => x.kind === kind)}
            shiftTip={shiftTip[kind]}
            onExpand={handleExpand}
          />
        ))}
      </Table>
      <PrintableShifts />
    </div>
  );
};

export default ByAssignment;
