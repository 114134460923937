import { FC, useMemo } from 'react';
import { startCase } from 'lodash';
import { TSkill } from 'src/components/0500_character_builders/dystopia_rising/types';
import useSkillFinder from 'src/components/0500_character_builders/dystopia_rising/hooks/useSkillFinder';
import useXp from 'src/components/0500_character_builders/dystopia_rising/hooks/useXp';

type TSkillAnnotated = TSkill & {
  isAberrant?: boolean;
};

interface IProps {
  category: 'impact' | 'development';
  maxRows: number;
}

const CommonSkills: FC<IProps> = ({ category, maxRows }) => {
  const { skillsFinder } = useSkillFinder();
  const { basicSkillsCost } = useXp();
  const skillsCost = basicSkillsCost[category];

  const skills: TSkillAnnotated[] = useMemo(
    () =>
      skillsFinder({
        category,
        isAberrant: false,
      })
        .sort((a, b) => a.name.localeCompare(b.name))
        .concat(
          skillsFinder({
            category,
            isAberrant: true,
          })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(x => ({ ...x, isAberrant: true })),
        ),

    [ category, skillsFinder ],
  );

  const cappedRows = Math.min(skills.length + 5, maxRows);
  const blankRows = Math.max(cappedRows - skills.length, 0);

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>
            {startCase(category)} Skills
            {skillsCost > 0 && ` (${skillsCost}XP)`}
          </th>
        </tr>
      </thead>
      <tbody className="grid-4-1 numeric-last">
        {skills.map(skill => (
          <tr key={skill.id}>
            <td>
              <div className="flex justify-between">
                <div>{skill.name}</div>
                <div>{skill.isAberrant && 'A'}</div>
              </div>
            </td>
            <td>{skill.level}</td>
          </tr>
        ))}
        {Array.from({ length: blankRows }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td className="text-transparent">.</td>
            <td />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CommonSkills;
