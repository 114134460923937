import { Link, useParams } from '@tanstack/react-router';
import { format, isBefore, parseISO } from 'date-fns';
import { FC } from 'react';
import useOrganizationRebirthedCharacters from 'src/hooks/organizations/useOrganizationRebirthedCharacters';

import 'src/basic-table.css';

const CharacterRetirements: FC = () => {
  const { organizationSlug } = useParams({ strict: false });
  const { rebirthedCharacters } = useOrganizationRebirthedCharacters();

  return (
    <table className="basic-table table-fixed">
      <thead>
        <tr>
          <th>ID</th>
          <th>Player Name</th>
          <th>Rebirthed Character</th>
          <th>Retired Character</th>
        </tr>
      </thead>
      <tbody>
        {rebirthedCharacters
          .sort((a, b) =>
            isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? 1 : -1,
          )
          .map(x => (
            <tr key={x.id}>
              <td className="text-right">
                <Link
                  to="/$organizationSlug/players/$playerId/*"
                  params={{
                    organizationSlug: String(organizationSlug),
                    playerId: String(x.user.id),
                  }}
                  className="underline"
                >
                  {x.user.id}
                </Link>
              </td>
              <td>
                <div>{x.user.fullName}</div>
                <div className="text-sm opacity-50">
                  {x.user.userOrganization?.branch.name}
                </div>
              </td>
              <td>
                <Link
                  to="/$organizationSlug/players/$playerId/characters/$characterId/*"
                  params={{
                    organizationSlug: String(organizationSlug),
                    playerId: String(x.user.id),
                    characterId: String(x.id),
                  }}
                  className="underline"
                >
                  {x.name}
                </Link>
                <div className="text-sm opacity-50">
                  {x.legacyHomeGameCount} Games
                </div>
              </td>
              {x.retiredCharacter ? (
                <td>
                  <Link
                    to="/$organizationSlug/players/$playerId/characters/$characterId/*"
                    params={{
                      organizationSlug: String(organizationSlug),
                      playerId: String(x.user.id),
                      characterId: String(x.retiredCharacter.id),
                    }}
                    className="underline"
                  >
                    {x.retiredCharacter?.name}
                  </Link>
                  <div className="text-sm opacity-50">
                    {format(parseISO(x.createdAt), 'yyyy-MM-dd')}
                  </div>
                </td>
              ) : (
                <td />
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default CharacterRetirements;
