import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLinkProps, useParams } from '@tanstack/react-router';
import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useRootUser from 'src/hooks/players/useRootUser';

const EventCalendarSidebar: FC = () => {
  const { rootUserId } = useRootUser();
  const { organizationSlug } = useParams({ strict: false });
  const linkToEvents = useLinkProps({
    to: rootUserId ? '/$organizationSlug/events' : '/calendar',
    params: {
      organizationSlug: String(organizationSlug),
    },
  });

  if (rootUserId && !organizationSlug) return null;

  return (
    <Tab
      highlightMode="vertical"
      label={
        <div>
          <FontAwesomeIcon icon={faCalendar} className="pr-2 fa-fw" />
          Event Calendar
        </div>
      }
      to={linkToEvents.href}
    />
  );
};

export default EventCalendarSidebar;
