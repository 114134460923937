import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@tanstack/react-router';
import { startCase } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'src/components/0100_button';
import ResponseBox from 'src/components/0100_response_box';
import BaseSelect from 'src/components/0300_base_select';
import { copyCharacterBuild } from 'src/graphql/mutations/characters.graphql';
import {
  ICopyCharacterBuildMutation,
  ICopyCharacterBuildMutationVariables,
} from 'src/graphql/mutations/characters.graphql.types';
import useButtonStates from 'src/hooks/buttonStates/useButtonStates';
import usePlayerWithCharactersInOrganization from 'src/hooks/players/usePlayerWithCharactersInOrganization';
import { useMutation } from 'urql';

const Copyable: FC = () => {
  const { buttonState } = useButtonStates();
  const { playerId, characterId } = useParams({ strict: false });
  const methods = useForm({
    defaultValues: {
      otherCharacterId: 0,
    },
  });
  const { register, setValue, watch } = methods;
  const [ error, setError ] = useState<string | null>(null);
  const [ isSuccessful, setIsSuccessful ] = useState(false);
  const { otherCharacterId } = watch();
  const { player, characters } = usePlayerWithCharactersInOrganization({
    playerId: Number(playerId),
  });
  const [ direction, setDirection ] = useState<'to' | 'from'>('to');
  const thisCharacter = useMemo(
    () => characters.find(x => x.id === Number(characterId)),
    [ characterId, characters ],
  );
  const options = useMemo(() => {
    const preResult = () => {
      if (direction === 'to') {
        return characters
          .filter(x => x.id !== Number(characterId))
          .filter(x => x.status === 'staged')
          .map(x => ({ label: x.name, value: x.id }));
      }

      if (thisCharacter?.status !== 'staged') {
        return [
          {
            label: 'Cannot copy from non-Staged Characters',
            value: -1,
            isLocked: true,
          },
        ];
      }

      return characters
        .filter(x => x.id !== Number(characterId))
        .filter(x => x.version === 4)
        .map(x => ({ label: x.name, value: x.id }));
    };

    if (preResult().length === 0) {
      return [
        {
          label: `No ${direction === 'to' ? 'Target' : 'Source'} Available`,
          value: -1,
          isLocked: true,
        },
      ];
    }

    return preResult();
  }, [ characterId, characters, direction, thisCharacter?.status ]);

  const [ copyResult, copy ] = useMutation<
    ICopyCharacterBuildMutation,
    ICopyCharacterBuildMutationVariables
  >(copyCharacterBuild);
  const handleCopy = useCallback(() => {
    setIsSuccessful(false);
    setError(null);
    copy({
      sourceCharacterId:
        direction === 'to' ? Number(characterId) : otherCharacterId,
      targetCharacterId:
        direction === 'to' ? otherCharacterId : Number(characterId),
    }).then(res => {
      if (res.data?.copyCharacterBuild?.character?.id) {
        setIsSuccessful(true);
        setTimeout(() => setIsSuccessful(false), 3000);
      }

      if (res.data?.copyCharacterBuild?.error) {
        setError(res.data.copyCharacterBuild.error);
      }
    });
  }, [ characterId, copy, direction, otherCharacterId ]);

  useEffect(() => {
    setError(null);
    setIsSuccessful(false);
  }, [ otherCharacterId, characterId ]);

  useEffect(() => {
    setValue('otherCharacterId', 0);
  }, [ direction, setValue ]);

  if (!player) return null;

  return (
    <FormProvider {...methods}>
      <div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            className="underline whitespace-nowrap pr-2 lg:px-4"
            onClick={() => setDirection(x => (x === 'from' ? 'to' : 'from'))}
          >
            Copy Build {startCase(direction)}
          </button>
          <div className="w-full z-30">
            <BaseSelect
              defaultLabel="Select Character"
              width="w-full"
              rounding="rounded-l"
              options={options}
              selectedValue={otherCharacterId}
              {...register('otherCharacterId', { required: true })}
            />
          </div>
          <Button
            defaultLabel="Execute"
            className="rounded-l-none rounded-r-sm"
            state={buttonState({
              isValid: !!otherCharacterId && otherCharacterId > 0,
              isDirty: !!otherCharacterId && otherCharacterId > 0,
              isFetching: copyResult.fetching,
              isSuccessful,
            })}
            stateLabel={{
              loading: 'Copying...',
              success: (
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faCheck} />
                  Copied
                </div>
              ),
            }}
            onClick={() => handleCopy()}
          />
        </div>
        {error && <ResponseBox type="error">{error}</ResponseBox>}
      </div>
    </FormProvider>
  );
};

export default Copyable;
