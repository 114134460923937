/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as CalendarImport } from './routes/calendar';
import { Route as IndexImport } from './routes/index';
import { Route as OrganizationSlugIndexImport } from './routes/$organizationSlug/index';
import { Route as OrganizationSlugStatisticsIndexImport } from './routes/$organizationSlug/statistics/index';
import { Route as OrganizationSlugReportsIndexImport } from './routes/$organizationSlug/reports/index';
import { Route as OrganizationSlugMechanicsIndexImport } from './routes/$organizationSlug/mechanics/index';
import { Route as OrganizationSlugInvoiceIndexImport } from './routes/$organizationSlug/invoice/index';
import { Route as OrganizationSlugEventsIndexImport } from './routes/$organizationSlug/events/index';
import { Route as OrganizationSlugCharactersIndexImport } from './routes/$organizationSlug/characters/index';
import { Route as OrganizationSlugBlueprintsSplatImport } from './routes/$organizationSlug/blueprints/$';
import { Route as OrganizationSlugPlayersPlayerIdRouteImport } from './routes/$organizationSlug/players/$playerId/route';
import { Route as OrganizationSlugEventsEventIdRouteImport } from './routes/$organizationSlug/events/$eventId/route';
import { Route as OrganizationSlugBranchesBranchIdRouteImport } from './routes/$organizationSlug/branches/$branchId/route';
import { Route as OrganizationSlugSettingsSpecialdatesIndexImport } from './routes/$organizationSlug/settings/special_dates/index';
import { Route as OrganizationSlugReportsMembershipsIndexImport } from './routes/$organizationSlug/reports/memberships/index';
import { Route as OrganizationSlugReportsCorrectiveactionsIndexImport } from './routes/$organizationSlug/reports/corrective_actions/index';
import { Route as OrganizationSlugReportsCharacterretirementsIndexImport } from './routes/$organizationSlug/reports/character_retirements/index';
import { Route as OrganizationSlugReportsCapsIndexImport } from './routes/$organizationSlug/reports/caps/index';
import { Route as OrganizationSlugMechanicsSocietiesIndexImport } from './routes/$organizationSlug/mechanics/societies/index';
import { Route as OrganizationSlugMechanicsDiseasesIndexImport } from './routes/$organizationSlug/mechanics/diseases/index';
import { Route as OrganizationSlugBlueprintsTabularIndexImport } from './routes/$organizationSlug/blueprints/tabular/index';
import { Route as OrganizationSlugReportsCorrectiveactionsBranchIdImport } from './routes/$organizationSlug/reports/corrective_actions/$branchId';
import { Route as OrganizationSlugReportsCapsBranchIdImport } from './routes/$organizationSlug/reports/caps/$branchId';
import { Route as OrganizationSlugPlayersPlayerIdReferralsImport } from './routes/$organizationSlug/players/$playerId/referrals';
import { Route as OrganizationSlugPlayersPlayerIdPerksImport } from './routes/$organizationSlug/players/$playerId/perks';
import { Route as OrganizationSlugPlayersPlayerIdMembershipsImport } from './routes/$organizationSlug/players/$playerId/memberships';
import { Route as OrganizationSlugPlayersPlayerIdCorrectiveactionsImport } from './routes/$organizationSlug/players/$playerId/corrective_actions';
import { Route as OrganizationSlugPlayersPlayerIdAttendanceImport } from './routes/$organizationSlug/players/$playerId/attendance';
import { Route as OrganizationSlugPlayersPlayerIdImport } from './routes/$organizationSlug/players/$playerId/*';
import { Route as OrganizationSlugMechanicsSocietiesFellowshipIdImport } from './routes/$organizationSlug/mechanics/societies/$fellowshipId';
import { Route as OrganizationSlugMechanicsDiseasesFellowshipIdImport } from './routes/$organizationSlug/mechanics/diseases/$fellowshipId';
import { Route as OrganizationSlugEventsEventIdWarroomImport } from './routes/$organizationSlug/events/$eventId/war_room';
import { Route as OrganizationSlugEventsEventIdTransactionsauditImport } from './routes/$organizationSlug/events/$eventId/transactions_audit';
import { Route as OrganizationSlugEventsEventIdTicketsImport } from './routes/$organizationSlug/events/$eventId/tickets';
import { Route as OrganizationSlugEventsEventIdShiftsImport } from './routes/$organizationSlug/events/$eventId/shifts';
import { Route as OrganizationSlugEventsEventIdPrintoutsImport } from './routes/$organizationSlug/events/$eventId/printouts';
import { Route as OrganizationSlugEventsEventIdLogisticsImport } from './routes/$organizationSlug/events/$eventId/logistics';
import { Route as OrganizationSlugEventsEventIdCheckoutImport } from './routes/$organizationSlug/events/$eventId/checkout';
import { Route as OrganizationSlugEventsEventIdBasicsImport } from './routes/$organizationSlug/events/$eventId/basics';
import { Route as OrganizationSlugEventsEventIdImport } from './routes/$organizationSlug/events/$eventId/*';
import { Route as OrganizationSlugBranchesBranchIdLocationsImport } from './routes/$organizationSlug/branches/$branchId/locations';
import { Route as OrganizationSlugBranchesBranchIdCorrectiveactionsImport } from './routes/$organizationSlug/branches/$branchId/corrective_actions';
import { Route as OrganizationSlugBranchesBranchIdCapsImport } from './routes/$organizationSlug/branches/$branchId/caps';
import { Route as OrganizationSlugBranchesBranchIdAttendanceImport } from './routes/$organizationSlug/branches/$branchId/attendance';
import { Route as OrganizationSlugBranchesBranchIdImport } from './routes/$organizationSlug/branches/$branchId/*';
import { Route as OrganizationSlugPlayersPlayerIdCharactersIndexImport } from './routes/$organizationSlug/players/$playerId/characters/index';
import { Route as OrganizationSlugEventsEventIdBlueprintsSplatImport } from './routes/$organizationSlug/events/$eventId/blueprints/$';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/route';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/printout';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/journal';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/inventory';
import { Route as OrganizationSlugPlayersPlayerIdCharactersCharacterIdImport } from './routes/$organizationSlug/players/$playerId/characters/$characterId/*';

// Create/Update Routes

const CalendarRoute = CalendarImport.update({
  id: '/calendar',
  path: '/calendar',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const OrganizationSlugIndexRoute = OrganizationSlugIndexImport.update({
  id: '/$organizationSlug/',
  path: '/$organizationSlug/',
  getParentRoute: () => rootRoute,
} as any);

const OrganizationSlugStatisticsIndexRoute =
  OrganizationSlugStatisticsIndexImport.update({
    id: '/$organizationSlug/statistics/',
    path: '/$organizationSlug/statistics/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsIndexRoute =
  OrganizationSlugReportsIndexImport.update({
    id: '/$organizationSlug/reports/',
    path: '/$organizationSlug/reports/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugMechanicsIndexRoute =
  OrganizationSlugMechanicsIndexImport.update({
    id: '/$organizationSlug/mechanics/',
    path: '/$organizationSlug/mechanics/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugInvoiceIndexRoute =
  OrganizationSlugInvoiceIndexImport.update({
    id: '/$organizationSlug/invoice/',
    path: '/$organizationSlug/invoice/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugEventsIndexRoute =
  OrganizationSlugEventsIndexImport.update({
    id: '/$organizationSlug/events/',
    path: '/$organizationSlug/events/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugCharactersIndexRoute =
  OrganizationSlugCharactersIndexImport.update({
    id: '/$organizationSlug/characters/',
    path: '/$organizationSlug/characters/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugBlueprintsSplatRoute =
  OrganizationSlugBlueprintsSplatImport.update({
    id: '/$organizationSlug/blueprints/$',
    path: '/$organizationSlug/blueprints/$',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdRouteRoute =
  OrganizationSlugPlayersPlayerIdRouteImport.update({
    id: '/$organizationSlug/players/$playerId',
    path: '/$organizationSlug/players/$playerId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugEventsEventIdRouteRoute =
  OrganizationSlugEventsEventIdRouteImport.update({
    id: '/$organizationSlug/events/$eventId',
    path: '/$organizationSlug/events/$eventId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugBranchesBranchIdRouteRoute =
  OrganizationSlugBranchesBranchIdRouteImport.update({
    id: '/$organizationSlug/branches/$branchId',
    path: '/$organizationSlug/branches/$branchId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugSettingsSpecialdatesIndexRoute =
  OrganizationSlugSettingsSpecialdatesIndexImport.update({
    id: '/$organizationSlug/settings/special_dates/',
    path: '/$organizationSlug/settings/special_dates/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsMembershipsIndexRoute =
  OrganizationSlugReportsMembershipsIndexImport.update({
    id: '/$organizationSlug/reports/memberships/',
    path: '/$organizationSlug/reports/memberships/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsCorrectiveactionsIndexRoute =
  OrganizationSlugReportsCorrectiveactionsIndexImport.update({
    id: '/$organizationSlug/reports/corrective_actions/',
    path: '/$organizationSlug/reports/corrective_actions/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsCharacterretirementsIndexRoute =
  OrganizationSlugReportsCharacterretirementsIndexImport.update({
    id: '/$organizationSlug/reports/character_retirements/',
    path: '/$organizationSlug/reports/character_retirements/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsCapsIndexRoute =
  OrganizationSlugReportsCapsIndexImport.update({
    id: '/$organizationSlug/reports/caps/',
    path: '/$organizationSlug/reports/caps/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugMechanicsSocietiesIndexRoute =
  OrganizationSlugMechanicsSocietiesIndexImport.update({
    id: '/$organizationSlug/mechanics/societies/',
    path: '/$organizationSlug/mechanics/societies/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugMechanicsDiseasesIndexRoute =
  OrganizationSlugMechanicsDiseasesIndexImport.update({
    id: '/$organizationSlug/mechanics/diseases/',
    path: '/$organizationSlug/mechanics/diseases/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugBlueprintsTabularIndexRoute =
  OrganizationSlugBlueprintsTabularIndexImport.update({
    id: '/$organizationSlug/blueprints/tabular/',
    path: '/$organizationSlug/blueprints/tabular/',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsCorrectiveactionsBranchIdRoute =
  OrganizationSlugReportsCorrectiveactionsBranchIdImport.update({
    id: '/$organizationSlug/reports/corrective_actions/$branchId',
    path: '/$organizationSlug/reports/corrective_actions/$branchId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugReportsCapsBranchIdRoute =
  OrganizationSlugReportsCapsBranchIdImport.update({
    id: '/$organizationSlug/reports/caps/$branchId',
    path: '/$organizationSlug/reports/caps/$branchId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdReferralsRoute =
  OrganizationSlugPlayersPlayerIdReferralsImport.update({
    id: '/referrals',
    path: '/referrals',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdPerksRoute =
  OrganizationSlugPlayersPlayerIdPerksImport.update({
    id: '/perks',
    path: '/perks',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdMembershipsRoute =
  OrganizationSlugPlayersPlayerIdMembershipsImport.update({
    id: '/memberships',
    path: '/memberships',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute =
  OrganizationSlugPlayersPlayerIdCorrectiveactionsImport.update({
    id: '/corrective_actions',
    path: '/corrective_actions',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdAttendanceRoute =
  OrganizationSlugPlayersPlayerIdAttendanceImport.update({
    id: '/attendance',
    path: '/attendance',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdRoute =
  OrganizationSlugPlayersPlayerIdImport.update({
    id: '/*',
    path: '/*',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugMechanicsSocietiesFellowshipIdRoute =
  OrganizationSlugMechanicsSocietiesFellowshipIdImport.update({
    id: '/$organizationSlug/mechanics/societies/$fellowshipId',
    path: '/$organizationSlug/mechanics/societies/$fellowshipId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugMechanicsDiseasesFellowshipIdRoute =
  OrganizationSlugMechanicsDiseasesFellowshipIdImport.update({
    id: '/$organizationSlug/mechanics/diseases/$fellowshipId',
    path: '/$organizationSlug/mechanics/diseases/$fellowshipId',
    getParentRoute: () => rootRoute,
  } as any);

const OrganizationSlugEventsEventIdWarroomRoute =
  OrganizationSlugEventsEventIdWarroomImport.update({
    id: '/war_room',
    path: '/war_room',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdTransactionsauditRoute =
  OrganizationSlugEventsEventIdTransactionsauditImport.update({
    id: '/transactions_audit',
    path: '/transactions_audit',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdTicketsRoute =
  OrganizationSlugEventsEventIdTicketsImport.update({
    id: '/tickets',
    path: '/tickets',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdShiftsRoute =
  OrganizationSlugEventsEventIdShiftsImport.update({
    id: '/shifts',
    path: '/shifts',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdPrintoutsRoute =
  OrganizationSlugEventsEventIdPrintoutsImport.update({
    id: '/printouts',
    path: '/printouts',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdLogisticsRoute =
  OrganizationSlugEventsEventIdLogisticsImport.update({
    id: '/logistics',
    path: '/logistics',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdCheckoutRoute =
  OrganizationSlugEventsEventIdCheckoutImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdBasicsRoute =
  OrganizationSlugEventsEventIdBasicsImport.update({
    id: '/basics',
    path: '/basics',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdRoute =
  OrganizationSlugEventsEventIdImport.update({
    id: '/*',
    path: '/*',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugBranchesBranchIdLocationsRoute =
  OrganizationSlugBranchesBranchIdLocationsImport.update({
    id: '/locations',
    path: '/locations',
    getParentRoute: () => OrganizationSlugBranchesBranchIdRouteRoute,
  } as any);

const OrganizationSlugBranchesBranchIdCorrectiveactionsRoute =
  OrganizationSlugBranchesBranchIdCorrectiveactionsImport.update({
    id: '/corrective_actions',
    path: '/corrective_actions',
    getParentRoute: () => OrganizationSlugBranchesBranchIdRouteRoute,
  } as any);

const OrganizationSlugBranchesBranchIdCapsRoute =
  OrganizationSlugBranchesBranchIdCapsImport.update({
    id: '/caps',
    path: '/caps',
    getParentRoute: () => OrganizationSlugBranchesBranchIdRouteRoute,
  } as any);

const OrganizationSlugBranchesBranchIdAttendanceRoute =
  OrganizationSlugBranchesBranchIdAttendanceImport.update({
    id: '/attendance',
    path: '/attendance',
    getParentRoute: () => OrganizationSlugBranchesBranchIdRouteRoute,
  } as any);

const OrganizationSlugBranchesBranchIdRoute =
  OrganizationSlugBranchesBranchIdImport.update({
    id: '/*',
    path: '/*',
    getParentRoute: () => OrganizationSlugBranchesBranchIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersIndexRoute =
  OrganizationSlugPlayersPlayerIdCharactersIndexImport.update({
    id: '/characters/',
    path: '/characters/',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugEventsEventIdBlueprintsSplatRoute =
  OrganizationSlugEventsEventIdBlueprintsSplatImport.update({
    id: '/blueprints/$',
    path: '/blueprints/$',
    getParentRoute: () => OrganizationSlugEventsEventIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport.update({
    id: '/characters/$characterId',
    path: '/characters/$characterId',
    getParentRoute: () => OrganizationSlugPlayersPlayerIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditImport.update({
    id: '/xp_audit',
    path: '/xp_audit',
    getParentRoute: () =>
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutImport.update({
    id: '/printout',
    path: '/printout',
    getParentRoute: () =>
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalImport.update({
    id: '/journal',
    path: '/journal',
    getParentRoute: () =>
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryImport.update({
    id: '/inventory',
    path: '/inventory',
    getParentRoute: () =>
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute,
  } as any);

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdImport.update({
    id: '/*',
    path: '/*',
    getParentRoute: () =>
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/calendar': {
      id: '/calendar';
      path: '/calendar';
      fullPath: '/calendar';
      preLoaderRoute: typeof CalendarImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/': {
      id: '/$organizationSlug/';
      path: '/$organizationSlug';
      fullPath: '/$organizationSlug';
      preLoaderRoute: typeof OrganizationSlugIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/branches/$branchId': {
      id: '/$organizationSlug/branches/$branchId';
      path: '/$organizationSlug/branches/$branchId';
      fullPath: '/$organizationSlug/branches/$branchId';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/events/$eventId': {
      id: '/$organizationSlug/events/$eventId';
      path: '/$organizationSlug/events/$eventId';
      fullPath: '/$organizationSlug/events/$eventId';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/players/$playerId': {
      id: '/$organizationSlug/players/$playerId';
      path: '/$organizationSlug/players/$playerId';
      fullPath: '/$organizationSlug/players/$playerId';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/blueprints/$': {
      id: '/$organizationSlug/blueprints/$';
      path: '/$organizationSlug/blueprints/$';
      fullPath: '/$organizationSlug/blueprints/$';
      preLoaderRoute: typeof OrganizationSlugBlueprintsSplatImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/characters/': {
      id: '/$organizationSlug/characters/';
      path: '/$organizationSlug/characters';
      fullPath: '/$organizationSlug/characters';
      preLoaderRoute: typeof OrganizationSlugCharactersIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/events/': {
      id: '/$organizationSlug/events/';
      path: '/$organizationSlug/events';
      fullPath: '/$organizationSlug/events';
      preLoaderRoute: typeof OrganizationSlugEventsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/invoice/': {
      id: '/$organizationSlug/invoice/';
      path: '/$organizationSlug/invoice';
      fullPath: '/$organizationSlug/invoice';
      preLoaderRoute: typeof OrganizationSlugInvoiceIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/mechanics/': {
      id: '/$organizationSlug/mechanics/';
      path: '/$organizationSlug/mechanics';
      fullPath: '/$organizationSlug/mechanics';
      preLoaderRoute: typeof OrganizationSlugMechanicsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/': {
      id: '/$organizationSlug/reports/';
      path: '/$organizationSlug/reports';
      fullPath: '/$organizationSlug/reports';
      preLoaderRoute: typeof OrganizationSlugReportsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/statistics/': {
      id: '/$organizationSlug/statistics/';
      path: '/$organizationSlug/statistics';
      fullPath: '/$organizationSlug/statistics';
      preLoaderRoute: typeof OrganizationSlugStatisticsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/branches/$branchId/*': {
      id: '/$organizationSlug/branches/$branchId/*';
      path: '/*';
      fullPath: '/$organizationSlug/branches/$branchId/*';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdImport;
      parentRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
    };
    '/$organizationSlug/branches/$branchId/attendance': {
      id: '/$organizationSlug/branches/$branchId/attendance';
      path: '/attendance';
      fullPath: '/$organizationSlug/branches/$branchId/attendance';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdAttendanceImport;
      parentRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
    };
    '/$organizationSlug/branches/$branchId/caps': {
      id: '/$organizationSlug/branches/$branchId/caps';
      path: '/caps';
      fullPath: '/$organizationSlug/branches/$branchId/caps';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdCapsImport;
      parentRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
    };
    '/$organizationSlug/branches/$branchId/corrective_actions': {
      id: '/$organizationSlug/branches/$branchId/corrective_actions';
      path: '/corrective_actions';
      fullPath: '/$organizationSlug/branches/$branchId/corrective_actions';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdCorrectiveactionsImport;
      parentRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
    };
    '/$organizationSlug/branches/$branchId/locations': {
      id: '/$organizationSlug/branches/$branchId/locations';
      path: '/locations';
      fullPath: '/$organizationSlug/branches/$branchId/locations';
      preLoaderRoute: typeof OrganizationSlugBranchesBranchIdLocationsImport;
      parentRoute: typeof OrganizationSlugBranchesBranchIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/*': {
      id: '/$organizationSlug/events/$eventId/*';
      path: '/*';
      fullPath: '/$organizationSlug/events/$eventId/*';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/basics': {
      id: '/$organizationSlug/events/$eventId/basics';
      path: '/basics';
      fullPath: '/$organizationSlug/events/$eventId/basics';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdBasicsImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/checkout': {
      id: '/$organizationSlug/events/$eventId/checkout';
      path: '/checkout';
      fullPath: '/$organizationSlug/events/$eventId/checkout';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdCheckoutImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/logistics': {
      id: '/$organizationSlug/events/$eventId/logistics';
      path: '/logistics';
      fullPath: '/$organizationSlug/events/$eventId/logistics';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdLogisticsImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/printouts': {
      id: '/$organizationSlug/events/$eventId/printouts';
      path: '/printouts';
      fullPath: '/$organizationSlug/events/$eventId/printouts';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdPrintoutsImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/shifts': {
      id: '/$organizationSlug/events/$eventId/shifts';
      path: '/shifts';
      fullPath: '/$organizationSlug/events/$eventId/shifts';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdShiftsImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/tickets': {
      id: '/$organizationSlug/events/$eventId/tickets';
      path: '/tickets';
      fullPath: '/$organizationSlug/events/$eventId/tickets';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdTicketsImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/transactions_audit': {
      id: '/$organizationSlug/events/$eventId/transactions_audit';
      path: '/transactions_audit';
      fullPath: '/$organizationSlug/events/$eventId/transactions_audit';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdTransactionsauditImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/war_room': {
      id: '/$organizationSlug/events/$eventId/war_room';
      path: '/war_room';
      fullPath: '/$organizationSlug/events/$eventId/war_room';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdWarroomImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/mechanics/diseases/$fellowshipId': {
      id: '/$organizationSlug/mechanics/diseases/$fellowshipId';
      path: '/$organizationSlug/mechanics/diseases/$fellowshipId';
      fullPath: '/$organizationSlug/mechanics/diseases/$fellowshipId';
      preLoaderRoute: typeof OrganizationSlugMechanicsDiseasesFellowshipIdImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/mechanics/societies/$fellowshipId': {
      id: '/$organizationSlug/mechanics/societies/$fellowshipId';
      path: '/$organizationSlug/mechanics/societies/$fellowshipId';
      fullPath: '/$organizationSlug/mechanics/societies/$fellowshipId';
      preLoaderRoute: typeof OrganizationSlugMechanicsSocietiesFellowshipIdImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/players/$playerId/*': {
      id: '/$organizationSlug/players/$playerId/*';
      path: '/*';
      fullPath: '/$organizationSlug/players/$playerId/*';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/attendance': {
      id: '/$organizationSlug/players/$playerId/attendance';
      path: '/attendance';
      fullPath: '/$organizationSlug/players/$playerId/attendance';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdAttendanceImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/corrective_actions': {
      id: '/$organizationSlug/players/$playerId/corrective_actions';
      path: '/corrective_actions';
      fullPath: '/$organizationSlug/players/$playerId/corrective_actions';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCorrectiveactionsImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/memberships': {
      id: '/$organizationSlug/players/$playerId/memberships';
      path: '/memberships';
      fullPath: '/$organizationSlug/players/$playerId/memberships';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdMembershipsImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/perks': {
      id: '/$organizationSlug/players/$playerId/perks';
      path: '/perks';
      fullPath: '/$organizationSlug/players/$playerId/perks';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdPerksImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/referrals': {
      id: '/$organizationSlug/players/$playerId/referrals';
      path: '/referrals';
      fullPath: '/$organizationSlug/players/$playerId/referrals';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdReferralsImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/reports/caps/$branchId': {
      id: '/$organizationSlug/reports/caps/$branchId';
      path: '/$organizationSlug/reports/caps/$branchId';
      fullPath: '/$organizationSlug/reports/caps/$branchId';
      preLoaderRoute: typeof OrganizationSlugReportsCapsBranchIdImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/corrective_actions/$branchId': {
      id: '/$organizationSlug/reports/corrective_actions/$branchId';
      path: '/$organizationSlug/reports/corrective_actions/$branchId';
      fullPath: '/$organizationSlug/reports/corrective_actions/$branchId';
      preLoaderRoute: typeof OrganizationSlugReportsCorrectiveactionsBranchIdImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/blueprints/tabular/': {
      id: '/$organizationSlug/blueprints/tabular/';
      path: '/$organizationSlug/blueprints/tabular';
      fullPath: '/$organizationSlug/blueprints/tabular';
      preLoaderRoute: typeof OrganizationSlugBlueprintsTabularIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/mechanics/diseases/': {
      id: '/$organizationSlug/mechanics/diseases/';
      path: '/$organizationSlug/mechanics/diseases';
      fullPath: '/$organizationSlug/mechanics/diseases';
      preLoaderRoute: typeof OrganizationSlugMechanicsDiseasesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/mechanics/societies/': {
      id: '/$organizationSlug/mechanics/societies/';
      path: '/$organizationSlug/mechanics/societies';
      fullPath: '/$organizationSlug/mechanics/societies';
      preLoaderRoute: typeof OrganizationSlugMechanicsSocietiesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/caps/': {
      id: '/$organizationSlug/reports/caps/';
      path: '/$organizationSlug/reports/caps';
      fullPath: '/$organizationSlug/reports/caps';
      preLoaderRoute: typeof OrganizationSlugReportsCapsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/character_retirements/': {
      id: '/$organizationSlug/reports/character_retirements/';
      path: '/$organizationSlug/reports/character_retirements';
      fullPath: '/$organizationSlug/reports/character_retirements';
      preLoaderRoute: typeof OrganizationSlugReportsCharacterretirementsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/corrective_actions/': {
      id: '/$organizationSlug/reports/corrective_actions/';
      path: '/$organizationSlug/reports/corrective_actions';
      fullPath: '/$organizationSlug/reports/corrective_actions';
      preLoaderRoute: typeof OrganizationSlugReportsCorrectiveactionsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/reports/memberships/': {
      id: '/$organizationSlug/reports/memberships/';
      path: '/$organizationSlug/reports/memberships';
      fullPath: '/$organizationSlug/reports/memberships';
      preLoaderRoute: typeof OrganizationSlugReportsMembershipsIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/settings/special_dates/': {
      id: '/$organizationSlug/settings/special_dates/';
      path: '/$organizationSlug/settings/special_dates';
      fullPath: '/$organizationSlug/settings/special_dates';
      preLoaderRoute: typeof OrganizationSlugSettingsSpecialdatesIndexImport;
      parentRoute: typeof rootRoute;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId';
      path: '/characters/$characterId';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/events/$eventId/blueprints/$': {
      id: '/$organizationSlug/events/$eventId/blueprints/$';
      path: '/blueprints/$';
      fullPath: '/$organizationSlug/events/$eventId/blueprints/$';
      preLoaderRoute: typeof OrganizationSlugEventsEventIdBlueprintsSplatImport;
      parentRoute: typeof OrganizationSlugEventsEventIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/': {
      id: '/$organizationSlug/players/$playerId/characters/';
      path: '/characters';
      fullPath: '/$organizationSlug/players/$playerId/characters';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersIndexImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId/*': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId/*';
      path: '/*';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId/*';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId/inventory': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId/inventory';
      path: '/inventory';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId/inventory';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId/journal': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId/journal';
      path: '/journal';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId/journal';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId/printout': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId/printout';
      path: '/printout';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId/printout';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
    };
    '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit': {
      id: '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
      path: '/xp_audit';
      fullPath: '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
      preLoaderRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditImport;
      parentRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteImport;
    };
  }
}

// Create and export the route tree

interface OrganizationSlugBranchesBranchIdRouteRouteChildren {
  OrganizationSlugBranchesBranchIdRoute: typeof OrganizationSlugBranchesBranchIdRoute;
  OrganizationSlugBranchesBranchIdAttendanceRoute: typeof OrganizationSlugBranchesBranchIdAttendanceRoute;
  OrganizationSlugBranchesBranchIdCapsRoute: typeof OrganizationSlugBranchesBranchIdCapsRoute;
  OrganizationSlugBranchesBranchIdCorrectiveactionsRoute: typeof OrganizationSlugBranchesBranchIdCorrectiveactionsRoute;
  OrganizationSlugBranchesBranchIdLocationsRoute: typeof OrganizationSlugBranchesBranchIdLocationsRoute;
}

const OrganizationSlugBranchesBranchIdRouteRouteChildren: OrganizationSlugBranchesBranchIdRouteRouteChildren =
  {
    OrganizationSlugBranchesBranchIdRoute:
      OrganizationSlugBranchesBranchIdRoute,
    OrganizationSlugBranchesBranchIdAttendanceRoute:
      OrganizationSlugBranchesBranchIdAttendanceRoute,
    OrganizationSlugBranchesBranchIdCapsRoute:
      OrganizationSlugBranchesBranchIdCapsRoute,
    OrganizationSlugBranchesBranchIdCorrectiveactionsRoute:
      OrganizationSlugBranchesBranchIdCorrectiveactionsRoute,
    OrganizationSlugBranchesBranchIdLocationsRoute:
      OrganizationSlugBranchesBranchIdLocationsRoute,
  };

const OrganizationSlugBranchesBranchIdRouteRouteWithChildren =
  OrganizationSlugBranchesBranchIdRouteRoute._addFileChildren(
    OrganizationSlugBranchesBranchIdRouteRouteChildren,
  );

interface OrganizationSlugEventsEventIdRouteRouteChildren {
  OrganizationSlugEventsEventIdRoute: typeof OrganizationSlugEventsEventIdRoute;
  OrganizationSlugEventsEventIdBasicsRoute: typeof OrganizationSlugEventsEventIdBasicsRoute;
  OrganizationSlugEventsEventIdCheckoutRoute: typeof OrganizationSlugEventsEventIdCheckoutRoute;
  OrganizationSlugEventsEventIdLogisticsRoute: typeof OrganizationSlugEventsEventIdLogisticsRoute;
  OrganizationSlugEventsEventIdPrintoutsRoute: typeof OrganizationSlugEventsEventIdPrintoutsRoute;
  OrganizationSlugEventsEventIdShiftsRoute: typeof OrganizationSlugEventsEventIdShiftsRoute;
  OrganizationSlugEventsEventIdTicketsRoute: typeof OrganizationSlugEventsEventIdTicketsRoute;
  OrganizationSlugEventsEventIdTransactionsauditRoute: typeof OrganizationSlugEventsEventIdTransactionsauditRoute;
  OrganizationSlugEventsEventIdWarroomRoute: typeof OrganizationSlugEventsEventIdWarroomRoute;
  OrganizationSlugEventsEventIdBlueprintsSplatRoute: typeof OrganizationSlugEventsEventIdBlueprintsSplatRoute;
}

const OrganizationSlugEventsEventIdRouteRouteChildren: OrganizationSlugEventsEventIdRouteRouteChildren =
  {
    OrganizationSlugEventsEventIdRoute: OrganizationSlugEventsEventIdRoute,
    OrganizationSlugEventsEventIdBasicsRoute:
      OrganizationSlugEventsEventIdBasicsRoute,
    OrganizationSlugEventsEventIdCheckoutRoute:
      OrganizationSlugEventsEventIdCheckoutRoute,
    OrganizationSlugEventsEventIdLogisticsRoute:
      OrganizationSlugEventsEventIdLogisticsRoute,
    OrganizationSlugEventsEventIdPrintoutsRoute:
      OrganizationSlugEventsEventIdPrintoutsRoute,
    OrganizationSlugEventsEventIdShiftsRoute:
      OrganizationSlugEventsEventIdShiftsRoute,
    OrganizationSlugEventsEventIdTicketsRoute:
      OrganizationSlugEventsEventIdTicketsRoute,
    OrganizationSlugEventsEventIdTransactionsauditRoute:
      OrganizationSlugEventsEventIdTransactionsauditRoute,
    OrganizationSlugEventsEventIdWarroomRoute:
      OrganizationSlugEventsEventIdWarroomRoute,
    OrganizationSlugEventsEventIdBlueprintsSplatRoute:
      OrganizationSlugEventsEventIdBlueprintsSplatRoute,
  };

const OrganizationSlugEventsEventIdRouteRouteWithChildren =
  OrganizationSlugEventsEventIdRouteRoute._addFileChildren(
    OrganizationSlugEventsEventIdRouteRouteChildren,
  );

interface OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteChildren {
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute;
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute;
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute;
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute;
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute;
}

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteChildren: OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteChildren =
  {
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute,
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute,
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute,
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute,
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute,
  };

const OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren =
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute._addFileChildren(
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteChildren,
  );

interface OrganizationSlugPlayersPlayerIdRouteRouteChildren {
  OrganizationSlugPlayersPlayerIdRoute: typeof OrganizationSlugPlayersPlayerIdRoute;
  OrganizationSlugPlayersPlayerIdAttendanceRoute: typeof OrganizationSlugPlayersPlayerIdAttendanceRoute;
  OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute: typeof OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute;
  OrganizationSlugPlayersPlayerIdMembershipsRoute: typeof OrganizationSlugPlayersPlayerIdMembershipsRoute;
  OrganizationSlugPlayersPlayerIdPerksRoute: typeof OrganizationSlugPlayersPlayerIdPerksRoute;
  OrganizationSlugPlayersPlayerIdReferralsRoute: typeof OrganizationSlugPlayersPlayerIdReferralsRoute;
  OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute: typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren;
  OrganizationSlugPlayersPlayerIdCharactersIndexRoute: typeof OrganizationSlugPlayersPlayerIdCharactersIndexRoute;
}

const OrganizationSlugPlayersPlayerIdRouteRouteChildren: OrganizationSlugPlayersPlayerIdRouteRouteChildren =
  {
    OrganizationSlugPlayersPlayerIdRoute: OrganizationSlugPlayersPlayerIdRoute,
    OrganizationSlugPlayersPlayerIdAttendanceRoute:
      OrganizationSlugPlayersPlayerIdAttendanceRoute,
    OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute:
      OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute,
    OrganizationSlugPlayersPlayerIdMembershipsRoute:
      OrganizationSlugPlayersPlayerIdMembershipsRoute,
    OrganizationSlugPlayersPlayerIdPerksRoute:
      OrganizationSlugPlayersPlayerIdPerksRoute,
    OrganizationSlugPlayersPlayerIdReferralsRoute:
      OrganizationSlugPlayersPlayerIdReferralsRoute,
    OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRoute:
      OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren,
    OrganizationSlugPlayersPlayerIdCharactersIndexRoute:
      OrganizationSlugPlayersPlayerIdCharactersIndexRoute,
  };

const OrganizationSlugPlayersPlayerIdRouteRouteWithChildren =
  OrganizationSlugPlayersPlayerIdRouteRoute._addFileChildren(
    OrganizationSlugPlayersPlayerIdRouteRouteChildren,
  );

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '/calendar': typeof CalendarRoute;
  '/$organizationSlug': typeof OrganizationSlugIndexRoute;
  '/$organizationSlug/branches/$branchId': typeof OrganizationSlugBranchesBranchIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId': typeof OrganizationSlugEventsEventIdRouteRouteWithChildren;
  '/$organizationSlug/players/$playerId': typeof OrganizationSlugPlayersPlayerIdRouteRouteWithChildren;
  '/$organizationSlug/blueprints/$': typeof OrganizationSlugBlueprintsSplatRoute;
  '/$organizationSlug/characters': typeof OrganizationSlugCharactersIndexRoute;
  '/$organizationSlug/events': typeof OrganizationSlugEventsIndexRoute;
  '/$organizationSlug/invoice': typeof OrganizationSlugInvoiceIndexRoute;
  '/$organizationSlug/mechanics': typeof OrganizationSlugMechanicsIndexRoute;
  '/$organizationSlug/reports': typeof OrganizationSlugReportsIndexRoute;
  '/$organizationSlug/statistics': typeof OrganizationSlugStatisticsIndexRoute;
  '/$organizationSlug/branches/$branchId/*': typeof OrganizationSlugBranchesBranchIdRoute;
  '/$organizationSlug/branches/$branchId/attendance': typeof OrganizationSlugBranchesBranchIdAttendanceRoute;
  '/$organizationSlug/branches/$branchId/caps': typeof OrganizationSlugBranchesBranchIdCapsRoute;
  '/$organizationSlug/branches/$branchId/corrective_actions': typeof OrganizationSlugBranchesBranchIdCorrectiveactionsRoute;
  '/$organizationSlug/branches/$branchId/locations': typeof OrganizationSlugBranchesBranchIdLocationsRoute;
  '/$organizationSlug/events/$eventId/*': typeof OrganizationSlugEventsEventIdRoute;
  '/$organizationSlug/events/$eventId/basics': typeof OrganizationSlugEventsEventIdBasicsRoute;
  '/$organizationSlug/events/$eventId/checkout': typeof OrganizationSlugEventsEventIdCheckoutRoute;
  '/$organizationSlug/events/$eventId/logistics': typeof OrganizationSlugEventsEventIdLogisticsRoute;
  '/$organizationSlug/events/$eventId/printouts': typeof OrganizationSlugEventsEventIdPrintoutsRoute;
  '/$organizationSlug/events/$eventId/shifts': typeof OrganizationSlugEventsEventIdShiftsRoute;
  '/$organizationSlug/events/$eventId/tickets': typeof OrganizationSlugEventsEventIdTicketsRoute;
  '/$organizationSlug/events/$eventId/transactions_audit': typeof OrganizationSlugEventsEventIdTransactionsauditRoute;
  '/$organizationSlug/events/$eventId/war_room': typeof OrganizationSlugEventsEventIdWarroomRoute;
  '/$organizationSlug/mechanics/diseases/$fellowshipId': typeof OrganizationSlugMechanicsDiseasesFellowshipIdRoute;
  '/$organizationSlug/mechanics/societies/$fellowshipId': typeof OrganizationSlugMechanicsSocietiesFellowshipIdRoute;
  '/$organizationSlug/players/$playerId/*': typeof OrganizationSlugPlayersPlayerIdRoute;
  '/$organizationSlug/players/$playerId/attendance': typeof OrganizationSlugPlayersPlayerIdAttendanceRoute;
  '/$organizationSlug/players/$playerId/corrective_actions': typeof OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute;
  '/$organizationSlug/players/$playerId/memberships': typeof OrganizationSlugPlayersPlayerIdMembershipsRoute;
  '/$organizationSlug/players/$playerId/perks': typeof OrganizationSlugPlayersPlayerIdPerksRoute;
  '/$organizationSlug/players/$playerId/referrals': typeof OrganizationSlugPlayersPlayerIdReferralsRoute;
  '/$organizationSlug/reports/caps/$branchId': typeof OrganizationSlugReportsCapsBranchIdRoute;
  '/$organizationSlug/reports/corrective_actions/$branchId': typeof OrganizationSlugReportsCorrectiveactionsBranchIdRoute;
  '/$organizationSlug/blueprints/tabular': typeof OrganizationSlugBlueprintsTabularIndexRoute;
  '/$organizationSlug/mechanics/diseases': typeof OrganizationSlugMechanicsDiseasesIndexRoute;
  '/$organizationSlug/mechanics/societies': typeof OrganizationSlugMechanicsSocietiesIndexRoute;
  '/$organizationSlug/reports/caps': typeof OrganizationSlugReportsCapsIndexRoute;
  '/$organizationSlug/reports/character_retirements': typeof OrganizationSlugReportsCharacterretirementsIndexRoute;
  '/$organizationSlug/reports/corrective_actions': typeof OrganizationSlugReportsCorrectiveactionsIndexRoute;
  '/$organizationSlug/reports/memberships': typeof OrganizationSlugReportsMembershipsIndexRoute;
  '/$organizationSlug/settings/special_dates': typeof OrganizationSlugSettingsSpecialdatesIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId/blueprints/$': typeof OrganizationSlugEventsEventIdBlueprintsSplatRoute;
  '/$organizationSlug/players/$playerId/characters': typeof OrganizationSlugPlayersPlayerIdCharactersIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/*': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/inventory': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/journal': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/printout': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '/calendar': typeof CalendarRoute;
  '/$organizationSlug': typeof OrganizationSlugIndexRoute;
  '/$organizationSlug/branches/$branchId': typeof OrganizationSlugBranchesBranchIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId': typeof OrganizationSlugEventsEventIdRouteRouteWithChildren;
  '/$organizationSlug/players/$playerId': typeof OrganizationSlugPlayersPlayerIdRouteRouteWithChildren;
  '/$organizationSlug/blueprints/$': typeof OrganizationSlugBlueprintsSplatRoute;
  '/$organizationSlug/characters': typeof OrganizationSlugCharactersIndexRoute;
  '/$organizationSlug/events': typeof OrganizationSlugEventsIndexRoute;
  '/$organizationSlug/invoice': typeof OrganizationSlugInvoiceIndexRoute;
  '/$organizationSlug/mechanics': typeof OrganizationSlugMechanicsIndexRoute;
  '/$organizationSlug/reports': typeof OrganizationSlugReportsIndexRoute;
  '/$organizationSlug/statistics': typeof OrganizationSlugStatisticsIndexRoute;
  '/$organizationSlug/branches/$branchId/*': typeof OrganizationSlugBranchesBranchIdRoute;
  '/$organizationSlug/branches/$branchId/attendance': typeof OrganizationSlugBranchesBranchIdAttendanceRoute;
  '/$organizationSlug/branches/$branchId/caps': typeof OrganizationSlugBranchesBranchIdCapsRoute;
  '/$organizationSlug/branches/$branchId/corrective_actions': typeof OrganizationSlugBranchesBranchIdCorrectiveactionsRoute;
  '/$organizationSlug/branches/$branchId/locations': typeof OrganizationSlugBranchesBranchIdLocationsRoute;
  '/$organizationSlug/events/$eventId/*': typeof OrganizationSlugEventsEventIdRoute;
  '/$organizationSlug/events/$eventId/basics': typeof OrganizationSlugEventsEventIdBasicsRoute;
  '/$organizationSlug/events/$eventId/checkout': typeof OrganizationSlugEventsEventIdCheckoutRoute;
  '/$organizationSlug/events/$eventId/logistics': typeof OrganizationSlugEventsEventIdLogisticsRoute;
  '/$organizationSlug/events/$eventId/printouts': typeof OrganizationSlugEventsEventIdPrintoutsRoute;
  '/$organizationSlug/events/$eventId/shifts': typeof OrganizationSlugEventsEventIdShiftsRoute;
  '/$organizationSlug/events/$eventId/tickets': typeof OrganizationSlugEventsEventIdTicketsRoute;
  '/$organizationSlug/events/$eventId/transactions_audit': typeof OrganizationSlugEventsEventIdTransactionsauditRoute;
  '/$organizationSlug/events/$eventId/war_room': typeof OrganizationSlugEventsEventIdWarroomRoute;
  '/$organizationSlug/mechanics/diseases/$fellowshipId': typeof OrganizationSlugMechanicsDiseasesFellowshipIdRoute;
  '/$organizationSlug/mechanics/societies/$fellowshipId': typeof OrganizationSlugMechanicsSocietiesFellowshipIdRoute;
  '/$organizationSlug/players/$playerId/*': typeof OrganizationSlugPlayersPlayerIdRoute;
  '/$organizationSlug/players/$playerId/attendance': typeof OrganizationSlugPlayersPlayerIdAttendanceRoute;
  '/$organizationSlug/players/$playerId/corrective_actions': typeof OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute;
  '/$organizationSlug/players/$playerId/memberships': typeof OrganizationSlugPlayersPlayerIdMembershipsRoute;
  '/$organizationSlug/players/$playerId/perks': typeof OrganizationSlugPlayersPlayerIdPerksRoute;
  '/$organizationSlug/players/$playerId/referrals': typeof OrganizationSlugPlayersPlayerIdReferralsRoute;
  '/$organizationSlug/reports/caps/$branchId': typeof OrganizationSlugReportsCapsBranchIdRoute;
  '/$organizationSlug/reports/corrective_actions/$branchId': typeof OrganizationSlugReportsCorrectiveactionsBranchIdRoute;
  '/$organizationSlug/blueprints/tabular': typeof OrganizationSlugBlueprintsTabularIndexRoute;
  '/$organizationSlug/mechanics/diseases': typeof OrganizationSlugMechanicsDiseasesIndexRoute;
  '/$organizationSlug/mechanics/societies': typeof OrganizationSlugMechanicsSocietiesIndexRoute;
  '/$organizationSlug/reports/caps': typeof OrganizationSlugReportsCapsIndexRoute;
  '/$organizationSlug/reports/character_retirements': typeof OrganizationSlugReportsCharacterretirementsIndexRoute;
  '/$organizationSlug/reports/corrective_actions': typeof OrganizationSlugReportsCorrectiveactionsIndexRoute;
  '/$organizationSlug/reports/memberships': typeof OrganizationSlugReportsMembershipsIndexRoute;
  '/$organizationSlug/settings/special_dates': typeof OrganizationSlugSettingsSpecialdatesIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId/blueprints/$': typeof OrganizationSlugEventsEventIdBlueprintsSplatRoute;
  '/$organizationSlug/players/$playerId/characters': typeof OrganizationSlugPlayersPlayerIdCharactersIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/*': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/inventory': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/journal': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/printout': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/calendar': typeof CalendarRoute;
  '/$organizationSlug/': typeof OrganizationSlugIndexRoute;
  '/$organizationSlug/branches/$branchId': typeof OrganizationSlugBranchesBranchIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId': typeof OrganizationSlugEventsEventIdRouteRouteWithChildren;
  '/$organizationSlug/players/$playerId': typeof OrganizationSlugPlayersPlayerIdRouteRouteWithChildren;
  '/$organizationSlug/blueprints/$': typeof OrganizationSlugBlueprintsSplatRoute;
  '/$organizationSlug/characters/': typeof OrganizationSlugCharactersIndexRoute;
  '/$organizationSlug/events/': typeof OrganizationSlugEventsIndexRoute;
  '/$organizationSlug/invoice/': typeof OrganizationSlugInvoiceIndexRoute;
  '/$organizationSlug/mechanics/': typeof OrganizationSlugMechanicsIndexRoute;
  '/$organizationSlug/reports/': typeof OrganizationSlugReportsIndexRoute;
  '/$organizationSlug/statistics/': typeof OrganizationSlugStatisticsIndexRoute;
  '/$organizationSlug/branches/$branchId/*': typeof OrganizationSlugBranchesBranchIdRoute;
  '/$organizationSlug/branches/$branchId/attendance': typeof OrganizationSlugBranchesBranchIdAttendanceRoute;
  '/$organizationSlug/branches/$branchId/caps': typeof OrganizationSlugBranchesBranchIdCapsRoute;
  '/$organizationSlug/branches/$branchId/corrective_actions': typeof OrganizationSlugBranchesBranchIdCorrectiveactionsRoute;
  '/$organizationSlug/branches/$branchId/locations': typeof OrganizationSlugBranchesBranchIdLocationsRoute;
  '/$organizationSlug/events/$eventId/*': typeof OrganizationSlugEventsEventIdRoute;
  '/$organizationSlug/events/$eventId/basics': typeof OrganizationSlugEventsEventIdBasicsRoute;
  '/$organizationSlug/events/$eventId/checkout': typeof OrganizationSlugEventsEventIdCheckoutRoute;
  '/$organizationSlug/events/$eventId/logistics': typeof OrganizationSlugEventsEventIdLogisticsRoute;
  '/$organizationSlug/events/$eventId/printouts': typeof OrganizationSlugEventsEventIdPrintoutsRoute;
  '/$organizationSlug/events/$eventId/shifts': typeof OrganizationSlugEventsEventIdShiftsRoute;
  '/$organizationSlug/events/$eventId/tickets': typeof OrganizationSlugEventsEventIdTicketsRoute;
  '/$organizationSlug/events/$eventId/transactions_audit': typeof OrganizationSlugEventsEventIdTransactionsauditRoute;
  '/$organizationSlug/events/$eventId/war_room': typeof OrganizationSlugEventsEventIdWarroomRoute;
  '/$organizationSlug/mechanics/diseases/$fellowshipId': typeof OrganizationSlugMechanicsDiseasesFellowshipIdRoute;
  '/$organizationSlug/mechanics/societies/$fellowshipId': typeof OrganizationSlugMechanicsSocietiesFellowshipIdRoute;
  '/$organizationSlug/players/$playerId/*': typeof OrganizationSlugPlayersPlayerIdRoute;
  '/$organizationSlug/players/$playerId/attendance': typeof OrganizationSlugPlayersPlayerIdAttendanceRoute;
  '/$organizationSlug/players/$playerId/corrective_actions': typeof OrganizationSlugPlayersPlayerIdCorrectiveactionsRoute;
  '/$organizationSlug/players/$playerId/memberships': typeof OrganizationSlugPlayersPlayerIdMembershipsRoute;
  '/$organizationSlug/players/$playerId/perks': typeof OrganizationSlugPlayersPlayerIdPerksRoute;
  '/$organizationSlug/players/$playerId/referrals': typeof OrganizationSlugPlayersPlayerIdReferralsRoute;
  '/$organizationSlug/reports/caps/$branchId': typeof OrganizationSlugReportsCapsBranchIdRoute;
  '/$organizationSlug/reports/corrective_actions/$branchId': typeof OrganizationSlugReportsCorrectiveactionsBranchIdRoute;
  '/$organizationSlug/blueprints/tabular/': typeof OrganizationSlugBlueprintsTabularIndexRoute;
  '/$organizationSlug/mechanics/diseases/': typeof OrganizationSlugMechanicsDiseasesIndexRoute;
  '/$organizationSlug/mechanics/societies/': typeof OrganizationSlugMechanicsSocietiesIndexRoute;
  '/$organizationSlug/reports/caps/': typeof OrganizationSlugReportsCapsIndexRoute;
  '/$organizationSlug/reports/character_retirements/': typeof OrganizationSlugReportsCharacterretirementsIndexRoute;
  '/$organizationSlug/reports/corrective_actions/': typeof OrganizationSlugReportsCorrectiveactionsIndexRoute;
  '/$organizationSlug/reports/memberships/': typeof OrganizationSlugReportsMembershipsIndexRoute;
  '/$organizationSlug/settings/special_dates/': typeof OrganizationSlugSettingsSpecialdatesIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRouteRouteWithChildren;
  '/$organizationSlug/events/$eventId/blueprints/$': typeof OrganizationSlugEventsEventIdBlueprintsSplatRoute;
  '/$organizationSlug/players/$playerId/characters/': typeof OrganizationSlugPlayersPlayerIdCharactersIndexRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/*': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/inventory': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdInventoryRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/journal': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdJournalRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/printout': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdPrintoutRoute;
  '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit': typeof OrganizationSlugPlayersPlayerIdCharactersCharacterIdXpauditRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | '/calendar'
    | '/$organizationSlug'
    | '/$organizationSlug/branches/$branchId'
    | '/$organizationSlug/events/$eventId'
    | '/$organizationSlug/players/$playerId'
    | '/$organizationSlug/blueprints/$'
    | '/$organizationSlug/characters'
    | '/$organizationSlug/events'
    | '/$organizationSlug/invoice'
    | '/$organizationSlug/mechanics'
    | '/$organizationSlug/reports'
    | '/$organizationSlug/statistics'
    | '/$organizationSlug/branches/$branchId/*'
    | '/$organizationSlug/branches/$branchId/attendance'
    | '/$organizationSlug/branches/$branchId/caps'
    | '/$organizationSlug/branches/$branchId/corrective_actions'
    | '/$organizationSlug/branches/$branchId/locations'
    | '/$organizationSlug/events/$eventId/*'
    | '/$organizationSlug/events/$eventId/basics'
    | '/$organizationSlug/events/$eventId/checkout'
    | '/$organizationSlug/events/$eventId/logistics'
    | '/$organizationSlug/events/$eventId/printouts'
    | '/$organizationSlug/events/$eventId/shifts'
    | '/$organizationSlug/events/$eventId/tickets'
    | '/$organizationSlug/events/$eventId/transactions_audit'
    | '/$organizationSlug/events/$eventId/war_room'
    | '/$organizationSlug/mechanics/diseases/$fellowshipId'
    | '/$organizationSlug/mechanics/societies/$fellowshipId'
    | '/$organizationSlug/players/$playerId/*'
    | '/$organizationSlug/players/$playerId/attendance'
    | '/$organizationSlug/players/$playerId/corrective_actions'
    | '/$organizationSlug/players/$playerId/memberships'
    | '/$organizationSlug/players/$playerId/perks'
    | '/$organizationSlug/players/$playerId/referrals'
    | '/$organizationSlug/reports/caps/$branchId'
    | '/$organizationSlug/reports/corrective_actions/$branchId'
    | '/$organizationSlug/blueprints/tabular'
    | '/$organizationSlug/mechanics/diseases'
    | '/$organizationSlug/mechanics/societies'
    | '/$organizationSlug/reports/caps'
    | '/$organizationSlug/reports/character_retirements'
    | '/$organizationSlug/reports/corrective_actions'
    | '/$organizationSlug/reports/memberships'
    | '/$organizationSlug/settings/special_dates'
    | '/$organizationSlug/players/$playerId/characters/$characterId'
    | '/$organizationSlug/events/$eventId/blueprints/$'
    | '/$organizationSlug/players/$playerId/characters'
    | '/$organizationSlug/players/$playerId/characters/$characterId/*'
    | '/$organizationSlug/players/$playerId/characters/$characterId/inventory'
    | '/$organizationSlug/players/$playerId/characters/$characterId/journal'
    | '/$organizationSlug/players/$playerId/characters/$characterId/printout'
    | '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | '/calendar'
    | '/$organizationSlug'
    | '/$organizationSlug/branches/$branchId'
    | '/$organizationSlug/events/$eventId'
    | '/$organizationSlug/players/$playerId'
    | '/$organizationSlug/blueprints/$'
    | '/$organizationSlug/characters'
    | '/$organizationSlug/events'
    | '/$organizationSlug/invoice'
    | '/$organizationSlug/mechanics'
    | '/$organizationSlug/reports'
    | '/$organizationSlug/statistics'
    | '/$organizationSlug/branches/$branchId/*'
    | '/$organizationSlug/branches/$branchId/attendance'
    | '/$organizationSlug/branches/$branchId/caps'
    | '/$organizationSlug/branches/$branchId/corrective_actions'
    | '/$organizationSlug/branches/$branchId/locations'
    | '/$organizationSlug/events/$eventId/*'
    | '/$organizationSlug/events/$eventId/basics'
    | '/$organizationSlug/events/$eventId/checkout'
    | '/$organizationSlug/events/$eventId/logistics'
    | '/$organizationSlug/events/$eventId/printouts'
    | '/$organizationSlug/events/$eventId/shifts'
    | '/$organizationSlug/events/$eventId/tickets'
    | '/$organizationSlug/events/$eventId/transactions_audit'
    | '/$organizationSlug/events/$eventId/war_room'
    | '/$organizationSlug/mechanics/diseases/$fellowshipId'
    | '/$organizationSlug/mechanics/societies/$fellowshipId'
    | '/$organizationSlug/players/$playerId/*'
    | '/$organizationSlug/players/$playerId/attendance'
    | '/$organizationSlug/players/$playerId/corrective_actions'
    | '/$organizationSlug/players/$playerId/memberships'
    | '/$organizationSlug/players/$playerId/perks'
    | '/$organizationSlug/players/$playerId/referrals'
    | '/$organizationSlug/reports/caps/$branchId'
    | '/$organizationSlug/reports/corrective_actions/$branchId'
    | '/$organizationSlug/blueprints/tabular'
    | '/$organizationSlug/mechanics/diseases'
    | '/$organizationSlug/mechanics/societies'
    | '/$organizationSlug/reports/caps'
    | '/$organizationSlug/reports/character_retirements'
    | '/$organizationSlug/reports/corrective_actions'
    | '/$organizationSlug/reports/memberships'
    | '/$organizationSlug/settings/special_dates'
    | '/$organizationSlug/players/$playerId/characters/$characterId'
    | '/$organizationSlug/events/$eventId/blueprints/$'
    | '/$organizationSlug/players/$playerId/characters'
    | '/$organizationSlug/players/$playerId/characters/$characterId/*'
    | '/$organizationSlug/players/$playerId/characters/$characterId/inventory'
    | '/$organizationSlug/players/$playerId/characters/$characterId/journal'
    | '/$organizationSlug/players/$playerId/characters/$characterId/printout'
    | '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
  id:
    | '__root__'
    | '/'
    | '/calendar'
    | '/$organizationSlug/'
    | '/$organizationSlug/branches/$branchId'
    | '/$organizationSlug/events/$eventId'
    | '/$organizationSlug/players/$playerId'
    | '/$organizationSlug/blueprints/$'
    | '/$organizationSlug/characters/'
    | '/$organizationSlug/events/'
    | '/$organizationSlug/invoice/'
    | '/$organizationSlug/mechanics/'
    | '/$organizationSlug/reports/'
    | '/$organizationSlug/statistics/'
    | '/$organizationSlug/branches/$branchId/*'
    | '/$organizationSlug/branches/$branchId/attendance'
    | '/$organizationSlug/branches/$branchId/caps'
    | '/$organizationSlug/branches/$branchId/corrective_actions'
    | '/$organizationSlug/branches/$branchId/locations'
    | '/$organizationSlug/events/$eventId/*'
    | '/$organizationSlug/events/$eventId/basics'
    | '/$organizationSlug/events/$eventId/checkout'
    | '/$organizationSlug/events/$eventId/logistics'
    | '/$organizationSlug/events/$eventId/printouts'
    | '/$organizationSlug/events/$eventId/shifts'
    | '/$organizationSlug/events/$eventId/tickets'
    | '/$organizationSlug/events/$eventId/transactions_audit'
    | '/$organizationSlug/events/$eventId/war_room'
    | '/$organizationSlug/mechanics/diseases/$fellowshipId'
    | '/$organizationSlug/mechanics/societies/$fellowshipId'
    | '/$organizationSlug/players/$playerId/*'
    | '/$organizationSlug/players/$playerId/attendance'
    | '/$organizationSlug/players/$playerId/corrective_actions'
    | '/$organizationSlug/players/$playerId/memberships'
    | '/$organizationSlug/players/$playerId/perks'
    | '/$organizationSlug/players/$playerId/referrals'
    | '/$organizationSlug/reports/caps/$branchId'
    | '/$organizationSlug/reports/corrective_actions/$branchId'
    | '/$organizationSlug/blueprints/tabular/'
    | '/$organizationSlug/mechanics/diseases/'
    | '/$organizationSlug/mechanics/societies/'
    | '/$organizationSlug/reports/caps/'
    | '/$organizationSlug/reports/character_retirements/'
    | '/$organizationSlug/reports/corrective_actions/'
    | '/$organizationSlug/reports/memberships/'
    | '/$organizationSlug/settings/special_dates/'
    | '/$organizationSlug/players/$playerId/characters/$characterId'
    | '/$organizationSlug/events/$eventId/blueprints/$'
    | '/$organizationSlug/players/$playerId/characters/'
    | '/$organizationSlug/players/$playerId/characters/$characterId/*'
    | '/$organizationSlug/players/$playerId/characters/$characterId/inventory'
    | '/$organizationSlug/players/$playerId/characters/$characterId/journal'
    | '/$organizationSlug/players/$playerId/characters/$characterId/printout'
    | '/$organizationSlug/players/$playerId/characters/$characterId/xp_audit';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  CalendarRoute: typeof CalendarRoute;
  OrganizationSlugIndexRoute: typeof OrganizationSlugIndexRoute;
  OrganizationSlugBranchesBranchIdRouteRoute: typeof OrganizationSlugBranchesBranchIdRouteRouteWithChildren;
  OrganizationSlugEventsEventIdRouteRoute: typeof OrganizationSlugEventsEventIdRouteRouteWithChildren;
  OrganizationSlugPlayersPlayerIdRouteRoute: typeof OrganizationSlugPlayersPlayerIdRouteRouteWithChildren;
  OrganizationSlugBlueprintsSplatRoute: typeof OrganizationSlugBlueprintsSplatRoute;
  OrganizationSlugCharactersIndexRoute: typeof OrganizationSlugCharactersIndexRoute;
  OrganizationSlugEventsIndexRoute: typeof OrganizationSlugEventsIndexRoute;
  OrganizationSlugInvoiceIndexRoute: typeof OrganizationSlugInvoiceIndexRoute;
  OrganizationSlugMechanicsIndexRoute: typeof OrganizationSlugMechanicsIndexRoute;
  OrganizationSlugReportsIndexRoute: typeof OrganizationSlugReportsIndexRoute;
  OrganizationSlugStatisticsIndexRoute: typeof OrganizationSlugStatisticsIndexRoute;
  OrganizationSlugMechanicsDiseasesFellowshipIdRoute: typeof OrganizationSlugMechanicsDiseasesFellowshipIdRoute;
  OrganizationSlugMechanicsSocietiesFellowshipIdRoute: typeof OrganizationSlugMechanicsSocietiesFellowshipIdRoute;
  OrganizationSlugReportsCapsBranchIdRoute: typeof OrganizationSlugReportsCapsBranchIdRoute;
  OrganizationSlugReportsCorrectiveactionsBranchIdRoute: typeof OrganizationSlugReportsCorrectiveactionsBranchIdRoute;
  OrganizationSlugBlueprintsTabularIndexRoute: typeof OrganizationSlugBlueprintsTabularIndexRoute;
  OrganizationSlugMechanicsDiseasesIndexRoute: typeof OrganizationSlugMechanicsDiseasesIndexRoute;
  OrganizationSlugMechanicsSocietiesIndexRoute: typeof OrganizationSlugMechanicsSocietiesIndexRoute;
  OrganizationSlugReportsCapsIndexRoute: typeof OrganizationSlugReportsCapsIndexRoute;
  OrganizationSlugReportsCharacterretirementsIndexRoute: typeof OrganizationSlugReportsCharacterretirementsIndexRoute;
  OrganizationSlugReportsCorrectiveactionsIndexRoute: typeof OrganizationSlugReportsCorrectiveactionsIndexRoute;
  OrganizationSlugReportsMembershipsIndexRoute: typeof OrganizationSlugReportsMembershipsIndexRoute;
  OrganizationSlugSettingsSpecialdatesIndexRoute: typeof OrganizationSlugSettingsSpecialdatesIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  CalendarRoute: CalendarRoute,
  OrganizationSlugIndexRoute: OrganizationSlugIndexRoute,
  OrganizationSlugBranchesBranchIdRouteRoute:
    OrganizationSlugBranchesBranchIdRouteRouteWithChildren,
  OrganizationSlugEventsEventIdRouteRoute:
    OrganizationSlugEventsEventIdRouteRouteWithChildren,
  OrganizationSlugPlayersPlayerIdRouteRoute:
    OrganizationSlugPlayersPlayerIdRouteRouteWithChildren,
  OrganizationSlugBlueprintsSplatRoute: OrganizationSlugBlueprintsSplatRoute,
  OrganizationSlugCharactersIndexRoute: OrganizationSlugCharactersIndexRoute,
  OrganizationSlugEventsIndexRoute: OrganizationSlugEventsIndexRoute,
  OrganizationSlugInvoiceIndexRoute: OrganizationSlugInvoiceIndexRoute,
  OrganizationSlugMechanicsIndexRoute: OrganizationSlugMechanicsIndexRoute,
  OrganizationSlugReportsIndexRoute: OrganizationSlugReportsIndexRoute,
  OrganizationSlugStatisticsIndexRoute: OrganizationSlugStatisticsIndexRoute,
  OrganizationSlugMechanicsDiseasesFellowshipIdRoute:
    OrganizationSlugMechanicsDiseasesFellowshipIdRoute,
  OrganizationSlugMechanicsSocietiesFellowshipIdRoute:
    OrganizationSlugMechanicsSocietiesFellowshipIdRoute,
  OrganizationSlugReportsCapsBranchIdRoute:
    OrganizationSlugReportsCapsBranchIdRoute,
  OrganizationSlugReportsCorrectiveactionsBranchIdRoute:
    OrganizationSlugReportsCorrectiveactionsBranchIdRoute,
  OrganizationSlugBlueprintsTabularIndexRoute:
    OrganizationSlugBlueprintsTabularIndexRoute,
  OrganizationSlugMechanicsDiseasesIndexRoute:
    OrganizationSlugMechanicsDiseasesIndexRoute,
  OrganizationSlugMechanicsSocietiesIndexRoute:
    OrganizationSlugMechanicsSocietiesIndexRoute,
  OrganizationSlugReportsCapsIndexRoute: OrganizationSlugReportsCapsIndexRoute,
  OrganizationSlugReportsCharacterretirementsIndexRoute:
    OrganizationSlugReportsCharacterretirementsIndexRoute,
  OrganizationSlugReportsCorrectiveactionsIndexRoute:
    OrganizationSlugReportsCorrectiveactionsIndexRoute,
  OrganizationSlugReportsMembershipsIndexRoute:
    OrganizationSlugReportsMembershipsIndexRoute,
  OrganizationSlugSettingsSpecialdatesIndexRoute:
    OrganizationSlugSettingsSpecialdatesIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/calendar",
        "/$organizationSlug/",
        "/$organizationSlug/branches/$branchId",
        "/$organizationSlug/events/$eventId",
        "/$organizationSlug/players/$playerId",
        "/$organizationSlug/blueprints/$",
        "/$organizationSlug/characters/",
        "/$organizationSlug/events/",
        "/$organizationSlug/invoice/",
        "/$organizationSlug/mechanics/",
        "/$organizationSlug/reports/",
        "/$organizationSlug/statistics/",
        "/$organizationSlug/mechanics/diseases/$fellowshipId",
        "/$organizationSlug/mechanics/societies/$fellowshipId",
        "/$organizationSlug/reports/caps/$branchId",
        "/$organizationSlug/reports/corrective_actions/$branchId",
        "/$organizationSlug/blueprints/tabular/",
        "/$organizationSlug/mechanics/diseases/",
        "/$organizationSlug/mechanics/societies/",
        "/$organizationSlug/reports/caps/",
        "/$organizationSlug/reports/character_retirements/",
        "/$organizationSlug/reports/corrective_actions/",
        "/$organizationSlug/reports/memberships/",
        "/$organizationSlug/settings/special_dates/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/calendar": {
      "filePath": "calendar.tsx"
    },
    "/$organizationSlug/": {
      "filePath": "$organizationSlug/index.tsx"
    },
    "/$organizationSlug/branches/$branchId": {
      "filePath": "$organizationSlug/branches/$branchId/route.tsx",
      "children": [
        "/$organizationSlug/branches/$branchId/*",
        "/$organizationSlug/branches/$branchId/attendance",
        "/$organizationSlug/branches/$branchId/caps",
        "/$organizationSlug/branches/$branchId/corrective_actions",
        "/$organizationSlug/branches/$branchId/locations"
      ]
    },
    "/$organizationSlug/events/$eventId": {
      "filePath": "$organizationSlug/events/$eventId/route.tsx",
      "children": [
        "/$organizationSlug/events/$eventId/*",
        "/$organizationSlug/events/$eventId/basics",
        "/$organizationSlug/events/$eventId/checkout",
        "/$organizationSlug/events/$eventId/logistics",
        "/$organizationSlug/events/$eventId/printouts",
        "/$organizationSlug/events/$eventId/shifts",
        "/$organizationSlug/events/$eventId/tickets",
        "/$organizationSlug/events/$eventId/transactions_audit",
        "/$organizationSlug/events/$eventId/war_room",
        "/$organizationSlug/events/$eventId/blueprints/$"
      ]
    },
    "/$organizationSlug/players/$playerId": {
      "filePath": "$organizationSlug/players/$playerId/route.tsx",
      "children": [
        "/$organizationSlug/players/$playerId/*",
        "/$organizationSlug/players/$playerId/attendance",
        "/$organizationSlug/players/$playerId/corrective_actions",
        "/$organizationSlug/players/$playerId/memberships",
        "/$organizationSlug/players/$playerId/perks",
        "/$organizationSlug/players/$playerId/referrals",
        "/$organizationSlug/players/$playerId/characters/$characterId",
        "/$organizationSlug/players/$playerId/characters/"
      ]
    },
    "/$organizationSlug/blueprints/$": {
      "filePath": "$organizationSlug/blueprints/$.tsx"
    },
    "/$organizationSlug/characters/": {
      "filePath": "$organizationSlug/characters/index.tsx"
    },
    "/$organizationSlug/events/": {
      "filePath": "$organizationSlug/events/index.tsx"
    },
    "/$organizationSlug/invoice/": {
      "filePath": "$organizationSlug/invoice/index.tsx"
    },
    "/$organizationSlug/mechanics/": {
      "filePath": "$organizationSlug/mechanics/index.tsx"
    },
    "/$organizationSlug/reports/": {
      "filePath": "$organizationSlug/reports/index.tsx"
    },
    "/$organizationSlug/statistics/": {
      "filePath": "$organizationSlug/statistics/index.tsx"
    },
    "/$organizationSlug/branches/$branchId/*": {
      "filePath": "$organizationSlug/branches/$branchId/*.tsx",
      "parent": "/$organizationSlug/branches/$branchId"
    },
    "/$organizationSlug/branches/$branchId/attendance": {
      "filePath": "$organizationSlug/branches/$branchId/attendance.tsx",
      "parent": "/$organizationSlug/branches/$branchId"
    },
    "/$organizationSlug/branches/$branchId/caps": {
      "filePath": "$organizationSlug/branches/$branchId/caps.tsx",
      "parent": "/$organizationSlug/branches/$branchId"
    },
    "/$organizationSlug/branches/$branchId/corrective_actions": {
      "filePath": "$organizationSlug/branches/$branchId/corrective_actions.tsx",
      "parent": "/$organizationSlug/branches/$branchId"
    },
    "/$organizationSlug/branches/$branchId/locations": {
      "filePath": "$organizationSlug/branches/$branchId/locations.tsx",
      "parent": "/$organizationSlug/branches/$branchId"
    },
    "/$organizationSlug/events/$eventId/*": {
      "filePath": "$organizationSlug/events/$eventId/*.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/basics": {
      "filePath": "$organizationSlug/events/$eventId/basics.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/checkout": {
      "filePath": "$organizationSlug/events/$eventId/checkout.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/logistics": {
      "filePath": "$organizationSlug/events/$eventId/logistics.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/printouts": {
      "filePath": "$organizationSlug/events/$eventId/printouts.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/shifts": {
      "filePath": "$organizationSlug/events/$eventId/shifts.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/tickets": {
      "filePath": "$organizationSlug/events/$eventId/tickets.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/transactions_audit": {
      "filePath": "$organizationSlug/events/$eventId/transactions_audit.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/events/$eventId/war_room": {
      "filePath": "$organizationSlug/events/$eventId/war_room.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/mechanics/diseases/$fellowshipId": {
      "filePath": "$organizationSlug/mechanics/diseases/$fellowshipId.tsx"
    },
    "/$organizationSlug/mechanics/societies/$fellowshipId": {
      "filePath": "$organizationSlug/mechanics/societies/$fellowshipId.tsx"
    },
    "/$organizationSlug/players/$playerId/*": {
      "filePath": "$organizationSlug/players/$playerId/*.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/attendance": {
      "filePath": "$organizationSlug/players/$playerId/attendance.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/corrective_actions": {
      "filePath": "$organizationSlug/players/$playerId/corrective_actions.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/memberships": {
      "filePath": "$organizationSlug/players/$playerId/memberships.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/perks": {
      "filePath": "$organizationSlug/players/$playerId/perks.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/referrals": {
      "filePath": "$organizationSlug/players/$playerId/referrals.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/reports/caps/$branchId": {
      "filePath": "$organizationSlug/reports/caps/$branchId.tsx"
    },
    "/$organizationSlug/reports/corrective_actions/$branchId": {
      "filePath": "$organizationSlug/reports/corrective_actions/$branchId.tsx"
    },
    "/$organizationSlug/blueprints/tabular/": {
      "filePath": "$organizationSlug/blueprints/tabular/index.tsx"
    },
    "/$organizationSlug/mechanics/diseases/": {
      "filePath": "$organizationSlug/mechanics/diseases/index.tsx"
    },
    "/$organizationSlug/mechanics/societies/": {
      "filePath": "$organizationSlug/mechanics/societies/index.tsx"
    },
    "/$organizationSlug/reports/caps/": {
      "filePath": "$organizationSlug/reports/caps/index.tsx"
    },
    "/$organizationSlug/reports/character_retirements/": {
      "filePath": "$organizationSlug/reports/character_retirements/index.tsx"
    },
    "/$organizationSlug/reports/corrective_actions/": {
      "filePath": "$organizationSlug/reports/corrective_actions/index.tsx"
    },
    "/$organizationSlug/reports/memberships/": {
      "filePath": "$organizationSlug/reports/memberships/index.tsx"
    },
    "/$organizationSlug/settings/special_dates/": {
      "filePath": "$organizationSlug/settings/special_dates/index.tsx"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/route.tsx",
      "parent": "/$organizationSlug/players/$playerId",
      "children": [
        "/$organizationSlug/players/$playerId/characters/$characterId/*",
        "/$organizationSlug/players/$playerId/characters/$characterId/inventory",
        "/$organizationSlug/players/$playerId/characters/$characterId/journal",
        "/$organizationSlug/players/$playerId/characters/$characterId/printout",
        "/$organizationSlug/players/$playerId/characters/$characterId/xp_audit"
      ]
    },
    "/$organizationSlug/events/$eventId/blueprints/$": {
      "filePath": "$organizationSlug/events/$eventId/blueprints/$.tsx",
      "parent": "/$organizationSlug/events/$eventId"
    },
    "/$organizationSlug/players/$playerId/characters/": {
      "filePath": "$organizationSlug/players/$playerId/characters/index.tsx",
      "parent": "/$organizationSlug/players/$playerId"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId/*": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/*.tsx",
      "parent": "/$organizationSlug/players/$playerId/characters/$characterId"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId/inventory": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/inventory.tsx",
      "parent": "/$organizationSlug/players/$playerId/characters/$characterId"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId/journal": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/journal.tsx",
      "parent": "/$organizationSlug/players/$playerId/characters/$characterId"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId/printout": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/printout.tsx",
      "parent": "/$organizationSlug/players/$playerId/characters/$characterId"
    },
    "/$organizationSlug/players/$playerId/characters/$characterId/xp_audit": {
      "filePath": "$organizationSlug/players/$playerId/characters/$characterId/xp_audit.tsx",
      "parent": "/$organizationSlug/players/$playerId/characters/$characterId"
    }
  }
}
ROUTE_MANIFEST_END */
