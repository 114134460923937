import { FC } from 'react';
import { IPropsWithOnUpdate } from '../../types';
import SingleItemEditor from '../SingleItemEditor';

type TOutput = {
  id: number;
  name: string;
  metadata: {
    mechanics?: string | null;
    requirementsToUse?: string | null;
  };
};
export type TItemCraftingOutputUsageRequirement = {
  id: number;
  craftingFinalProducts?: {
    id: number;
    finalProduct: TOutput & {
      childItemClassifications: {
        id: number;
        childItem: TOutput;
      }[];
    };
  }[];
};

interface IProps extends IPropsWithOnUpdate {
  itemCraftings: TItemCraftingOutputUsageRequirement[];
}

const UsageRequirements: FC<IProps> = ({ itemCraftings, onUpdate }) => {
  const craftingFinalProducts = itemCraftings
    ? itemCraftings[0]?.craftingFinalProducts
    : null;
  const finalProduct = craftingFinalProducts
    ? craftingFinalProducts[0]?.finalProduct
    : null;
  const actualProducts = finalProduct
    ? finalProduct.childItemClassifications.length > 1
      ? finalProduct.childItemClassifications.map(x => x.childItem)
      : [ finalProduct ]
    : null;

  if (!actualProducts) return <td />;

  return (
    <td>
      {actualProducts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(product => (
          <SingleItemEditor
            key={product.id}
            itemId={product.id}
            field="requirementsToUse"
            initialValue={product.metadata.requirementsToUse}
            onUpdate={onUpdate}
          />
        ))}
    </td>
  );
};

export default UsageRequirements;
