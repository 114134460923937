import { useSearch } from '@tanstack/react-router';
import { FC } from 'react';
import DystopiaRisingPrintout from 'src/components/0500_printouts/dystopia_rising';
import useCharacter from 'src/hooks/characters/useCharacter';
import usePermission from 'src/hooks/permissions/usePermissions';

const Printout: FC = () => {
  const { player_id: playerId, character_id: characterId } = useSearch({
    strict: false,
  });
  const { isPermitted: canPrint } = usePermission({
    action: 'update_character_data_sensitive',
    characterId: Number(characterId),
  });

  const character = useCharacter({
    playerId: Number(playerId),
    characterId: Number(characterId),
  });

  if (!canPrint) return null;

  return (
    <div className="printout-container">
      <DystopiaRisingPrintout {...character} />
    </div>
  );
};

export default Printout;
