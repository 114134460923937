import { FC } from 'react';
import Tab from 'src/components/0200_tab';
import useAuth from 'src/hooks/auths/useAuth';
import PlayerHeader from './PlayerHeader';
import OrganizationHeader from './OrganizationHeader';
import CharacterHeader from './CharacterHeader';
import EventsHeader from './EventsHeader';
import EventHeader from './EventHeader';
import BlueprintsHeader from './BlueprintsHeader';
import BranchHeader from './BranchHeader';
import MechanicsHeader from './MechanicsHeader';
import ReportsHeader from './ReportsHeader';
import OrganizationSettingsHeader from './OrganizationSettingsHeader';

const Header: FC = () => {
  const { openModal, isRootUserValidated } = useAuth();

  if (!isRootUserValidated)
    return (
      <div className="flex justify-end pt-1">
        <div>
          <Tab label="Sign In" onClick={() => openModal({ modal: 'login' })} />
        </div>
      </div>
    );

  return (
    <div className="flex justify-end">
      <BranchHeader />
      <BlueprintsHeader />
      <CharacterHeader />
      <EventHeader />
      <EventsHeader />
      <MechanicsHeader />
      <OrganizationHeader />
      <OrganizationSettingsHeader />
      <PlayerHeader />
      <ReportsHeader />
    </div>
  );
};

export default Header;
