import { FC } from 'react';
import clsx from 'clsx';
import EmbossAndReproduction from '../EmbossAndReproduction';

import '../index.css';
import Security from '../Security';

interface IProps {
  id: number;
  fingerprint: string;
  name: string;
  updatedAt: string;
  metadata: {
    printHeader?: string | null;
    mechanics?: string | null;
    notes?: string | null;
    requirementsToUse?: string | null;
    durationOfEffect?: string | null;
    validTargetDescription?: string | null;
    locationOfUse?: string | null;
    equipmentRequiredForUse?: string | null;
    durationOfRoleplay?: string | null;
    descriptionOfRoleplay?: string | null;
    activationRequirement?: string | null;
  };
  itemReproduction?: {
    id: number;
    reproductionTimeInMinute?: number | null;
    reproductionMindCost?: number | null;
    reproductionSkills?: string | null;
    metadata: {
      notes?: string | null;
    };
    itemReproductionComponents: {
      id: number;
      amount: number;
      item: {
        id: number;
        name: string;
      };
    }[];
  };
}

const Procedure: FC<IProps> = ({ fingerprint, ...procedure }) => (
  <div
    className={clsx(
      'printout benediction border border-juno-gray-700 overflow-hidden',
      procedure.metadata.printHeader?.match(/benediction/i) && 'benediction',
      procedure.metadata.printHeader?.match(/necrology/i) && 'necrology',
    )}
  >
    <div className="printout-wrapper">
      <div className="border-b border-juno-gray-700 printout-header">
        {procedure.metadata.printHeader}
      </div>
      <div className="border-b border-juno-gray-700 text-xl h-[6.84in]">
        <div className="border-b border-juno-gray-700 p-2">
          <table className="header">
            <tbody>
              <tr>
                <td>Procedure Name</td>
                <td>{procedure.name}</td>
              </tr>
              <tr>
                <td>Requirements To Use</td>
                <td>{procedure.metadata.requirementsToUse}</td>
              </tr>
              <tr>
                <td>Duration of Effect</td>
                <td>{procedure.metadata.durationOfEffect}</td>
              </tr>
              <tr>
                <td>Valid Target Description</td>
                <td>{procedure.metadata.validTargetDescription}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="border-b border-juno-gray-700 p-2">
          <table className="header">
            <tbody>
              <tr>
                <td>Location of Use</td>
                <td>{procedure.metadata.locationOfUse}</td>
              </tr>
              <tr>
                <td>Equipment Required for Use</td>
                <td>{procedure.metadata.equipmentRequiredForUse}</td>
              </tr>
              <tr>
                <td>Resource Expended in Use</td>
                <td>None</td>
              </tr>
              <tr>
                <td>Duration of Role-play</td>
                <td>{procedure.metadata.durationOfRoleplay}</td>
              </tr>
              <tr>
                <td>Mind/Resolve to Activate</td>
                <td>{procedure.metadata.activationRequirement}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="grid gap-2 p-2">
          <div>
            <div className="pl-2 font-semibold text-xl">
              Description of Role-play
            </div>
            <div className="pl-2 text-sm min-h-[32px] whitespace-pre-wrap">
              {procedure.metadata.descriptionOfRoleplay}
            </div>
          </div>
          <div>
            <div className="pl-2 font-semibold text-xl">Mechanics</div>
            <div className="pl-2 text-sm min-h-[32px] whitespace-pre-wrap">
              {procedure.metadata.mechanics}
            </div>
          </div>
          <div>
            <div className="pl-2 font-semibold text-xl">Special Notes</div>
            <div className="pl-2 text-sm min-h-[32px] whitespace-pre-wrap">
              {procedure.metadata.notes}
            </div>
          </div>
        </div>
      </div>

      <EmbossAndReproduction itemReproduction={procedure.itemReproduction} />
    </div>
    <div className="printout-footer" />
    <Security version={procedure.updatedAt} fingerprint={fingerprint} />
  </div>
);

export default Procedure;
