import { FC, useCallback, useState } from 'react';
import usePermission from 'src/hooks/permissions/usePermissions';
import Title from 'src/components/0100_title';
import useOrganizationBranches from 'src/hooks/organizations/branches/useOrganizationBranches';
import Loading from 'src/components/0100_loading';
import Currency from 'src/components/0100_currency';
import StreamingProgress from './StreamingProgress';
import BranchInvoice from './BranchInvoices';

const Invoice: FC = () => {
  const [ operationInProgress, setOperationInProgress ] =
    useState<string>('idle');
  const { isPermitted: canManageOrganization } = usePermission({
    action: 'manage_organization',
  });
  const { branches, fetching } = useOrganizationBranches({});
  const [ expansion, setExpansion ] = useState<
    'collapseAll' | 'expandAll' | null
  >(null);
  const [ showAllBalance, setShowAllBalance ] = useState(true);
  const [ revenues, setRevenues ] = useState<Record<number, number>>({});
  const updateRevenues = useCallback(
    ({ branchId, balance }: { branchId: number; balance: number }) => {
      setRevenues(x => ({ ...x, [branchId]: balance }));
    },
    [],
  );

  if (!canManageOrganization) return null;
  if (fetching) return <Loading />;

  return (
    <div className="grid grid-cols-1 gap-4">
      <Title title="Invoicing System" />
      <StreamingProgress
        isBusy={![ 'idle', 'snapshot' ].includes(operationInProgress)}
        endpoint="event_attendances"
        name="Snapshot"
        onBusy={isBusy => setOperationInProgress(isBusy ? 'snapshot' : 'idle')}
      />
      <StreamingProgress
        isBusy={![ 'idle', 'diff' ].includes(operationInProgress)}
        endpoint="diff"
        name="Diff"
        onBusy={isBusy => setOperationInProgress(isBusy ? 'diff' : 'idle')}
      />
      <div className="text-right">
        <div className="text-xs uppercase text-juno-gray-200 leading-3">
          Total Revenue
        </div>
        <div className="text-xl font-bold">
          <Currency
            value={Object.values(revenues).reduce((a, b) => a + b, 0)}
          />
        </div>
      </div>
      <div className="border-b border-juno-gray-700 flex justify-end gap-2">
        <div className="text-juno-gray-200">All:</div>
        <button
          type="button"
          className="underline"
          onClick={() => setExpansion('collapseAll')}
        >
          Collapse
        </button>
        <button
          type="button"
          className="underline"
          onClick={() => setExpansion('expandAll')}
        >
          Expand
        </button>

        <div className="text-juno-gray-200">Balance:</div>
        <button
          type="button"
          className="underline"
          onClick={() => setShowAllBalance(x => !x)}
        >
          {showAllBalance ? 'All' : 'Non-Zero'}
        </button>
      </div>
      {branches
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(branch => (
          <BranchInvoice
            key={branch.id}
            {...branch}
            expansion={expansion}
            showAllBalance={showAllBalance}
            onLoad={updateRevenues}
          />
        ))}
    </div>
  );
};

export default Invoice;
